import React from 'react'
import { Button } from 'components/Button/Button'
import { Field, Formik, FieldProps } from 'formik'
import * as yup from 'yup'
import * as api from 'api'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { AxiosResponse } from 'typings/axios'
import { TextArea } from 'components/TextArea/TextArea'
import { TextInput } from 'components/TextInput/TextInput'
import { MainstayModal, ModalSize } from 'components/Modal/Modal'
import { HiddenInput } from 'components/HiddenInput/HiddenInput'

const SFTP_HOST_NAME = 'sftp.data.admithub.com'

interface IConfigureSFTPModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (publicKey: string | undefined) => void
}

const publicKeyValidationSchema = yup.object().shape({
  publicKey: yup
    .string()
    .matches(/^ssh-rsa/, 'Public Key must start with "ssh-rsa"')
    .min(384, 'Public Key must be 3072 bits (at least 384 characters)'),
})

const SFTPModalInitialValues = { publicKey: undefined }
interface ISFTPModalValues {
  publicKey: string | undefined
}

export const ConfigureSFTPModal = ({
  isOpen,
  onClose,
  onSubmit,
}: IConfigureSFTPModalProps) => {
  const handleSubmit = (values: ISFTPModalValues) => onSubmit(values.publicKey)

  return (
    <Formik
      enableReinitialize
      initialValues={SFTPModalInitialValues}
      validationSchema={publicKeyValidationSchema}
      onSubmit={handleSubmit}
      render={() => {
        return (
          <MainstayModal
            wrapContentInForm
            hideFooter
            className="main"
            contentClassName="pb-4"
            onClose={onClose}
            show={isOpen}
            text="Configure SFTP">
            <p className="mb-2">
              If available, please paste your SSH Public Key below. It should
              follow the OpenSSH public key format and begin with 'ssh-rsa'. If
              you don't have access to this key, leave this field blank.
            </p>
            <Field
              name="publicKey"
              type="textarea"
              render={({ form, field }: FieldProps<ISFTPModalValues>) => {
                return (
                  <div>
                    <TextArea
                      className="mb-2"
                      onChange={field.onChange}
                      name="publicKey"
                      id="publicKey"
                      value={form.values.publicKey}
                    />
                    {form.touched.publicKey && form.errors.publicKey && (
                      <div className="alert alert-danger mt-3">
                        {form.errors.publicKey}
                      </div>
                    )}
                  </div>
                )
              }}
            />
            <div className="d-flex justify-content-end pt-3">
              <Button
                className="btn btn-secondary-teal px-3 py-2"
                type="submit">
                Configure
              </Button>
            </div>
          </MainstayModal>
        )
      }}
    />
  )
}

interface ISFTPCredsModalProps {
  userName: string
  isOpen: boolean
  onClose: () => void
  publicKey: string | null
  password?: string
  onDelete: () => void
}

interface ISFTPCreds {
  userName: string
  publicKey: string | null
  password: string
}

export const SFTPCredsModal = ({
  userName,
  isOpen,
  onClose,
  publicKey,
  password,
  onDelete,
}: ISFTPCredsModalProps) => {
  return (
    <MainstayModal
      hideFooter
      className="main"
      contentClassName="pb-4"
      show={isOpen}
      size={ModalSize.Medium}
      text="Your SFTP Credentials"
      onClose={onClose}
      onSubmit={onDelete}
      submitText="Delete"
      cancelText="Cancel">
      <div className="container">
        <div className="row mb-1 d-flex align-items-center justify-content-between">
          <div className="col">Protocol:</div>
          <TextInput readOnly={true} value="SFTP" className="col col-9 pr-3" />
        </div>
        <div className="row mb-1 d-flex align-items-center justify-content-between">
          <div className="col">Hostname:</div>
          <TextInput
            readOnly={true}
            value={SFTP_HOST_NAME}
            className="col col-9 pr-3"
          />
        </div>
        <div className="row mb-1 d-flex align-items-center justify-content-between">
          <div className="col">Username:</div>
          <TextInput
            readOnly={true}
            value={userName}
            className="col col-9 pr-3"
          />
        </div>
        <div className="row mb-1 d-flex align-items-center justify-content-between">
          <div className="col">Password:</div>
          <HiddenInput
            readOnly={true}
            value={password}
            className="col col-9 pr-3"
          />
        </div>
        <div className="row mb-1 d-flex align-items-center justify-content-between">
          <div className="col">Port:</div>
          <TextInput readOnly={true} value="22" className="col col-9 pr-3" />
        </div>
        <div className="row mt-2 mb-1 d-flex align-items-center justify-content-between">
          <div className="col col-6">Public Key Used:</div>
          <TextArea
            readOnly={true}
            value={publicKey || ''}
            className="ml-2 mb-1 pr-3"
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between ml-2">
        <Button color="primary" onClick={onClose}>
          Close
        </Button>
        <Button color="danger" className="ml-2" onClick={onDelete}>
          Delete
        </Button>
      </div>
    </MainstayModal>
  )
}

export const SFTPConfiguration = () => {
  const [showSFTPConfigureModal, setShowSFTPConfigureModal] = React.useState<
    boolean
  >(false)
  const [showSFTPCredsModal, setShowSFTPCredsModal] = React.useState<boolean>(
    false
  )

  const [SFTPUserData, setSFTPUserData] = React.useState<ISFTPCreds>()

  const handleSubmit = async (publicKey: string | undefined = undefined) => {
    try {
      const res: AxiosResponse<{
        user_name: string
        password: string
        public_key: string | null
      }> = await api.createSFTPUser(publicKey)
      setSFTPUserData({
        ...SFTPUserData,
        userName: res.data.user_name,
        password: res.data.password,
        publicKey: res.data.public_key,
      })
      setShowSFTPConfigureModal(false)
      setShowSFTPCredsModal(true)
    } catch (err) {
      toast('There was an error creating your SFTP user', {
        type: 'error',
        options: {
          autoClose: 2000,
        },
      })
    }
  }

  const checkForExistingUser = async () => {
    try {
      const res = await api.getSFTPUser()
      res.data
        ? (setSFTPUserData({
            ...SFTPUserData,
            userName: res.data.user_name,
            password: res.data.password,
            publicKey: res.data.public_key,
          }),
          setShowSFTPCredsModal(true))
        : setShowSFTPConfigureModal(true)
    } catch (err) {
      toast('There was an error fetching an existing SFTP user', {
        type: 'error',
        options: {
          autoClose: 2000,
        },
      })
    }
  }

  const deleteUser = async () => {
    try {
      if (!confirm('Are you sure you want to delete this credential?')) {
        return
      }
      await api.deleteSFTPUser()
      setShowSFTPCredsModal(false)
      setSFTPUserData(undefined)
      toast('SFTP user deleted', {
        type: 'success',
        options: { autoClose: 2000 },
      })
    } catch (err) {
      setShowSFTPCredsModal(false)
      toast('There was an error deleting your SFTP user', {
        type: 'error',
        options: {
          autoClose: 2000,
        },
      })
    }
  }

  return (
    <>
      <Button color="primary" className="mr-2" onClick={checkForExistingUser}>
        Configure SFTP
      </Button>
      <ConfigureSFTPModal
        isOpen={showSFTPConfigureModal}
        onClose={() => setShowSFTPConfigureModal(false)}
        onSubmit={handleSubmit}
      />
      {!!SFTPUserData?.userName && (
        <SFTPCredsModal
          userName={SFTPUserData.userName}
          password={SFTPUserData.password}
          publicKey={SFTPUserData.publicKey}
          isOpen={showSFTPCredsModal}
          onClose={() => setShowSFTPCredsModal(false)}
          onDelete={deleteUser}
        />
      )}
    </>
  )
}
