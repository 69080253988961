import * as nope from 'yup'
import {
  ICampaignSchedulerFormData,
  TriggerType,
  StudentSelectionType,
  IDataTriggeredSettings,
  MINIMUM_DTC_MINUTES_DELAY,
} from 'store/campaign-scheduler/reducer'
import moment from 'moment-timezone'
import { DHMToSeconds } from 'util/timeConversion'
import { IContactLabel } from 'store/contacts/reducer'
import { RepeatInterval } from 'util/dateRepeatIntervals'

const campaignSchedulerSchema = (
  timezone: string
): nope.Schema<ICampaignSchedulerFormData> => {
  return nope.object({
    name: nope
      .string()
      .trim()
      .required(),
    description: nope
      .string()
      .trim()
      .required(),
    date: nope.string().when('triggerType', {
      is: val =>
        val === TriggerType.DataTriggered || val === TriggerType.Immediate,
      then: nope.string(),
      otherwise: nope
        .string()
        .required('You must choose a date.')
        .test(
          'scheduled-in-past',
          'Cannot schedule a campaign in the past!',
          (value: string): boolean => {
            if (!value) {
              return false
            }
            const setDateTime = moment.tz(value, timezone)
            return setDateTime.isAfter(moment.tz(timezone))
          }
        ),
    }),
    importAutoLabel: nope.string(),
    immediate: nope.boolean(),
    recurrenceSettings: nope.object().when('triggerType', {
      is: val => val === TriggerType.Recurring,
      then: nope.object().shape({
        interval: nope
          .mixed()
          .oneOf([
            RepeatInterval.daily,
            RepeatInterval.weekly,
            RepeatInterval.monthly,
            RepeatInterval.weekends,
            RepeatInterval.weekdays,
            null,
          ]),
        infinite: nope.boolean().nullable(),
        endDate: nope.string().when(['interval', 'infinite'], {
          is: (interval: RepeatInterval, infinite: boolean) =>
            !interval || (interval && !infinite),
          then: nope.string().required('You must choose an end date.'),
          otherwise: nope.string().nullable(true),
        }),
      }),
    }),
    dialogId: nope
      .string()
      .trim()
      .required('You must select a valid campaign script.'),
    studentSelectionType: nope
      .mixed()
      .required('You must select a valid contact group.')
      .when('triggerType', {
        is: val => val === TriggerType.DataTriggered,
        then: nope
          .mixed()
          .oneOf(
            [StudentSelectionType.ContactSegment],
            'You must select an audience.'
          ),
        otherwise: nope
          .mixed()
          .oneOf([
            StudentSelectionType.CustomImportLabel,
            StudentSelectionType.ImportLabel,
            StudentSelectionType.UploadCSV,
            StudentSelectionType.ContactSegment,
            StudentSelectionType.MultiAudience,
          ]),
      }),
    multiContactFilters: nope.array().of(nope.number()),
    multiContactFilterDelay: nope.number().moreThan(-1),
    dataTriggeredSettings: nope
      .object()
      .test(
        'dataTriggeredSettings',
        `Time delay must be at least ${MINIMUM_DTC_MINUTES_DELAY} minutes`,
        (value: IDataTriggeredSettings) => {
          const { timeDelay } = value

          if (!timeDelay) {
            return false
          }

          return DHMToSeconds(timeDelay) >= MINIMUM_DTC_MINUTES_DELAY * 60
        }
      ),
    triggerType: nope
      .mixed()
      .oneOf([
        TriggerType.Immediate,
        TriggerType.SpecificDate,
        TriggerType.DataTriggered,
        TriggerType.Recurring,
      ])
      .required('You must select a valid trigger.'),
    importReportId: nope
      .string()
      .when(['studentSelectionType', 'triggerType'], {
        is: (
          studentSelectionType: StudentSelectionType,
          triggerType: TriggerType
        ) =>
          studentSelectionType === StudentSelectionType.UploadCSV &&
          triggerType !== TriggerType.DataTriggered,
        then: nope
          .string()
          .trim()
          .required('You must create a valid import.'),
      }),
    importLabels: nope
      .array()
      .of(nope.string().trim())
      .when('studentSelectionType', {
        is: (value: StudentSelectionType) =>
          value === StudentSelectionType.ImportLabel,
        then: nope
          .array()
          .of(nope.string().trim())
          .min(1, 'You must select at least one import label.'),
      }),
    selectedAutoLabels: nope.array().of(nope.string()),
    contactLabels: nope
      .array()
      .of(
        nope.object<Omit<IContactLabel, 'count'>>().shape({
          id: nope.string().required(),
          text: nope.string().required(),
          institutionId: nope.string().required(),
          createdAt: nope.string().required(),
          createdBy: nope.string().required(),
        })
      )
      .when('studentSelectionType', {
        is: StudentSelectionType.CustomImportLabel,
        then: nope
          .array()
          .of(
            nope.object<Omit<IContactLabel, 'count'>>().shape({
              id: nope.string().required(),
              text: nope.string().required(),
              institutionId: nope.string().required(),
              createdAt: nope.string().required(),
              createdBy: nope.string().required(),
            })
          )
          .min(1, 'You must select at least one import label.'),
      }),
  })
}

export default campaignSchedulerSchema
