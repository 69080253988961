import * as api from 'api'
import cls from 'classnames'
import { Button } from 'components/Button/Button'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import {
  KnowledgeReviewMark,
  knowledgeReviewRouterParamToEnum,
} from 'page/conversations-v2/ConversationsDetail'
import { MarkForKnowledgeReviewModal } from 'page/conversations-v2/ConversationsDetail/MessageRow/MarkForKnowledgeReviewModal/MarkForKnowledgeReviewModal'
import {
  resetCheckboxList,
  setCheckboxActionLoading,
  updateSelectedItems,
} from 'page/knowledge-base/KnowledgeReview/actions'
import { getKnowledgeBaseReviewItems } from 'page/knowledge-base/KnowledgeReview/selectors'
import { fetchKnowledgeReviewItems } from 'page/knowledge-base/KnowledgeReview/thunks'
import pluralize from 'pluralize'
import React from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'util/hooks'

export const KnowledgeReviewCheckboxHeader = ({
  isAllMessageTab,
  fetchCounts,
  count,
}: {
  readonly isAllMessageTab: boolean
  readonly fetchCounts: () => void
  readonly count: number
}): JSX.Element => {
  const {
    checkboxList: { selectedIds, deselectedIds, all, isLoading },
  } = useSelector(getKnowledgeBaseReviewItems)
  const dispatch = useDispatch()
  const routerParams = useParams<{
    readonly reason: string
  }>()
  const cancelResolvingRef = React.useRef<boolean>(false)
  const editItemsRef = React.useRef<boolean>(false)
  const [showModal, setShowModal] = React.useState<boolean>()

  const countToDisplay = all ? count - deselectedIds.length : selectedIds.length

  const onResolveUndo = React.useCallback(() => {
    cancelResolvingRef.current = true
    editItemsRef.current = true
    toast.dismiss()
    dispatch(setCheckboxActionLoading(false))
  }, [dispatch])

  const resolve = React.useCallback(async () => {
    dispatch(updateSelectedItems.request())
    await api.knowledgeBaseItemsMarkAsReviewed({
      all,
      selectedIds,
      deselectedIds,
    })
    await fetchKnowledgeReviewItems(dispatch)(null, routerParams.reason)
    dispatch(resetCheckboxList())
    dispatch(updateSelectedItems.success())
  }, [dispatch, all, selectedIds, deselectedIds, routerParams.reason])

  const editItems = React.useCallback(
    async (mark: KnowledgeReviewMark) => {
      dispatch(updateSelectedItems.request())
      await api.conversationsV2MarkForKnowledgeReviewBatch({
        all,
        selectedIds,
        deselectedIds,
        mark,
      })
      await fetchKnowledgeReviewItems(dispatch)(null, routerParams.reason)
      dispatch(resetCheckboxList())
      dispatch(updateSelectedItems.success())
    },
    [dispatch, all, selectedIds, deselectedIds, routerParams.reason]
  )

  const onResolve = React.useCallback(() => {
    cancelResolvingRef.current = false
    dispatch(setCheckboxActionLoading(true))
    toast.success(
      `${countToDisplay} ${pluralize('item', countToDisplay)} resolved`,
      {
        link: {
          text: 'Undo',
          onClick: () => onResolveUndo(),
        },
        options: {
          autoClose: 5000,
          onClose: async () => {
            if (cancelResolvingRef.current) {
              return
            }

            await resolve()
            fetchCounts()
            dispatch(setCheckboxActionLoading(false))
          },
        },
      }
    )
  }, [onResolveUndo, countToDisplay, dispatch, resolve, fetchCounts])

  const onEditItems = React.useCallback(
    (mark: KnowledgeReviewMark | null) => {
      editItemsRef.current = false
      dispatch(setCheckboxActionLoading(true))
      toast.success(
        `${countToDisplay} ${pluralize('item', countToDisplay)} updated`,
        {
          link: {
            text: 'Undo',
            onClick: () => onResolveUndo(),
          },
          options: {
            autoClose: 5000,
            onClose: async () => {
              if (editItemsRef.current || !mark) {
                return
              }

              await editItems(mark)
              fetchCounts()
              dispatch(setCheckboxActionLoading(false))
            },
          },
        }
      )
    },
    [countToDisplay, dispatch, onResolveUndo, editItems, fetchCounts]
  )

  const onEdit = () => {
    setShowModal(true)
  }

  const reason = knowledgeReviewRouterParamToEnum(routerParams.reason)
  return (
    <div className="d-flex align-items-center">
      <span className="mr-1">{countToDisplay}</span>of{' '}
      <span className="ml-1">{count}</span> <span className="mx-3">|</span>
      {!isAllMessageTab && (
        <Button
          disabled={isLoading}
          color="link"
          className="p-0 d-flex align-items-center text-decoration-none"
          onClick={onResolve}>
          <AHIcon name="delete" overrideWidth={true} />
          <span className="ml-2">Delete</span>
        </Button>
      )}
      <Button
        disabled={isLoading}
        color="link"
        className={cls('p-0 d-flex align-items-center text-decoration-none', {
          'ml-4': !isAllMessageTab,
        })}
        onClick={onEdit}>
        <AHIcon name="edit" overrideWidth={true} />
        <span className="ml-2">Edit</span>
      </Button>
      {showModal && (
        <MarkForKnowledgeReviewModal
          loading={false}
          modalVisible={showModal}
          toggleModal={() => setShowModal(prev => !prev)}
          applyKnowledgeReviewMark={data => onEditItems(data)}
          bulkEdit={true}
          countToDisplay={countToDisplay}
          knowledgeReviewMark={
            reason
              ? {
                  reason,
                  note: '',
                }
              : null
          }
        />
      )}
    </div>
  )
}
