import * as React from 'react'
import { secondsToDHMS } from 'util/timeConversion'

export interface ICountdownTimerProps {
  startingSeconds: number
  className?: string
  countdownAction: () => void
}

export interface ICountdownTimerState {
  seconds: number
}

export class CountdownTimer extends React.PureComponent<
  ICountdownTimerProps,
  ICountdownTimerState
> {
  state = {
    seconds: 0,
  }
  interval?: NodeJS.Timer

  componentDidMount = () => {
    this.setState({ seconds: this.props.startingSeconds })
    this.interval = setInterval(this.decrementTimer, 1000)
  }

  decrementTimer = () => {
    const currentSeconds = this.state.seconds - 1
    if (this.state.seconds > 0) {
      this.setState({ seconds: currentSeconds })
    } else if (this.interval) {
      clearInterval(this.interval)
      this.props.countdownAction()
    }
  }

  render() {
    const { minutes, seconds } = secondsToDHMS(this.state.seconds)
    return (
      <div className={this.props.className}>
        {`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
      </div>
    )
  }
}
