import {
  getInstitutionAttributesTable,
  getInstitutionAttrsCountByType,
  getInstitutionAttrsCount,
} from 'store/personalization/institutionAttributes/actions'
import { Dispatch } from 'store/store'
import * as Api from 'api'
import { AxiosError } from 'typings/axios'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { toastOnHttpError500or400 } from 'api/http'
import { IGetAttributesRequestData } from 'api/request'

export const getInstitutionAttributesAsync = (dispatch: Dispatch) => {
  return (
    data: IGetAttributesRequestData,
    shouldAppendResults: boolean = false
  ) => {
    dispatch(getInstitutionAttributesTable.request())
    return Api.fetchInstitutionAttributes(data)
      .then(response => {
        dispatch(
          getInstitutionAttributesTable.success([
            response.data.results,
            shouldAppendResults,
          ])
        )
        dispatch(getInstitutionAttrsCount.success(response.data.count))
      })
      .catch((err: AxiosError) => {
        if (err.response && err.response.status === 404) {
          toast('Failed to fetch institution attributes.', {
            type: 'error',
            options: { autoClose: 1000 },
          })
        }
        toastOnHttpError500or400(err)
        dispatch(getInstitutionAttributesTable.failure(err))
      })
  }
}

export const getInstitutionAttrsCountByTypeAsync = (dispatch: Dispatch) => {
  return () => {
    dispatch(getInstitutionAttrsCountByType.request())
    return Api.fetchInstitutionAttributeCountsByType()
      .then(response => {
        dispatch(getInstitutionAttrsCountByType.success(response.data))
      })
      .catch((err: AxiosError) => {
        if (err.response && err.response.status === 404) {
          toast('Failed to fetch institution attributes.', {
            type: 'error',
            options: { autoClose: 1000 },
          })
        }
        toastOnHttpError500or400(err)
        dispatch(getInstitutionAttrsCountByType.failure(err))
      })
  }
}
