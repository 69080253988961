export function GroupIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path
          id="Vector"
          d="M16 11C16.5933 11 17.1734 10.8241 17.6667 10.4944C18.1601 10.1648 18.5446 9.69623 18.7716 9.14805C18.9987 8.59987 19.0581 7.99667 18.9424 7.41473C18.8266 6.83279 18.5409 6.29824 18.1213 5.87868C17.7018 5.45912 17.1672 5.1734 16.5853 5.05765C16.0033 4.94189 15.4001 5.0013 14.8519 5.22836C14.3038 5.45543 13.8352 5.83994 13.5056 6.33329C13.1759 6.82664 13 7.40666 13 8C12.9984 8.39442 13.0749 8.78525 13.2251 9.14995C13.3753 9.51465 13.5962 9.84601 13.8751 10.1249C14.154 10.4038 14.4853 10.6247 14.8501 10.7749C15.2148 10.9251 15.6056 11.0016 16 11ZM8 11C8.59334 11 9.17336 10.8241 9.66671 10.4944C10.1601 10.1648 10.5446 9.69623 10.7716 9.14805C10.9987 8.59987 11.0581 7.99667 10.9424 7.41473C10.8266 6.83279 10.5409 6.29824 10.1213 5.87868C9.70176 5.45912 9.16721 5.1734 8.58527 5.05765C8.00333 4.94189 7.40013 5.0013 6.85195 5.22836C6.30377 5.45543 5.83524 5.83994 5.50559 6.33329C5.17595 6.82664 5 7.40666 5 8C4.99839 8.39442 5.07489 8.78525 5.22509 9.14995C5.37529 9.51465 5.5962 9.84601 5.8751 10.1249C6.15399 10.4038 6.48535 10.6247 6.85005 10.7749C7.21475 10.9251 7.60558 11.0016 8 11ZM8 13C5.66956 13 1 14.17 1 16.4996V18.9991H15V16.4996C15 14.17 10.3296 13 8 13ZM16 13C15.7103 13 15.3799 13.0199 15.0304 13.0503C15.6166 13.4195 16.103 13.9274 16.4465 14.5291C16.79 15.1308 16.9801 15.8079 17 16.5004V19H23V16.4996C23 14.17 18.3296 13 16 13Z"
          fill="#5E6B72"
        />
      </g>
    </svg>
  )
}
