import * as React from 'react'
import classnames from 'classnames'

interface IAddBranchButtonProps {
  className?: string
  transitionType?: React.ReactNode
  onClick: () => void
}

export default class AddBranchButton extends React.PureComponent<
  IAddBranchButtonProps
> {
  handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    this.props.onClick()
  }
  render() {
    return (
      <div className={classnames('d-flex node', this.props.className)}>
        <div
          onClick={this.handleClick}
          className="border body pt-2 pb-2 pl-3 pr-3 bg-blue-grey-701 text-white hover-shadow-md rounded pointer ">
          <div className="inner w-100 h-100  no-highlight ">+</div>
        </div>
      </div>
    )
  }
}
