import { IBranchProps } from 'components/AutoBranch/AutoBranch'
import EditableIncomingNode from 'components/EditableIncomingNode/EditableIncomingNode'
import { EditableOutgoingNode } from 'components/EditableOutgoingNode/EditableOutgoingNode'
import LinkingIndicator from 'components/LinkingIndicator/LinkingIndicatorConnected'
import MediaNode from 'components/MediaNode/MediaNode'
import NodeSubtree, {
  HorizontalNode,
  VerticalNode,
} from 'components/NodeSubtree/NodeSubtree'
import { OutgoingNode } from 'components/OutgoingNode/OutgoingNode'
import SelectNodeRadio from 'components/SelectNodeRadio/SelectNodeRadio'
import * as React from 'react'
import { PromptType } from 'store/campaign-scripts/reducer'
import { getPromptTypeDefaults } from 'util/campaign-scripts'
import { DEFAULT_DIALOG_STATE_MESSAGE } from 'const/settings'

export default class BooleanBranch extends React.PureComponent<IBranchProps> {
  handleAddTrue = (type: PromptType) => {
    if (this.props.spliceNode) {
      this.props.spliceNode({
        dialogId: this.props.workflowStep.parentDialog,
        dialogStateId: this.props.workflowStep.id,
        data: {
          ...getPromptTypeDefaults(type),
          prompt: DEFAULT_DIALOG_STATE_MESSAGE,
          promptType: type,
          transitionType: 'true',
        },
      })
    }
  }

  handleAddFalse = (type: PromptType) => {
    if (this.props.spliceNode) {
      this.props.spliceNode({
        dialogId: this.props.workflowStep.parentDialog,
        dialogStateId: this.props.workflowStep.id,
        data: {
          ...getPromptTypeDefaults(type),
          prompt: DEFAULT_DIALOG_STATE_MESSAGE,
          promptType: type,
          transitionType: 'false',
        },
      })
    }
  }

  handleAddLink = (branch: 'true' | 'false') => {
    if (this.props.startLinking) {
      this.props.startLinking(branch)
    }
  }

  handleRemoveMedia = () => {
    this.props.changeImage?.('')
  }

  handleEditNode = (editing: boolean) => {
    this.props.editNode?.({
      dialogStateId: this.props.workflowStep.id,
      editing,
    })
  }

  render() {
    const branches = React.Children.toArray(this.props.branch)
    const { workflowStep, editable, children } = this.props
    const trueOrDefault = workflowStep.nextStates['true']
      ? workflowStep.nextStates['true']
      : workflowStep.nextStates['default']
    const falseOrDefault = workflowStep.nextStates['false']
      ? workflowStep.nextStates['false']
      : workflowStep.nextStates['default']
    return (
      <>
        <>
          <a id={workflowStep.id} />
          <VerticalNode>
            <div className="d-flex flex-row">
              {this.props.linking && this.props.createLink && (
                <SelectNodeRadio onClick={this.props.createLink} />
              )}
              <EditableOutgoingNode
                node={this.props.workflowStep}
                isTerminal={!!this.props.isTerminal}
                editing={this.props.editing}
                onEditNode={this.handleEditNode}
                onChangeImage={this.props.changeImage}
                editable={this.props.editable}
                onChange={editable ? this.props.updateNode : undefined}
                onOptOut={this.props.optOut}
                onDelete={this.props.deleteEdge}
                value={workflowStep.prompt}
                personalizedPrompt={workflowStep.personalizedPrompt}
                setValueBeingSaved={this.props.setValueBeingSaved}
                handlePromptChange={this.props.handlePromptChange}>
                <OutgoingNode
                  border={this.props.border}
                  personalizedPrompt={workflowStep.personalizedPrompt}
                  editable={editable}>
                  {this.props.valueBeingSaved || workflowStep.prompt}
                </OutgoingNode>
              </EditableOutgoingNode>
            </div>
          </VerticalNode>
          {workflowStep.media && (
            <VerticalNode>
              <MediaNode
                url={workflowStep.media}
                onRemove={this.handleRemoveMedia}
              />
            </VerticalNode>
          )}
        </>
        {children}
        <NodeSubtree
          parent={
            <EditableIncomingNode
              editable={Boolean(this.props.editable)}
              value="Yes"
              transitionType="Y"
              transitionKey="true"
              menuType={
                trueOrDefault == null || trueOrDefault.default === false
                  ? 'full'
                  : 'limited'
              }
              onClickAddLink={() => this.handleAddLink('true')}
              onClickAddChild={this.handleAddTrue}
              onEditNode={this.handleEditNode}
              hasHorizontalNode={true}
            />
          }>
          {this.props.linking && (
            <LinkingIndicator stepId={workflowStep.id} transition="true" />
          )}
          {branches[0]}
        </NodeSubtree>
        <NodeSubtree
          className="last"
          parent={
            <HorizontalNode>
              <EditableIncomingNode
                value="No"
                transitionType="N"
                transitionKey="false"
                editable={Boolean(this.props.editable)}
                menuType={
                  falseOrDefault == null || falseOrDefault.default === false
                    ? 'full'
                    : 'limited'
                }
                onClickAddLink={() => this.handleAddLink('false')}
                onClickAddChild={this.handleAddFalse}
                onEditNode={this.handleEditNode}
              />
            </HorizontalNode>
          }>
          {this.props.linking && (
            <LinkingIndicator stepId={workflowStep.id} transition="false" />
          )}
          {branches[1]}
        </NodeSubtree>
      </>
    )
  }
}
