import * as React from 'react'
import { Button, IButtonProps } from 'components/Button/Button'
import { FontAwesomeIcon } from 'components/Icons/FontAwesomeIcon/FontAwesomeIcon'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import ClassNames from 'classnames'

export const FontAwesomeIconButtonFactory = (
  iconName: IconProp,
  buttonClasses: string
) => {
  return class IconButton extends React.PureComponent<IButtonProps> {
    render() {
      const { children, className, ...rest } = this.props
      return (
        <Button {...rest} className={ClassNames(className, buttonClasses)}>
          <FontAwesomeIcon icon={iconName} />
          {children && <span className="text ml-1">{children}</span>}
        </Button>
      )
    }
  }
}
