import { MessageContentCard } from 'components/ContentCard/ContentCard'
import { ProfileIcon } from 'components/Icons/ProfileIcon/ProfileIcon'
import { PanelDrawer } from 'components/Panel/Panel'
import { PanelDrawerHeader } from 'components/PanelDrawerHeader/PanelDrawerHeader'
import { LabeledDivider } from 'components/Pill/Pill'
import * as React from 'react'
import * as _ from 'lodash'
import * as api from 'api'
import { isLeft } from 'fp-ts/lib/Either'
import {
  Failure,
  isRefetching,
  isSuccess,
  isSuccessOrRefetching,
  Refetching,
  Success,
  WebData,
} from 'store/webdata'
import { AlertBanner, AlertType } from 'components/Alert/Alert'
import Loader from 'components/Loader/Loader'
import { WireframeMessage } from 'components/WireframeMessage/WireframeMessage'
import { Circle } from 'components/Circle/Circle'
import scssVariables from 'scss/_variables.scss'
import { CommentIcon } from 'components/Icons/CommentIcon/CommentIcon'
import cls from 'classnames'
import { ANONYMOUS_CONTACT } from 'page/knowledge-base/KnowledgeReview/KnowledgeReviewTable/KnowledgeReviewTableRow/KnowledgeReviewTableRow'
import { TransportEnum, transportToChannel } from 'store/transport'
import { useParams } from 'react-router-dom'

type openResponseCardData = {
  body: string
  name: string | null
  dateTime: string
  smsLogId?: string
  contactId: string
  transport: keyof typeof TransportEnum
}

export const sortCardsByDateBucket = (
  cards: openResponseCardData[]
): Record<string, openResponseCardData[]> => {
  return _.groupBy(
    [
      ...cards.map(card => ({
        ...card,
        convertedDateTime: new Date(card.dateTime),
      })),
    ].sort((a, b) => Number(b.convertedDateTime) - Number(a.convertedDateTime)),
    card =>
      card.convertedDateTime.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
  )
}

type PresentationalOpenResponsesDrawerProps = {
  onClose: () => void
  prompt: string
  cardGroups: Record<string, openResponseCardData[]>
  scrollRef: React.RefObject<HTMLDivElement>
  onScroll: () => void
  reachedBottom: boolean
  refetching: boolean
  show: boolean
}

export const PresentationalOpenResponsesDrawer = ({
  onClose,
  prompt,
  cardGroups,
  scrollRef,
  onScroll,
  reachedBottom,
  refetching,
  show,
}: PresentationalOpenResponsesDrawerProps) => {
  return (
    <PanelDrawer
      onScroll={onScroll}
      panelContentRef={scrollRef}
      onClose={onClose}
      open={show}
      header={
        <PanelDrawerHeader
          eventLocation="campaigns"
          closeEventObject="open text responses drawer x"
          onClose={onClose}
          title="Open Text Responses"
          icon={
            <CommentIcon className="field-profile-icon fill-mainstay-dark-blue-50 ml-3 mr-2 mb-1" />
          }
        />
      }>
      <div
        style={{
          paddingTop: '1rem',
          paddingRight: '2rem',
          paddingBottom: '23.5px',
          paddingLeft: '2rem',
        }}>
        <WireframeMessage
          message={prompt}
          messageClassName="text-mainstay-dark-blue-80 mainstay-header-h4"
          className="text-mainstay-dark-blue-80 mainstay-header-h4"
        />
      </div>
      {Object.keys(cardGroups).map((dateKey, i) => {
        return (
          <div key={dateKey}>
            <LabeledDivider
              color="mainstay-dark-blue-10"
              textColor="mainstay-dark-blue"
              text={dateKey}
              className={cls('mb-4', { 'mt-4': i > 0 })}
            />
            {cardGroups[dateKey].map(card => {
              const {
                body,
                name,
                dateTime,
                smsLogId,
                contactId,
                transport,
              } = card
              return (
                <MessageContentCard
                  eventLocation="campaigns"
                  eventAction="click"
                  eventObject="open text responses individual message link"
                  key={smsLogId}
                  linkClassName="mb-3"
                  className="mx-4 my-2 open-response-card"
                  link={
                    `/conversations-v2/${contactId}/${transportToChannel(
                      transport
                    )}` + (smsLogId ? `?message_id=${smsLogId}` : '')
                  }
                  body={body}
                  name={name || ANONYMOUS_CONTACT}
                  time={new Date(dateTime).toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                  openInNewTab
                />
              )
            })}
          </div>
        )
      })}
      {refetching && (
        <div className="d-flex w-100 justify-content-center">
          <Loader />
        </div>
      )}
      {reachedBottom && (
        <div className="w-100 pt-4">
          <div className="w-100 d-flex justify-content-center pb-3">
            <Circle radius={3} fill={scssVariables.mainstayDisabledGray} />
          </div>
          <hr />
        </div>
      )}
    </PanelDrawer>
  )
}

export const OpenResponsesDrawer = ({
  stateId,
  campaignId,
  isDataTriggered,
  isRecurring,
  isAggregate,
  isHelloPage,
  isWebBot,
  audience,
  ...props
}: Omit<
  PresentationalOpenResponsesDrawerProps,
  'cardGroups' | 'scrollRef' | 'onScroll' | 'reachedBottom' | 'refetching'
> & {
  stateId: string
  campaignId: string
  isRecurring: boolean
  isAggregate: boolean
  audience?: string
  isDataTriggered?: boolean
  isHelloPage?: boolean
  isWebBot?: boolean
}) => {
  const [cardData, setCardData] = React.useState<
    WebData<openResponseCardData[], undefined>
  >(undefined)
  const [reachedBottom, setreachedBottom] = React.useState(false)
  const { show } = props

  const { dialogId } = useParams<{ dialogId: string }>()

  const loadMoreCards = React.useCallback(() => {
    if (reachedBottom || isRefetching(cardData)) {
      return
    }
    const latest = isSuccess(cardData)
      ? cardData.data[cardData.data.length - 1].dateTime
      : new Date().toISOString()
    const existingCards = isSuccess(cardData) ? [...cardData.data] : []
    setCardData(Refetching(existingCards))
    api
      .getOpenTextResponses({
        stateId,
        id: campaignId,
        latest,
        isRecurring,
        isHelloPage,
        dialogId,
        isWebBot,
        isAggregate,
        isDataTriggered,
        audience,
      })
      .then(res => {
        if (isLeft(res)) {
          setCardData(Failure(undefined))
          return
        }
        if (res.right.length < 1) {
          setreachedBottom(true)
          setCardData(Success(existingCards))
          return
        }
        const combinedCards = existingCards.concat(res.right)
        setCardData(Success(combinedCards))
      })
      .catch(() => setCardData(Failure(undefined)))
  }, [
    dialogId,
    isHelloPage,
    isWebBot,
    stateId,
    campaignId,
    isRecurring,
    audience,
    cardData,
    reachedBottom,
    isDataTriggered,
    isAggregate,
  ])

  const showRef = React.useRef(show)
  React.useEffect(() => {
    if (show && show !== showRef.current) {
      loadMoreCards()
    }
    showRef.current = show
  }, [show, loadMoreCards, showRef])

  const scrollRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (cardData === undefined) {
      loadMoreCards()
      return
    }
    const scrollLengthNotReached =
      scrollRef?.current &&
      scrollRef?.current?.clientHeight === scrollRef?.current?.scrollHeight
    if (isSuccess(cardData) && !reachedBottom && scrollLengthNotReached) {
      loadMoreCards()
    }
  }, [cardData, loadMoreCards, scrollRef, reachedBottom])

  function handleScroll() {
    if (!scrollRef?.current) {
      return
    }
    if (
      scrollRef?.current?.scrollTop + scrollRef?.current?.clientHeight >
      scrollRef?.current?.scrollHeight - 1
    ) {
      loadMoreCards()
    }
  }

  return isSuccessOrRefetching(cardData) ? (
    <PresentationalOpenResponsesDrawer
      onScroll={handleScroll}
      scrollRef={scrollRef}
      cardGroups={sortCardsByDateBucket(cardData.data)}
      reachedBottom={reachedBottom}
      refetching={isRefetching(cardData)}
      {...props}
    />
  ) : (
    <PanelDrawer
      open={props.show}
      onClose={props.onClose}
      header={
        <PanelDrawerHeader
          eventLocation="campaigns"
          closeEventObject="open text responses drawer x"
          onClose={props.onClose}
          title="Open Text Responses"
          icon={
            <ProfileIcon className="field-profile-icon fill-mainstay-dark-blue-50 ml-3 mr-2 mb-1" />
          }
        />
      }>
      <AlertBanner
        type={AlertType.Danger}
        subtitle="Error while loading open text responses. Try again or contact support at support@mainstay.com"
      />
    </PanelDrawer>
  )
}
