import { IVCard } from 'store/campaign-scripts/reducer'
import 'components/VCard/VCard.scss'
import classNames from 'classnames'
import { ProfileIcon } from 'components/Icons/ProfileIcon/ProfileIcon'

export const VCard = ({
  contactName,
  imageUrl,
  showBorder,
}: IVCard & { showBorder?: boolean }) => {
  return (
    <div
      className={classNames('vcard', {
        'vcard--border': showBorder,
      })}>
      <div className="fw-bold">{contactName}</div>
      {imageUrl ? (
        <img className="vcard__img" src={imageUrl} />
      ) : (
        <ProfileIcon className="fill-mainstay-dark-blue-50" />
      )}
    </div>
  )
}
