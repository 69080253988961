import * as React from 'react'
import classnames from 'classnames'
import { CloseableImage } from 'components/CloseableImage/CloseableImage'
interface IMediaNodeProps {
  readonly className?: string
  readonly url: string
  readonly onRemove?: () => void
}

export default class MediaNode extends React.PureComponent<IMediaNodeProps> {
  render() {
    return (
      <div className={classnames('node', this.props.className)}>
        <CloseableImage
          image={this.props.url}
          visible
          onClose={this.props.onRemove}
        />
      </div>
    )
  }
}
