export interface ILineChartIconProps {
  readonly height?: number | string
  readonly width?: number | string
  readonly muted?: boolean
}
export function LineChartIcon({
  height = '24px',
  width = '24px',
  muted = false,
}: ILineChartIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.5 18.49L9.5 12.48L13.5 16.48L22 6.92001L20.59 5.51001L13.5 13.48L9.5 9.48001L2 16.99L3.5 18.49Z"
        fill="#5F779A"
        fillOpacity={muted ? 0.5 : 1}
      />
    </svg>
  )
}
