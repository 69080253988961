import { useCallback, useState } from 'react'
import SideEffectNode from 'components/SideEffectNode/SideEffectNode'
import { Button } from 'components/Button/Button'
import { Label } from 'reactstrap'
import { updateCampaignScriptStateAsync } from 'store/campaign-scripts/thunks'
import { setShowVCardForm } from 'store/campaign-scripts/actions'
import * as yup from 'yup'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { getCurrentInstitutionDisplayName } from 'store/triage/profile/selectors'
import { useSelector, useDispatch } from 'util/hooks'
import { Formik, FormikProps, Field, Form, FieldProps } from 'formik'
import { IVCard, ICampaignScriptStep } from 'store/campaign-scripts/reducer'
import { ImageUpload } from 'components/ImageUpload/ImageUpload'
import { TextInput } from 'components/TextInput/TextInput'
import { VCard } from 'components/VCard/VCard'
import { createVCard } from 'api/index'
import { isLeft, isRight } from 'fp-ts/lib/These'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { toastOnHttpError500or400 } from 'api/http'

interface IVCardNodeProps {
  vCard: IVCard
  dialogState: ICampaignScriptStep
  startInEditMode: boolean
  editable?: boolean
}

interface IVCardMediaInfo {
  url: string
  fileName: string
}

const validationSchema = yup.object().shape({
  contactName: yup.string().required('Contact name is required'),
  imageUrl: yup.string().nullable(),
})

export const VCardNode = ({
  dialogState,
  vCard,
  startInEditMode,
  editable,
}: IVCardNodeProps) => {
  const dispatch = useDispatch()
  const [editMode, setEditMode] = useState(startInEditMode)

  const handleSave = useCallback(
    (newValue: IVCardMediaInfo) => {
      updateCampaignScriptStateAsync(dispatch)({
        dialogId: dialogState.parentDialog,
        dialogStateId: dialogState.id,
        data: {
          media: newValue.url,
          mediaName: newValue.fileName,
          mediaContentType: 'text/x-vcard',
        },
      })
      setEditMode(false)
    },
    [dialogState.id, dialogState.parentDialog, dispatch]
  )

  const handleDelete = useCallback(() => {
    updateCampaignScriptStateAsync(dispatch)({
      dialogId: dialogState.parentDialog,
      dialogStateId: dialogState.id,
      data: {
        media: '',
        mediaName: '',
        mediaContentType: '',
      },
    })
    setEditMode(false)
  }, [dialogState.id, dialogState.parentDialog, dispatch])

  const handleCancel = useCallback(() => {
    dispatch(
      setShowVCardForm({
        showForm: false,
        dialogStateId: dialogState.id,
      })
    )
    setEditMode(false)
  }, [dialogState.id, dispatch])
  const handleClick = useCallback(() => {
    if (editable) {
      setEditMode(true)
    }
  }, [setEditMode, editable])
  if (editMode) {
    return (
      <VCardForm
        vCard={vCard}
        onCancel={handleCancel}
        onDelete={handleDelete}
        onSave={handleSave}
      />
    )
  } else {
    return (
      <SideEffectNode editable={editable} onClick={handleClick}>
        <VCard {...vCard} />
      </SideEffectNode>
    )
  }
}

interface IVCardFormProps {
  vCard: IVCard
  onCancel: () => void
  onSave: (newValue: IVCardMediaInfo) => void
  onDelete: () => void
}

export const VCardForm = ({
  vCard,
  onCancel,
  onSave,
  onDelete,
}: IVCardFormProps) => {
  const botName = useSelector(getCurrentInstitutionDisplayName)
  const mapInitialValues = (): IVCard => {
    return {
      contactName: vCard.contactName || botName || '',
      imageUrl: vCard.imageUrl || null,
    }
  }

  const [submitting, setSubmitting] = useState(false)
  const onSubmit = useCallback(
    async (formState: IVCard) => {
      setSubmitting(true)
      try {
        const res = await createVCard(formState)
        if (isRight(res)) {
          const createdVCard = res.right
          onSave(createdVCard)
        }

        if (isLeft(res)) {
          if (res.left.kind === 'http') {
            toastOnHttpError500or400(res.left.http)
          } else {
            toast.error('Unable to create vCard')
          }
        }
      } catch (e) {
        toast.error('Unable to create vCard')
      } finally {
        setSubmitting(false)
      }
    },
    [onSave]
  )

  return (
    <div className="border rounded background-white shadow-md p-3 w-content">
      <div className="d-flex justify-content-between">
        <h6>Attach vCard</h6>
        <AHIcon
          name="delete"
          onClick={onDelete}
          className="pointer opacity-50 hover-opacity-100 hover-text-new-ui-danger"
        />
      </div>
      <div className="mr-3 my-2 max-width-450">
        <span className="caption">
          A contact card file will be included with this message. This allows
          recipients to save the bot to their phone contacts.
        </span>
      </div>
      <Formik<IVCard>
        validationSchema={validationSchema}
        initialValues={mapInitialValues()}
        onSubmit={onSubmit}
        render={(formikProps: FormikProps<IVCard>) => {
          const { errors } = formikProps
          const hasErrors = errors && Object.keys(errors).length > 0
          return (
            <Form>
              <Field
                name="contactName"
                render={({ form, field }: FieldProps<IVCard>) => (
                  <div>
                    <Label className="caption">Contact Name</Label>
                    <TextInput
                      {...field}
                      error={Boolean(errors?.contactName)}
                      type="text"
                      name="contactName"
                      className="form-control"
                    />
                    {errors.contactName && form.touched.contactName && (
                      <div className="text-danger small">
                        {errors.contactName}
                      </div>
                    )}
                  </div>
                )}
              />
              <Field
                name="imageUrl"
                render={({ form, field }: FieldProps<IVCard>) => (
                  <div className="mt-4">
                    <Label className="caption">Contact Image</Label>
                    <ImageUpload
                      buttonType="button"
                      onChange={upload => {
                        form.setFieldValue(field.name, upload.href)
                      }}
                      // tslint:disable-next-line:no-unsafe-any
                      uploadUrl={field.value}
                    />
                  </div>
                )}
              />

              <div className="mt-3">
                <Button
                  color="mainstay-teal"
                  className="mr-4"
                  type="submit"
                  loading={submitting}
                  disabled={hasErrors}>
                  Save
                </Button>
                <Button
                  onClick={onCancel}
                  className="text-blue-grey-501 text-decoration-none"
                  color="link">
                  Cancel
                </Button>
              </div>
            </Form>
          )
        }}
      />
    </div>
  )
}
