import { Pager } from 'components/LinkPager/LinkPager'
import { RootState } from 'store/store'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import {
  getCurrentCampaignHistoryPage,
  getCampaignHistoryPagerCount,
} from 'store/campaign-history/selectors'
import { ICampaignHistoryPageRouteProps } from 'page/campaign/CampaignHistory'
import { CAMPAIGN_HISTORY } from 'const/routes'
import { appendQueryFilter } from 'util/queryFilters'

interface ICampaignPager {
  currentPage: number
  lastPage: number
  onClick: () => void
}
function CampaignPager(props: ICampaignPager) {
  if (props.lastPage <= 1) {
    return null
  }

  return (
    <Pager
      urlFormat={x =>
        `${CAMPAIGN_HISTORY.ROOT}${appendQueryFilter(
          window.location,
          'page',
          x
        )}`
      }
      size="md"
      first={1}
      last={props.lastPage}
      margin={4}
      current={props.currentPage}
      onClick={props.onClick}
    />
  )
}

const mapStateToProps = (
  state: RootState,
  ownProps: ICampaignHistoryPageRouteProps
): Pick<ICampaignPager, 'currentPage' | 'lastPage'> => {
  const currentPage = getCurrentCampaignHistoryPage(ownProps)
  return {
    currentPage,
    lastPage: getCampaignHistoryPagerCount(state) || currentPage,
  }
}

export default withRouter(connect(mapStateToProps)(CampaignPager))
