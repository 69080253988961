import { CheckboxChannelFilter } from 'components/ChannelsCheckboxList/CheckboxChannelFilter/CheckboxChannelFilter'
import { useSelector } from 'util/hooks'
import { getInstitutionChannels } from 'store/triage/profile/selectors'

export function ChannelsCheckboxList({
  filterBySMS,
  filterByWebChat,
  filterByFacebook,
  filterBySlack,
  setFilterBySMS,
  setFilterByWebChat,
  setFilterByFacebook,
  setFilterBySlack,
}: {
  readonly filterBySMS: boolean
  readonly filterByWebChat: boolean
  readonly filterBySlack: boolean
  readonly filterByFacebook: boolean
  readonly setFilterBySMS: () => void
  readonly setFilterByWebChat: () => void
  readonly setFilterByFacebook: () => void
  readonly setFilterBySlack: () => void
}) {
  const institutionChannels = useSelector(getInstitutionChannels)

  return (
    <div className="d-flex flex-column mb-1">
      {institutionChannels.includes('sms') && (
        <CheckboxChannelFilter
          type="twilio"
          inputType="checkbox"
          checkBoxSize="sm"
          checked={filterBySMS}
          onChange={setFilterBySMS}
        />
      )}
      {institutionChannels.includes('web_bot') && (
        <CheckboxChannelFilter
          type="web"
          inputType="checkbox"
          checkBoxSize="sm"
          checked={filterByWebChat}
          onChange={setFilterByWebChat}
        />
      )}
      {institutionChannels.includes('facebook') && (
        <CheckboxChannelFilter
          type="facebook"
          inputType="checkbox"
          checkBoxSize="sm"
          checked={filterByFacebook}
          onChange={setFilterByFacebook}
        />
      )}
      {institutionChannels.includes('slack') && (
        <CheckboxChannelFilter
          type="slack"
          inputType="checkbox"
          checkBoxSize="sm"
          checked={filterBySlack}
          onChange={setFilterBySlack}
        />
      )}
    </div>
  )
}
