import React from 'react'

import classnames from 'classnames'
import Tooltip from 'components/Tooltip/Tooltip'
import { AHIcon, AHIconType } from 'components/Icons/AHIcon/AHIcon'
import { IFancyTextboxWrapperProps } from 'components/FancyTextBox/FancyTextBoxV2'

export const FancyTextBoxWrapper: React.FC<IFancyTextboxWrapperProps & {
  focusBorder?: boolean
  shadowBorder?: boolean
}> = props => {
  const {
    active,
    className,
    hasValue,
    isValid,
    error,
    focusBorder = false,
    shadowBorder = false,
    focused,
    children,
    textarea,
    noFlex,
  } = props
  const classNames = classnames(
    'fancy-text-box p-1',
    {
      'has-value': !!hasValue,
      'shadow-border': !!shadowBorder,
      'is-invalid': !!error || !isValid,
      'border-turquoise-400': focusBorder && focused,
    },
    active && focusBorder ? 'active-input' : 'inactive-input',
    className
  )
  return (
    <div className={classNames} tabIndex={-1}>
      <section className={classnames({ 'd-flex': !noFlex }, 'max-width-100')}>
        <div
          className={classnames(
            { 'd-flex justify-items-around': !noFlex },
            'content-wrapper'
          )}>
          {textarea}
        </div>
      </section>
      {children}
    </div>
  )
}

export interface IFancyMenuProps {
  sendButton: React.ReactElement
  menuContent?: React.ReactElement
  counter?: React.ReactElement
  menuExtra?: React.ReactElement
}

export const FancyTextboxMenuWrapper: React.FC<IFancyMenuProps> = props => {
  return (
    <div
      className="d-flex align-items-center justify-content-between px-2 pb-1"
      aria-hidden="true">
      {props.menuContent}
      <section className="d-flex max-width-100">
        {props.counter}
        {props.sendButton}
      </section>
    </div>
  )
}

interface IFancyTextCounterProps {
  readonly value: string
  readonly overClassName: string
  readonly underClassName: string
  readonly softCap: number
  readonly className?: string
}

export function FancyTextBoxCounter({
  value = '',
  overClassName,
  underClassName,
  softCap,
  className,
}: IFancyTextCounterProps) {
  return (
    <span
      className={classnames(
        'px-1 text-black-50 fs-14 align-items-center d-flex',
        className
      )}>
      <span
        className={classnames({
          [overClassName]: value.length > softCap,
          [underClassName]: value.length <= softCap,
        })}>
        {value ? value.length : 0}
      </span>
      <span>{`/${softCap}`}</span>
    </span>
  )
}

interface IFancyTextBoxSendButtonProps {
  readonly disabled: boolean
  readonly disabledMessage?: string
  readonly onClick: () => void
}

export const FancyTextBoxSendButton = ({
  disabled,
  disabledMessage,
  onClick,
}: IFancyTextBoxSendButtonProps) => {
  const onEnter = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onClick()
    }
  }
  return (
    <Tooltip
      touchHold
      content={
        !disabled
          ? 'Send message.'
          : disabledMessage || 'Cannot send empty message.'
      }>
      <div
        className="line-height-0 bg-transparent"
        aria-label="Send Message Button"
        aria-hidden="true"
        tabIndex={-1}
        onKeyPress={onEnter}>
        <AHIcon
          name="send"
          className={classnames(
            'fancy-before',
            disabled && 'opacity-25',
            'text-primary',
            'pl-2 pr-0 mb-2',
            'fs-1_75rem',
            'pointer',
            'bg-transparent'
          )}
          onClick={onClick}
        />
      </div>
    </Tooltip>
  )
}

export const FancyTextboxMenuItemButton = (props: {
  active: boolean
  onClick: () => void
  name: AHIconType
  className?: string
}) => {
  const buttonClassnames = classnames(
    {
      'text-blue-grey-600': props.active,
      'bg-blue-grey-015': props.active,
      'text-blue-grey-400': !props.active,
    },
    'hover-text-blue-grey-600',
    'h4',
    'p-1',
    'pointer',
    'rounded',
    props.className
  )

  return (
    <AHIcon
      className={buttonClassnames}
      name={props.name}
      onClick={props.onClick}
    />
  )
}
