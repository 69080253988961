import React from 'react'
import { usePrevious } from 'util/hooks'

interface IBoundingBoxes {
  // Most versions of Edge return ClientRect instead of DomRect when calling
  // getBoundingClientRect. They are nearly identical, except DOMRect supports
  // toJSON() and has additional `x` and `y` fields.
  [key: string]: DOMRect | ClientRect
  [key: number]: DOMRect | ClientRect
}

type RefsByKey = {
  [key: string]: React.RefObject<HTMLElement>
  [key: number]: React.RefObject<HTMLElement>
}

const calculateBoundingBoxes = (
  refs: RefsByKey,
  scrollRef?: React.RefObject<HTMLElement>
): IBoundingBoxes => {
  // Returns the parent-relative position and heights for every ref provided
  const boundingBoxes: IBoundingBoxes = {}
  const scrollOffset = scrollRef?.current?.scrollTop || 0
  Object.keys(refs).forEach(key => {
    const ref = refs[key]

    if (ref.current) {
      const box = ref.current.getBoundingClientRect()
      boundingBoxes[key] = {
        height: box.height,
        width: box.width,
        left: box.left,
        right: box.right,
        x: box.x || box.left,
        y: box.y || box.top,
        // Account for the scroll offset if this component is nested inside
        // a vertically scrollable parent.
        top: box.top + scrollOffset,
        bottom: box.bottom + scrollOffset,
      }
    }
  })

  return boundingBoxes
}

const clearAnimationStyles = (refs: RefsByKey) => {
  Object.keys(refs).forEach(key => {
    const ref = refs[key]
    if (ref.current) {
      ref.current.style.height = ''
      ref.current.style.overflowY = ''
      ref.current.style.transform = ''
    }
  })
}

const shouldAnimate = (
  prev: IBoundingBoxes = {},
  current: IBoundingBoxes = {}
) => {
  // TODO Add Typeguard to prevBoundingBox not being undefined
  if (Object.keys(prev).length === 0) {
    return false
  }

  return (
    Object.keys(prev).length !== Object.keys(current).length ||
    Object.keys(prev).some(k => {
      if (!current[k]) {
        return true
      }
      return (
        prev[k].top !== current[k].top || prev[k].height !== current[k].height
      )
    })
  )
}

interface IAnimateRefsProps {
  children: React.ReactNode
  refs: RefsByKey
  scrollOffsetRef?: React.RefObject<HTMLElement>
  enableAnimation?: boolean
  time?: number
  disable?: boolean
}

export function AnimateRefs({
  children,
  refs,
  scrollOffsetRef,
  enableAnimation = true,
  time = 750,
  disable,
}: IAnimateRefsProps) {
  const [boundingBoxes, setBoundingBoxes] = React.useState<IBoundingBoxes>({})
  const previousBoundingBoxes = usePrevious(boundingBoxes)

  React.useLayoutEffect(() => {
    if (children && !disable) {
      clearAnimationStyles(refs)
      setBoundingBoxes(calculateBoundingBoxes(refs, scrollOffsetRef))
    }
  }, [children, disable, refs, scrollOffsetRef, setBoundingBoxes])

  React.useLayoutEffect(() => {
    if (
      !disable &&
      shouldAnimate(previousBoundingBoxes, boundingBoxes) &&
      enableAnimation &&
      previousBoundingBoxes
    ) {
      // If an element's height changes, we apply a transition on it, and as
      // such we should account for it in all future
      let cumulativeHeightTransition = 0
      Object.keys(refs)
        .sort((a, b) => {
          return !boundingBoxes[a]
            ? -1
            : !boundingBoxes[b]
            ? 1
            : boundingBoxes[a]?.top - boundingBoxes[b]?.top
        })
        .forEach(refKey => {
          const domNode = refs[refKey].current
          const prevBox = previousBoundingBoxes[refKey]
          const newBox = boundingBoxes[refKey]
          if (domNode && newBox) {
            const changeInY = prevBox
              ? prevBox.top - newBox.top + cumulativeHeightTransition
              : 0
            const oldHeight = prevBox?.height || 0
            const heightChanged = newBox.height !== oldHeight
            if (heightChanged || changeInY) {
              requestAnimationFrame(() => {
                domNode.style.transform = changeInY
                  ? `translateY(${changeInY}px)`
                  : ''
                domNode.style.transition = `transform 0s, height ${time}ms ease`
                domNode.style.height = `${oldHeight}px`
                domNode.style.overflowY = 'hidden'
                requestAnimationFrame(() => {
                  domNode.style.transform = ''
                  domNode.style.transition = `transform ${time}ms ease, height ${time}ms ease`
                  domNode.style.height = `${newBox.height}px`
                  setTimeout(() => clearAnimationStyles(refs), time)
                })
              })
            }
            cumulativeHeightTransition += newBox.height - oldHeight
          }
        })
    }
  }, [
    disable,
    refs,
    boundingBoxes,
    previousBoundingBoxes,
    enableAnimation,
    time,
  ])

  return <>{children}</>
}
