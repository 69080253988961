import * as React from 'react'
import { useAsyncCall, useDispatch, useSelector } from 'util/hooks'
import { AxiosResponse, AxiosError } from 'typings/axios'
import {
  IEscalationRulesResponseData,
  ICounselorsResponseData,
  ISaveableTopLevelContactFieldsResponseData,
  IContactAttributeResponseData,
} from 'api/response'
import {
  getRules,
  getCounselors,
  fetchContactAttributes,
  fetchSaveableTopLevelContactFields,
} from 'api'
import { WebData, isInitial } from 'store/webdata'
import {
  getContactAttributesRes,
  getSaveableTopLevelFieldsRes,
} from 'store/personalization/contactAttributes/selectors'

import {
  getAllContactAttributes as getAllContactAttributesAction,
  listSaveableTopLevelContactFields as listSaveableTopLevelContactFieldsAction,
} from 'store/personalization/contactAttributes/actions'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { isRight } from 'fp-ts/lib/Either'
import { getAttributeByIdMapping } from 'store/personalization/selectors'
import { useGetAttributeMapping } from 'components/DraftEditor/DraftEditor'

interface IEditableOutgoingNodeContext {
  loading: boolean
  response?: [
    AxiosResponse<IEscalationRulesResponseData>,
    AxiosResponse<ICounselorsResponseData>
  ]
  error?: Error
  topLevelFields?: WebData<ISaveableTopLevelContactFieldsResponseData>
  contactAttributes?: WebData<IContactAttributeResponseData[]>
  mappingResults?: WebData<ReturnType<typeof getAttributeByIdMapping>>
}

export const EditableOutgoingNodeContext = React.createContext<
  IEditableOutgoingNodeContext
>({
  loading: false,
  response: undefined,
  error: undefined,
  topLevelFields: undefined,
  contactAttributes: undefined,
  mappingResults: undefined,
})

const chainedAsyncCall = () => {
  return Promise.all([getRules(), getCounselors()])
}

export const EditableOutgoingNodeProvider: React.FC = ({ children }) => {
  const [loading, response, error] = useAsyncCall<
    [
      AxiosResponse<IEscalationRulesResponseData>,
      AxiosResponse<ICounselorsResponseData>
    ]
  >(chainedAsyncCall, [])

  const contactAttributes = useSelector(getContactAttributesRes)
  const topLevelFields = useSelector(getSaveableTopLevelFieldsRes)
  // contact, institution, and top level fields for replacing Mustache
  // templates with correct data in WireFrameMessage
  useGetAttributeMapping()

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (isInitial(contactAttributes)) {
      dispatch(getAllContactAttributesAction.request())
      fetchContactAttributes({ pageSize: 2000 })
        .then(res => {
          dispatch(getAllContactAttributesAction.success(res.data.results))
        })
        .catch((err: AxiosError) => {
          if (err.response && err.response.status === 404) {
            toast('Failed to fetch contact attributes.', {
              type: 'error',
              options: { autoClose: 1000 },
            })
          }
          dispatch(getAllContactAttributesAction.failure(err))
        })
    }
  }, [contactAttributes, dispatch])

  React.useEffect(() => {
    if (isInitial(topLevelFields)) {
      dispatch(listSaveableTopLevelContactFieldsAction.request())
      fetchSaveableTopLevelContactFields()
        .then(res => {
          if (isRight(res)) {
            dispatch(listSaveableTopLevelContactFieldsAction.success(res.right))
          } else {
            throw new Error()
          }
        })
        .catch(_e => {
          toast('Failed to fetch top level contact fields', {
            type: 'error',
            options: { autoClose: 1000 },
          })
          dispatch(
            listSaveableTopLevelContactFieldsAction.failure({
              message: 'Failed to fetch top level contact fields',
            })
          )
        })
    }
  }, [topLevelFields, dispatch])

  return (
    <EditableOutgoingNodeContext.Provider
      value={{ loading, response, error, contactAttributes, topLevelFields }}>
      {children}
    </EditableOutgoingNodeContext.Provider>
  )
}
