import * as React from 'react'
import { Input } from 'components/Input/Input'
import { IContactAttributeResponseData } from 'api/response'
import { Formik, FieldProps, Field, Form } from 'formik'
import { FormGroup, Label, ModalBody } from 'reactstrap'
import {
  AutocompleteModalForm,
  AutoCompleteOptionProps,
} from 'components/AutocompleteWithModalForm/AutocompleteWithModalForm'
import Select from 'components/Select/SelectV2'
import { Styles } from 'react-select/lib/styles'
import { SimpleModalHeader } from 'components/SimpleModalHeader/SimpleModalHeader'
import { SimpleModalFooter } from 'components/SimpleModalFooter/SimpleModalFooter'

export interface IContactAttributeModal {
  key: string
  contactAttribute: IContactAttributeResponseData
}
export const ContactAttributeOption = (
  props: AutoCompleteOptionProps<IContactAttributeModal>
) => {
  return (
    <div className="description">{`${
      props.data.contactAttribute.name
    } - ${props.data.contactAttribute.data_type.toLowerCase()}`}</div>
  )
}

export const humanizedContactAttributeTypes = [
  {
    value: 'PHONE',
    label: 'Accept Phone Numbers Only',
    description:
      'Responses must be 10 digits. Can only contain digits and related special characters',
  },
  {
    value: 'EMAIL',
    label: 'Accept Email Addresses Only',
    description:
      'Responses must include a domain. Can only contain letters, numbers, and related special characters',
  },
  {
    value: 'DATE',
    label: 'Accept Dates Only',
    description:
      'Responses must be formatted in a recognized date format, such as Month DD, YY or  YYYY/MM/DD',
  },
  {
    value: 'NUMBER',
    label: 'Accept Numbers Only',
    description:
      'Responses can only be numbers and related special characters.',
  },
  {
    value: 'URL',
    label: 'Accept URLs Only',
    description:
      'Responses must include a protocol, such as http:// or https:// and a domain, such as mainstay.com',
  },
  // Default selection
  {
    value: 'TEXT',
    label: 'Collect All Responses',
    description: 'All responses and future import data will be captured',
  },
]

export interface IContactAttributeTypeItem {
  value: string
  label: string
  description: string
}

export const ContactAttributeTypeItem = ({
  selectOption,
  data,
}: AutoCompleteOptionProps<IContactAttributeTypeItem>) => {
  const handleClickOption = React.useCallback(() => {
    selectOption({
      value: data.value,
      label: data.label,
      description: data.description,
    })
  }, [data.description, data.label, data.value, selectOption])
  return (
    <div onClick={handleClickOption} className="px-3 py-2 pointer">
      <h6 className="font-weight-normal">{data.label}</h6>
      <div className="text-muted small">{data.description}</div>
    </div>
  )
}

export interface ICreateContactAttributeForm {
  name: string
  fieldType: {
    value: string
    label: string
    description: string
  }
}

export const CreateContactAttributeForm = (
  props: AutocompleteModalForm<ICreateContactAttributeForm>
) => {
  const typeMenuOptions = humanizedContactAttributeTypes

  const handleCancel = props.onClose

  const selectStyles: Partial<Styles> = {
    control: (styles): { [k: string]: string | number | undefined } => ({
      ...styles,
      backgroundColor: 'bg-blue-grey-501',
    }),
  }

  return (
    <div>
      <SimpleModalHeader
        text="Create Contact Field"
        onClose={() => props.onClose?.()}
      />

      <Formik
        initialValues={{
          name: '',
          fieldType:
            humanizedContactAttributeTypes[
              humanizedContactAttributeTypes.length - 1
            ],
        }}
        onSubmit={r => {
          props.onSubmit(r)
        }}
        validate={vals => {
          if (vals.name.length === 0) {
            return { name: 'Required' }
          }
        }}
        render={formik => (
          <Form>
            <ModalBody>
              <Field
                name="name"
                render={({
                  field,
                }: FieldProps<ICreateContactAttributeForm>) => (
                  <FormGroup>
                    <Label for="name" className="caption text-blue-grey-080">
                      Field Name
                    </Label>
                    <Input
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={field.onChange}
                      autoFocus={true}
                    />
                  </FormGroup>
                )}
              />
              <Field
                name="fieldType"
                render={() => (
                  <FormGroup>
                    <Label for="fieldType" className="caption">
                      <div className="text-blue-grey-080 pb-1">
                        Select Field Type
                      </div>
                      <div>
                        Field Types will improve the overall quality of your
                        data and integrations by filtering out irrelevant or
                        inaccurate contact responses.
                      </div>
                    </Label>
                    <Select
                      id="fieldType"
                      name="fieldType"
                      value={formik.values.fieldType}
                      className="bg-blue-grey-050"
                      onChange={newValue =>
                        formik.setFieldValue('fieldType', newValue)
                      }
                      components={{ Option: ContactAttributeTypeItem }}
                      options={typeMenuOptions}
                      styles={selectStyles}
                    />
                  </FormGroup>
                )}
              />
            </ModalBody>
            <SimpleModalFooter
              onCancel={handleCancel}
              disableSubmit={!formik.isValid}
              submitText="Create Field"
              cancelText="Cancel"
            />
          </Form>
        )}
      />
    </div>
  )
}
