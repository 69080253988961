import { MainstayFlexTable } from 'mainstay-ui-kit/MainstayFlexTable/MainstayFlexTable'
import { MainstayFlexTableHeader } from 'mainstay-ui-kit/MainstayFlexRow/MainstayFlexRow'
import { MainstayFlexTableHeaderCol } from 'mainstay-ui-kit/MainstayFlexCol/MainstayFlexCol'
import { appendQueryFilter } from 'util/queryFilters'
import { Pager } from 'components/LinkPager/LinkPager'
import ContactFeedbackTableRow from 'page/knowledge-base/ContactFeedback/ContactFeedbackTable/ContactFeedbackTableRow/ContactFeedbackTableRow'
import { useSelector } from 'util/hooks'
import { getContactFeedback } from 'page/knowledge-base/ContactFeedback/selectors'
import Loader from 'components/Loader/Loader'
import { ContactFeedbackStatus } from 'page/knowledge-base/ContactFeedback/reducer'
import {
  CONTACT_FEEDBACK_PAGE_LIMIT,
  getContactFeedbackParams,
} from 'page/knowledge-base/ContactFeedback/ContactFeedback'

import 'page/knowledge-base/ContactFeedback/ContactFeedbackTable/ContactFeedbackTable.scss'
import * as api from 'api'

export default function ContactFeedbackTable({
  fetchCounts,
}: {
  readonly fetchCounts: () => Promise<void>
}) {
  const {
    data: { items, status, totalCount },
  } = useSelector(getContactFeedback)
  const { page } = getContactFeedbackParams()

  if (status === ContactFeedbackStatus.loading) {
    return (
      <div className="d-flex justify-content-center flex-grow-1 align-items-center">
        <Loader />
      </div>
    )
  }

  if (status === ContactFeedbackStatus.error) {
    return (
      <div className="d-flex justify-content-center flex-grow-1 align-items-center pt-5">
        <div>Something went wrong, please try again later.</div>
      </div>
    )
  }

  if (
    status === ContactFeedbackStatus.ok &&
    (totalCount === 0 || !items.length)
  ) {
    return (
      <div className="d-flex justify-content-center flex-grow-1 align-items-center pt-5">
        <div>No feedback responses yet</div>
      </div>
    )
  }

  return (
    <>
      <MainstayFlexTable className="contact-feedback-table">
        <MainstayFlexTableHeader>
          <MainstayFlexTableHeaderCol xs={1}>Rating</MainstayFlexTableHeaderCol>
          <MainstayFlexTableHeaderCol xs={3}>
            Contact Message
          </MainstayFlexTableHeaderCol>
          <MainstayFlexTableHeaderCol xs={3}>
            Bot Response
          </MainstayFlexTableHeaderCol>
          <MainstayFlexTableHeaderCol xs={2}>
            Comment
          </MainstayFlexTableHeaderCol>
          <MainstayFlexTableHeaderCol xs={2}>Date</MainstayFlexTableHeaderCol>
        </MainstayFlexTableHeader>
        {items.map((item: api.ContactFeedbackShapeType) => (
          <ContactFeedbackTableRow
            key={item.id}
            item={item}
            fetchCounts={fetchCounts}
          />
        ))}
      </MainstayFlexTable>
      {totalCount > 0 && (
        <Pager
          first={1}
          current={page}
          last={Math.max(
            Math.ceil(totalCount / CONTACT_FEEDBACK_PAGE_LIMIT),
            1
          )}
          urlFormat={x => `${appendQueryFilter(window.location, 'page', x)}`}
        />
      )}
    </>
  )
}
