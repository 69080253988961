import { Checkbox } from '@material-ui/core'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import last from 'lodash/last'
import React from 'react'

export function ActionCheckbox({
  id,
  show = false,
  all,
  indeterminate,
  deselectedIds,
  selectedIds,
  itemIds,
  onAddToCheckboxList,
  onRemoveFromCheckboxList,
  isChecked,
}: {
  readonly id: string
  readonly show?: boolean
  readonly all?: boolean
  readonly indeterminate?: boolean
  readonly deselectedIds?: string[]
  readonly selectedIds: string[]
  // tslint:disable-next-line:no-any
  readonly itemIds: any[]
  readonly onAddToCheckboxList: (ids: string[]) => void
  readonly onRemoveFromCheckboxList: (id: string) => void
  readonly isChecked?: boolean
}) {
  if (!show) {
    return null
  }

  const checked =
    isChecked ||
    (all
      ? !deselectedIds?.some(item => item === id)
      : selectedIds.some(item => item === id))

  const onClick = (e: React.MouseEvent<HTMLInputElement>) => {
    const lastSelectedItem = last(selectedIds)

    if (e.shiftKey && lastSelectedItem) {
      const from = itemIds.indexOf(lastSelectedItem)
      const to = itemIds.indexOf(id)

      const range = to < from ? [to, from] : [from, to] // direction up/down
      onAddToCheckboxList(itemIds.slice(...range))
    }
  }

  const onChange = () => {
    if (checked) {
      onRemoveFromCheckboxList(id)
    } else {
      onAddToCheckboxList([id])
    }
  }

  return (
    <div className="d-flex align-self-center pr-2">
      <Checkbox
        id={id}
        color="primary"
        checked={checked}
        checkedIcon={
          indeterminate ? (
            <AHIcon
              name="indeterminate_check_box"
              className="fs-24px"
              overrideWidth={true}
            />
          ) : (
            undefined
          )
        }
        name="popup_message"
        className="p-0"
        onClick={onClick}
        onChange={onChange}
      />
    </div>
  )
}
