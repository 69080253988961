export interface ITrashCanIconProps {
  readonly height?: number | string
  readonly width?: number | string
  readonly muted?: boolean
}
export function TrashCanIcon({
  height = '32px',
  width = '32px',
  muted = false,
}: ITrashCanIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none">
      <path
        d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z"
        fill="#5F779A"
        fillOpacity={muted ? 0.5 : 1}
      />
    </svg>
  )
}
