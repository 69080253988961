export function CommandsIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.68325 11.9345H7.89633L7.2297 15.7306H5.53537L6.20199 11.9345H4.10028V10.3421H6.47975L6.94269 7.7126H4.79468V6.1016H7.2297L7.90559 2.25H9.59066L8.91478 6.1016H10.711L11.3868 2.25H13.0812L12.4053 6.1016H14.4329V7.7126H12.1183L11.6553 10.3421H13.7293V11.9345H11.3776L10.711 15.7306H9.01662L9.68325 11.9345ZM8.17409 10.3421H9.96101L10.4239 7.7126H8.62776L8.17409 10.3421Z"
        fill="#5F779A"
      />
    </svg>
  )
}
