import { Button } from 'components/Button/Button'
import { useLocalStorage } from 'util/hooks'
import 'components/CookiesConsentBanner/CookiesConsentBanner.scss'

const LinkToPrivacyPolicy = () => {
  return (
    <a
      className="caption text-mainstay-spark-red text-underline"
      href="https://www.mainstay.com/privacy/"
      target="_blank">
      see our Privacy Policy.
    </a>
  )
}

export const CookiesBanner = () => {
  const [showBanner, setshowBanner] = useLocalStorage('ms-banner-visible', true)
  if (!showBanner) {
    return null
  }
  return (
    <div className="cookies-banner-container w-100 d-flex flex-column align-items-center">
      <div className="cookies-banner-content">
        <div className="mb-2 position-relative w-100 d-flex flex-column align-items-center">
          <strong>Manage Cookie Consent</strong>
        </div>
        <div className="d-flex align-items-end">
          <div>
            <span className="caption text-white">
              By clicking "Accept All Cookies", you agree to the storing of
              cookies on your device to enhance site navigation and analyze site
              usage. To find out more about the cookies we use,{' '}
            </span>
            <LinkToPrivacyPolicy />
          </div>
          <Button
            className="ml-3 bg-mainstay-spark-red text-white"
            onClick={() => setshowBanner(false)}>
            Accept
          </Button>
        </div>
      </div>
    </div>
  )
}
