import * as api from 'api'
import { ActionButton } from 'components/ActionButton/ActionButton'
import { MoreIcon } from 'components/Icons/MoreIcon/MoreIcon'
import { ThumbsDownIcon } from 'components/Icons/ThumbsDownIcon/ThumbsDownIcon'
import { ThumbsUpIcon } from 'components/Icons/ThumbsUpIcon/ThumbsUpIcon'
import { PopoverComponent } from 'components/PopoverComponent/PopoverComponent'
import { formatDistanceToNow } from 'date-fns'
import format from 'date-fns/format'
import { isLeft } from 'fp-ts/Either'
import { MainstayFlexTableCol } from 'mainstay-ui-kit/MainstayFlexCol/MainstayFlexCol'
import { MainstayFlexTableRow } from 'mainstay-ui-kit/MainstayFlexRow/MainstayFlexRow'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { KnowledgeReviewMark } from 'page/conversations-v2/ConversationsDetail/'
import { MarkForKnowledgeReviewModal } from 'page/conversations-v2/ConversationsDetail/MessageRow/MarkForKnowledgeReviewModal/MarkForKnowledgeReviewModal'
import 'page/knowledge-base/KnowledgeReview/KnowledgeReviewTable/KnowledgeReviewTable.scss'
import { ANONYMOUS_CONTACT } from 'page/knowledge-base/KnowledgeReview/KnowledgeReviewTable/KnowledgeReviewTableRow/KnowledgeReviewTableRow'
import React from 'react'
import { ButtonGroup } from 'reactstrap'
import { Link } from 'util/routing'
import { transportToChannel } from 'store/transport'

export function ConversationMessageLink({
  reviewItem,
}: {
  reviewItem: api.ContactFeedbackShapeType
}) {
  const relativeDate = formatDistanceToNow(new Date(reviewItem.createdAt), {
    addSuffix: true,
  })
  const url = reviewItem?.transport
    ? `/conversations-v2/${reviewItem?.contact?.id}/${transportToChannel(
        reviewItem.transport
      )}?message_id=${reviewItem.id}`
    : `/conversations-v2/${reviewItem?.contact?.id}?message_id=${reviewItem.id}`
  return (
    <span>
      {relativeDate}, from{' '}
      <Link to={url}>{reviewItem?.contact?.name || ANONYMOUS_CONTACT}</Link>
    </span>
  )
}

export default function ContactFeedbackTableRow({
  item,
  fetchCounts,
}: {
  readonly item: api.ContactFeedbackShapeType
  readonly fetchCounts: () => Promise<void>
}) {
  const [showKBReviewModal, setKBReviewModal] = React.useState(false)
  const [toggleMenu, setToggleMenu] = React.useState<boolean>(false)
  const onApplyKnowledgeReviewMark = async (
    mark: KnowledgeReviewMark | null
  ) => {
    if (!mark) {
      return
    }

    const res = await api.conversationsV2MarkForKnowledgeReview({
      messageId: item.messageId,
      mark,
    })

    if (isLeft(res)) {
      toast.error('Failed to mark message for knowledge review')
      return
    }

    toast.success('Successfully marked message for knowledge review.')
    setKBReviewModal(prev => !prev)
    await fetchCounts()
  }

  const renderPopperItems = () => {
    return (
      <ButtonGroup className="rounded shadow" vertical>
        <ActionButton onClick={() => setKBReviewModal(prev => !prev)}>
          Add to Knowledge Maintenance
        </ActionButton>
      </ButtonGroup>
    )
  }

  const renderPopover = () => {
    return (
      <PopoverComponent
        visible={toggleMenu}
        popoverPlacement="bottom-start"
        onClickOutside={() => setToggleMenu(false)}
        renderReference={() => (
          <MoreIcon
            className="pointer"
            onClick={() => setToggleMenu(prev => !prev)}
          />
        )}
        renderPopper={renderPopperItems}
      />
    )
  }

  return (
    <MainstayFlexTableRow className="hover-show-parent hover-bg-mainstay-dark-blue-05">
      <MainstayFlexTableCol xs={1}>
        {item.rating === 'thumbs_up' ? <ThumbsUpIcon /> : <ThumbsDownIcon />}
      </MainstayFlexTableCol>
      <MainstayFlexTableCol xs={3}>
        "{item.message}" <ConversationMessageLink reviewItem={item} />
      </MainstayFlexTableCol>
      <MainstayFlexTableCol xs={3}>
        {item.botResponse || '-'}
      </MainstayFlexTableCol>
      <MainstayFlexTableCol xs={2}>{item.comment || '-'}</MainstayFlexTableCol>
      <MainstayFlexTableCol xs={2}>
        {format(new Date(item.createdAt), 'MM/dd/yyyy HH:mm:ssaa')}
      </MainstayFlexTableCol>

      <MainstayFlexTableCol xs={1} className="flex-column">
        <div className="d-flex h-100 justify-content-end align-items-start">
          <div className="hover-show-child-invis pl-4 d-flex flex-column align-items-center justify-content-center h-100">
            {renderPopover()}
          </div>
        </div>
      </MainstayFlexTableCol>

      {showKBReviewModal && (
        <MarkForKnowledgeReviewModal
          loading={false}
          modalVisible={showKBReviewModal}
          toggleModal={() => setKBReviewModal(prev => !prev)}
          applyKnowledgeReviewMark={mark => onApplyKnowledgeReviewMark(mark)}
          knowledgeReviewMark={null}
          item={item}
        />
      )}
    </MainstayFlexTableRow>
  )
}
