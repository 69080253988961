import * as api from 'api'
import axios, { CancelTokenSource } from 'axios'
import { Button } from 'components/Button/Button'
import { PageHeader } from 'components/PageHeader/PageHeader'
import { replace } from 'connected-react-router'
import { isLeft } from 'fp-ts/Either'
import { default as ContactFeedbackStatistic } from 'page/dashboard/KnowledgeInsights/ContactFeedback/ContactFeedback'
import { fetchContactFeedback } from 'page/knowledge-base/ContactFeedback/actions'
import ContactFeedbackTable from 'page/knowledge-base/ContactFeedback/ContactFeedbackTable/ContactFeedbackTable'
import { KBReviewItemsFilterParams } from 'page/knowledge-base/KnowledgeReview/KnowledgeReviewFilter/KnowledgeReviewFilter'
import { KnowledgeSideBar } from 'page/knowledge-base/KnowledgeSideBar/KnowledgeSideBar'
import NavBarPage from 'page/NavBarPage'
import React from 'react'
import { useLocation } from 'react-router'
import { useDispatch } from 'util/hooks'
import {
  parseFirstQueryParam,
  parseQueryString,
  updateQueryString,
} from 'util/string'

import { RadioGroup } from 'components/RadioGroup/RadioGroup'
import { ReadOnlyDatePicker } from 'components/ReadOnlyDatePicker/ReadOnlyDatePicker'
import { Field, FieldProps, Formik, FormikProps } from 'formik'
import moment, { Moment } from 'moment-timezone'
import 'react-dates/lib/css/_datepicker.css'
import * as yup from 'yup'
import {
  CollapsibleFieldsSection,
  CustomDownloadModal,
  generateReportFileName,
  IPreferencesRequestPayload,
} from 'components/TrendsV2/CustomizedDownloadModal'
import { isRight } from 'fp-ts/lib/These'
import { openDownloadURL } from 'util/links'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { MainstayModal, ModalSize } from 'components/Modal/Modal'

export const CONTACT_FEEDBACK_PAGE_LIMIT = 10

export const ContactFeedbackParams = {
  Page: 'page',
  Limit: 'limit',
}

interface IIntroDialogDownloadFormProps {
  range: 'dates' | 'allTime'
  startDate: null | Moment
  endDate: null | Moment
}
interface IIntroDialogDownloadFormV2Props {
  range: 'dates' | 'allTime'
  startDate: null | Date
  endDate: null | Date
}

interface IDateRangeSelectModalProps {
  onClose: () => void
  initialDateRangeValues: IIntroDialogDownloadFormV2Props
  onSubmitDateRangeValues: (values: IIntroDialogDownloadFormV2Props) => void
}

const downloadFormSchema = yup.object().shape({
  range: yup
    .string()
    .matches(/(dates|allTime)/)
    .required(),
  startDate: yup.mixed().when('range', {
    is: 'dates',
    then: yup.mixed().required(),
    otherwise: yup.mixed(),
  }),
  endDate: yup.mixed().when('range', {
    is: 'dates',
    then: yup.mixed().required(),
    otherwise: yup.mixed(),
  }),
})

const DateRangeSelectModal = ({
  onClose,
  initialDateRangeValues,
  onSubmitDateRangeValues,
}: IDateRangeSelectModalProps) => {
  return (
    <Formik<IIntroDialogDownloadFormV2Props>
      validationSchema={downloadFormSchema}
      isInitialValid={true}
      initialValues={initialDateRangeValues}
      onSubmit={onSubmitDateRangeValues}
      render={(props: FormikProps<IIntroDialogDownloadFormV2Props>) => {
        return (
          <MainstayModal
            wrapContentInForm
            size={ModalSize.Large}
            show={true}
            onClose={onClose}
            text="Date Range"
            onSubmit={() => onSubmitDateRangeValues(props.values)}
            onCancel={onClose}
            disableSubmit={
              props.values.range === 'dates' &&
              !(props.values.startDate && props.values.endDate)
            }
            submitText="Next"
            cancelText="Cancel"
            submitTrackingEvent={{
              location: 'knowledge maintenenace',
              action: 'click',
              object: 'download webchat feedback report',
            }}
            cancelTrackingEvent={{
              location: 'knowledge maintenenace',
              action: 'click',
              object: 'cancel download webchat feedback report',
            }}>
            <Field
              name="range"
              render={({
                field,
              }: FieldProps<IIntroDialogDownloadFormProps>) => {
                return (
                  <div className="py-3">
                    <div className="mb-1">
                      Include webchat feedback for the following span of time:
                    </div>
                    <RadioGroup
                      className="d-flex flex-column"
                      inline={true}
                      selectedValue={props.values.range}
                      onChange={e => {
                        props.setFieldValue('range', e.target.value)
                      }}
                      items={[
                        {
                          name: field.name,
                          id: 'allTime',
                          value: 'allTime',
                          label: 'All time',
                          checked: props.values.range === 'allTime',
                        },
                        {
                          name: field.name,
                          id: 'dates',
                          value: 'dates',
                          label: 'Select a date range',
                          checked: props.values.range === 'dates',
                        },
                      ]}
                    />
                  </div>
                )
              }}
            />
            {props.values.range === 'dates' && (
              <div className="w-100 d-flex align-items-center pt-2 pb-4 px-3">
                <div className="d-flex flex-column mr-3">
                  <div className="mainstay-body-caption mb-2 text-mainstay-dark-blue-80">
                    Start Date
                  </div>
                  <ReadOnlyDatePicker
                    value={props.values.startDate ?? undefined}
                    onDayChange={startDate => {
                      props.setValues({ ...props.values, startDate })
                    }}
                    dayPickerProps={{
                      disabledDays: {
                        after: moment()
                          .startOf('day')
                          .toDate(),
                      },
                    }}
                    classNames={{
                      container: 'DayPickerInput-Container mr-1',
                      overlayWrapper: 'DayPickerInput-OverlayWrapper',
                      overlay: 'DayPickerInput-Overlay',
                    }}
                  />
                </div>
                <div className="d-flex flex-column">
                  <div className="mainstay-body-caption mb-2 text-mainstay-dark-blue-80">
                    End Date
                  </div>
                  <ReadOnlyDatePicker
                    value={props.values.endDate ?? undefined}
                    onDayChange={endDate => {
                      props.setValues({ ...props.values, endDate })
                    }}
                    dayPickerProps={{
                      disabledDays: {
                        before: props.values.startDate || undefined,
                        after: moment()
                          .startOf('day')
                          .toDate(),
                      },
                    }}
                    classNames={{
                      container: 'DayPickerInput-Container mr-1',
                      overlayWrapper: 'DayPickerInput-OverlayWrapper',
                      overlay: 'DayPickerInput-Overlay',
                    }}
                  />
                </div>
              </div>
            )}
          </MainstayModal>
        )
      }}
    />
  )
}

const initialDateRangeValues: IIntroDialogDownloadFormV2Props = {
  range: 'allTime',
  startDate: null,
  endDate: null,
}

enum DownloadRequestState {
  Initial = 'Initial',
  InProgress = 'InProgess',
  Success = 'Success',
  Failed = 'Failed',
}

export default function ContactFeedback({
  fetchCounts,
}: {
  readonly fetchCounts: () => Promise<void>
}) {
  const dispatch = useDispatch()
  const location = useLocation()
  const requestCancelSource = React.useRef<CancelTokenSource | null>(null)

  const fetchData = React.useCallback(async () => {
    if (!location.search) {
      const newSearch = updateQueryString(
        {
          [KBReviewItemsFilterParams.Page]: 1,
          [KBReviewItemsFilterParams.Limit]: CONTACT_FEEDBACK_PAGE_LIMIT,
        },
        location.search
      )
      dispatch(replace({ search: newSearch }))
      return
    }

    dispatch(fetchContactFeedback.request())
    const params = getContactFeedbackParams()
    if (requestCancelSource.current) {
      requestCancelSource.current.cancel()
    }
    requestCancelSource.current = axios.CancelToken.source()
    const res = await api.contactFeedback({
      params,
      cancelToken: requestCancelSource.current.token,
    })
    if (isLeft(res)) {
      if (res.left.kind !== 'cancel') {
        dispatch(fetchContactFeedback.failure())
      }
      return
    }
    dispatch(fetchContactFeedback.success(res.right))
  }, [dispatch, location.search])

  React.useEffect(() => {
    fetchData()
  }, [fetchData])

  const [dateRangeValues, setDateRangeValues] = React.useState<
    IIntroDialogDownloadFormV2Props
  >(initialDateRangeValues)
  const [showDateRangeModal, setShowDateRangeModal] = React.useState<boolean>(
    false
  )
  const [showSelectFieldsModal, setShowSelectFieldsModal] = React.useState<
    boolean
  >(false)
  const [downloadRequestState, setDownloadRequestState] = React.useState(
    DownloadRequestState.Initial
  )

  const handleClose = React.useCallback(() => {
    setShowSelectFieldsModal(false)
    setShowDateRangeModal(false)
    setDownloadRequestState(DownloadRequestState.Initial)
    setDateRangeValues(initialDateRangeValues)
  }, [])

  const pollProgress = React.useCallback(
    (taskId: string) => {
      api.pollDownloadContactFeedbackReport(taskId).then(res => {
        if (isRight(res)) {
          if (res.right.status === 'SUCCESS' && res.right.report_url) {
            setDownloadRequestState(DownloadRequestState.Success)
            handleClose()
            openDownloadURL({
              url: res.right.report_url,
            })
            setTimeout(
              () => setDownloadRequestState(_p => DownloadRequestState.Initial),
              3000
            )
          } else {
            setTimeout(pollProgress, 2000, taskId)
          }
        } else {
          handleClose()
          toast.error('Failed to download the contact feedback report.')
        }
      })
    },
    [handleClose]
  )

  const onDownloadContactFeedback = React.useCallback(
    (preferences?: IPreferencesRequestPayload) => {
      setDownloadRequestState(DownloadRequestState.InProgress)
      api
        .downloadContactFeedbackReport({
          preferences,
          startDate: dateRangeValues.startDate,
          endDate: dateRangeValues.endDate,
        })
        .then(res => {
          if (isRight(res)) {
            pollProgress(res.right.taskId)
          } else {
            handleClose()
            toast.error('Failed to download the contact feedback report.')
          }
        })
    },
    [
      pollProgress,
      dateRangeValues.startDate,
      dateRangeValues.endDate,
      handleClose,
    ]
  )

  const getPreferenceData = React.useCallback(
    () =>
      api.getReportPreferences({
        insight: 'webchat_feedback_report',
        contactsParams: {
          searchQuery: '',
          filters: {
            startDate: dateRangeValues.startDate
              ?.toISOString()
              .substring(0, 10),
            endDate: dateRangeValues.endDate?.toISOString().substring(0, 10),
          },
        },
      }),
    [dateRangeValues.startDate, dateRangeValues.endDate]
  )

  return (
    <NavBarPage title="Knowledge Base" className="d-flex h-100">
      <KnowledgeSideBar
        topics={[]}
        totalApprovedSeedsCount={0}
        totalBlankAnswers={0}
        totalSeedsCount={0}
        totalUnapprovedSeedsCount={0}
        pageContent={
          <>
            <PageHeader
              title="Webchat Feedback"
              description="After your webbot answers a question, contacts can rate the response
          and leave feedback.">
              <Button
                outlined
                onClick={() => setShowDateRangeModal(true)}
                color="secondary-teal">
                Download Report
              </Button>
            </PageHeader>
            <ContactFeedbackStatistic justGraph={true} />
            <ContactFeedbackTable fetchCounts={fetchCounts} />
          </>
        }
      />
      {showDateRangeModal && (
        <DateRangeSelectModal
          onClose={handleClose}
          initialDateRangeValues={dateRangeValues}
          onSubmitDateRangeValues={values => {
            setDateRangeValues(values)
            setShowDateRangeModal(false)
            setShowSelectFieldsModal(true)
          }}
        />
      )}
      {!showDateRangeModal && (
        <CustomDownloadModal
          initialFileName={generateReportFileName('Webchat_Feedback')}
          reportTitle="Webchat Feedback Report"
          submissionInProgress={
            downloadRequestState === DownloadRequestState.InProgress
          }
          onClose={handleClose}
          onSubmit={onDownloadContactFeedback}
          getPreferenceData={getPreferenceData}
          show={showSelectFieldsModal}
          reportSpecificFieldsSection={
            <CollapsibleFieldsSection
              name="reportSpecific"
              title="Report Fields"
            />
          }
        />
      )}
    </NavBarPage>
  )
}

export function getContactFeedbackParams(): api.ContactFeedbackParams {
  const urlParams = parseQueryString(location.search)

  return {
    page: parseInt(
      parseFirstQueryParam(urlParams[ContactFeedbackParams.Page]) || '1',
      10
    ),
    limit: CONTACT_FEEDBACK_PAGE_LIMIT,
  }
}
