export function FallbackResponseIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9.75 14.25H8.25V12.75H9.75V14.25ZM11.3025 8.4375L10.6275 9.1275C10.0875 9.675 9.75 10.125 9.75 11.25H8.25V10.875C8.25 10.05 8.5875 9.3 9.1275 8.7525L10.0575 7.8075C10.335 7.5375 10.5 7.1625 10.5 6.75C10.5 5.925 9.825 5.25 9 5.25C8.175 5.25 7.5 5.925 7.5 6.75H6C6 5.0925 7.3425 3.75 9 3.75C10.6575 3.75 12 5.0925 12 6.75C12 7.41 11.73 8.01 11.3025 8.4375Z"
        fill="#5F779A"
      />
    </svg>
  )
}
