import * as api from 'api'
import * as React from 'react'
import randomColor from 'randomcolor'
import { isLeft } from 'fp-ts/Either'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { Spinner } from 'components/Spinner/Spinner'
import { Button } from 'components/Button/Button'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { ContainerButton } from 'components/ContainerButton/ContainerButton'
import bpcCollectionWelcomeKitImage from 'page/calendar/bpc-collection-welcome-kit.png'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { PanelDrawer } from 'components/Panel/Panel'
import { HorizontalPaginationBar } from 'components/HorizontalPaginationBar/HorizontalPaginationBar'
import { Card } from 'components/Card/Card'
import { CancelTokenSource } from 'typings/axios'
import axios from 'axios'
import moment from 'moment-timezone'
import { Pill } from 'components/Pill/Pill'
import 'page/calendar/BestPracticesCampaignsCollections.scss'
import { Link } from 'util/routing'
import { CAMPAIGN_HISTORY } from 'const/routes'
import { generatePath } from 'react-router'
import { GroupIcon } from 'components/Icons/GroupIcon/GroupIcon'
import AdmithubOnly from 'components/AdmithubOnly/AdmithubOnly'

const UseBestPracticesCampaignsCollectionsModal = ({
  open,
  setOpen,
  addedCollectionIds,
  addCollections,
}: {
  readonly open: boolean
  readonly setOpen: (open: boolean) => void
  readonly addedCollectionIds: string[]
  readonly addCollections: (collectionIds: string[]) => Promise<void>
}) => {
  const [allCollections, setAllCollections] = React.useState<
    | api.BestPracticesCampaignsCollectionSuggestionCountShapeType[]
    | 'loading'
    | 'error'
  >('loading')
  const [selectedCollectionIds, setSelectedCollectionIds] = React.useState<
    string[]
  >([])
  const [addCollectionsLoading, setAddCollectionsLoading] = React.useState(
    false
  )

  const fetchAllCollections = React.useCallback(() => {
    api.bpccService.collections.list().then(res => {
      if (isLeft(res)) {
        setAllCollections('error')
        return
      }

      setAllCollections(res.right)
    })
  }, [setAllCollections])

  React.useEffect(() => {
    fetchAllCollections()
  }, [fetchAllCollections])

  const makeOnCheckBoxChange = (
    collectionId: string
  ): ((e: React.ChangeEvent<HTMLInputElement>) => void) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        // Add
        setSelectedCollectionIds(selectedCollectionIds => [
          ...selectedCollectionIds,
          collectionId,
        ])
      } else {
        // Remove
        setSelectedCollectionIds(selectedCollectionIds => [
          ...selectedCollectionIds.filter(id => id !== collectionId),
        ])
      }
    }
  }

  const makeToggleSelected = (collectionId: string): (() => void) => {
    return () => {
      if (selectedCollectionIds.includes(collectionId)) {
        // Remove
        setSelectedCollectionIds(selectedCollectionIds => [
          ...selectedCollectionIds.filter(id => id !== collectionId),
        ])
      } else {
        // Add
        setSelectedCollectionIds(selectedCollectionIds => [
          ...selectedCollectionIds,
          collectionId,
        ])
      }
    }
  }

  const selectAll = () => {
    if (allCollections === 'loading' || allCollections === 'error') {
      return
    }

    setSelectedCollectionIds(allCollections.map(collection => collection.id))
  }

  const doClose = () => {
    setSelectedCollectionIds([])
    setOpen(false)
  }

  const onAddCollections = async () => {
    setAddCollectionsLoading(true)
    try {
      await addCollections(selectedCollectionIds)
      doClose()
    } finally {
      setAddCollectionsLoading(false)
    }
  }

  return (
    <Modal
      isOpen={open}
      toggle={doClose}
      onClose={doClose}
      className="campaign-calendar-add-collection-modal">
      <ModalHeader
        toggle={doClose}
        close={
          <ContainerButton>
            <AHIcon
              name="close"
              className="campaign-calendar-add-collections-modal-header-close color-mainstay-dark-blue-80"
            />
          </ContainerButton>
        }>
        <h4 className="color-mainstay-dark-blue-80 mb-0">
          Add collections to your campaign plan
        </h4>
      </ModalHeader>

      <ModalBody className="p-0 m-0">
        <div className="campaign-calendar-add-collections-modal-body">
          <p className="color-mainstay-dark-blue-80">
            Mainstay has a proven formula to help you meet desired goals. Select
            the the welcome to kit to access Mainstays Best Practice approach to
            campaign sends. Below are the collections we currently offer . If
            you don't see the theme you need, please contact your sales rep.
          </p>

          <div>
            <h4 className="color-mainstay-blue-brand">Collections</h4>
            {allCollections === 'loading' ? (
              <>
                <Spinner
                  size="sm"
                  className="stroke-mainstay-dark-blue-80 mr-2"
                />
                Loading collections
              </>
            ) : allCollections === 'error' ? (
              <>Sorry, we failed to load collections</>
            ) : (
              <div className="d-flex">
                <ContainerButton
                  onClick={selectAll}
                  className="campaign-calendar-add-collection-modal-welcome-kit">
                  <img src={bpcCollectionWelcomeKitImage} />
                </ContainerButton>

                <div className="w-100">
                  {allCollections.map(collection => (
                    <Checkbox
                      key={`campaign-calendar-add-collection-modal-bpc-collection-${collection.id}`}
                      type="check"
                      checkBoxSize="md"
                      checkBoxClassName="mb-4"
                      containerClassName="campaign-calendar-add-collection-modal-checkbox-container pt-3"
                      checked={
                        addedCollectionIds.includes(collection.id) ||
                        selectedCollectionIds.includes(collection.id)
                      }
                      disabled={addedCollectionIds.includes(collection.id)}
                      onChange={makeOnCheckBoxChange(collection.id)}
                      titleComponent={
                        <ContainerButton
                          disabled={addedCollectionIds.includes(collection.id)}
                          onClick={makeToggleSelected(collection.id)}
                          className="w-100 d-flex flex-column w-100 campaign-calendar-add-collection-modal-checkbox-title cursor-pointer text-left">
                          <div className="w-100 d-flex flex-row justify-content-between">
                            <h5 className="d-flex color-mainstay-dark-blue pb-0 mb-0">
                              <strong>{collection.name}</strong>
                            </h5>
                            <span className="d-flex color-mainstay-dark-blue-80">
                              {collection.campaignSuggestionsCount} scripts
                            </span>
                          </div>
                          <span className="fw-400 color-mainstay-dark-blue-80">
                            {collection.description}
                          </span>
                        </ContainerButton>
                      }
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="campaign-calendar-add-collections-modal-footer">
          <Button
            className="color-mainstay-dark-blue-80"
            color="white"
            onClick={doClose}>
            Cancel
          </Button>
          <Button
            color="secondary-teal"
            disabled={
              addCollectionsLoading ||
              allCollections === 'error' ||
              allCollections === 'loading' ||
              selectedCollectionIds.length === 0
            }
            onClick={onAddCollections}
            className="d-flex flex-row align-items-center justify-content-center campaign-calendar-add-collections-modal-add-button">
            {addCollectionsLoading ? (
              <>
                <Spinner size="sm" className="stroke-white mr-2" />
                Add Selected
              </>
            ) : (
              <>
                <AHIcon name="add_circle_outline" />
                Add Selected
              </>
            )}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}
const BestPracticesCampaignsCollectionsListItem = ({
  collection,
  collectionColor,
  removeCollection,
  onClick,
}: {
  readonly collection: api.BestPracticesCampaignsCollectionOverviewShapeType
  readonly collectionColor: string
  readonly removeCollection: () => Promise<void>
  readonly onClick: (
    collection: api.BestPracticesCampaignsCollectionOverviewShapeType
  ) => void
}) => {
  const [removeLoading, setRemoveLoading] = React.useState(false)
  const [hovered, setHovered] = React.useState(false)

  const onRemoveCollectionClicked = async () => {
    setRemoveLoading(true)
    try {
      await removeCollection()
    } finally {
      setRemoveLoading(false)
    }
  }

  const onHover = () => setHovered(true)
  const onUnHover = () => setHovered(false)

  return (
    <div
      onMouseEnter={onHover}
      onMouseLeave={onUnHover}
      onFocus={onHover}
      onBlur={onUnHover}
      className="d-flex flex-row align-items-center my-2">
      <ContainerButton
        onClick={() => onClick(collection)}
        className="d-flex flex-row align-items-center w-100">
        <div
          className="calendar-best-practices-campaigns-item-circle"
          style={{
            borderColor: collectionColor,
          }}>
          {' '}
        </div>

        <div className="calendar-best-practices-campaigns-item-name pl-1">
          {collection.name}
        </div>
      </ContainerButton>
      <AdmithubOnly>
        <div className="calendar-best-practices-campaigns-item-remove">
          {removeLoading ? (
            <Spinner size="sm" className="stroke-mainstay-dark-blue" />
          ) : (
            <ContainerButton
              style={{
                opacity: hovered ? 1 : 0,
              }}
              className="calendar-best-practices-campaigns-item-remove-button"
              onClick={onRemoveCollectionClicked}>
              <AHIcon name="close" />
            </ContainerButton>
          )}
        </div>
      </AdmithubOnly>
    </div>
  )
}
export const BestPracticesCampaignsCollectionsList = ({
  onCollectionSelected,
}: {
  readonly onCollectionSelected: (
    collection: api.BestPracticesCampaignsCollectionOverviewShapeType
  ) => void
}) => {
  const [addedCollections, setAddedCollections] = React.useState<
    | api.BestPracticesCampaignsCollectionOverviewShapeType[]
    | 'loading'
    | 'error'
  >('loading')
  const [
    notAddedCollectionsCount,
    setNotAddedCollectionsCount,
  ] = React.useState<number | 'loading' | 'error'>('loading')
  const [showAddCollectionModal, setShowAddCollectionModal] = React.useState(
    false
  )

  const collectionColors = randomColor({
    seed: 1,
    count: addedCollections.length,
  })

  const fetchCollections = React.useCallback(async () => {
    const res = await api.bpccService.usage.list()
    if (isLeft(res)) {
      setAddedCollections('error')
      return
    }

    setAddedCollections(res.right.added_collections)
    setNotAddedCollectionsCount(res.right.not_added_collections.length)
  }, [])

  React.useEffect(() => {
    fetchCollections()
  }, [fetchCollections])

  const addCollections = React.useCallback(
    async (collectionIds: string[]) => {
      const res = await api.bpccService.usage.addUsage({
        collectionIds,
      })

      if (isLeft(res)) {
        toast.error('Failed to add best practices collections')
        return
      }

      await fetchCollections()
    },
    [fetchCollections]
  )

  const generateOnRemoveCollectionClicked = (
    collectionId: string
  ): (() => Promise<void>) => {
    return async () => {
      const res = await api.bpccService.usage.deleteUsage({
        collectionIds: [collectionId],
      })

      if (isLeft(res)) {
        toast.error('Failed to remove collection')
        return
      }

      await fetchCollections()
    }
  }

  return (
    <>
      <UseBestPracticesCampaignsCollectionsModal
        open={showAddCollectionModal}
        setOpen={setShowAddCollectionModal}
        addedCollectionIds={
          addedCollections !== 'loading' && addedCollections !== 'error'
            ? addedCollections.map(collection => collection.id)
            : []
        }
        addCollections={addCollections}
      />

      <div className="pl-4">
        {addedCollections === 'loading' ||
        notAddedCollectionsCount === 'loading' ? (
          <div className="d-flex">
            <Spinner size="sm" className="stroke-mainstay-dark-blue" />
            <div className="ml-2">Loading collections</div>
          </div>
        ) : addedCollections === 'error' ||
          notAddedCollectionsCount === 'error' ? (
          <>Failed to load collections</>
        ) : (
          <div className="d-flex flex-column">
            {addedCollections.length > 0 ? (
              <div className="color-mainstay-dark-blue-65 campaign-calendar-sidebar-description">
                Click to view best practices on a specific topic.
              </div>
            ) : (
              <div className="color-mainstay-dark-blue-65 campaign-calendar-sidebar-description">
                Add collections to view best practices on a specific topic.
              </div>
            )}

            {addedCollections.map((collection, i) => (
              <BestPracticesCampaignsCollectionsListItem
                key={`campaign-calendar-sidebar-bpc-collection-${collection.id}`}
                collection={collection}
                collectionColor={collectionColors[i]}
                removeCollection={generateOnRemoveCollectionClicked(
                  collection.id
                )}
                onClick={onCollectionSelected}
              />
            ))}
          </div>
        )}
      </div>
      <AdmithubOnly>
        {notAddedCollectionsCount !== 0 &&
          notAddedCollectionsCount !== 'loading' &&
          notAddedCollectionsCount !== 'error' && (
            <Button
              color="white"
              className="d-flex flex-row align-items-center color-mainstay-teal ml-2 mt-2"
              onClick={() => setShowAddCollectionModal(true)}>
              <AHIcon name="add_circle_outline" />
              Add A Collection
            </Button>
          )}
      </AdmithubOnly>
    </>
  )
}

const CAMPAIGN_SUGGESTION_MONTHS = [
  { label: 'JAN', value: '1' },
  { label: 'FEB', value: '2' },
  { label: 'MAR', value: '3' },
  { label: 'APR', value: '4' },
  { label: 'MAY', value: '5' },
  { label: 'JUN', value: '6 ' },
  { label: 'JUL', value: '7' },
  { label: 'AUG', value: '8' },
  { label: 'SEP', value: '9' },
  { label: 'OCT', value: '10' },
  { label: 'NOV', value: '11' },
  { label: 'DEC', value: '12' },
]

export function BestPracticesCampaignsCollectionDrawer({
  calendarDate,
  collection,
  onClose,
}: {
  readonly calendarDate: Date
  readonly collection: api.BestPracticesCampaignsCollectionOverviewShapeType | null
  readonly onClose: () => void
}) {
  const [paginationMonth, setPaginationMonth] = React.useState(
    (calendarDate.getMonth() + 1).toString()
  )
  const [campaignSuggestions, setCampaignSuggestions] = React.useState<
    | api.BestPracticesCampaignsCollectionCampaignSuggestionShapeType[]
    | 'loading'
    | 'error'
  >('loading')
  const fetchCampaignSuggestionsCancel = React.useRef<CancelTokenSource | null>(
    null
  )

  const fetchCampaignSuggestions = React.useCallback(
    async (collectionId: string, month: number) => {
      setCampaignSuggestions('loading')

      fetchCampaignSuggestionsCancel.current = axios.CancelToken.source()
      const res = await api.bpccService.campaignSuggestions.list({
        collectionId,
        month,
        cancelToken: fetchCampaignSuggestionsCancel.current.token,
      })
      if (isLeft(res)) {
        if (res.left.kind === 'cancel') {
          return
        }

        setCampaignSuggestions('error')
        return
      }

      setCampaignSuggestions(res.right)

      fetchCampaignSuggestionsCancel.current = null
    },
    []
  )

  const onSelectMonth = React.useCallback((month: string) => {
    if (fetchCampaignSuggestionsCancel.current !== null) {
      fetchCampaignSuggestionsCancel.current.cancel()
    }

    setPaginationMonth(month)
  }, [])

  React.useEffect(() => {
    if (collection === null) {
      return
    }

    fetchCampaignSuggestions(collection.id, parseInt(paginationMonth, 10))
  }, [collection, paginationMonth, fetchCampaignSuggestions])

  if (collection === null) {
    return null
  }

  return (
    <PanelDrawer
      width="21.8125rem"
      header={
        <div className="pl-4 pt-3">
          <div className="d-flex flex-row justify-content-between">
            <h4>{collection.name}</h4>
            <ContainerButton
              className="campaign-calendar-collection-drawer-close-button"
              onClick={onClose}>
              <AHIcon name="close" className="color-blue-grey-500" />
            </ContainerButton>
          </div>
          <p className="color-mainstay-dark-blue-80 fw-400 campaign-calendar-collection-drawer-description">
            {collection.description}
          </p>
        </div>
      }
      showHr={false}
      onClose={onClose}>
      <div className="px-4">
        <div className="d-flex flex-row justify-content-center">
          <HorizontalPaginationBar
            keyPrefix="campaign-calendar-collections"
            selected={paginationMonth}
            onSelect={onSelectMonth}
            options={CAMPAIGN_SUGGESTION_MONTHS}
          />
        </div>

        <div className="d-flex flex-column align-items-center mt-3">
          {campaignSuggestions === 'loading' ? (
            <div className="d-flex flex-row justify-content-center mt-3">
              <Spinner
                size="sm"
                className="stroke-mainstay-dark-blue-80 mr-2"
              />
              Loading campaign suggestions
            </div>
          ) : campaignSuggestions === 'error' ? (
            <p className="mt-3">Sorry, failed to load campaign suggestions</p>
          ) : campaignSuggestions.length > 0 ? (
            campaignSuggestions.map(suggestion => (
              <BestPracticesCampaignSuggestion
                key={`best-practices-campaigns-sidebar-suggestion-${suggestion.id}`}
                suggestion={suggestion}
              />
            ))
          ) : (
            <p className="mt-3">No campaign suggestions for this month</p>
          )}
        </div>
      </div>
    </PanelDrawer>
  )
}

function BestPracticesCampaignSuggestion({
  suggestion,
  onClick,
}: {
  readonly suggestion: api.BestPracticesCampaignsCollectionCampaignSuggestionShapeType
  readonly onClick?: (
    suggestion: api.BestPracticesCampaignsCollectionCampaignSuggestionShapeType
  ) => void
}) {
  const [showAllCampaigns, setShowAllCampaigns] = React.useState(false)

  const onCardClicked = () => onClick !== undefined && onClick(suggestion)

  const onShowMoreLessClick = () => setShowAllCampaigns(current => !current)

  return (
    <Card
      paddingClassName="py-2 pl-3 pr-2"
      className="w-100 mt-2 border-radius-05em border-mainstay-dark-blue-50"
      title={
        <ContainerButton
          onClick={onCardClicked}
          className="w-100 d-flex flex-row justify-content-between mb-2 justify-space-between">
          <p className="fw-600 color-mainstay-dark-blue m-0">
            {suggestion.suggestedName}
          </p>

          <AHIcon
            name="add_circle"
            className="color-secondary-teal"
            overrideWidth={true}
          />
        </ContainerButton>
      }>
      <ContainerButton
        onClick={onCardClicked}
        className="color-mainstay-dark-blue-80 fw-400 fs-0875rem text-left mb-0">
        {suggestion.suggestedDescription}
      </ContainerButton>

      {suggestion.usage.campaigns.length > 0 && (
        <div className="d-flex flex-column mt-3">
          {(showAllCampaigns
            ? suggestion.usage.campaigns
            : suggestion.usage.campaigns.slice(0, 5)
          ).map(campaign => (
            <BestPracticesCampaignSuggestionCampaign
              key={`best-practices-campaign-suggestion-campaign-${campaign.id}`}
              campaign={campaign}
            />
          ))}
          {suggestion.usage.campaigns.length > 5 && (
            <ContainerButton
              onClick={onShowMoreLessClick}
              className="color-mainstay-teal d-flex flex-row align-items-center justify-content-center mt-2">
              <AHIcon
                name={showAllCampaigns ? 'arrow_upward' : 'arrow_downward'}
              />
              Show {showAllCampaigns ? 'Less' : 'More'}
            </ContainerButton>
          )}
        </div>
      )}
    </Card>
  )
}

function BestPracticesCampaignSuggestionCampaign({
  campaign,
}: {
  readonly campaign: api.BestPracticesCampaignsCollectionCampaignSuggestionCampaignShapeType
}) {
  const scheduledAt = moment(campaign.scheduledAt)
  const sent = scheduledAt < moment()

  return (
    <Link
      to={generatePath(CAMPAIGN_HISTORY.DETAIL, {
        id: campaign.id,
      })}
      className="d-flex flex-row align-items-center mt-2">
      <div className="campaign-calendar-collection-drawer-item-campaign-pill">
        <Pill
          text={sent ? 'Sent' : 'Scheduled'}
          color={sent ? 'mainstay-success-100' : 'mainstay-blue-15'}
          textColor={sent ? 'mainstay-success-700' : 'mainstay-blue-70'}
        />
      </div>
      <p className="mb-0 ml-2 fw-400 color-mainstay-dark-blue-80 campaign-calendar-collection-drawer-item-campaign-date">
        {scheduledAt.format('YY.MM.DD')}
      </p>

      <div className="d-flex flex-row ml-2 align-items-center">
        <div className="campaign-calendar-collection-drawer-item-campaign-audience-icon ml-2">
          <GroupIcon />
        </div>
        <p className="fw-400 fs-0875rem color-mainstay-dark-blue-80 campaign-calendar-collection-drawer-item-campaign-audience mb-0">
          {campaign.contactFilterName}
        </p>
      </div>

      <AHIcon className="pl-2" name="open_in_new" />
    </Link>
  )
}
