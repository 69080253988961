export function AttributesIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.375 8.25H14.25V5.25C14.25 4.425 13.575 3.75 12.75 3.75H9.75V2.625C9.75 1.59 8.91 0.75 7.875 0.75C6.84 0.75 6 1.59 6 2.625V3.75H3C2.175 3.75 1.5075 4.425 1.5075 5.25V8.1H2.625C3.7425 8.1 4.65 9.0075 4.65 10.125C4.65 11.2425 3.7425 12.15 2.625 12.15H1.5V15C1.5 15.825 2.175 16.5 3 16.5H5.85V15.375C5.85 14.2575 6.7575 13.35 7.875 13.35C8.9925 13.35 9.9 14.2575 9.9 15.375V16.5H12.75C13.575 16.5 14.25 15.825 14.25 15V12H15.375C16.41 12 17.25 11.16 17.25 10.125C17.25 9.09 16.41 8.25 15.375 8.25Z"
        fill="#5F779A"
      />
    </svg>
  )
}
