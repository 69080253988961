import React, { useCallback, useEffect } from 'react'
import {
  IAsyncRows,
  IAsyncTableOptions,
  SortTableAsync,
} from 'components/TableAsyncSort/TableAsyncSort'
import { useSelector } from 'util/hooks'
import { getInstitutionDateFormat } from 'store/triage/institution/selectors'
import { isRight } from 'fp-ts/lib/Either'
import { generatePath } from 'react-router'
import { MainstayFlexTableCol } from 'mainstay-ui-kit/MainstayFlexCol/MainstayFlexCol'
import strftime from 'strftime'

import * as ROUTES from 'const/routes'
import { CampaignTag } from 'page/CampaignDetailsPage'
import { generateHelloPageLink } from 'page/hello-pages/SettingsHelloPagesList'
import { calculateResponsePercentage } from 'components/CampaignCard/CampaignCard'

import { listHelloPageCampaigns } from 'api/index'
import { useHistory } from 'react-router-dom'

const eventAction = 'click'
const eventLocation = 'hello page campaigns'

interface IHelloPageTableRow {
  helloPagePreferenceId: number
  name: string
  urlPath: string
  dialogName: string
  dialogId: string
  currentDialogId: string | null
  isRespondable: boolean
  countEligibleUsersProcessed: number
  distinctResponses: number
  lastSentDate: string | null
}

const HelloPageTableRow = ({ rowRes }: { rowRes: IHelloPageTableRow }) => {
  const history = useHistory()
  const dateFormat = useSelector(getInstitutionDateFormat)

  const responseRate =
    calculateResponsePercentage(
      rowRes.countEligibleUsersProcessed,
      rowRes.distinctResponses
    ) || '0'

  const routeToHelloDetailPage = (e: React.MouseEvent) => {
    e.preventDefault()
    history.push(
      generatePath(ROUTES.CAMPAIGN_HISTORY.HELLO_PAGE_DETAIL, {
        id: rowRes.helloPagePreferenceId,
        dialogId: rowRes.dialogId,
      })
    )
  }

  const routeToHelloPage = (
    e: React.MouseEvent,
    rowRes: IHelloPageTableRow
  ) => {
    e.stopPropagation()
    const path = generateHelloPageLink(rowRes.urlPath)
    window.open(path, '_blank')
  }
  return (
    <div
      onClick={routeToHelloDetailPage}
      className="hover-bg-mainstay-dark-blue-10 hover-text-decoration-none row text-mainstay-dark-blue-80 flex-nowrap no-gutters w-100 pointer">
      <MainstayFlexTableCol xs={5}>
        <div className="d-flex align-items-center">
          <h5 className="fw-600 mr-2 mb-0">{rowRes.name}</h5>
          <CampaignTag isIntroductory={true} />
        </div>
        <div className="fw-400">
          When a learner submits the opt-in form on{' '}
          <span
            style={{ color: '#14a28a' }}
            className="text-underline-on-hover"
            onClick={e => routeToHelloPage(e, rowRes)}>
            {generateHelloPageLink(rowRes.urlPath)}
          </span>
          , they receive "{rowRes.dialogName}"{' '}
          {rowRes.dialogId === rowRes.currentDialogId
            ? '(Currenty active)'
            : ''}
          .
        </div>
      </MainstayFlexTableCol>

      <MainstayFlexTableCol xs={3}>
        <div className="fw-600">
          {rowRes.isRespondable ? 'Interactive' : 'Nudge'}
        </div>
        <div className="fw-400">
          {rowRes.isRespondable ? `${responseRate}%` : ''}
        </div>
      </MainstayFlexTableCol>

      <MainstayFlexTableCol xs={2}>
        {rowRes.countEligibleUsersProcessed}
      </MainstayFlexTableCol>

      <MainstayFlexTableCol xs={2}>
        {rowRes.lastSentDate
          ? strftime(dateFormat, new Date(rowRes.lastSentDate))
          : ''}
      </MainstayFlexTableCol>
    </div>
  )
}

const DEFAULT_PAGE_SIZE = 30

export const HelloPagesCampaignTable = ({
  debouncedQuery,
}: {
  debouncedQuery?: string
}) => {
  const loadHelloDialogs = useCallback(
    async (
      { page = 1, sortBy, order }: IAsyncTableOptions,
      pageSize: number = DEFAULT_PAGE_SIZE
    ): Promise<IAsyncRows> => {
      const res = await listHelloPageCampaigns({
        sortBy,
        order,
        page,
        pageSize,
        query: debouncedQuery,
      })
      if (isRight(res)) {
        return {
          total: res.right.count || 0,
          rows: res.right.results.map(rowRes => [
            <HelloPageTableRow key={rowRes.urlPath} rowRes={rowRes} />,
          ]),
        }
      }
      return { total: 0, rows: [] }
    },
    [debouncedQuery]
  )

  useEffect(() => {
    loadHelloDialogs({ sortBy: 'date', order: 'desc' }, DEFAULT_PAGE_SIZE)
  }, [debouncedQuery, loadHelloDialogs])

  return (
    <SortTableAsync
      itemsName="hello page campaigns"
      loadRows={loadHelloDialogs}
      pageSize={DEFAULT_PAGE_SIZE}
      eventAction="click"
      rowEventObject="individual hello page campaign"
      pagerEventObject="of hello page campaigns"
      eventLocation="hello page campaigns"
      initialTableOptions={{
        sortBy: 'date',
        order: 'desc',
      }}
      columns={[
        {
          title: 'Name',
          xs: 5,
          sortByField: 'name',
          eventAction,
          eventLocation,
          eventTrackerName: 'hello page campaign name',
        },
        {
          title: 'Type',
          xs: 3,
          alignment: 'start',
          sortByField: 'type',
          eventAction,
          eventLocation,
          eventTrackerName: 'hello page campaign type',
        },
        {
          title: 'Sent',
          xs: 2,
          alignment: 'start',
          sortByField: 'sent',
          eventAction,
          eventLocation,
          eventTrackerName: 'hello page campaign sent',
        },
        {
          title: 'Last Sent',
          xs: 2,
          alignment: 'start',
          sortByField: 'date',
          eventAction,
          eventLocation,
          eventTrackerName: 'hello page campaign date sent',
        },
      ]}
    />
  )
}
