import * as React from 'react'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import 'components/SelectNodeRadio/SelectNodeRadio.scss'

const SelectNodeRadio: React.FC<{ onClick: () => void }> = props => {
  return (
    <AHIcon
      className="pointer select-node-radio"
      name="radio_button_unchecked"
      onClick={props.onClick}
    />
  )
}

export default SelectNodeRadio
