import * as React from 'react'
import { OutgoingNode } from 'components/OutgoingNode/OutgoingNode'
import { IBranchProps } from 'components/AutoBranch/AutoBranch'
import MediaNode from 'components/MediaNode/MediaNode'
import { EditableOutgoingNode } from 'components/EditableOutgoingNode/EditableOutgoingNode'
import EditableIncomingNode from 'components/EditableIncomingNode/EditableIncomingNode'
import { PromptType } from 'store/campaign-scripts/reducer'
import { getPromptTypeDefaults } from 'util/campaign-scripts'
import LinkingIndicator from 'components/LinkingIndicator/LinkingIndicatorConnected'
import SelectNodeRadio from 'components/SelectNodeRadio/SelectNodeRadio'
import { ITransitionType } from 'api/request'
import { VerticalNode } from 'components/NodeSubtree/NodeSubtree'
import { DEFAULT_DIALOG_STATE_MESSAGE } from 'const/settings'

export default class OpenBranch extends React.PureComponent<IBranchProps> {
  handleBranchLabelChange = async (
    _transitionKey: ITransitionType,
    value: string
  ) => {
    if (this.props.updateNode) {
      return await this.props.updateNode({
        dialogId: this.props.workflowStep.parentDialog,
        dialogStateId: this.props.workflowStep.id,
        data: {
          promptType: PromptType.open,
          openPromptLabel: value,
        },
      })
    }
  }

  handleAdd = (type: PromptType) => {
    if (this.props.spliceNode) {
      this.props.spliceNode({
        dialogId: this.props.workflowStep.parentDialog,
        dialogStateId: this.props.workflowStep.id,
        data: {
          ...getPromptTypeDefaults(type),
          prompt: DEFAULT_DIALOG_STATE_MESSAGE,
          promptType: type,
          transitionType: 'default',
        },
      })
    }
  }

  handleAddLink = () => {
    if (this.props.startLinking) {
      this.props.startLinking('default')
    }
  }

  handleRemoveMedia = () => {
    this.props.changeImage?.('')
  }

  handleEditNode = (editing: boolean) => {
    this.props.editNode?.({
      dialogStateId: this.props.workflowStep.id,
      editing,
    })
  }

  render() {
    const { workflowStep, isTerminal, editable, children, branch } = this.props
    return (
      <>
        <a id={workflowStep.id} />
        <VerticalNode>
          <div className="d-flex flex-row">
            {this.props.linking && this.props.createLink && (
              <SelectNodeRadio onClick={this.props.createLink} />
            )}
            <EditableOutgoingNode
              node={this.props.workflowStep}
              isTerminal={!!this.props.isTerminal}
              editing={this.props.editing}
              onEditNode={this.handleEditNode}
              onChangeImage={this.props.changeImage}
              onOptOut={this.props.optOut}
              onChange={this.props.updateNode}
              onDelete={this.props.deleteEdge}
              editable={editable}
              menuType={isTerminal ? 'full' : 'limited'}
              onClickAddLink={this.handleAddLink}
              onClickAddChild={this.handleAdd}
              value={workflowStep.prompt}
              personalizedPrompt={workflowStep.personalizedPrompt}
              setValueBeingSaved={this.props.setValueBeingSaved}>
              <OutgoingNode
                border={this.props.border}
                personalizedPrompt={workflowStep.personalizedPrompt}
                editable={editable}>
                {this.props.valueBeingSaved || workflowStep.prompt}
              </OutgoingNode>
            </EditableOutgoingNode>
          </div>
        </VerticalNode>
        {workflowStep.media && (
          <VerticalNode>
            <MediaNode
              url={workflowStep.media}
              onRemove={this.handleRemoveMedia}
            />
          </VerticalNode>
        )}
        <VerticalNode>
          <EditableIncomingNode
            onChange={this.handleBranchLabelChange}
            transitionKey="default"
            transitionType="PROMPT"
            value={
              workflowStep.openPromptLabel
                ? workflowStep.openPromptLabel
                : '(Incoming Message from Contact)'
            }
            menuType={isTerminal ? 'full' : 'limited'}
            onClickAddLink={this.handleAddLink}
            onClickAddChild={this.handleAdd}
            onEditNode={this.handleEditNode}
            // props.editable may be undefined here, the !! is to convert to boolean
            editable={!!editable}
          />
        </VerticalNode>
        {children}
        {this.props.linking && (
          <LinkingIndicator stepId={workflowStep.id} transition="default" />
        )}
        {branch}
      </>
    )
  }
}
