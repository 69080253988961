import classnames from 'classnames'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { WireframeMessage } from 'components/WireframeMessage/WireframeMessage'
import 'components/OutgoingNode/OutgoingNode.scss'

interface IOutgoingNodeProps {
  readonly className?: string
  readonly children: string
  readonly editable?: boolean
  readonly personalizedPrompt?: string | null
  readonly border?: boolean
}

export const FallbackTextMessage = () => (
  <p className="pt-2 text-mainstay-dark-blue-65 caption m-0">
    Contacts without this profile information receive a backup text.
  </p>
)
export const OutgoingNode = ({
  className,
  children,
  editable,
  personalizedPrompt,
  border,
}: IOutgoingNodeProps) => (
  <div
    className={classnames(
      'rounded text-blue-grey-080 bg-white d-flex node py-2 px-3 justify-content-between',
      className,
      {
        'hover-shadow-md pointer hover-opacity-100-parent': editable,
        'border border-color-bluegrey-30': border,
      }
    )}>
    <div className="d-block">
      <WireframeMessage
        message={personalizedPrompt || children}
        messageClassName="text-mainstay-dark-blue fs-14"
      />
      {personalizedPrompt && <FallbackTextMessage />}
    </div>
    {editable && (
      <AHIcon
        name="edit"
        overrideWidth={true}
        className={classnames(
          'text-mainstay-dark-blue-50 hover-opacity-100-child opacity-50'
        )}
      />
    )}
  </div>
)
