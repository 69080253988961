export const calculateResponsePercentage = (
  sent: number,
  responded: number | undefined
) => {
  const responseRate =
    responded && sent && Math.round((responded / sent) * 100 * 10) / 10
  if (responseRate && responseRate > 100) {
    return 100
  }
  return responseRate
}
