import 'components/Icons/PeopleSoftIcon/PeopleSoftIcon.scss'

interface IPeopleSoftIconProps {
  readonly height?: number | string
  readonly width?: number | string
}
export function PeopleSoftIcon({
  height = '32px',
  width = '100%',
}: IPeopleSoftIconProps) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 300 300">
      <title>-icon-color</title>
      <rect className="cls-1" width="300" height="300" />
      <rect className="cls-2" x="17.1" y="159.48" width="265.8" height="2.17" />
      <g id="Symbols">
        <g id="img_color-logo-oracle" data-name="img/color-logo-oracle">
          <g id="Oracle_logo" data-name="Oracle logo">
            <path
              id="path16197"
              className="cls-2"
              d="M134.42,131.49h16L142,117.89l-15.51,24.59h-7.06l18.87-29.54A4.45,4.45,0,0,1,142,111a4.37,4.37,0,0,1,3.63,1.86l18.94,29.59h-7.06l-3.33-5.5H138ZM207.76,137V111.33h-6v28.16a2.93,2.93,0,0,0,.87,2.09,3.07,3.07,0,0,0,2.19.9h27.32l3.53-5.5Zm-99.13-4.6a10.53,10.53,0,1,0,0-21H82.42v31.15h6V116.82h19.83a5,5,0,0,1,0,10.09l-16.89,0,17.88,15.59h8.7l-12-10.1Zm-63.08,10.1a15.58,15.58,0,1,1,0-31.15H63.66a15.58,15.58,0,1,1,0,31.15ZM63.26,137a10.08,10.08,0,1,0,0-20.16H46A10.08,10.08,0,1,0,46,137ZM177,142.48a15.58,15.58,0,1,1,0-31.15h21.51L195,116.82h-17.6a10.08,10.08,0,1,0,0,20.16H199l-3.53,5.5ZM250.3,137a10.08,10.08,0,0,1-9.7-7.33h25.61l3.53-5.5H240.6a10.11,10.11,0,0,1,9.7-7.33h17.58l3.55-5.49H249.9a15.58,15.58,0,1,0,0,31.15h18.47l3.53-5.5Z"
            />
            <path
              id="path16199"
              className="cls-2"
              d="M274.64,114.63a2.75,2.75,0,1,1,2.73,2.76A2.73,2.73,0,0,1,274.64,114.63Zm2.73,3.53a3.51,3.51,0,1,0-3.48-3.5A3.5,3.5,0,0,0,277.37,118.16Zm-.32-5.59a2.53,2.53,0,0,1,1,.12,1.11,1.11,0,0,1,.77,1.1,1,1,0,0,1,0,.32,1,1,0,0,1-.47.67l-.18.1.9,1.62h-.87l-.8-1.49h-.54v1.49h-.77v-3.93Zm.27,1.79c.25,0,.5,0,.65-.25a.56.56,0,0,0-.2-.82,1.9,1.9,0,0,0-.72-.07h-.22v1.14Z"
            />
          </g>
        </g>
      </g>
      <path
        className="cls-2"
        d="M52.52,177.66h8.13a8.64,8.64,0,0,1,4,.92A6.93,6.93,0,0,1,68.53,185a7.11,7.11,0,0,1-1,3.81,7,7,0,0,1-2.84,2.6,8.76,8.76,0,0,1-4,.92H56.88v8.49H52.52Zm8.26,10.49a3.39,3.39,0,0,0,2.54-.94,3.1,3.1,0,0,0,.92-2.26,3.06,3.06,0,0,0-.92-2.24,3.46,3.46,0,0,0-2.54-.92h-3.9v6.36Z"
      />
      <path
        className="cls-2"
        d="M72.21,177.66H86.87v4.13H76.57v5.36h9.26v4.13H76.57v5.36h10.3v4.13H72.21Z"
      />
      <path
        className="cls-2"
        d="M95.47,199.69a11.6,11.6,0,0,1-4.34-4.37,12.58,12.58,0,0,1,0-12.21,11.53,11.53,0,0,1,4.34-4.37,12.62,12.62,0,0,1,12.3,0,11.53,11.53,0,0,1,4.34,4.37,12.58,12.58,0,0,1,0,12.21,11.6,11.6,0,0,1-4.34,4.37,12.62,12.62,0,0,1-12.3,0Zm10.06-3.53a7.31,7.31,0,0,0,2.79-2.81,9,9,0,0,0,0-8.27,7.31,7.31,0,0,0-2.79-2.81,7.93,7.93,0,0,0-3.91-1,7.8,7.8,0,0,0-3.89,1A7.36,7.36,0,0,0,95,185.08a9,9,0,0,0,0,8.27,7.36,7.36,0,0,0,2.78,2.81,7.8,7.8,0,0,0,3.89,1A7.93,7.93,0,0,0,105.53,196.16Z"
      />
      <path
        className="cls-2"
        d="M117.82,177.66H126a8.6,8.6,0,0,1,4,.92,7,7,0,0,1,2.84,2.58,7,7,0,0,1,1,3.79,7.12,7.12,0,0,1-1,3.81,6.93,6.93,0,0,1-2.84,2.6,8.72,8.72,0,0,1-4,.92h-3.78v8.49h-4.36Zm8.27,10.49a3.36,3.36,0,0,0,2.53-.94,3.06,3.06,0,0,0,.92-2.26,3,3,0,0,0-.92-2.24,3.43,3.43,0,0,0-2.53-.92h-3.91v6.36Z"
      />
      <path className="cls-2" d="M137.52,177.66h4.35v19h9.79v4.13H137.52Z" />
      <path
        className="cls-2"
        d="M155.3,177.66H170v4.13h-10.3v5.36h9.27v4.13h-9.27v5.36H170v4.13H155.3Z"
      />
      <path
        className="cls-2"
        d="M176.06,199.58a9,9,0,0,1-3.26-4.85l4.1-1.61a6,6,0,0,0,1.65,2.94,4.06,4.06,0,0,0,2.9,1.1,4.26,4.26,0,0,0,2.49-.73,2.32,2.32,0,0,0,1-2,2.64,2.64,0,0,0-1-2.11,12.68,12.68,0,0,0-3.51-1.76l-1.42-.52a9.87,9.87,0,0,1-3.81-2.37,5.51,5.51,0,0,1-1.58-4,6,6,0,0,1,.94-3.29,6.41,6.41,0,0,1,2.64-2.34,8.58,8.58,0,0,1,3.88-.86,7.28,7.28,0,0,1,7.42,4.91l-3.84,1.61a3.85,3.85,0,0,0-1.24-1.79,3.5,3.5,0,0,0-2.28-.73,4,4,0,0,0-2.34.67,2,2,0,0,0-.92,1.72,2.21,2.21,0,0,0,.87,1.76,9.45,9.45,0,0,0,2.88,1.41l1.45.48a11.27,11.27,0,0,1,4.6,2.73,6.19,6.19,0,0,1,1.6,4.44,6.26,6.26,0,0,1-1.16,3.82,7,7,0,0,1-3,2.34,9.49,9.49,0,0,1-3.76.78A8.81,8.81,0,0,1,176.06,199.58Z"
      />
      <path
        className="cls-2"
        d="M198.22,199.69a11.6,11.6,0,0,1-4.34-4.37,12.58,12.58,0,0,1,0-12.21,11.53,11.53,0,0,1,4.34-4.37,12.62,12.62,0,0,1,12.3,0,11.53,11.53,0,0,1,4.34,4.37,12.58,12.58,0,0,1,0,12.21,11.6,11.6,0,0,1-4.34,4.37,12.62,12.62,0,0,1-12.3,0Zm10.06-3.53a7.31,7.31,0,0,0,2.79-2.81,8.89,8.89,0,0,0,0-8.27,7.31,7.31,0,0,0-2.79-2.81,7.93,7.93,0,0,0-3.91-1,7.8,7.8,0,0,0-3.89,1,7.36,7.36,0,0,0-2.78,2.81,9,9,0,0,0,0,8.27,7.36,7.36,0,0,0,2.78,2.81,7.8,7.8,0,0,0,3.89,1A7.93,7.93,0,0,0,208.28,196.16Z"
      />
      <path
        className="cls-2"
        d="M220.58,177.66h14.65v4.13h-10.3v5.62h9.27v4.13h-9.27v9.23h-4.35Z"
      />
      <path
        className="cls-2"
        d="M243.75,181.79H237.3v-4.13h17.27v4.13h-6.46v19h-4.36Z"
      />
    </svg>
  )
}
