import * as React from 'react'
import classnames from 'classnames'
import 'components/NodeSubtree/NodeSubtree.scss'
export interface INodeTreeProps {
  parent: React.ReactNode
  root?: boolean
  className?: string
}

export const HorizontalLine: React.FunctionComponent<{
  hideLine?: boolean
}> = props => {
  return (
    <div className="d-flex flex-column flex-grow-1 justify-content-around horizontal-line">
      <div className={classnames('flex-grow-1 top pt-3 pl-3')} />
      <div
        className={classnames('middle', { 'border-0': props.hideLine })}
        style={{ height: '1px' }}
      />
      <div className={classnames('flex-grow-1 bottom pl-3')} />
    </div>
  )
}

export const VerticalLine: React.FunctionComponent = props => {
  return (
    <div className="d-flex flex-column flex-grow-1 pl-3">
      <div
        style={{ width: '1px' }}
        className="vertical-line  align-self-start py-2 pr-2"
      />
      <div className="flex-grow-1">{props.children}</div>
    </div>
  )
}

export const VerticalNode: React.FunctionComponent<{
  className?: string
}> = props => {
  return (
    <div
      className={classnames(
        'd-flex flex-column vertical-node position-relative',
        props.className
      )}>
      <VerticalLine />
      {props.children}
    </div>
  )
}

export const HorizontalNode: React.FunctionComponent<{
  className?: string
  hideLine?: boolean
}> = props => {
  return (
    <div
      className={classnames(
        'd-flex flex-row horizontal-node position-relative',
        props.className
      )}>
      <div className="line-container">
        <HorizontalLine hideLine={props.hideLine} />
      </div>
      <div className="d-flex flex-column pt-3">{props.children}</div>
    </div>
  )
}

export default class NodeSubtree extends React.PureComponent<INodeTreeProps> {
  renderChildren() {
    const count = React.Children.count(this.props.children)
    if (this.props.children && count === 0) {
      return null
    }
    return React.Children.map(this.props.children, (x, i) => {
      if (!React.isValidElement(x)) {
        return
      }
      const classNames = classnames('node-content-child', {
        last: i === count - 1,
        first: i === 0,
      })
      return <div className={classNames}>{x}</div>
    })
  }

  render() {
    return (
      <div
        className={classnames('node-subtree', 'pl-3', this.props.className, {
          root: this.props.root,
        })}>
        <div className="node-parent d-flex flex-column">
          {this.props.parent}
        </div>
        <div className="node-content">
          {!!this.props.children && (
            <div className="flex-grow-1 pl-3">{this.renderChildren()}</div>
          )}
        </div>
      </div>
    )
  }
}
