import React from 'react'
import { PanelDrawer } from 'components/Panel/Panel'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { PopoverComponent } from 'components/PopoverComponent/PopoverComponent'
import { ContainerButton } from 'components/ContainerButton/ContainerButton'
import PermissionsGuard from 'util/permissions/PermissionsGuard'
import { PERMISSIONS } from 'util/permissions/permissions'
import { useKnowledgeSeed } from 'components/KnowledgeSeedEditPanel/KnowledgeSeedEditPanel'
import { KnowledgeSeedEditPanelContent } from 'components/KnowledgeSeedEditPanel/KnowledgeSeedEditPanelContent'
import { ConfirmationModal } from 'components/Modal/Modal'

interface IKnowledgeSeedEditPanelV2Props {
  understandingId: string
  onClose: () => void
  isNewlyCreated?: boolean
}

export const KnowledgeDrawerHeader = ({
  title,
  titleClassName,
  actions,
  onClose,
  knowledgeMatchDrawer = false,
  conversationMessageLink,
}: {
  title: string
  titleClassName?: string
  actions?: Omit<
    ReturnType<typeof useKnowledgeSeed>,
    'state' | 'showUnderstandingDeleteConfModal'
  >
  onClose: () => void
  knowledgeMatchDrawer?: boolean
  conversationMessageLink?: React.ReactNode
}) => {
  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex w-100 align-items-start justify-content-between py-3">
        <div className="d-flex align-items-center">
          {!knowledgeMatchDrawer && (
            <AHIcon
              className="ml-3 mr-2 fs-1_5rem rounded ah-knowledge-seeder-icon"
              name="knowledge-base"
            />
          )}
          <div className={titleClassName || 'knowledge-panel-title'}>
            {title}
          </div>
        </div>

        <div className="d-flex align-items-center pr-3">
          {actions && (
            <PopoverComponent
              className="d-flex"
              popoverPlacement="bottom-start"
              onClickOutside={() => {
                actions.setShowMore(false)
              }}
              tabIndex={-1}
              visible={actions.showMore}
              renderReference={() => (
                <ContainerButton
                  className="mr-2 text-mainstay-dark-blue-80"
                  onClick={() => actions.setShowMore(true)}>
                  <AHIcon name="more_horiz" />
                </ContainerButton>
              )}
              renderPopper={() => (
                <PermissionsGuard permission={PERMISSIONS.UNDERSTANDING.DELETE}>
                  <button
                    onClick={() => {
                      actions.setShowMore(false)
                      actions.setShowUnderstandingDeleteConfModal(true)
                    }}
                    className="list-group-item text-black-50 btn bg-white pointer d-flex align-items-center p-2 pr-3">
                    <AHIcon name="delete" />
                    <div>Delete</div>
                  </button>
                </PermissionsGuard>
              )}
            />
          )}
          <ContainerButton
            onClick={onClose}
            className="text-mainstay-dark-blue-80">
            <AHIcon name="close" />
          </ContainerButton>
        </div>
      </div>
      {conversationMessageLink && (
        <div className="ml-3">{conversationMessageLink}</div>
      )}
    </div>
  )
}

export function KnowledgeSeedEditPanelV2({
  understandingId,
  onClose,
  isNewlyCreated,
}: IKnowledgeSeedEditPanelV2Props) {
  const {
    state,
    showUnderstandingDeleteConfModal,
    ...actions
  } = useKnowledgeSeed({ understandingId, onClose })

  // Creating a bunch of Refs for use in KB Panel Answer Animations
  const panelRef = React.useRef<HTMLDivElement>(null)

  return (
    <PanelDrawer
      onClose={onClose}
      panelContentRef={panelRef}
      panelContentClassName="px-3"
      header={
        <KnowledgeDrawerHeader
          actions={actions}
          onClose={onClose}
          title="Understanding"
        />
      }>
      <KnowledgeSeedEditPanelContent
        understandingId={understandingId}
        onClose={onClose}
        isNewlyCreated={isNewlyCreated}
        panelRef={panelRef}
      />
      <ConfirmationModal
        show={showUnderstandingDeleteConfModal}
        hideCheckbox={true}
        zIndex={5000}
        helpText={
          <div>
            <p>Are you sure you want to delete this understanding?</p>
            <p>
              It will be removed from your bot's knowledge base and your bot
              will stop using it in response to incoming messages.
            </p>
          </div>
        }
        onConfirm={() => {
          actions.setShowUnderstandingDeleteConfModal(false)
          actions.archiveUnderstanding()
        }}
        title="Delete Understanding?"
        confirmButtonText="Yes, Delete"
        onClose={() => actions.setShowUnderstandingDeleteConfModal(false)}
      />
    </PanelDrawer>
  )
}
