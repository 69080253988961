import { ICounselors } from 'store/escalation/selectors'
import { ActionMeta, ValueType, MenuPlacement } from 'react-select/lib/types'
import { OptionProps } from 'react-select/lib/components/Option'
import scssVariables from 'scss/_variables.scss'
import { FloatingLabelSelect } from 'components/SettingsEscalation/reactSelectUtils'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import classNames from 'classnames'
import 'components/SettingsEscalation/CounselorSelect.scss'
import { CustomMultiValue } from 'components/AutoSuggest/AsyncAutoSuggest'
import { Chip } from 'components/Chip/Chip'

export interface ICounselorOption {
  label: string
  value: number
}

interface ICounselorSelectProps {
  id?: string
  counselors: ICounselors
  selectedCounselorId?: number | string
  className?: string
  hasError?: boolean
  onChange?: (value: ValueType<ICounselorOption>, meta: ActionMeta) => void
  menuPlacement?: MenuPlacement
  explicitPreventOverlap?: boolean
}

export function CounselorSelect({
  id,
  counselors,
  onChange,
  selectedCounselorId,
  className,
  hasError,
  menuPlacement,
  explicitPreventOverlap,
}: ICounselorSelectProps) {
  const options: ICounselorOption[] =
    counselors &&
    counselors.map(counselor => {
      return {
        value: counselor.id,
        label: `${counselor.name} <${counselor.email}>`,
      }
    })
  const selectedOption = options.find(
    option => option.value === selectedCounselorId
  )
  return (
    <FloatingLabelSelect<ICounselorOption>
      menuPlacement={menuPlacement}
      id={id || 'counselor'}
      options={options}
      onChange={onChange}
      value={selectedOption}
      classNamePrefix="react-select"
      className={className}
      placeholder=""
      floatingLabel="Email"
      hasError={hasError}
      explicitPreventOverlap={!!explicitPreventOverlap}
    />
  )
}

interface ICounselorMultiSelectProps {
  id?: string
  counselors: ICounselors
  selectedCounselorIds: number[]
  className?: string
  hasError?: boolean
  onChange: (value: ValueType<ICounselorOption>, meta: ActionMeta) => void
  onRemoveSelectedValue: (option: ValueType<ICounselorOption>) => void
  menuPlacement?: MenuPlacement
  explicitPreventOverlap?: boolean
}

interface ICustomCounselorOptionProps<ICounselorOption>
  extends OptionProps<ICounselorOption> {}

const CustomCounselorOption = (
  props: ICustomCounselorOptionProps<ICounselorOption>
) => {
  const { innerProps, innerRef } = props
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={props.getStyles('option', props)}
      className={classNames(
        'react-select__option d-flex align-items-center justify-content-between',
        { 'react-select__option--is-selected': props.isSelected }
      )}>
      {/* tslint:disable-next-line: no-unsafe-any */}
      <div>{props.data.label}</div>
      {props.isSelected ? (
        <div>
          <AHIcon
            name="check"
            style={{ color: scssVariables.mainstaySuccess500 }}
          />
        </div>
      ) : null}
    </div>
  )
}

export const CounselorMultiSelect = ({
  id,
  counselors,
  onChange,
  selectedCounselorIds,
  className,
  hasError,
  menuPlacement,
  explicitPreventOverlap,
  onRemoveSelectedValue,
}: ICounselorMultiSelectProps) => {
  const options: ICounselorOption[] =
    counselors &&
    counselors.map(counselor => {
      return {
        value: counselor.id,
        label: `${counselor.name} <${counselor.email}>`,
      }
    })
  const selectedOptions = options.filter(option =>
    selectedCounselorIds?.includes(option.value)
  )

  return (
    <>
      <FloatingLabelSelect<ICounselorOption>
        isMulti={true}
        menuPlacement={menuPlacement}
        isClearable={false}
        id={id || 'counselor'}
        options={options}
        onChange={onChange}
        value={selectedOptions}
        classNamePrefix="react-select"
        className={className}
        placeholder="Email"
        floatingLabel=""
        hasError={hasError}
        explicitPreventOverlap={!!explicitPreventOverlap}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option: props => <CustomCounselorOption {...props} />,
          MultiValue: props => <CustomMultiValue {...props} />,
        }}
        controlShouldRenderValue={false}
      />
      <div className="mt-2 selected-counselors-container">
        {selectedOptions.map(elem => (
          <Chip
            type="clear"
            key={elem.value}
            label={elem.label}
            className="bg-mainstay-dark-blue-10 react-select__multi-value w-content"
            onClear={() => onRemoveSelectedValue(elem)}
          />
        ))}
      </div>
    </>
  )
}
