import moment from 'moment-timezone'
import { Button } from 'components/Button/Button'
import 'react-dates/lib/css/_datepicker.css'
import { DayPickerRangeController, FocusedInputShape } from 'react-dates'
import { MainstayModal, ModalSize } from 'components/Modal/Modal'

interface IDateRangeModalProps {
  isOpen: boolean
  onToggle: () => void
  dateRange: {
    startDate: moment.Moment
    endDate: moment.Moment
  }
  onChangeDateRange: (dateRanges: {
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }) => void
  focusedInput: FocusedInputShape
  onFocusChange: (focusedInput: FocusedInputShape | null) => void
  isOutsideRange: (day: moment.Moment) => boolean
  onClickSubmit: () => void
}

export const DateRangeModal = ({
  isOpen,
  onToggle,
  dateRange,
  onChangeDateRange,
  focusedInput,
  onFocusChange,
  isOutsideRange,
  onClickSubmit,
}: IDateRangeModalProps) => {
  return (
    <MainstayModal
      show={isOpen}
      size={ModalSize.Large}
      text="Select a date range"
      onClose={onToggle}
      hideFooter>
      <div className="d-flex justify-content-around">
        <DayPickerRangeController
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          numberOfMonths={2}
          onDatesChange={({ startDate, endDate }) =>
            onChangeDateRange({ startDate, endDate })
          }
          focusedInput={focusedInput}
          onFocusChange={(focusedInput: FocusedInputShape | null) =>
            onFocusChange(focusedInput)
          }
          isOutsideRange={(day: moment.Moment) => isOutsideRange(day)}
        />
      </div>
      <div className="w-100 mt-4 mb-3 d-flex align-items-center justify-content-end">
        <Button color="primary" onClick={onClickSubmit}>
          Generate
        </Button>
      </div>
    </MainstayModal>
  )
}
