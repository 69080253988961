import React, { lazy, Suspense, useEffect } from 'react'
import * as ROUTES from 'const/routes'
import { Location as ILocation } from 'history'
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch as RRSwitch,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { history } from 'store/store'
import { ConnectedRouter } from 'connected-react-router'
import flattenChildren from 'react-flatten-children'
import NoMatch from 'page/NoMatch'
import { setLoginMessage } from 'store/auth/actions'
import { hot } from 'react-hot-loader/root'
import { setConfig } from 'react-hot-loader'
import 'react-dates/initialize'
import AutoLogout from 'components/AutoLogout/AutoLogoutConnected'
import { ChunkLoading } from 'components/ChunkLoading/ChunkLoading'
import { useDispatch, useIsLoggedIn } from 'util/hooks'
import ScriptLibraryAdmin from 'components/ScriptLibraryAdmin/ScriptLibraryAdmin'
import SettingsBrowseIntegrationsPage from 'page/SettingsBrowseIntegrations'
import SettingsTraySolution from 'page/SettingsTraySolution'
import IntegrationsErrorsPage from 'page/IntegrationsErrorPage'
import CommandsIndexPage from 'page/knowledge-base/Commands/CommandsIndexPage'
import { LiveUpdates } from 'components/LiveUpdates/LiveUpdates'
import AdmithubOnly from 'components/AdmithubOnly/AdmithubOnly'
import QuickSwitchOrganization from 'page/triage/QuickSwitchOrganization'
import {
  toast,
  ToastContainer,
} from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { ZendeskDisabledRoutes } from 'components/ZendeskHelpWidget/utils'
import KnowledgeBaseMaintenance from 'page/knowledge-base/KnowledgeBaseMaintenance/KnowledgeBaseMaintenance'
import {
  PermissionsProvider,
  usePermissionsContext,
} from 'util/permissions/PermissionsContext'
import { PERMISSIONS } from 'util/permissions/permissions'
import { LAST_PAGE_STORAGE_KEY } from 'util/routing'
import { getItemFromLocalStorage } from 'store/localStorage'
import { pathAllowsGuests } from 'api/sharedUtils'
import { InternalKnowledgeBaseScraping } from 'page/internal-tools/KnowledgeBaseScraping'

// The following is necessary to make hooks work
// see: https://github.com/gaearon/react-hot-loader
setConfig({ ignoreSFC: true })

declare global {
  // tslint:disable-next-line interface-name
  interface Window {
    environment?: string
    Appcues?: {
      identify: (
        userId: string,
        details: { [k: string]: number | boolean | string | null | undefined }
      ) => void
      page: () => void
      loadLaunchpad: (
        targetDiv: string,
        options: {
          position: string
          header?: string
          footer?: string
          icon?: string
        }
      ) => void
    }
    heap?: {
      identify: (userId: string) => void
      addUserProperties: (data: {
        [k: string]: string | number | boolean | undefined
      }) => void
      track: (event: string, properties?: object) => void
    }
    // https://developer.zendesk.com/api-reference/widget/api/
    zE?: {
      setHelpCenterSuggestions?: (_: { url: string }) => void
      hide?: () => void
      show?: () => void
    }

    /**
     * Sometimes the Mainstay permalink button in the sidebar is not accessible due to a drawer or other UI element obscuring it.
     * In these cases a developer might still want the link, so a custom variable is set on the window which then can be retrieved via the console.
     */
    mainstayPermalink: string
  }
}

// lazy-load route components
const DashboardPage = lazy(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "DashboardPage" */ 'page/dashboard/DashboardPage'
  )
)
const Triage = lazy(() =>
  import(/* webpackChunkName: "Triage" */ 'page/triage/TriageApp')
)
const ScheduledCampaigns = lazy(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "ScheduledCampaigns" */ 'page/calendar/CalendarContainer'
  )
)
const CampaignHistoryPage = lazy(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "CampaignHistoryPage" */ './campaign/CampaignHistory'
  )
)
const CampaignDetailsPage = lazy(() =>
  import(
    /* webpackChunkName: "CampaignDetailsPage" */ 'page/CampaignDetailsPage'
  )
)
const TriggerDetailsPage = lazy(() =>
  import(/* webpackChunkName: "TriggerDetailsPage" */ 'page/TriggerDetailsPage')
)
const CampaignDialogDetailsPage = lazy(() =>
  import(
    /* webpackChunkName: "CampaignDialogDetailsPage" */ 'page/CampaignDialogDetailsPage'
  )
)
const ContactsContainer = lazy(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "ContactsContainer" */ 'components/ContactsContainerPage/ContactsContainerPageConnected'
  )
)
const KnowledgeSeeder = lazy(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "KnowledgeSeeder" */ 'page/knowledge-base/KnowledgeSeederPage'
  )
)
const CampaignSchedulerPage = lazy(() =>
  import(
    /* webpackChunkName: "CampaignSchedulerPage" */ 'page/CampaignSchedulerPage'
  )
)
const MascotPicker = lazy(() =>
  import(/* webpackChunkName: "MascotPicker" */ 'page/MascotPicker')
)
const StudentUploadPage = lazy(() =>
  import(/* webpackChunkName: "StudentUploadPage" */ 'page/StudentUploadPage')
)
const CreateBotPage = lazy(() =>
  import(/* webpackChunkName: "CreateBotPage" */ 'page/CreateBotPageContainer')
)

const PasswordReset = lazy(() =>
  import(
    /* webpackChunkName: "PasswordReset" */ 'components/PasswordReset/PasswordResetConnected'
  )
)

const ForgotPassword = lazy(() =>
  import(/* webpackChunkName: "ForgotPassword" */ 'page/ForgotPasswordV2')
)

const UserAccount = lazy(() =>
  import(/* webpackChunkName: "UserAccount" */ 'page/UserAccount')
)

const SettingsManageUsersPage = lazy(() =>
  import(
    /* webpackChunkName: "SettingsManageUsersPage" */ './users-v2/ManageUsersPage'
  )
)

const AttributeMapper = lazy(() =>
  import(
    /* webpackChunkName: "AdminAttributeMapper" */ 'page/AdminAttributeMapper'
  )
)

const Login = lazy(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "Login" */ 'components/Login/LoginConnected'
  )
)

const SSOVerifiedPage = lazy(() =>
  import(/* webpackChunkName: "SSOVerified" */ 'page/SSOVerified')
)

const Enroll = lazy(() =>
  import(/* webpackChunkName: "Enroll" */ 'page/Enroll')
)
const CampaignScriptEditorPage = lazy(() =>
  import(
    /* webpackChunkName: "CampaignScriptEditorPage" */ 'page/CampaignScriptEditor'
  )
)
const CampaignScriptListPage = lazy(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "CampaignScriptListContainer" */ 'page/scripts/CampaignScriptListContainer'
  )
)
const TemplateScriptListPage = lazy(() =>
  import(
    /* webpackChunkName: "TemplateScriptListContainer" */ 'page/TemplateScriptListContainer'
  )
)
const SettingsGeneralPage = lazy(() =>
  import(/* webpackChunkName: "SettingsGeneral" */ 'page/SettingsGeneral')
)

const SettingsSMSPage = lazy(() =>
  import(/* webpackChunkName: "SettingsSMS" */ 'page/SettingsSMS')
)

const SettingsConversationPage = lazy(() =>
  import(
    /* webpackChunkName: "ConversationSettings" */ 'page/settings/ConversationSettings/ConversationSettings'
  )
)

const SettingsGenerativeAI = lazy(() =>
  import(
    /* webpackChunkName: "SettingsGenerativeAI" */ 'page/SettingsGenerativeAI'
  )
)

const SettingsEscalation = lazy(() =>
  import(/* webpackChunkName: "SettingsEscalation" */ 'page/SettingsEscalation')
)

const SettingsWebChatListPage = lazy(() =>
  import(
    /* webpackChunkName: "SettingsWebChatList" */ 'page/webchat/SettingsWebChatList/SettingsWebChatList'
  )
)

const SettingsWebChatFormPage = lazy(() =>
  import(
    /* webpackChunkName: "SettingsWebChatFormPage" */ 'page/webchat/SettingsWebChatForm/SettingsWebChatFormPage'
  )
)

const SettingsHelloPagesListPage = lazy(() =>
  import(
    /* webpackChunkName: "SettingsHelloPagesList" */ 'page/hello-pages/SettingsHelloPagesList'
  )
)

const SettingsHelloPagesConfig = lazy(() =>
  import(
    /* webpackChunkName: "SettingsHelloPagesConfig" */ 'page/hello-pages/SettingsHelloPagesConfig'
  )
)

const SettingsAPIAuthPage = lazy(() =>
  import(/* webpackChunkName: "SettingsAPIAuth" */ 'page/SettingsAPIAuth')
)
const SettingsSalesforcePage = lazy(() =>
  import(/* webpackChunkName: "SettingsSalesforce" */ 'page/SettingsSalesforce')
)

const SettingsSlackPage = lazy(() =>
  import(/* webpackChunkName: "SettingsSlackPage" */ 'page/SettingsSlack')
)

const SettingsTalkToTheBot = lazy(() =>
  import(
    /* webpackChunkName: "SettingsTalkToTheBot" */ 'page/SettingsTalkToTheBot'
  )
)

const IntroDialogDetailsPage = lazy(() =>
  import(
    /* webpackChunkName: "IntroDialogDetailsPage" */ 'page/IntroDialogDetailsPage'
  )
)

const AttributesPageContainer = lazy(() =>
  import(
    /* webpackChunkName: "AttributesPage" */ 'page/knowledge-base/AttributesPageContainer'
  )
)

const SpecialResponsesPageContainer = lazy(() =>
  import(
    /* webpackChunkName: "SpecialResponsesPage" */ 'page/knowledge-base/SpecialResponsesPage'
  )
)
const ConversationsPageV2 = lazy(() =>
  import(
    /* webpackChunkName: "ConversationsPageV2" */ 'page/conversations-v2/ConversationsPage'
  )
)

const BotDeletion = lazy(() =>
  import(
    /* webpackChunkName: "BotDeletion" */ 'page/internal-tools/BotDeletion'
  )
)

const AuditUserAccess = lazy(() =>
  import(
    /* webpackChunkName: "AuditUserAccess" */ 'page/internal-tools/AuditUserAccess'
  )
)

const UploadKnowledgeBase = lazy(() =>
  import(
    /* webpackChunkName: "UploadKnowledgeBase" */ 'page/internal-tools/UploadKnowledgeBase'
  )
)

const GenerativeAITransactions = lazy(() =>
  import(
    /* webpackChunkName: "GenerativeAITransactions" */ 'page/internal-tools/GenerativeAITransactions'
  )
)

const KnowledgeBaseScraping = lazy(() =>
  import(
    /* webpackChunkName: "KnowledgeBaseScraping" */ 'page/internal-tools/KnowledgeBaseScraping'
  )
)

const Debug = lazy(() => import(/* webpackChunkName: "Debug" */ 'page/Debug'))
const DebugSentryFrontend = lazy(() =>
  import(
    /* webpackChunkName: "DebugSentryFrontend" */ 'page/DebugSentryFrontend'
  )
)

interface IRouteProps<T> {
  readonly location?: ILocation
  readonly render?: (props: RouteComponentProps<T>) => React.ReactNode
  readonly children?:
    | ((props: RouteComponentProps<T>) => React.ReactNode)
    | React.ReactNode
  readonly path?: string
  readonly exact?: boolean
  readonly sensitive?: boolean
  readonly strict?: boolean
  readonly permission?: string
}

// NOTE: we console log redirects to help with debugging
export function PrivateRoute<T = {}>({
  render,
  permission,
  ...rest
}: IRouteProps<T>) {
  const { hasPermission, permissions, getPermissions } = usePermissionsContext()
  const isAuthed = useIsLoggedIn()
  const dispatch = useDispatch()
  const canAccess = hasPermission(permission)

  useEffect(() => {
    if (!permissions) {
      getPermissions()
    }
  }, [getPermissions, permissions])

  return (
    <Route
      {...rest}
      render={props => {
        if (render == null || (!permissions && isAuthed)) {
          return null
        }
        if (ZendeskDisabledRoutes.has(props.location.pathname)) {
          window.zE?.hide?.()
        } else {
          window.zE?.setHelpCenterSuggestions?.({
            url: props.location.pathname,
          })
          window.zE?.show?.()
        }
        if (pathAllowsGuests(props.location.pathname)) {
          return render(props)
        }
        if (isAuthed && canAccess) {
          return render(props)
        } else if (isAuthed && !canAccess) {
          toast(
            'Your assigned permission level doesn’t allow you to perform this action. Please contact an admin for more information.',
            {
              type: 'warning',
              options: {
                autoClose: false,
              },
            }
          )
          return (
            <Redirect
              to={{
                pathname: ROUTES.DASHBOARD.INDEX,
                state: { from: props.location },
              }}
            />
          )
        } else {
          /* tslint:disable-next-line no-console */
          console.log('Redirect: from private only route')
          dispatch(setLoginMessage('Please login to access this page.'))
          return (
            <Redirect
              to={{
                pathname: ROUTES.AUTH.LOGIN,
                state: { from: props.location },
              }}
            />
          )
        }
      }}
    />
  )
}

function useZendeskWidget() {
  React.useEffect(() => {
    const s = document.createElement('script')
    s.src =
      'https://static.zdassets.com/ekr/snippet.js?key=5b8c214b-22e5-46ef-8df6-4f3b467e44f7'
    s.defer = true
    s.id = 'ze-snippet'
    s.type = 'text/javascript'
    document.body.appendChild(s)
    return () => {
      document.body.removeChild(s)
    }
  }, [])
}

/** React Router doesn't handle React Fragments correctly so we need to use
 * this flattenChildren function to "flatten" the nodes into an array that works.
 * NOTE(chdsbd): I don't understand _why_ we need to do this, but we definitely do.
 */
const Switch: React.FunctionComponent = ({ children }) => (
  <RRSwitch>{flattenChildren(children)}</RRSwitch>
)

const getValidRootPathOrDefault = (pathName: string | null) => {
  const rootPathName = pathName?.split(/\/|\?/g).slice(1, 2)[0]
  return rootPathName && ROUTES.VALID_LAST_PAGE_REDIRECTS.has(rootPathName)
    ? `/${rootPathName}`
    : ROUTES.DEFAULT_ROUTE
}

function App() {
  // effect to enable toast dismissal on ESC key across the app
  React.useEffect(() => {
    const onKeyUp = (event: KeyboardEvent) => {
      if (event.key !== 'Escape') {
        return
      }
      toast.dismiss()
    }

    document.addEventListener('keyup', onKeyUp, false)
    return () => {
      document.removeEventListener('keyup', onKeyUp, false)
    }
  }, [])

  useZendeskWidget()

  const lastPage = getItemFromLocalStorage(LAST_PAGE_STORAGE_KEY) ?? null

  const rootRedirect = getValidRootPathOrDefault(lastPage)

  return (
    <div className="d-flex flex-column h-100">
      <AutoLogout />
      <Helmet defaultTitle="Mainstay" titleTemplate="%s - Mainstay" />
      <ToastContainer />
      <LiveUpdates />
      <PermissionsProvider>
        <ConnectedRouter history={history}>
          <Suspense fallback={<ChunkLoading />}>
            {/* NOTE: Here be dragons!
            Make sure that your params in your `path` props are correct
            because we cannot typecheck them currently.
            */}
            <Switch>
              <PrivateRoute
                exact
                path="/"
                render={() => <Redirect to={rootRedirect} />}
              />
              <PrivateRoute
                path={ROUTES.DASHBOARD.INDEX}
                render={() => <DashboardPage />}
              />
              <PrivateRoute
                path={ROUTES.TRIAGE.ROOT}
                render={() => (
                  <AdmithubOnly>
                    <Triage />
                  </AdmithubOnly>
                )}
              />
              <PrivateRoute
                exact
                path={ROUTES.CALENDAR.INDEX}
                render={() => <ScheduledCampaigns />}
              />
              <PrivateRoute
                exact
                path={ROUTES.CAMPAIGN_HISTORY.ROOT}
                render={() => <CampaignHistoryPage />}
              />
              <PrivateRoute<{ id: string }>
                exact
                path={ROUTES.CAMPAIGN_HISTORY.HELLO_PAGE_DETAIL}
                render={props => (
                  <CampaignDialogDetailsPage
                    isRecurringInstance={false}
                    isHelloPage={true}
                    {...props}
                  />
                )}
              />
              <PrivateRoute
                exact
                path={ROUTES.CAMPAIGN_HISTORY.DTC_DETAIL_TAB}
                render={props => (
                  <TriggerDetailsPage
                    isRecurringInstance={false}
                    isDataTriggered={true}
                    {...props}
                  />
                )}
              />
              <PrivateRoute<{ id: string }>
                exact
                path={ROUTES.CAMPAIGN_HISTORY.DETAIL}
                render={props => (
                  <CampaignDetailsPage isRecurringInstance={false} {...props} />
                )}
              />
              <PrivateRoute<{ id: string }>
                exact
                path={ROUTES.CAMPAIGN_HISTORY.RECURRING_DETAIL}
                render={props => (
                  <CampaignDetailsPage isRecurringInstance={true} {...props} />
                )}
              />
              <Route
                path="/knowledge/:category/:understanding_id"
                permission={PERMISSIONS.UNDERSTANDING.VIEW}
                render={(
                  props: RouteComponentProps<{
                    category: string
                    understanding_id: string
                  }>
                ) => {
                  const category = props.match.params['category']
                  const understandingId = props.match.params['understanding_id']
                  const newUrl = `/knowledge/${category}?understanding_id=${understandingId}`
                  return <Redirect to={newUrl} />
                }}
              />
              <PrivateRoute<{ category?: string }>
                path="/knowledge/:category?"
                permission={PERMISSIONS.UNDERSTANDING.VIEW}
                render={() => <KnowledgeSeeder />}
              />
              {/* Necessary to allow the campaign scheduler sidebar to highlight the correct route */}
              <Redirect
                from={ROUTES.CAMPAIGN_SCHEDULER_ROUTES.INDEX}
                to={`${ROUTES.CAMPAIGN_SCHEDULER_ROUTES.INDEX}${ROUTES.CAMPAIGN_SCHEDULER_ROUTES.TRIGGER_CONFIG}`}
                exact
              />
              <PrivateRoute
                path={ROUTES.CAMPAIGN_SCHEDULER_ROUTES.INDEX}
                render={() => <CampaignSchedulerPage />}
              />
              <PrivateRoute
                path={`${ROUTES.CAMPAIGN_SCHEDULER_ROUTES.EDIT_INDEX}:id`}
                render={() => <CampaignSchedulerPage />}
              />
              <PrivateRoute
                path={ROUTES.CAMPAIGN_HISTORY.EDIT_DATA_TRIGGERED_DETAIL}
                render={() => <CampaignSchedulerPage />}
              />
              {/* NOTE(sbdchd): UMN's CRM (Slate) causes a `-` to be inserted when they construct these URLs on the fly so we add a redirect to make their lives easier. */}
              <Redirect from="/conversations/-:id" to="/conversations/:id" />
              <Redirect
                from="/conversations/:id?"
                to="/conversations-v2/:id?"
              />
              <PrivateRoute
                path="/conversations-v2/:id?/:channel?"
                permission={PERMISSIONS.CONVERSATION.VIEW}
                render={(
                  props: RouteComponentProps<{
                    id: string
                    channel: string
                  }>
                ) => (
                  <ConversationsPageV2
                    contactId={props.match.params.id}
                    channel={props.match.params.channel}
                  />
                )}
              />
              <PrivateRoute
                exact
                path="/organizations/"
                render={() => <MascotPicker />}
              />
              <PrivateRoute
                exact
                path="/switch/:newOrg"
                render={(
                  props: RouteComponentProps<{
                    newOrg: string
                  }>
                ) => (
                  <QuickSwitchOrganization newOrg={props.match.params.newOrg} />
                )}
              />
              {/* TODO(jsimms): This needs to get removed at some point once everything in the review import epic is done */}
              <PrivateRoute
                exact
                path="/student-upload"
                render={() => <StudentUploadPage />}
              />
              <PrivateRoute
                path={ROUTES.CONTACTS.INDEX}
                permission={PERMISSIONS.CONTACT.VIEW}
                render={() => <ContactsContainer />}
              />
              <PrivateRoute
                path={ROUTES.CONTACTS.AUDIENCES}
                permission={PERMISSIONS.AUDIENCE.VIEW}
                render={() => <ContactsContainer />}
              />
              <PrivateRoute
                path="/commands/"
                render={() => <CommandsIndexPage />}
              />
              <PrivateRoute
                permission={PERMISSIONS.UNDERSTANDING.VIEW}
                path="/review-items/:reason?"
                render={(params: RouteComponentProps<{ reason: string }>) => {
                  return <KnowledgeBaseMaintenance params={params} />
                }}
              />
              <PrivateRoute
                exact
                path="/campaign-scripts/"
                permission={PERMISSIONS.SCRIPT.VIEW}
                render={() => <CampaignScriptListPage />}
              />
              <Redirect
                exact
                from="/campaign-scripts/:id"
                to="/campaign-scripts/:id/view"
              />
              <PrivateRoute<{ id: string }>
                exact
                path="/campaign-scripts/:id/view"
                permission={PERMISSIONS.SCRIPT.EDIT}
                render={props => (
                  <CampaignScriptEditorPage isTemplate={false} {...props} />
                )}
              />
              <PrivateRoute<{ id: string }>
                exact
                path="/campaign-script-library/:id/view"
                permission={PERMISSIONS.SCRIPT.EDIT}
                render={props => (
                  <CampaignScriptEditorPage isTemplate={true} {...props} />
                )}
              />
              <PrivateRoute
                path="/campaign-script-library/:publishFilter?"
                permission={PERMISSIONS.SCRIPT.VIEW}
                render={(
                  props: RouteComponentProps<{
                    publishFilter?: 'published' | 'unpublished'
                  }>
                ) => <TemplateScriptListPage {...props} />}
              />
              <PrivateRoute
                exact
                path="/bot/create"
                render={props => <CreateBotPage {...props} />}
              />
              <PrivateRoute
                exact
                permission={PERMISSIONS.SETTINGS.VIEW}
                path="/settings"
                render={() => <SettingsGeneralPage />}
              />
              <PrivateRoute
                exact
                permission={PERMISSIONS.SETTINGS.VIEW}
                path="/settings/sms"
                render={() => <SettingsSMSPage />}
              />
              <PrivateRoute
                exact
                permission={PERMISSIONS.SETTINGS.VIEW}
                path="/settings/conversations"
                render={() => <SettingsConversationPage />}
              />
              <PrivateRoute
                exact
                permission={PERMISSIONS.SETTINGS.VIEW}
                path="/settings/generativeAI"
                render={() => <SettingsGenerativeAI />}
              />
              <PrivateRoute
                exact
                permission={PERMISSIONS.SETTINGS.VIEW}
                path="/settings/escalation"
                render={() => <SettingsEscalation />}
              />
              <PrivateRoute
                exact
                permission={PERMISSIONS.SETTINGS.VIEW}
                path="/settings/hello-pages/:id"
                render={(props: RouteComponentProps<{ id: string }>) => {
                  return (
                    <SettingsHelloPagesConfig
                      id={
                        props.match.params.id === 'create'
                          ? props.match.params.id
                          : parseInt(props.match.params.id, 10)
                      }
                    />
                  )
                }}
              />
              <PrivateRoute
                exact
                permission={PERMISSIONS.SETTINGS.VIEW}
                path="/settings/hello-pages"
                render={() => <SettingsHelloPagesListPage />}
              />
              <PrivateRoute
                exact
                permission={PERMISSIONS.SETTINGS.VIEW}
                path="/settings/webchat"
                render={() => <SettingsWebChatListPage />}
              />
              <PrivateRoute
                exact
                path="/settings/webchat/create"
                render={() => <SettingsWebChatFormPage />}
              />
              <PrivateRoute
                exact
                path="/settings/webchat/duplicate/:id"
                render={(
                  props: RouteComponentProps<{
                    id: string
                  }>
                ) => {
                  return (
                    <SettingsWebChatFormPage
                      webchatId={parseInt(props.match.params.id, 10)}
                      isDuplicate={true}
                    />
                  )
                }}
              />
              <PrivateRoute
                exact
                path="/settings/webchat/:id"
                render={(
                  props: RouteComponentProps<{
                    id: string
                  }>
                ) => {
                  return (
                    <SettingsWebChatFormPage
                      webchatId={parseInt(props.match.params.id, 10)}
                    />
                  )
                }}
              />
              <PrivateRoute
                exact
                path="/settings/talk-to-bot"
                render={() => <SettingsTalkToTheBot />}
              />
              <PrivateRoute
                exact
                path="/settings/api-tokens"
                render={() => <SettingsAPIAuthPage />}
              />
              <PrivateRoute
                exact
                path="/settings/salesforce"
                render={() => <SettingsSalesforcePage />}
              />
              <PrivateRoute
                exact
                path="/settings/browse-integrations"
                render={() => <SettingsBrowseIntegrationsPage />}
              />
              <Redirect
                exact
                from="/settings/browse-integrations/sftp"
                to="/settings/browse-integrations/sftp-sync"
              />
              <PrivateRoute
                exact
                path="/settings/sync-log"
                render={() => <IntegrationsErrorsPage history={history} />}
              />
              <PrivateRoute
                exact
                path="/settings/browse-integrations/:solutionTitle"
                render={(
                  props: RouteComponentProps<{
                    solutionTitle: string
                  }>
                ) => (
                  <SettingsTraySolution
                    title={props.match.params['solutionTitle']}
                    {...props}
                  />
                )}
              />
              <PrivateRoute
                exact
                path="/settings/slack"
                render={() => <SettingsSlackPage />}
              />
              <PrivateRoute
                exact
                path="/users-v2"
                permission={PERMISSIONS.USER.VIEW}
                render={() => <SettingsManageUsersPage />}
              />
              <PrivateRoute
                exact
                path={ROUTES.INTRO_DIALOGS.UNKNOWN_STUDENT}
                render={() => {
                  return <IntroDialogDetailsPage kind="UNKNOWN_STUDENT" />
                }}
              />
              <PrivateRoute
                exact
                path={ROUTES.INTRO_DIALOGS.WEB_BOT}
                render={() => {
                  return <IntroDialogDetailsPage kind="WEB_BOT" />
                }}
              />
              <PrivateRoute
                exact
                path={ROUTES.INTRO_DIALOGS.WEB_HOOK}
                render={() => {
                  return <IntroDialogDetailsPage kind="WEB_HOOK" />
                }}
              />
              <PrivateRoute
                exact
                path={ROUTES.INTRO_DIALOGS.IM_SICK}
                render={() => {
                  return <IntroDialogDetailsPage kind="IM_SICK" />
                }}
              />
              <PrivateRoute
                exact
                path={ROUTES.AUTH.ACCOUNT}
                render={() => <UserAccount />}
              />
              <PrivateRoute
                exact
                path={ROUTES.ATTRIBUTES.INDEX}
                render={() => <AttributesPageContainer />}
              />
              <PrivateRoute
                exact
                path={ROUTES.SPECIAL_RESPONSES.INDEX}
                permission={PERMISSIONS.FALLBACK.VIEW}
                render={() => <SpecialResponsesPageContainer />}
              />
              <PrivateRoute
                exact
                path={ROUTES.INTERNAL.MAP_ATTRIBUTES}
                render={() => (
                  <AdmithubOnly fallback={() => <AttributesPageContainer />}>
                    <AttributeMapper />
                  </AdmithubOnly>
                )}
              />
              <PrivateRoute
                exact
                permission={PERMISSIONS.KNOWLEDGE_SOURCE.CREATE}
                path={ROUTES.KNOWLEDGE_SOURCES.INDEX}
                render={() => <KnowledgeBaseScraping />}
              />
              <PrivateRoute
                exact
                path={ROUTES.INTERNAL.DELETE_BOT}
                render={() => (
                  <AdmithubOnly>
                    <BotDeletion />
                  </AdmithubOnly>
                )}
              />
              <PrivateRoute
                exact
                path={ROUTES.INTERNAL.AUDIT_ACCESS}
                render={() => (
                  <AdmithubOnly>
                    <AuditUserAccess />
                  </AdmithubOnly>
                )}
              />
              <PrivateRoute
                exact
                path={ROUTES.INTERNAL.GENERATIVE_AI_TRANSACTIONS}
                render={() => (
                  <AdmithubOnly>
                    <GenerativeAITransactions />
                  </AdmithubOnly>
                )}
              />
              <PrivateRoute
                exact
                path={ROUTES.INTERNAL.KNOWLEDGE_BASE_SCRAPING}
                render={() => (
                  <AdmithubOnly>
                    <InternalKnowledgeBaseScraping />
                  </AdmithubOnly>
                )}
              />
              <PrivateRoute
                exact
                path={ROUTES.INTERNAL.UPLOAD_KNOWLEDGE_BASE}
                render={() => (
                  <AdmithubOnly>
                    <UploadKnowledgeBase />
                  </AdmithubOnly>
                )}
              />
              <PrivateRoute
                path="/internal/script-library"
                render={() => <ScriptLibraryAdmin />}
              />
              <Route exact path={ROUTES.AUTH.LOGIN} render={() => <Login />} />
              <Route
                exact
                path={ROUTES.AUTH.SSO_LOGIN_VERIFY}
                render={() => <SSOVerifiedPage />}
              />
              <Route
                exact
                path={ROUTES.AUTH.ENROLL}
                render={() => <Enroll />}
              />
              <Route
                exact
                path={ROUTES.AUTH.PASSWORD_RESET}
                render={() => <PasswordReset />}
              />
              <Route
                exact
                path={ROUTES.AUTH.FORGOT_PASSWORD}
                render={() => <ForgotPassword />}
              />
              <Route
                exact
                path={ROUTES.AUTH.PASSWORD_RESET_REQUEST}
                render={() => <PasswordReset />}
              />
              <Route exact path="/debug" render={() => <Debug />} />
              <Route
                exact
                path="/_sentry_frontend_debug_error"
                render={() => <DebugSentryFrontend />}
              />
              <Redirect from="/n(.*)" to="*" />
              <Redirect from="/import-reports/" to={ROUTES.CONTACTS.INDEX} />
              <Redirect from="/users" to="/users-v2" />
              <Redirect from="/test-chat" to="/settings/talk-to-bot" />
              <Redirect from="/bot" to="/settings" />
              <Redirect from="/testing/users" to="/users" />
              <Redirect from="/mascot/" to="/organizations/" />
              <Route component={NoMatch} />
            </Switch>
          </Suspense>
        </ConnectedRouter>
      </PermissionsProvider>
    </div>
  )
}

export default hot(App)
