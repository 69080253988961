import cls from 'classnames'
import 'components/Pill/Pill.scss'

type PillProps = {
  color?: string
  textColor?: string
  text: string
  className?: string
}

export const Pill = ({ color, textColor, text, className }: PillProps) => {
  const classNames = cls(
    className,
    color ? `bg-${color}` : 'bg-mainstay-success-600',
    textColor ? `text-${textColor}` : 'text-white',
    className,
    'ms-pill'
  )
  return <div className={classNames}>{text.toLocaleUpperCase()}</div>
}

export const LabeledDivider = ({
  className,
  ...props
}: PillProps & { className?: string }) => {
  return (
    <div className={cls('d-flex flex-wrap align-items-center', className)}>
      <div className="col dropdown-divider" />
      <Pill {...props} />
      <div className="col dropdown-divider" />
    </div>
  )
}
