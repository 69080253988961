import * as ROUTES from 'const/routes'

import { SkeletonNavBarPage } from 'page/NavBarPage'
import { matchPath } from 'react-router'

// match path to _not_ display a skeleton of the app sidebar. In the future
// we want to move the chrome (sidebar, navbar) outside of the chunks to the
// root of the app, so individual chunk loading doesn't rerender the app chrome.
// See AH-1051 for the issue.
const PAGES_WITHOUT_SIDEBAR = [
  ROUTES.AUTH.PASSWORD_RESET_REQUEST,
  ROUTES.AUTH.PASSWORD_RESET,
  ROUTES.AUTH.LOGIN,
  ROUTES.AUTH.ENROLL,
  ROUTES.AUTH.FORGOT_PASSWORD,
  ROUTES.TRIAGE.ROOT,
]

export function shouldHideSkeleton(path: string): boolean {
  return PAGES_WITHOUT_SIDEBAR.some(
    elem =>
      !!matchPath(path, {
        path: elem,
        exact: false,
        strict: false,
      })
  )
}

export function ChunkLoading() {
  const path = window.location.pathname
  if (shouldHideSkeleton(path)) {
    return null
  }
  return <SkeletonNavBarPage />
}
