import { useDispatch, useInstitutionId, useSelector } from 'util/hooks'
import React from 'react'
import {
  fetchProfileAsync,
  switchCurrentInstitutionAsync,
} from 'store/triage/profile/thunks'
import { getQueryFilters } from 'util/queryFilters'
import { ConfirmationModal } from 'components/Modal/Modal'
import { addToInstitution } from 'page/internal-tools/AuditUserAccess'
import { isLeft } from 'fp-ts/lib/Either'
import {
  getCanAuditUsers,
  getIsLoadingUsers,
  getUserProfile,
} from 'store/triage/profile/selectors'
import AdmithubOnly from 'components/AdmithubOnly/AdmithubOnly'
import { toastOnHttpError500or400 } from 'api/http'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'

export default function QuickSwitchOrganization({
  newOrg,
}: {
  readonly newOrg: string
}) {
  /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
  const redirect = getQueryFilters(window.location)['redirect'] as string
  const currentInstitutionId = useInstitutionId()

  const userProfile = useSelector(getUserProfile)
  const canAuditUsers = useSelector(getCanAuditUsers)
  const loadingUsers = useSelector(getIsLoadingUsers)

  const [show, setShow] = React.useState(false)
  const [status, setStatus] = React.useState<'switching' | 'failed'>(
    'switching'
  )
  const dispatch = useDispatch()

  const shouldLoadData = userProfile.id === ''
  React.useEffect(() => {
    if (shouldLoadData) {
      fetchProfileAsync()(dispatch)
    }
  }, [shouldLoadData, dispatch])

  React.useEffect(() => {
    if (loadingUsers) {
      return
    }
    if (!currentInstitutionId || !newOrg) {
      setStatus('failed')
      return
    }
    switchCurrentInstitutionAsync({
      currentInstitution: currentInstitutionId,
      newInstitution: newOrg,
      reloadBehavior: redirect ? 'redirect' : 'navigateRoot',
      redirect,
      propagateError: true,
    })(dispatch).catch(() => {
      if (canAuditUsers) {
        setShow(true)
      } else {
        setStatus('failed')
      }
    })
  }, [
    dispatch,
    currentInstitutionId,
    newOrg,
    redirect,
    loadingUsers,
    canAuditUsers,
  ])

  if (!currentInstitutionId || !newOrg || !userProfile.email) {
    return <p> Failed </p>
  }

  return (
    <AdmithubOnly allowStaffOnly>
      <p>{status}</p>
      <ConfirmationModal
        show={show}
        hideCheckbox={true}
        zIndex={5000}
        helpText={`Add your email address (${userProfile.email}) to the institution with ID, ${newOrg}.`}
        onConfirm={() => {
          addToInstitution(newOrg, userProfile.email ?? null).then(res => {
            if (isLeft(res)) {
              if (res.left.kind === 'http') {
                toastOnHttpError500or400(res.left.http)
              } else {
                toast.error('Unable to add user to the organization')
              }
              return
            }
            switchCurrentInstitutionAsync({
              currentInstitution: currentInstitutionId,
              newInstitution: newOrg,
              reloadBehavior: redirect ? 'redirect' : 'navigateRoot',
              redirect,
              propagateError: true,
            })(dispatch).catch(() => setStatus('failed'))
          })
        }}
        title="Join Institution"
        confirmButtonColor="primary"
        confirmButtonText="Yes, Join"
        onClose={() => setShow(false)}
      />
    </AdmithubOnly>
  )
}
