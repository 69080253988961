export interface IDonutChartIconProps {
  readonly height?: number | string
  readonly width?: number | string
  readonly muted?: boolean
}
export function DonutChartIcon({
  height = '24px',
  width = '24px',
  muted = false,
}: IDonutChartIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="bxs:doughnut-chart">
        <path
          id="Vector"
          d="M13 6C15.507 6.423 17.577 8.493 18 11H22C21.529 6.283 17.717 2.471 13 2V6Z"
          fill="#5F779A"
          fillOpacity={muted ? 0.5 : 1}
        />
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0508 17.949C15.0178 17.949 17.5218 15.833 17.9998 13H21.9998C21.4958 18.046 17.2268 21.949 12.0508 21.949C10.0155 21.949 8.12023 21.3378 6.53895 20.2893L9.46518 17.3631C10.2485 17.7386 11.1255 17.949 12.0508 17.949ZM7.80993 16.1899C6.72354 15.1035 6.05078 13.6035 6.05078 11.949C6.05078 8.982 8.16678 6.478 10.9998 6V2C5.95378 2.504 2.05078 6.773 2.05078 11.949C2.05078 14.706 3.17229 17.206 4.98305 19.0168L7.80993 16.1899Z"
          fill="#5F779A"
          fillOpacity={muted ? 0.5 : 1}
        />
      </g>
    </svg>
  )
}
