import * as React from 'react'
import SideEffectNode from 'components/SideEffectNode/SideEffectNode'
import { Input } from 'components/Input/Input'
import { Button } from 'components/Button/Button'
import { useDispatch } from 'util/hooks'
import { updateCampaignScriptStateAsync } from 'store/campaign-scripts/thunks'
import { ICampaignScriptStep } from 'store/campaign-scripts/reducer'
import { setShowPauseTimeForm } from 'store/campaign-scripts/actions'
import Select from 'components/Select/SelectV2'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import cls from 'classnames'

interface IPauseTimeNodeProps {
  pauseTimeSeconds: number
  dialogState: ICampaignScriptStep
  startInEditMode: boolean
  editable?: boolean
}

export const PauseTimeNode = ({
  dialogState,
  pauseTimeSeconds,
  startInEditMode,
  editable,
}: IPauseTimeNodeProps) => {
  const dispatch = useDispatch()
  const [editMode, setEditMode] = React.useState(startInEditMode)
  const handleSave = React.useCallback(
    (newTimeSeconds: number) => {
      updateCampaignScriptStateAsync(dispatch)({
        dialogId: dialogState.parentDialog,
        dialogStateId: dialogState.id,
        data: {
          pauseTime: newTimeSeconds,
        },
      })
      setEditMode(false)
    },
    [dialogState.id, dialogState.parentDialog, dispatch]
  )

  const handleCancel = React.useCallback(() => {
    dispatch(
      setShowPauseTimeForm({
        showForm: false,
        dialogStateId: dialogState.id,
      })
    )
    setEditMode(false)
  }, [dialogState.id, dispatch])
  const handleClick = React.useCallback(() => {
    // Don't allow the PauseTimeNode to enter Edit mode if the editable prop passed is set to false
    // the editable prop could be set to false if this node is contained in a CampaignPreviewCard
    // or if the script is locked because it has already been used in a campaign
    if (editable) {
      setEditMode(true)
    }
  }, [setEditMode, editable])
  if (editMode) {
    return (
      <PauseTimeForm
        pauseTimeSeconds={pauseTimeSeconds}
        onCancel={handleCancel}
        onSave={handleSave}
      />
    )
  } else {
    return (
      <SideEffectNode editable={editable} onClick={handleClick}>
        Pause for {pauseTimeSeconds} seconds
      </SideEffectNode>
    )
  }
}

interface IPauseTimeFormProps {
  pauseTimeSeconds: number
  onCancel: () => void
  onSave: (newTimeSeconds: number) => void
}

const PauseTimeForm = ({
  pauseTimeSeconds,
  onCancel,
  onSave,
}: IPauseTimeFormProps) => {
  const [timeUnit, setTimeUnit] = React.useState<
    'seconds' | 'minutes' | 'hours'
  >('seconds')
  const [timeValue, setTimeValue] = React.useState(pauseTimeSeconds)
  const handleUnitSelect = React.useCallback(
    (val: string) =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      setTimeUnit(val as 'seconds' | 'minutes' | 'hours'),
    []
  )
  const handleTimeValueChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setTimeValue(Number(e.target.value)),
    []
  )
  const handleSave = React.useCallback(() => {
    switch (timeUnit) {
      case 'seconds':
        onSave(timeValue)
        return
      case 'minutes':
        onSave(timeValue * 60)
        return
      case 'hours':
        onSave(timeValue * 60 * 60)
        return
    }
  }, [onSave, timeUnit, timeValue])

  const isValidTimeValue = timeValue > 0

  return (
    <div className="border rounded background-white shadow-md p-3 w-content">
      <div className="d-flex justify-content-between">
        <h6>Additional pause</h6>
        <AHIcon
          name="delete"
          onClick={() => onSave(0)}
          className="pointer opacity-50 hover-opacity-100 hover-text-new-ui-danger"
        />
      </div>
      <div className="mr-3 my-2 max-width-450">
        <span className="caption">
          The next message will be sent after this additional delay. Exact send
          times may be affected by overall message volume, device connectivity,
          and other factors.{' '}
        </span>
      </div>
      <div className="d-flex my-4">
        <Input
          type="number"
          value={isValidTimeValue ? timeValue : undefined}
          onChange={handleTimeValueChange}
          className="w-200px mr-3"
        />
        <Select
          className="w-200px"
          options={['seconds', 'minutes', 'hours'].map(u => ({
            label: u,
            value: u,
          }))}
          value={{ label: timeUnit, value: timeUnit }}
          onChange={opt => {
            if (Array.isArray(opt) || opt == null) {
              return
            }
            handleUnitSelect(opt.value)
          }}
        />
      </div>
      <hr className="w-100 m-0" />
      <div className="pt-3">
        <Button
          className={cls('mr-4 btn btn-primary bg-secondary-teal', {
            'pointer-events-none': !isValidTimeValue,
          })}
          disabled={!isValidTimeValue}
          onClick={handleSave}>
          Save
        </Button>
        <Button
          onClick={onCancel}
          className="text-blue-grey-501 text-decoration-none"
          color="link">
          Cancel
        </Button>
      </div>
    </div>
  )
}
