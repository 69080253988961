import * as React from 'react'
import {
  ScheduleJob,
  OptOut,
  UpdateHubspot,
} from 'components/ExitActions/ExitActionsSimple'
import { Save } from 'components/ExitActions/ExitActionsSave'
import { SendEmail } from 'components/ExitActions/ExitActionsSendEmail'
import {
  ExitActionType,
  IExitAction,
  ICampaignScriptStep,
  isSaveExitAction,
  isEmailExitAction,
  isHubspotContactExitAction,
  isScheduleJobExitAction,
  isWebhookExitAction,
  isSetMessagingStatusExitAction,
  isRagtimeAction,
} from 'store/campaign-scripts/reducer'
import { clearBlankExitAction } from 'store/campaign-scripts/actions'
import { updateCampaignScriptStateAsync } from 'store/campaign-scripts/thunks'
import { useDispatch } from 'util/hooks'
import { MessagingStatusEnum } from 'store/personalization/contactAttributes/selectors'
import { RagTimeAction } from 'components/ExitActions/ExitActionsRagtime'

const ExitActionConditionalRenderer = ({
  step,
  exitAction,
  editable,
  actionIndex,
  campaignReview = false,
}: {
  step: ICampaignScriptStep
  exitAction: IExitAction
  editable: boolean
  actionIndex: number
  campaignReview?: boolean
}) => {
  const [editMode, setEditMode] = React.useState(false)

  const dispatch = useDispatch()

  const handleUpdateNode = React.useCallback(
    (data: Partial<ICampaignScriptStep>) => {
      setEditMode(false)
      dispatch(clearBlankExitAction({ dialogStateId: step.id }))
      updateCampaignScriptStateAsync(dispatch)({
        dialogId: step.parentDialog,
        dialogStateId: step.id,
        data: {
          exitActions: (data.exitActions || []).filter(
            (a: IExitAction) => !a.newlyCreated
          ),
        },
      })
    },
    [dispatch, step.id, step.parentDialog]
  )

  const handleCancel = React.useCallback(() => {
    setEditMode(false)
    dispatch(clearBlankExitAction({ dialogStateId: step.id }))
  }, [dispatch, step.id])

  switch (exitAction.name) {
    case ExitActionType.Save: {
      if (isSaveExitAction(exitAction)) {
        return (
          <Save
            setEditMode={setEditMode}
            onCancel={handleCancel}
            editable={editable}
            step={step}
            editMode={editMode}
            onUpdateNode={handleUpdateNode}
            action={exitAction}
            actionIndex={actionIndex}
            border={campaignReview}
          />
        )
      }
    }
    case ExitActionType.ScheduleJob: {
      if (isScheduleJobExitAction(exitAction)) {
        if (exitAction.args.methodName === 'startDialog') {
          return <ScheduleJob />
        }
      }
    }
    case ExitActionType.SendEmail: {
      if (isEmailExitAction(exitAction)) {
        return (
          <SendEmail
            setEditMode={setEditMode}
            onCancel={handleCancel}
            editable={editable}
            step={step}
            editMode={editMode}
            onUpdateNode={handleUpdateNode}
            action={exitAction}
            actionIndex={actionIndex}
          />
        )
      }
    }
    case ExitActionType.sendToWebhook: {
      if (isWebhookExitAction(exitAction)) {
        return (
          <SendEmail
            setEditMode={setEditMode}
            onCancel={handleCancel}
            editable={editable}
            step={step}
            editMode={editMode}
            onUpdateNode={handleUpdateNode}
            action={exitAction}
            actionIndex={actionIndex}
          />
        )
      }
    }
    case ExitActionType.updateOrCreateHubspotContact: {
      if (isHubspotContactExitAction(exitAction)) {
        return <UpdateHubspot />
      }
    }
    case ExitActionType.SetMessagingStatus: {
      if (isSetMessagingStatusExitAction(exitAction)) {
        if (
          exitAction.args.newMessagingStatus === MessagingStatusEnum.Stopped
        ) {
          return (
            <OptOut
              deletable={!campaignReview}
              onUpdateNode={handleUpdateNode}
              step={step}
              actionIndex={actionIndex}
            />
          )
        }
      }
    }
    case ExitActionType.Ragtime: {
      if (isRagtimeAction(exitAction)) {
        return (
          <RagTimeAction
            setEditMode={setEditMode}
            onCancel={handleCancel}
            editable={editable}
            step={step}
            editMode={editMode}
            onUpdateNode={handleUpdateNode}
            action={exitAction}
            actionIndex={actionIndex}
          />
        )
      }
    }
    default: {
      return <></>
    }
  }
}

export default ExitActionConditionalRenderer
