import * as React from 'react'
import { FontAwesomeIcon as Icon, Props } from '@fortawesome/react-fontawesome'

export interface IFontAwesomeIconProps {
  onClick?: (e: React.MouseEvent) => void
}

// This component exists in order to isolate FontAwesome's react component as a dependency
// from our own code. Allows is to easily swap out or change things further down the line.
export class FontAwesomeIcon extends React.PureComponent<
  Props & IFontAwesomeIconProps
> {
  render() {
    const { className, onClick, ...rest } = this.props
    return (
      <span className={className} onClick={onClick}>
        <Icon {...rest} />
      </span>
    )
  }
}
