import React from 'react'
import { INTRO_DIALOGS as INTRO_DIALOGS_ROUTES } from 'const/routes'
import { IIntroDialog } from 'store/campaign-scripts/reducer'
import { INTRO_DIALOGS_COPY } from 'const/copy'
import { NavLink } from 'util/routing'
import { useSelector, useDispatch } from 'util/hooks'
import { getIntroDialogs } from 'store/campaign-scripts/selectors'
import { calculateResponsePercentage } from 'components/CampaignCard/CampaignCard'
import { fetchIntroDialogsListAsync } from 'store/campaign-scripts/thunks'
import {
  MainstayFlexTableCol,
  MainstayFlexTableHeaderCol,
} from 'mainstay-ui-kit/MainstayFlexCol/MainstayFlexCol'
import {
  MainstayFlexTableHeader,
  MainstayFlexTableRow,
} from 'mainstay-ui-kit/MainstayFlexRow/MainstayFlexRow'
import { MainstayFlexTable } from 'mainstay-ui-kit/MainstayFlexTable/MainstayFlexTable'
import { getInstitutionDateFormat } from 'store/triage/institution/selectors'
import strftime from 'strftime'
import { CampaignTag } from 'page/CampaignDetailsPage'

export interface IIntroDialogListItem
  extends Pick<IIntroDialog, 'id' | 'kind' | 'isRespondable'> {
  messagedUsersCount: number
  distinctResponsesCount: number
  lastSent: string | null
}

function useDialogs() {
  const dialogs = useSelector(getIntroDialogs)
  const dispatch = useDispatch()
  React.useEffect(() => {
    fetchIntroDialogsListAsync(dispatch)()
  }, [dispatch])

  return dialogs
}

const IntroCampaignsTableRow = ({
  id,
  isRespondable,
  kind,
  messagedUsersCount,
  distinctResponsesCount,
  lastSent,
}: IIntroDialogListItem) => {
  const dateFormat = useSelector(getInstitutionDateFormat)

  const getCampaignLastSent = React.useCallback(
    (sent: string | null) => {
      if (!sent) {
        return '-'
      }
      const sentDate = new Date(sent)
      return (
        <>
          <h5 className="fw-400 mb-0">{strftime(dateFormat, sentDate)}</h5>
          <h5 className="fw-400">{strftime('%l:%M %P', sentDate)}</h5>
        </>
      )
    },
    [dateFormat]
  )

  return (
    <NavLink
      to={INTRO_DIALOGS_ROUTES[kind]}
      key={id}
      className="w-100 campaign-row pointer hover-text-decoration-none">
      <MainstayFlexTableRow className="text-mainstay-dark-blue-80 hover-bg-mainstay-dark-blue-10">
        <MainstayFlexTableCol xs={5}>
          <div className="d-flex align-items-center">
            <h5 className="fw-600 mb-0 mr-2">
              {INTRO_DIALOGS_COPY.title[kind]}{' '}
            </h5>
            <CampaignTag isIntroductory={true} />
          </div>
          <h5 className="fw-400">{INTRO_DIALOGS_COPY.description[kind]}</h5>
        </MainstayFlexTableCol>
        <MainstayFlexTableCol xs={2}>
          <h5 className="fw-600 mb-0">
            {isRespondable ? 'Interactive' : 'Nudge'}
          </h5>
          {isRespondable && (
            <h5 className="fw-400">
              {calculateResponsePercentage(
                messagedUsersCount,
                distinctResponsesCount
              ) || 0}
              %
            </h5>
          )}
        </MainstayFlexTableCol>
        <MainstayFlexTableCol xs={2}>{messagedUsersCount}</MainstayFlexTableCol>
        <MainstayFlexTableCol className="fw-400" xs={2}>
          {getCampaignLastSent(lastSent)}
        </MainstayFlexTableCol>
      </MainstayFlexTableRow>
    </NavLink>
  )
}

export const IntroCampaignsTable = () => {
  const dialogs = useDialogs()

  if (dialogs.length === 0) {
    return null
  }

  return (
    <div className="pb-3">
      <h4> Introductory Campaigns</h4>
      <MainstayFlexTable>
        <MainstayFlexTableHeader>
          <MainstayFlexTableHeaderCol xs={5}>
            <div>Campaign</div>
          </MainstayFlexTableHeaderCol>
          <MainstayFlexTableHeaderCol xs={2}>
            <div>Type</div>
          </MainstayFlexTableHeaderCol>
          <MainstayFlexTableHeaderCol xs={2}>
            <div>Sent</div>
          </MainstayFlexTableHeaderCol>
          <MainstayFlexTableHeaderCol xs={2}>
            <div>Last Sent</div>
          </MainstayFlexTableHeaderCol>
        </MainstayFlexTableHeader>
        {dialogs.map(
          ({
            id,
            kind,
            isRespondable,
            messagedUsersCount,
            distinctResponsesCount,
            lastSent,
          }: IIntroDialogListItem) => (
            <IntroCampaignsTableRow
              key={id}
              id={id}
              kind={kind}
              isRespondable={isRespondable}
              messagedUsersCount={messagedUsersCount}
              distinctResponsesCount={distinctResponsesCount}
              lastSent={lastSent}
            />
          )
        )}
      </MainstayFlexTable>
    </div>
  )
}
