import * as React from 'react'
import {
  CheckableInput,
  ICheckableInputProps,
} from 'components/CheckableInput/CheckableInput'

export enum LabelPosition {
  left = 'left',
  right = 'right',
}
import 'components/CustomCheckableInput/CustomCheckableInput.scss'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'

interface ICustomCheckableInputProps {
  labelText?: string
  labelTextPosition?: LabelPosition
  CheckedCustomInputElement: JSX.Element
  UncheckedCustomInputElement: JSX.Element
}

// extendable component meant to create custom checkbox and radio button elements (see ToggleApprovalCheckbox)
// makes native input element screen-readable only and replaces with elements provided through props
class GenericCustomCheckableInput extends React.PureComponent<
  ICheckableInputProps & ICustomCheckableInputProps
> {
  static defaultProps = {
    labelClassName: 'pointer',
    inputClassName: 'sr-only sr-only-focuable',
  }
  render() {
    const {
      checked,
      labelText,
      labelTextPosition,
      CheckedCustomInputElement,
      UncheckedCustomInputElement,
      className,
      inputClassName,
      labelClassName,
    } = this.props
    return (
      <CheckableInput
        className={className}
        inputClassName={inputClassName}
        labelClassName={labelClassName}
        {...this.props}>
        {labelTextPosition === LabelPosition.left && labelText}
        {checked && CheckedCustomInputElement}
        {!checked && UncheckedCustomInputElement}
        {(!labelTextPosition || labelTextPosition === LabelPosition.right) &&
          labelText}
      </CheckableInput>
    )
  }
}

interface ICustomCheckableInputImplementationProps
  extends Omit<
      ICustomCheckableInputProps,
      'CheckedCustomInputElement' | 'UncheckedCustomInputElement'
    >,
    ICheckableInputProps {}

export const AddRemoveCheckbox = (
  props: ICustomCheckableInputImplementationProps
) => {
  return (
    <GenericCustomCheckableInput
      type="checkbox"
      className={props.className}
      value={props.value}
      id={props.id}
      name={props.name}
      checked={props.checked}
      onChange={props.onChange}
      CheckedCustomInputElement={
        <AHIcon className="mr-1" name="remove_circle_outline" />
      }
      UncheckedCustomInputElement={
        <AHIcon className="mr-1" name="add_circle_outline" />
      }
      labelText={props.labelText}
      labelTextPosition={props.labelTextPosition}
      inputClassName={props.inputClassName}
      labelClassName={props.labelClassName}
    />
  )
}

export const ColoredCheckBox = (
  props: ICustomCheckableInputImplementationProps & { color: string }
) => {
  return (
    <GenericCustomCheckableInput
      type="checkbox"
      className={props.className}
      value={props.value}
      id={props.id}
      name={props.name}
      checked={props.checked}
      onChange={props.onChange}
      CheckedCustomInputElement={
        <AHIcon
          className="mr-1"
          name="check_box"
          style={{ color: props.color }}
        />
      }
      UncheckedCustomInputElement={
        <AHIcon
          className="mr-1"
          name="check_box_outline_blank"
          style={{ color: props.color }}
        />
      }
      labelText={props.labelText}
      labelTextPosition={props.labelTextPosition}
      inputClassName={props.inputClassName}
      labelClassName={props.labelClassName}
    />
  )
}
