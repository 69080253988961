import * as React from 'react'
import classnames from 'classnames'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'

interface IIncomingNodeProps {
  className?: string
  transitionType?: React.ReactNode
  editing?: boolean
  editable?: boolean
}

export default class IncomingNode extends React.PureComponent<
  IIncomingNodeProps,
  { hover: boolean }
> {
  constructor(props: IIncomingNodeProps) {
    super(props)
    this.state = { hover: false }
  }
  render() {
    const {
      className,
      transitionType,
      children,
      editing,
      editable,
    } = this.props
    return (
      <div
        className={classnames(
          'text-white d-inline-flex hover-opacity-100-parent',
          className,
          { 'bg-blue-grey-501 rounded': !editing },
          {
            'hover-shadow-md pointer':
              editable &&
              !editing &&
              transitionType !== 'Y' &&
              transitionType !== 'N',
          }
        )}>
        {transitionType !== 'PROMPT' && transitionType && (
          <div className="transition-type bg-blue-grey-701 rounded-left text-white px-3 py-2 height-min-content">
            {transitionType}
          </div>
        )}
        <div
          className={classnames(
            { 'font-italic': transitionType === 'PROMPT' },
            !editing ? 'body py-2 px-3' : 'body'
          )}>
          {children}
        </div>
        {!editable ||
          transitionType === 'Y' ||
          transitionType === 'N' ||
          (!editing && (
            <AHIcon
              name="edit"
              className={classnames(
                'pt-2 pr-3 hover-opacity-100-child opacity-50',
                className
              )}
            />
          ))}
      </div>
    )
  }
}
