import React from 'react'

import { useInstitutionId } from 'util/hooks'
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch'
import { sendEvent } from 'api/events'
import 'components/LiveChatNotificationsPane/LiveChatNotificationsPane.scss'

const LIVE_CHAT_NOTIFICATIONS_KEY = 'admithub:live-chat-notifications-v1'
const LIVE_CHAT_ENABLED = 'enabled'
type NotificationState = 'on' | 'off' | 'blocked' | 'unsupported'

export function loadNotificationState(): NotificationState {
  const enabled =
    localStorage.getItem(LIVE_CHAT_NOTIFICATIONS_KEY) === LIVE_CHAT_ENABLED
  // Notification will not exist on iOS
  //
  // On load we don't want to display the error message, so we return "off".
  // When a user clicks the toggle, we'll display an error.
  if (!('Notification' in window)) {
    return 'off'
  }
  if (enabled && Notification.permission === 'granted') {
    return 'on'
  }
  return 'off'
}

function useNotifications() {
  const [state, setState] = React.useState<NotificationState>(
    loadNotificationState()
  )
  const institutionId = useInstitutionId()

  React.useEffect(() => {
    persistNotificationState(state)
  }, [state])

  function handleToggleNotifications(enable: boolean): undefined {
    // Notification will not exist on iOS
    //
    // Display an error if their browser doesn't support Notification
    if (!('Notification' in window)) {
      setState('unsupported')
      return
    }
    if (enable) {
      // We must use the callback version of Notification.requestPermission to support Safari
      Notification.requestPermission(() => {
        if (Notification.permission === 'granted') {
          setState('on')
          sendEvent('click:conversations-toggle-notifications', {
            institutionId,
            enabled: true,
          })
        } else {
          setState('blocked')
        }
      })
    } else {
      setState('off')
      sendEvent('click:conversations-toggle-notifications', {
        institutionId,
        enabled: false,
      })
    }
  }
  return {
    state,
    toggleNotifications: handleToggleNotifications,
  }
}

function persistNotificationState(state: NotificationState) {
  if (state === 'on') {
    localStorage.setItem(LIVE_CHAT_NOTIFICATIONS_KEY, LIVE_CHAT_ENABLED)
  } else {
    localStorage.removeItem(LIVE_CHAT_NOTIFICATIONS_KEY)
  }
}

export function LiveChatNotificationsPane() {
  const notifications = useNotifications()
  return (
    <div className="notification-pane d-flex flex-column pb-2">
      <div className="d-flex flex-column">
        <p className="m-0 d-flex flex-row justify-content-between align-items-center">
          Web Chat Notifications
          <ToggleSwitch
            className="pl-1"
            checked={notifications.state === 'on'}
            onChange={notifications.toggleNotifications}
          />
        </p>
        <p className="caption m-0">
          Receive notifications for new web chats while logged in to the
          platform
        </p>
      </div>
      {notifications.state === 'blocked' && (
        <p className="text-danger small mt-1">
          Notifications are blocked by your browser. Please allow notifications
          for this page.
        </p>
      )}
      {notifications.state === 'unsupported' && (
        <p className="text-danger small mt-1">
          Notifications are not supported by your browser.
        </p>
      )}
    </div>
  )
}
