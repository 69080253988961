import React from 'react'

export const TranslationIcon = React.forwardRef<
  SVGSVGElement,
  {
    readonly className?: string
    readonly fill?: string
  }
>(({ className, fill = '#5e6b72' }, ref) => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      ref={ref}
      className={className}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M11 4H9.49C8.86 4 8.24 4.3 7.9 4.7L7 2H4.13L1.74 9H3.43L4.17 7H7V11H2C0.9 11 0 10.1 0 9V2C0 0.9 0.9 0 2 0H9C10.1 0 11 0.9 11 2V4ZM6.51 6H4.49L5.49 3.07L6.51 6ZM10 5H17C18.1 5 19 5.9 19 7V14C19 15.1 18.1 16 17 16H10C8.9 16 8 15.1 8 14V7C8 5.9 8.9 5 10 5ZM17.25 10V8.92H14.08V6.75H12.92V8.92H9.75V10H11.03C11.14 10.85 11.59 11.85 12.31 12.62C11.44 12.98 10.42 13.24 10 13.24C9.99 13.26 10.22 14.21 10.2 14.7C11.04 14.7 12.41 14.2 13.48 13.55C14.57 14.2 15.96 14.7 16.82 14.7C16.8 14.21 17.02 13.26 17.02 13.24C16.59 13.24 15.53 12.97 14.64 12.61C15.34 11.84 15.78 10.84 15.89 10H17.25ZM13.44 11.93C12.94 11.47 12.59 10.8 12.43 10H14.52C14.35 10.8 14.01 11.47 13.52 11.93L13.48 11.96C13.48 11.96 13.45 11.94 13.44 11.93Z" />
    </svg>
  )
})
