import NodeSubtree, { HorizontalNode } from 'components/NodeSubtree/NodeSubtree'
import { IBranchProps } from 'components/AutoBranch/AutoBranch'
import { PopoverLinkNode } from 'components/PopoverLinkNode/PopoverLinkNode'
import { TrashcanButton } from 'components/Icons/IconButton/IconButton'

export interface ILinkBranchProps {
  id: string
  enableDeletion?: boolean
  enableJump?: boolean
}

export const LinkBranch = ({
  id,
  enableDeletion,
  enableJump,
  deleteEdge,
  workflowStep,
  editable,
}: ILinkBranchProps &
  Pick<IBranchProps, 'deleteEdge' | 'workflowStep' | 'editable'>) => {
  const handleDelete = () => {
    if (deleteEdge) {
      deleteEdge(true)
    }
  }
  return (
    <NodeSubtree
      parent={
        <HorizontalNode>
          <PopoverLinkNode
            enableJump={enableJump}
            enableDeletion={enableDeletion}
            message={workflowStep.personalizedPrompt || workflowStep.prompt}
            id={id}
            media={workflowStep.media}>
            {editable && deleteEdge && (
              <TrashcanButton onClick={handleDelete} />
            )}
          </PopoverLinkNode>
        </HorizontalNode>
      }
    />
  )
}
