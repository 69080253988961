import React from 'react'
import { Modal } from 'reactstrap'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { components, Async } from 'react-select'
import { ValueType, ActionMeta } from 'react-select/lib/types'
import {
  MenuListComponentProps,
  MenuProps,
} from 'react-select/lib/components/Menu'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import ControlComponent, {
  ControlProps,
} from 'react-select/lib/components/Control'
import classnames from 'classnames'

export type AutocompleteModalForm<FormValues> = {
  onSubmit: (values: FormValues) => void
  onClose?: () => void
}

export type AutoCompleteOptionProps<TOption> = {
  data: TOption
  selectOption: (option: TOption) => void
}

export type AutocompleteWithModalProps<
  TOption extends { key: string | number },
  TForm,
  TRequest
> = {
  id: string
  name: string
  value?: TOption['key'] | TOption['key'][]
  onChange: (event: {
    target: {
      name: string
      value: TOption['key'] | TOption['key'][]
    }
  }) => void
  messages: {
    addOption: React.ReactElement | string
    loading: React.ReactElement | string
    noOptions: React.ReactElement | string
  }
  components: {
    option: React.FC<AutoCompleteOptionProps<TOption>>
    form: React.FC<AutocompleteModalForm<TForm>>
    addOptionButton?: React.FC
    loading?: React.FC
    error?: React.FC<{ message: string }>
  }
  map: (form: TForm) => TRequest
  availableOptions?: TOption[]
  create: (request: TRequest) => Promise<TOption | undefined>
  search: (inputValue: string) => Promise<TOption[]>
  modalClassname?: string
  placeholder?: string
  showSearch?: boolean
  dropdownClassName?: string
  omitOption?: boolean
}

function AutocompleteWithModalControl<TOption>(
  props: ControlProps<TOption> & { icon?: React.ReactElement }
) {
  const { children, ...rest } = props
  return (
    <ControlComponent {...rest}>
      {props.icon}
      {children}
    </ControlComponent>
  )
}

function AutocompleteWithModalControlSearch<TOption>(
  props: ControlProps<TOption> & { icon?: React.ReactElement }
) {
  const { children, ...rest } = props
  return (
    <ControlComponent {...rest}>
      <AHIcon name="search" className="large pl-1" />
      {children}
    </ControlComponent>
  )
}

function AutoCompleteWithModalMenuList<TOption>(
  props: MenuListComponentProps<TOption> & {
    showModal: () => void
    addOption: React.ReactElement
    omitOption?: boolean
  }
) {
  return (
    <div
      className="h-100 d-flex flex-column"
      style={props.getStyles('menuList', props)}
      {...props}>
      <components.MenuList {...props} />
      {!props.omitOption && (
        <div
          onClick={props.showModal}
          className="text-secondary-teal p-3 d-flex align-self-end align-items-center pointer shadow-top w-100 justify-content-around">
          {props.addOption}
        </div>
      )}
    </div>
  )
}

function Menu<TOption>(props: MenuProps<TOption>) {
  const classNames = classnames(
    'mt-0 autosuggest-menu bg-white shadow',
    // tslint:disable-next-line: no-unsafe-any
    { 'd-none': props.selectProps.isDisabled }
  )
  return (
    <components.Menu {...props} className={classNames}>
      {props.children}
    </components.Menu>
  )
}

const AddOptionButton: React.FC = props => {
  return (
    <div>
      <AHIcon name="add_circle_outline" className="icon-height-adjust" />{' '}
      {props.children}
    </div>
  )
}

const AutocompleteWithCreateModal = <
  TO extends { key: string | number },
  TF,
  TR
>({
  search,
  create,
  map,
  onChange,
  components,
  id,
  availableOptions,
  name,
  value,
  messages,
  modalClassname,
  showSearch,
  placeholder,
  dropdownClassName,
  omitOption,
}: AutocompleteWithModalProps<TO, TF, TR>) => {
  const [shown, setShown] = React.useState(false)
  const [chosenOption, setChosen] = React.useState<TO | TO[]>()
  const [options, setOptions] = React.useState<TO[]>([])
  React.useEffect(() => {
    if (availableOptions) {
      setOptions(availableOptions)
    }
  }, [availableOptions])

  const handleSubmit = (values: TF) => {
    const mapped = map(values)
    create(mapped)
      .then(x => {
        setShown(false)
        if (x !== undefined) {
          setOptions(items => [...items, x])
          setChosen(x)
        }
      })
      .catch((e: { message: string }) => {
        toast(e.message, { type: 'error' })
      })
  }
  const handleChange = React.useCallback(
    (value: ValueType<TO>, _action: ActionMeta) => {
      if (value !== null && value !== undefined) {
        if (!Array.isArray(value)) {
          setChosen(options.find(x => x.key === value.key))
          onChange({
            target: {
              name,
              value: value.key,
            },
          })
        } else {
          onChange({ target: { name, value: value.map(x => x.key) } })
        }
      }
    },
    [name, onChange, options]
  )
  const show = React.useCallback(() => setShown(value => !value), [setShown])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptions = React.useCallback(
    async (inputValue: string) => {
      const options = await search(inputValue)
      if (Array.isArray(value)) {
        setChosen(options.filter(x => value.includes(x.key)))
      } else {
        setChosen(options.find(x => x.key === value))
      }
      setOptions(options)
      return options
    },
    [search, value]
  )

  return (
    <>
      <Async<TO>
        id={id}
        name={name}
        isMulti={Array.isArray(value)}
        onChange={handleChange}
        value={chosenOption}
        defaultOptions={options.length > 0 ? options : true}
        loadOptions={loadOptions}
        classNamePrefix="react-select"
        className={classnames('w-100', dropdownClassName)}
        placeholder={placeholder}
        styles={{
          menu: provided => {
            return {
              ...provided,
              width: '90%',
            }
          },
        }}
        components={{
          SingleValue: p => (
            <div style={p.getStyles('singleValue', p)}>
              <components.option {...p} />
            </div>
          ),
          Option: (p: AutoCompleteOptionProps<TO>) => {
            if (Array.isArray(chosenOption)) {
              return null
            }
            return (
              <div
                className={classnames(
                  'd-flex w-100 flex-row py-2 px-3 react-select__option',
                  {
                    'bg-mainstay-dark-blue-20':
                      chosenOption?.key === p.data.key,
                  }
                )}
                onClick={e => {
                  e.preventDefault()
                  p.selectOption(p.data)
                }}>
                <components.option {...p} />
              </div>
            )
          },
          Menu,
          LoadingIndicator: () => null,
          IndicatorSeparator: () => null,
          Control: showSearch
            ? AutocompleteWithModalControlSearch
            : AutocompleteWithModalControl,
          MenuList: p => (
            <AutoCompleteWithModalMenuList
              {...p}
              omitOption={omitOption}
              showModal={show}
              addOption={
                components.addOptionButton ? (
                  <components.addOptionButton>
                    {messages.addOption}
                  </components.addOptionButton>
                ) : (
                  <AddOptionButton>{messages.addOption}</AddOptionButton>
                )
              }
            />
          ),
          LoadingMessage: p => (
            <div
              {...p.innerProps}
              style={p.getStyles('loadingMessage', p)}
              className="text-left">
              {components.loading ? (
                <components.loading>{messages.loading}</components.loading>
              ) : (
                <div>{messages.loading}</div>
              )}
            </div>
          ),
          NoOptionsMessage: p => (
            <div
              {...p.innerProps}
              style={p.getStyles('noOptionsMessage', p)}
              className="text-left">
              {messages.noOptions}
            </div>
          ),
        }}
      />
      <Modal
        className={classnames('border-0', modalClassname)}
        isOpen={shown}
        toggle={show}
        onClose={() => setShown(false)}>
        <components.form
          onSubmit={handleSubmit}
          onClose={() => setShown(false)}
        />
      </Modal>
    </>
  )
}

export default AutocompleteWithCreateModal
