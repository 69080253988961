export default function TimeLocale({
  dateTime,
}: {
  readonly dateTime: string | Date | number
}) {
  const dateObj = new Date(dateTime)
  const dateStr = dateObj.toString()
  const normalizedTimeString = dateObj.toLocaleString()
  return <time dateTime={dateStr}>{normalizedTimeString}</time>
}
