import { CountdownTimer } from 'components/CountdownTimer/CountdownTimer'
import { MainstayModal } from 'components/Modal/Modal'
import { SimpleModalFooter } from 'components/SimpleModalFooter/SimpleModalFooter'

export interface IAutoLogoutModalProps {
  show: boolean
  onClose: () => void
  updateLoginExpiration: () => void
  logout: () => void
  onLogout: () => void
}

export const AutoLogoutModal = ({
  show,
  onClose,
  updateLoginExpiration,
  logout,
  onLogout,
}: IAutoLogoutModalProps) => {
  const handleStayLogged = () => {
    updateLoginExpiration()
    onClose()
  }
  const handleLogout = () => {
    logout()
    onClose()
    onLogout()
  }
  return (
    <MainstayModal
      hideFooter
      show={show}
      text="Inactive Session"
      onClose={onClose}>
      <p>
        You have been inactive for 90 minutes and will be automatically logged
        out shortly. Would you like to continue your session?
      </p>
      <div className="d-flex justify-content-between align-items-center pt-3 pb-4">
        <CountdownTimer
          countdownAction={handleLogout}
          startingSeconds={300}
          className="py-0 mt-2 px-2"
        />
        <SimpleModalFooter
          noPadding
          onSubmit={handleStayLogged}
          onCancel={handleLogout}
          submitText="Stay logged in"
          cancelText="Logout"
        />
      </div>
    </MainstayModal>
  )
}
