import * as React from 'react'
import { FontAwesomeIcon } from 'components/Icons/FontAwesomeIcon/FontAwesomeIcon'
import { ITextInputProps, TextInput } from 'components/TextInput/TextInput'
import classNames from 'classnames'

export const HiddenInput = (props: ITextInputProps) => {
  const ref = React.useRef<HTMLInputElement>(null)
  const [visible, setVisible] = React.useState(false)
  const { className, ...rest } = props
  return (
    <div
      className={classNames(
        'p-0 d-flex justify-content-begin align-items-center',
        className
      )}
      style={{ gap: '4px' }}>
      <TextInput ref={ref} {...rest} type={visible ? '' : 'password'} />
      <FontAwesomeIcon
        onClick={() => {
          setVisible(!visible)
          if (ref?.current) {
            ref.current.focus()
          }
        }}
        icon={visible ? 'eye' : 'eye-slash'}
      />
    </div>
  )
}
