import React from 'react'
import { RouteComponentProps } from 'react-router'
import { useDispatch, useFeatures } from 'util/hooks'
import * as api from 'api'
import { isLeft } from 'fp-ts/Either'
import { setKnowledgeReviewItemsCount } from 'page/knowledge-base/KnowledgeReview/actions'
import ContactFeedback from 'page/knowledge-base/ContactFeedback/ContactFeedback'
import KnowledgeReview from 'page/knowledge-base/KnowledgeReview/KnowledgeReview'

export default function KnowledgeBaseMaintenance({
  params,
}: {
  readonly params: RouteComponentProps<{ reason: string }>
}) {
  const { hasFeature, FeaturesType } = useFeatures()
  const dispatch = useDispatch()

  const fetchCounts = React.useCallback(async () => {
    const res = await api.knowledgeBaseReviewItemsCount()
    if (isLeft(res)) {
      return
    }
    dispatch(setKnowledgeReviewItemsCount(res.right))
  }, [dispatch])

  React.useEffect(() => {
    fetchCounts()
  }, [fetchCounts])

  const { reason } = params.match.params
  if (
    hasFeature(FeaturesType.KNOWLEDGE_BASE_CONTACT_FEEDBACK) &&
    reason === 'contact-feedback'
  ) {
    return <ContactFeedback fetchCounts={fetchCounts} />
  }
  return <KnowledgeReview fetchCounts={fetchCounts} />
}
