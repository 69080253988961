const colorMap = [
  'bg-primary',
  'bg-success',
  'bg-danger',
  'bg-warning',
  'bg-info',
  'bg-secondary',
]

// Utility function for choosing a color class decorator based on a "hash" of the provided string.
// Uses bitshifting and bitwise operations to generate an integer from a string, then uses that to
// index into a set of color choices.

// See also: UserIcon:
export function mapColor(stringToMap: string) {
  const hashValue = stringToMap
    .split('')
    .map(s => s.charCodeAt(0))
    .reduce((hash, character) => {
      // Intentional use of bitwise operators, surprissing warnings
      // tslint:disable-next-line:no-bitwise
      hash = (hash << 5) - hash + character
      // tslint:disable-next-line:no-bitwise
      hash = hash & hash
      return hash
    }, 0)
  return colorMap[Math.abs(hashValue) % colorMap.length]
}
