import { MainstayModal } from 'components/Modal/Modal'
import React from 'react'
import { Input, Label } from 'reactstrap'

export default function MarkForKnowledgeReviewNotesModal({
  isOpen,
  toggle,
  note,
  onSubmit,
  isLoading,
}: {
  readonly isOpen: boolean
  readonly toggle: () => void
  readonly onSubmit: (note: string) => void
  readonly note: string | undefined
  readonly isLoading: boolean
}) {
  const [reviewNote, setReviewNote] = React.useState<string>(note || '')

  return (
    <MainstayModal
      zIndex={1500}
      show={isOpen}
      text={`${note ? 'Edit' : 'Add'} Note`}
      onClose={toggle}
      onSubmit={() => onSubmit(reviewNote)}
      disableSubmit={!reviewNote || isLoading}
      submitText={
        isLoading ? 'Please wait...' : !note ? 'Add Note' : 'Update Note'
      }
      cancelText="Cancel">
      <Label>Note about this item (Optional)</Label>
      <Input
        type="textarea"
        name="note"
        disabled={false}
        onChange={e => setReviewNote(e.target.value)}
        value={reviewNote}
        placeholder="Notes"
        className="w-100"
      />
    </MainstayModal>
  )
}
