import React from 'react'
import { ColoredCheckBox } from 'components/CustomCheckableInput/CustomCheckableInput'
import { ICheckableItem } from 'components/CheckboxGroup/CheckboxGroup'

interface IColoredCheckboxItem extends ICheckableItem {
  color: string
}

interface IColoredCheckboxCollectionProps {
  items: IColoredCheckboxItem[]
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const ColoredCheckboxCollection = ({
  items,
  onChange,
}: IColoredCheckboxCollectionProps) => {
  return (
    <div className="d-flex flex-column">
      {items.map(item => {
        return (
          <ColoredCheckBox
            className="mb-2 p-0"
            type="checkbox"
            id={item.id}
            name={item.name}
            value={item.value}
            checked={item.checked}
            color={item.color}
            key={item.id}
            onChange={onChange}
            labelText={item.label}
          />
        )
      })}
    </div>
  )
}
