import * as React from 'react'
import Linkify from 'react-linkify'
import LinkifyIt from 'linkify-it'
import tlds from 'tlds'
import { normalizePhone } from 'util/phone'
import { orderBy } from 'lodash'

const linkify = new LinkifyIt()
linkify.tlds(tlds)

interface IMatchInfo {
  schema: string
  index: number
  lastIndex: number
  text: string
  url: string
}

interface ILinkifiedTextProps {
  children: React.ReactNode
  componentDecorator?: (
    decoratedHref: string,
    decoratedText: string,
    key: number
  ) => React.ReactNode
  hrefDecorator?: (urlHref: string) => string
  matchDecorator?: (text: string) => IMatchInfo[] | null
  textDecorator?: (urlText: string) => string
}

export default function({
  children,
  componentDecorator,
  hrefDecorator,
  matchDecorator,
  textDecorator,
}: ILinkifiedTextProps) {
  const defMatchDecorator = React.useCallback((text: string) => {
    // Uses default matching implementation, but also includes phone numbers
    const matches = linkify.match(text)
    const phoneRegex = /(^|\s|\()(?:((\+[0-9]{1,3})[ .-]?[0-9]{4,15})|((\+1\s?)?\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4}))($|\s|\))/g
    const allMatches = [...(matches || [])]
    let phoneMatch = null
    // tslint:disable-next-line no-conditional-assignment
    while ((phoneMatch = phoneRegex.exec(text)) != null) {
      // NOTE(neckenth): We may need to handle spaces and other separators in US numbers later on
      allMatches.push({
        index: phoneMatch.index,
        lastIndex: phoneMatch.index + phoneMatch[0].length,
        raw: phoneMatch[0],
        schema: '',
        text: phoneMatch[0],
        url: `tel:+${normalizePhone(phoneMatch[0])}`,
      })
    }
    return orderBy(allMatches, ['index'], ['asc'])
  }, [])

  return (
    <Linkify
      componentDecorator={componentDecorator}
      hrefDecorator={hrefDecorator}
      matchDecorator={matchDecorator ?? defMatchDecorator}
      textDecorator={textDecorator}>
      {children}
    </Linkify>
  )
}
