import cls from 'classnames'
import RatingStatistic from 'page/dashboard/KnowledgeInsights/ContactFeedback/RatingStatistic/RatingStatistic'

export default function ContactFeedback({
  justGraph,
}: {
  readonly justGraph?: boolean
}) {
  return (
    <div className={cls('d-flex flex-column pb-4', { 'pt-4': !justGraph })}>
      {!justGraph && (
        <>
          <h3 className="text-mainstay-dark-blue">Webchat Feedback</h3>
          <p className="m-0">
            What are your webchat users saying about your bot?
          </p>
        </>
      )}
      <RatingStatistic justGraph={justGraph} />
    </div>
  )
}
