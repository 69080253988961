import * as React from 'react'
import LinkNode from 'components/LinkNode/LinkNode'
import { ITransitionType } from 'api/request'
import { connect } from 'react-redux'
import { RootState as IState } from 'store/store'
import { VerticalNode } from 'components/NodeSubtree/NodeSubtree'

interface ILinkingIndicatorStateProps {
  linkingStepId?: string
  linkStepTransitionKey?: ITransitionType
  stepId: string
  transition: ITransitionType
}

const LinkingIndicator: React.FC<ILinkingIndicatorStateProps> = props => {
  if (
    props.stepId !== props.linkingStepId ||
    props.transition !== props.linkStepTransitionKey
  ) {
    return null
  }
  return (
    <VerticalNode>
      <LinkNode>Select an existing node.</LinkNode>
    </VerticalNode>
  )
}
const mapStateToProps = (
  state: IState
): Pick<
  ILinkingIndicatorStateProps,
  'linkingStepId' | 'linkStepTransitionKey'
> => {
  return {
    linkingStepId: state.campaignScripts.ui.linkFromId,
    linkStepTransitionKey: state.campaignScripts.ui.linkTransition,
  }
}

export default connect(mapStateToProps)(LinkingIndicator)
