import 'components/ThinkingEllipses/ThinkingEllipses.scss'

export default function ThinkingEllipses() {
  return (
    <span className="ellipsis-anim">
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  )
}
