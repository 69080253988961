export interface IBarChartIconProps {
  readonly height?: number | string
  readonly width?: number | string
  readonly muted?: boolean
}
export function BarChartIcon({
  height = '24px',
  width = '24px',
  muted = false,
}: IBarChartIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M4 20V9H8V20H4ZM10 20V4H14V20H10ZM16 20V13H20V20H16Z"
        fill="#5F779A"
        fillOpacity={muted ? 0.5 : 1}
      />
    </svg>
  )
}
