import { FontAwesomeIconButtonFactory } from 'components/FontAwesomeIconButtonFactory/FontAwesomeIconButtonFactory'
import 'components/Icons/IconButton/IconButton.scss'

export const ApproveButton = FontAwesomeIconButtonFactory(
  'thumbs-up',
  'bg-success text-white icon-button'
)
export const DenyButton = FontAwesomeIconButtonFactory(
  'thumbs-down',
  'bg-danger text-white icon-button'
)
export const TrashcanButton = FontAwesomeIconButtonFactory(
  'trash',
  'btn-outline-danger icon-button'
)
export const LightBulbButton = FontAwesomeIconButtonFactory(
  'lightbulb',
  'btn-info icon-button'
)
export const UndoButton = FontAwesomeIconButtonFactory(
  'undo',
  'btn-outline-dark icon-button'
)
export const CancelButton = FontAwesomeIconButtonFactory(
  'window-close',
  'btn-dark icon-button'
)
