interface IRoundedRectIconProps {
  readonly height?: number | string
  readonly width?: number | string
  readonly fill: string
}

export function RoundedRectIcon({
  height = '16px',
  width = '16px',
  fill,
}: IRoundedRectIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} rx="4" fill={fill} />
    </svg>
  )
}
