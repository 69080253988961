import { Button } from 'components/Button/Button'
import { intersection } from 'lodash'
import { InfoIcon } from 'mainstay-ui-kit/MainstayToast/Icons/InfoIcon/InfoIcon'
import { selectAllFromCheckboxList } from 'page/knowledge-base/KnowledgeReview/actions'
import { getKnowledgeBaseReviewItems } from 'page/knowledge-base/KnowledgeReview/selectors'
import { useDispatch, useSelector } from 'util/hooks'

export const KnowledgeReviewSelectionNotification = (): JSX.Element => {
  const dispatch = useDispatch()
  const {
    checkboxList: { selectedIds, all, deselectedIds },
    data: { items, totalCount },
  } = useSelector(getKnowledgeBaseReviewItems)

  const notificationText = () => {
    if (all) {
      const selectedCount = totalCount - deselectedIds.length
      const isEverythingSelected = totalCount === selectedCount
      return `${isEverythingSelected ? 'All ' : ''}${totalCount -
        deselectedIds.length} items selected.`
    }

    const allPageIds = items.map(item => item.message.id)
    const selectedPageIds = intersection(selectedIds, allPageIds)
    const isWholePageSelected = allPageIds.length === selectedPageIds.length

    return `${isWholePageSelected ? 'All ' : ''}${
      selectedPageIds.length
    } items selected on this page.`
  }

  const toggleSelect = () => {
    dispatch(
      selectAllFromCheckboxList({
        all: !all,
        selectedIds: !all ? items.map(i => i.message.id) : [],
      })
    )
  }

  const text = notificationText()
  return (
    <div className="d-flex align-items-center w-100 border border-color-bluegrey-20 border-radius-10 p-3 mt-3 bg-white">
      <InfoIcon fill="#5F779A" />
      <span className="ml-3">{text}</span>
      <Button
        color="link"
        className="text-decoration-none"
        onClick={toggleSelect}>
        <span>{all ? 'Deselect all' : 'Select all'}</span>
      </Button>
    </div>
  )
}
