interface IEllucianIconProps {
  readonly height?: number | string
  readonly width?: number | string
}
export function EllucianIcon({
  height = '32px',
  width = '100%',
}: IEllucianIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 67 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.3969 42.688C51.2255 55.4827 36.6921 61.6572 23.9197 56.4858C11.1472 51.3144 4.97276 36.7587 10.1442 23.9862C14.7583 12.5735 26.8397 6.44363 38.4754 8.94016C39.8574 9.22994 41.2394 9.63117 42.5991 10.1884C43.9588 10.7457 45.2294 11.4144 46.4331 12.1723L43.1787 20.2192L35.533 39.1438L27.5753 35.9117L35.221 16.987C28.0211 16.1177 20.8436 20.1077 18.0127 27.1515C14.6023 35.5773 18.6814 45.1845 27.1072 48.5949C35.533 52.0054 45.1179 47.9262 48.5284 39.5004C50.1556 35.4658 50.0664 31.1638 48.5953 27.3967L52.5853 17.522C58.1579 24.3652 59.9411 33.9278 56.3969 42.688ZM45.7421 2.43134C28.7121 -4.45642 9.31941 3.79106 2.43165 20.8433C-4.45612 37.8955 3.79137 57.3105 20.8213 64.2206C37.8512 71.1306 57.2663 62.8609 64.1317 45.8086C70.9972 28.7564 62.7943 9.3191 45.7421 2.43134Z"
        fill="#682673"
      />
    </svg>
  )
}
