import * as React from 'react'
import {
  getCountsOfInstAttributesByType,
  IAttributeTypeCounter,
  InstitutionAttributeType,
} from 'store/personalization/institutionAttributes/selectors'
import { useFeatures, useSelector } from 'util/hooks'
import { getKnowledgeBaseReviewItems } from 'page/knowledge-base/KnowledgeReview/selectors'
import {
  KnowledgeReviewReason,
  MARK_TITLES,
} from 'page/conversations-v2/ConversationsDetail/'
import { MainstayPage } from 'components/MainstayPageContainer/MainstayPageContainer'
import MainstaySidebarSection from 'mainstay-ui-kit/MainstaySidebar/MainstaySidebarSection/MainstaySidebarSection'
import { UnderstandingQuestionIcon } from 'components/Icons/UnderstandingQuestionIcon/UnderstandingQuestionIcon'
import { toTitleCase } from 'util/string'
import { useLocation, useParams } from 'react-router'
import queryString from 'query-string'
import { CommandsIcon } from 'components/Icons/CommandsIcon/CommandsIcon'
import { FallbackResponseIcon } from 'components/Icons/FallbackResponseIcon/FallbackResponseIcon'
import { AttributesIcon } from 'components/Icons/AttributesIcon/AttributesIcon'

import { PERMISSIONS } from 'util/permissions/permissions'
import { FeaturesType } from 'components/Feature/Feature'

export interface IKnowledgeTopic {
  name: string
  approvedCount: number
  totalCount: number
}

export interface IKnowledgeTopicListProps {
  topics: IKnowledgeTopic[]
  totalSeedsCount: number
  totalApprovedSeedsCount: number
  totalUnapprovedSeedsCount: number
  totalBlankAnswers: number
  fetchInstAttrsCountByType?: () => void
  countInstAttrsByType?: IAttributeTypeCounter
  onClickAddAttr?: () => void
  pageContent: React.ReactElement
  pageContentClassName?: string
}

export const attributeTypes: { [k: string]: string } = {
  date: 'Date',
  email: 'Email',
  number: 'Number',
  phone: 'Phone',
  text: 'Text',
  url: 'URL',
}

const linksKnowledgeSources = [
  {
    to: '/knowledge-sources',
    text: 'Knowledge Scraping',
    feature: FeaturesType.GENERATIVE_AI_PARTNER_FACING_SCRAPER,
  },
]

export const KnowledgeSideBar = (props: IKnowledgeTopicListProps) => {
  const location = useLocation()
  const { hasFeature, FeaturesType } = useFeatures()
  const { fetchInstAttrsCountByType } = props
  const params = useParams<{ readonly category: string }>()
  const { counts } = useSelector(getKnowledgeBaseReviewItems)
  const countInstAttrsByType = useSelector(getCountsOfInstAttributesByType)

  const parsed = queryString.parse(location.search)

  React.useEffect(() => {
    if (fetchInstAttrsCountByType) {
      fetchInstAttrsCountByType()
    }
  }, [fetchInstAttrsCountByType])

  const linksAllKnowledge = [
    {
      icon: <UnderstandingQuestionIcon small={true} />,
      to: '/knowledge',
      text: 'Understandings',
      scrollable: true,
      scrollContentClassName: 'min-height-250px',
      active:
        location.pathname.includes('/knowledge') &&
        !location.pathname.includes('sources'),
      expand: [
        {
          to: '/knowledge?filter=approved',
          text: 'Approved',
          active: parsed['filter'] === 'approved',
          count: props.totalApprovedSeedsCount,
        },
        {
          to: '/knowledge?filter=unapproved',
          text: 'Not Approved',
          active: parsed['filter'] === 'unapproved',
          count: props.totalUnapprovedSeedsCount,
        },
        {
          to: '/knowledge?filter=blank',
          text: 'No Answer',
          active: parsed['filter'] === 'blank',
          count: props.totalBlankAnswers,
          divider: true,
        },
        ...props.topics.map((topic: IKnowledgeTopic) => ({
          to: `/knowledge/${topic.name}`,
          text: toTitleCase(topic.name),
          active: params['category'] === topic.name,
          count: topic.totalCount,
        })),
      ],
    },
    {
      icon: <CommandsIcon />,
      text: 'Commands',
      to: '/commands',
    },
    {
      icon: <FallbackResponseIcon />,
      text: 'Fallback Response',
      to: '/special-responses',
      permission: PERMISSIONS.FALLBACK.VIEW,
    },
    {
      icon: <AttributesIcon />,
      text: 'Attributes',
      to: '/attributes',
      scrollable: true,
      active: location.pathname.includes('/attributes'),
      expand: [
        ...Object.keys(attributeTypes).map(elem => {
          return {
            to: `/attributes?type=${elem}`,
            text: String(attributeTypes[elem]),
            active: parsed['type'] === elem,
            count: countInstAttrsByType
              ? countInstAttrsByType[
                  /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
                  elem.toUpperCase() as InstitutionAttributeType
                ]
              : 0,
          }
        }),
      ],
    },
  ]

  const linksKnowledgeMaintenance = [
    {
      to: '/review-items',
      text: 'All Messages',
      active: location.pathname === '/review-items',
      forceOpen: location.pathname.includes('/review-items'),
      expand: [
        {
          to: '/review-items/bot-could-not-answer',
          text: MARK_TITLES[KnowledgeReviewReason.BotCouldNotAnswer],
          count: counts['botCouldNotAnswerCount'],
          permission: PERMISSIONS.UNDERSTANDING.VIEW,
        },
        {
          to: '/review-items/answer-incorrect',
          text: MARK_TITLES[KnowledgeReviewReason.AnswerIncorrect],
          count: counts['answerIncorrectCount'],
          permission: PERMISSIONS.UNDERSTANDING.VIEW,
        },
        {
          to: '/review-items/contact-feedback',
          text: 'Webchat Feedback',
          feature: FeaturesType.KNOWLEDGE_BASE_CONTACT_FEEDBACK,
          count: counts['contactFeedbackCount'],
          permission: PERMISSIONS.UNDERSTANDING.VIEW,
        },
      ],
    },
  ]

  return (
    <MainstayPage
      header="Knowledge Base"
      sidebarContent={
        <>
          <MainstaySidebarSection
            header="All Knowledge"
            links={linksAllKnowledge}
          />
          <MainstaySidebarSection
            header="Knowledge Maintenance"
            links={linksKnowledgeMaintenance}
          />
          {hasFeature(FeaturesType.GENERATIVE_AI_PARTNER_FACING_SCRAPER) && (
            <MainstaySidebarSection
              header="Knowledge Sources"
              links={linksKnowledgeSources}
            />
          )}
        </>
      }
      pageContentClassName={props.pageContentClassName}>
      {props.pageContent}
    </MainstayPage>
  )
}
