import AdmithubOnly from 'components/AdmithubOnly/AdmithubOnly'
import { INTERNAL } from 'const/routes'
import { MainstayPage } from 'components/MainstayPageContainer/MainstayPageContainer'
import NavBarContainer from 'page/NavBarPage'
import React from 'react'
import { getCanAuditUsers, getIsStaff } from 'store/triage/profile/selectors'
import { useSelector, useFeatures } from 'util/hooks'

export const InternalToolsPageContainer = ({
  loading,
  children,
}: {
  loading?: boolean
  children: React.ReactNode
}) => {
  const { hasFeature, FeaturesType } = useFeatures()

  const isStaff = useSelector(getIsStaff)
  const canAuditUsers = useSelector(getCanAuditUsers)

  const hasGenerativeAIFFEnabled = hasFeature(
    FeaturesType.GENERATIVE_AI_LIVE_CHAT_SUGGESTIONS
  )

  return (
    <NavBarContainer
      title="Admin Tools"
      className="d-flex h-100"
      loading={loading}>
      <MainstayPage
        header="Internal Tools"
        links={[
          {
            to: INTERNAL.MAP_ATTRIBUTES,
            text: 'Map Attributes',
          },
          ...(isStaff
            ? [
                {
                  to: INTERNAL.DELETE_BOT,
                  text: 'Remove Institution Data',
                },
                {
                  to: INTERNAL.UPLOAD_KNOWLEDGE_BASE,
                  text: 'Upload Knowledge Base Data',
                },
              ]
            : []),
          ...(canAuditUsers
            ? [
                {
                  to: INTERNAL.AUDIT_ACCESS,
                  text: 'Audit User Access',
                },
              ]
            : []),
          ...(hasGenerativeAIFFEnabled
            ? [
                {
                  to: INTERNAL.GENERATIVE_AI_TRANSACTIONS,
                  text: 'Generative AI Transactions',
                },
              ]
            : []),
          {
            to: INTERNAL.KNOWLEDGE_BASE_SCRAPING,
            text: 'Knowledge Base Scraping',
          },
        ]}>
        <AdmithubOnly>{children}</AdmithubOnly>
      </MainstayPage>
    </NavBarContainer>
  )
}
