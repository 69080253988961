import { ITransitionType } from 'api/request'
import classnames from 'classnames'
import AddBranchButton from 'components/AddBranchButton/AddBranchButton'
import { IBranchProps } from 'components/AutoBranch/AutoBranch'
import EditableIncomingNode from 'components/EditableIncomingNode/EditableIncomingNode'
import { EditableOutgoingNode } from 'components/EditableOutgoingNode/EditableOutgoingNode'
import LinkingIndicator from 'components/LinkingIndicator/LinkingIndicatorConnected'
import MediaNode from 'components/MediaNode/MediaNode'
import NodeSubtree, {
  HorizontalNode,
  VerticalNode,
} from 'components/NodeSubtree/NodeSubtree'
import NumberPrompt from 'components/NumberPrompt/NumberPrompt'
import SelectNodeRadio from 'components/SelectNodeRadio/SelectNodeRadio'
import { DEFAULT_DIALOG_STATE_MESSAGE } from 'const/settings'
import * as _ from 'lodash'
import * as React from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { PromptType } from 'store/campaign-scripts/reducer'
import {
  getPromptTypeDefaults,
  NEW_PROMPT_OPTION_INITIAL_MESSAGE,
} from 'util/campaign-scripts'

export default class NumberBranch extends React.PureComponent<IBranchProps> {
  state = {
    addingNewBranch: false,
  }

  handleBranchLabelChange = async (
    transitionType: ITransitionType,
    value: string
  ) => {
    if (this.props.updateNode) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const index = (transitionType as number) - 1
      if (index < 0 || isNaN(index)) {
        return
      }
      const multipleChoices = _.cloneDeep(
        this.props.workflowStep.multipleChoices || []
      )
      multipleChoices[index] = { prompt: value }
      const transitionState = this.props.workflowStep.nextStates[
        transitionType
      ] || { default: false }
      const data = {
        promptType: this.props.workflowStep.promptType,
        nextStates: {
          ...this.props.workflowStep.nextStates,
          [transitionType]: transitionState,
        },
        multipleChoices,
        editing: false,
      }
      return await this.props.updateNode({
        dialogId: this.props.workflowStep.parentDialog,
        dialogStateId: this.props.workflowStep.id,
        data,
      })
    }
  }

  handleDeleteBranch = (transitionType: ITransitionType) => {
    if (this.props.updateNode && typeof transitionType === 'number') {
      const {
        [transitionType]: value,
        ...rest
      } = this.props.workflowStep.nextStates
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const multipleChoices = this.props.workflowStep.multipleChoices!.filter(
        (_x, i) => i !== transitionType - 1
      )
      const newNextStates = Object.keys(rest)
        .sort()
        .map(x => rest[x])
      const statesDict: { [key: number]: { default: string | boolean } } = {}
      for (let i = 0; i < newNextStates.length; i++) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        statesDict[i + 1] = newNextStates[i]!
      }
      this.props.updateNode({
        dialogId: this.props.workflowStep.parentDialog,
        dialogStateId: this.props.workflowStep.id,
        data: {
          promptType: this.props.workflowStep.promptType,
          nextStates: statesDict,
          multipleChoices: [...multipleChoices],
          range: { min: 1, max: multipleChoices.length },
          editing: false,
        },
      })
    }
  }

  handleAddToBranch = (transitionType: number, type: PromptType) => {
    if (this.props.spliceNode) {
      this.props.spliceNode({
        dialogId: this.props.workflowStep.parentDialog,
        dialogStateId: this.props.workflowStep.id,
        data: {
          ...getPromptTypeDefaults(type),
          prompt: DEFAULT_DIALOG_STATE_MESSAGE,
          promptType: type,
          transitionType,
        },
      })
    }
  }

  handleAddLinkToBranch = (index: number) => {
    if (this.props.startLinking) {
      this.props.startLinking(index)
    }
  }

  handleEditNode = (editing: boolean) => {
    this.props.editNode?.({
      dialogStateId: this.props.workflowStep.id,
      editing,
    })
  }

  handleAddBranch = () => {
    this.setState({ addingNewBranch: true })
    if (this.props.updateNode) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const transitionKey = `${this.props.workflowStep.multipleChoices!.length +
        1}`
      const value = NEW_PROMPT_OPTION_INITIAL_MESSAGE
      if (!this.props.updateNode) {
        return
      }
      const multipleChoices = this.props.workflowStep.multipleChoices || []
      this.props.updateNode({
        dialogId: this.props.workflowStep.parentDialog,
        dialogStateId: this.props.workflowStep.id,
        data: {
          promptType: PromptType.number,
          editing: true,
          multipleChoices: [...multipleChoices, { prompt: value }],
          range: { min: 1, max: multipleChoices.length + 1 },
          nextStates: {
            ...this.props.workflowStep.nextStates,
            [transitionKey]: {
              default: false,
            },
          },
        },
      })
    }
  }

  handleReordering = (result: DropResult) => {
    if (this.props.updateNode && this.props.campaignScriptDragState) {
      const multipleChoices = this.props.workflowStep.multipleChoices || []
      const updatedMultipleChoices = [...multipleChoices]
      if (
        !result.destination ||
        result.source.droppableId !== 'multipleChoicePrompt'
      ) {
        return
      }
      updatedMultipleChoices.splice(
        result?.destination?.index,
        0,
        updatedMultipleChoices.splice(result?.source?.index, 1)[0]
      )

      const updatedWorkflowStepStates = Object.entries(
        this.props.workflowStep.nextStates
      )
      updatedWorkflowStepStates.splice(
        result?.destination?.index,
        0,
        updatedWorkflowStepStates.splice(result?.source?.index, 1)[0]
      )
      const statesDict: {
        [key: number]: { default: string | boolean } | undefined
      } = {}
      for (let i = 0; i < updatedWorkflowStepStates.length; i++) {
        statesDict[i + 1] = updatedWorkflowStepStates[i][1]
      }

      this.props.campaignScriptDragState({
        dialogStateId: this.props.workflowStep.id,
        nextStates: statesDict,
        multipleChoices: updatedMultipleChoices,
      })
      this.props.updateNode({
        dialogId: this.props.workflowStep.parentDialog,
        dialogStateId: this.props.workflowStep.id,
        data: {
          promptType: this.props.workflowStep.promptType,
          nextStates: statesDict,
          multipleChoices: updatedMultipleChoices,
          editing: false,
          range: { min: 1, max: multipleChoices.length },
        },
      })
    }
  }

  renderBranches = () => {
    const branches = React.Children.toArray(this.props.branch)
    const { workflowStep, editable } = this.props
    if (!workflowStep.multipleChoices) {
      return null
    }
    const length = workflowStep.multipleChoices.length
    return (
      <DragDropContext onDragEnd={result => this.handleReordering(result)}>
        <Droppable droppableId="multipleChoicePrompt">
          {(provided, snapshot) => {
            return (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={classnames(
                  snapshot.isDraggingOver
                    ? 'bg-mainstay-gray-backgrounds border-mainstay-dark-blue-65'
                    : 'bg-transparent'
                )}>
                {workflowStep?.multipleChoices?.map((x, i) => {
                  const transitionKey = workflowStep.nextStates[`${i + 1}`]
                    ? `${i + 1}`
                    : 'default'
                  const nextStateOrDefault =
                    workflowStep.nextStates[transitionKey] ||
                    workflowStep.nextStates['default']
                  const className =
                    i === length - 1 && !this.props.editable ? 'last' : ''
                  return (
                    <Draggable
                      key={transitionKey}
                      draggableId={transitionKey}
                      index={i}>
                      {provided => (
                        <div
                          className="draggable"
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}>
                          <NodeSubtree
                            className={className}
                            key={`${this.props.workflowStep.id}_${transitionKey}`}
                            parent={
                              <EditableIncomingNode
                                onDelete={this.handleDeleteBranch}
                                onChange={this.handleBranchLabelChange}
                                onEditNode={this.handleEditNode}
                                // Indicate if this branch has not been edited yet so we can open in edit mode
                                addingNewBranch={
                                  i === length - 1 && this.state.addingNewBranch
                                }
                                value={x.prompt}
                                editable={Boolean(editable)}
                                menuType={
                                  nextStateOrDefault == null ||
                                  nextStateOrDefault.default === false
                                    ? 'full'
                                    : 'limited'
                                }
                                onClickAddLink={() =>
                                  this.handleAddLinkToBranch(i + 1)
                                }
                                onClickAddChild={(type: PromptType) =>
                                  this.handleAddToBranch(i + 1, type)
                                }
                                transitionKey={i + 1}
                                transitionType={transitionKey}
                                hasHorizontalNode={true}
                              />
                            }>
                            {this.props.linking && (
                              <LinkingIndicator
                                stepId={workflowStep.id}
                                transition={i + 1}
                              />
                            )}
                            {branches[i]}
                          </NodeSubtree>
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )
          }}
        </Droppable>
      </DragDropContext>
    )
  }

  renderAddNodeButton = () => {
    return (
      <NodeSubtree
        className="last"
        parent={
          <HorizontalNode>
            <AddBranchButton onClick={this.handleAddBranch} />
          </HorizontalNode>
        }
      />
    )
  }

  handleRemoveMedia = () => {
    this.props.changeImage?.('')
  }

  render() {
    const { workflowStep, editable, children } = this.props
    const options = (workflowStep.multipleChoices || []).map(x => x.prompt)
    return (
      <VerticalNode>
        <a id={workflowStep.id} />
        <div className="d-flex flex-row">
          {this.props.linking && this.props.createLink && (
            <SelectNodeRadio onClick={this.props.createLink} />
          )}
          <EditableOutgoingNode
            node={this.props.workflowStep}
            editable={this.props.editable}
            isTerminal={!!this.props.isTerminal}
            value={workflowStep.prompt}
            personalizedPrompt={workflowStep.personalizedPrompt}
            editing={this.props.editing}
            onEditNode={this.handleEditNode}
            onChangeImage={this.props.changeImage}
            onDelete={() => {
              if (this.props.deleteEdge !== undefined) {
                this.props.deleteEdge()
              }
            }}
            onOptOut={this.props.optOut}
            onChange={this.props.updateNode}
            setValueBeingSaved={this.props.setValueBeingSaved}>
            <NumberPrompt
              editable={this.props.editable}
              question={this.props.valueBeingSaved || workflowStep.prompt}
              options={options}
              personalizedPrompt={workflowStep.personalizedPrompt}
              border={this.props.border}
            />
          </EditableOutgoingNode>
        </div>
        {workflowStep.media && (
          <VerticalNode>
            <MediaNode
              url={workflowStep.media}
              onRemove={this.handleRemoveMedia}
            />
          </VerticalNode>
        )}
        {children}
        {this.renderBranches()}
        {editable && this.renderAddNodeButton()}
      </VerticalNode>
    )
  }
}
