import moment from 'moment'

function getOperationDisplayString(op: string): string {
  switch (op) {
    case 'create':
      return 'New contacts'
    case 'update':
      return 'Updated contacts'
    case 'phone-update':
      return 'Phone updated contacts'
    default:
      return op || ''
  }
}

export function formatImportSegmentLabel(rawLabel: string): string {
  if (/^import-[\d]{13,14}$/.test(rawLabel)) {
    return `Import ${moment(Number(rawLabel.split('-').pop())).format(
      'MM/D/YY hh:mm a'
    )} (All contacts)`
  }
  if (/^import-[\d]{13,14}-.+/.test(rawLabel)) {
    const match = /^import-[\d]{13,14}-(.+)/.exec(rawLabel)
    if (match && match[1]) {
      const operation = match[1]
      const operationDisplayString = getOperationDisplayString(operation)
      return `Import ${moment(Number(rawLabel.split('-')[1])).format(
        'MM/D/YY hh:mm a'
      )} (${operationDisplayString})`
    } else {
      return `Import ${moment(Number(rawLabel.split('-')[1])).format(
        'MM/D/YY hh:mm a'
      )}`
    }
  }
  return rawLabel
}
