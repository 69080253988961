import React, { useCallback } from 'react'
import {
  MainstayFlexTableHeader,
  MainstayFlexTableRow,
} from 'mainstay-ui-kit/MainstayFlexRow/MainstayFlexRow'
import {
  MainstayFlexTableCol,
  MainstayFlexTableHeaderCol,
} from 'mainstay-ui-kit/MainstayFlexCol/MainstayFlexCol'
import SortArrow from 'components/Icons/SortArrow/SortArrow'
import { MainstayFlexTable } from 'mainstay-ui-kit/MainstayFlexTable/MainstayFlexTable'
import { calculateResponsePercentage } from 'components/CampaignCard/CampaignCard'
import { NavLink } from 'util/routing'
import { setCampaignHistorySortBy } from 'store/campaign-history/actions'
import { useDispatch, useFeatures, useSelector } from 'util/hooks'
import { getInstitutionDateFormat } from 'store/triage/institution/selectors'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { http2KeysOrDefault } from 'api/http'
import strftime from 'strftime'
import { ConfirmationModal } from 'components/Modal/Modal'
import { isRight } from 'fp-ts/lib/Either'
import {
  ICampaignBase,
  ICampaignTrigger,
  SortBy,
  SortOrder,
  Filters,
} from 'store/campaign-history/reducer'
import { RefetchingOverlay } from 'components/RefetchingOverlay/RefetchingOverlay'
import { getIsCampaignHistoryLoading } from 'store/campaign-history/selectors'
import classNames from 'classnames'
import { generatePath } from 'react-router'
import { CAMPAIGN_HISTORY, CAMPAIGN_SCHEDULER_ROUTES } from 'const/routes'
import { CampaignTag } from 'page/CampaignDetailsPage'
import CampaignPager from 'components/Pager/Pager'
import { OnOffToggleSwitch } from 'components/LabeledToggle/LabeledToggle'
import { updateCampaignTrigger } from 'api'

type CampaignTableSort = {
  readonly direction: SortOrder
  readonly field: SortBy
}

interface ICampaignTableProps {
  campaigns: ICampaignBase[]
  triggers: ICampaignTrigger[]
  campaignSidebarFilter: string | undefined
}

interface ICampaignBaseProps {
  campaign: ICampaignBase
}

const getCampaignType = (isRespondable: boolean) => {
  if (isRespondable) {
    return 'Interactive'
  }
  return 'Nudge'
}

export const getPath = (
  campaign: Omit<
    ICampaignBase,
    'date' | 'lastSentRecurringCampaign' | 'countEligibleUsersProcessed'
  >
) => {
  if (!campaign.recurring) {
    return campaign.upcoming
      ? CAMPAIGN_SCHEDULER_ROUTES.EDIT_INDEX +
          campaign.id +
          CAMPAIGN_SCHEDULER_ROUTES.TRIGGER_CONFIG
      : generatePath(CAMPAIGN_HISTORY.DETAIL, { id: campaign.id })
  }
  if (campaign.isParentRecurringInstance) {
    return campaign.started
      ? generatePath(CAMPAIGN_HISTORY.DETAIL, { id: campaign.id })
      : CAMPAIGN_SCHEDULER_ROUTES.EDIT_INDEX +
          campaign.id +
          CAMPAIGN_SCHEDULER_ROUTES.TRIGGER_CONFIG
  }
  return generatePath(CAMPAIGN_HISTORY.RECURRING_DETAIL, { id: campaign.id })
}

function CampaignTableRow({ campaign }: ICampaignBaseProps) {
  const dateFormat = useSelector(getInstitutionDateFormat)

  const getCampaignLastSent = React.useCallback(
    ({ upcoming, lastSentRecurringCampaign, date }: ICampaignBase) => {
      if (upcoming) {
        return (
          <>
            <h5 className="font-italic fw-400 mb-0">
              {strftime(dateFormat, date)}
            </h5>
            <h5 className="font-italic fw-400">{strftime('%l:%M %P', date)}</h5>
          </>
        )
      }
      if (lastSentRecurringCampaign) {
        return (
          <>
            <h5 className="fw-400 mb-0">
              {strftime(dateFormat, lastSentRecurringCampaign)}
            </h5>
            <h5 className="fw-400">
              {strftime('%l:%M %P', lastSentRecurringCampaign)}
            </h5>
          </>
        )
      }
      return (
        <>
          <h5 className="fw-400 mb-0">{strftime(dateFormat, date)}</h5>
          <h5 className="fw-400">{strftime('%l:%M %P', date)}</h5>
        </>
      )
    },
    [dateFormat]
  )

  const to = getPath(campaign)

  return (
    <NavLink
      to={to}
      key={campaign.id}
      className="w-100 campaign-row pointer hover-text-decoration-none"
      eventLocation="campaigns"
      eventAction="click"
      eventObject="individual campaign">
      <MainstayFlexTableRow className="text-mainstay-dark-blue-80 hover-bg-mainstay-dark-blue-10">
        <MainstayFlexTableCol xs={5}>
          <div className="d-flex align-items-center">
            <h5 className="fw-600 mb-0 mr-2">{campaign.name} </h5>
            <CampaignTag isRecurring={campaign.recurring} />
          </div>
          <h5 className="fw-400">{campaign.description}</h5>
        </MainstayFlexTableCol>
        <MainstayFlexTableCol xs={2}>
          <h5 className="fw-600 mb-0">
            {getCampaignType(campaign.isRespondable)}
          </h5>
          {campaign['isRespondable'] && (
            <h5 className="fw-400">
              {calculateResponsePercentage(
                campaign.countEligibleUsersProcessed,
                campaign.countDistinctUsersResponded
              ) || 0}
              %
            </h5>
          )}
        </MainstayFlexTableCol>
        <MainstayFlexTableCol xs={2}>
          {/* display "--" as sent count for upcoming campaigns */}
          {campaign.upcoming ? '--' : campaign.countEligibleUsersProcessed}
        </MainstayFlexTableCol>
        <MainstayFlexTableCol className="fw-400" xs={2}>
          {getCampaignLastSent(campaign)}
        </MainstayFlexTableCol>
      </MainstayFlexTableRow>
    </NavLink>
  )
}

export function TriggerToggle({
  trigger,
  showStatusCopy,
  fetchCampaignTriggerDetails,
}: {
  trigger: Partial<ICampaignTrigger> & {
    id: string
    enabled: boolean
    name: string
  }
  showStatusCopy?: boolean
  fetchCampaignTriggerDetails?: () => void
}) {
  const [triggerEnabled, setTriggerEnabled] = React.useState<boolean>(
    trigger.enabled
  )

  React.useEffect(() => {
    setTriggerEnabled(trigger.enabled)
  }, [trigger, trigger.enabled])

  const [
    showTriggerConfirmationModal,
    setShowTriggerConfirmationModal,
  ] = React.useState<boolean>(false)

  const updateTriggerEnabled = useCallback(
    async ({ id, enabled }: { id: string; enabled: boolean }) => {
      try {
        const res = await updateCampaignTrigger({
          id,
          data: { ...trigger, enabled },
        })
        if (isRight(res)) {
          setTriggerEnabled(enabled)

          if (fetchCampaignTriggerDetails) {
            // If toggling from the campaign detail page, we need to refetch the
            // trigger details so state stays in sync when navigating across tabs
            fetchCampaignTriggerDetails()
          }
        } else {
          setShowTriggerConfirmationModal(false)
          const messages = http2KeysOrDefault(
            res.left,
            'There was a problem updating your campaign.',
            ['contactFilterId']
          )
          Object.keys(messages).forEach(k => {
            toast(messages[k], { type: 'error' })
          })
        }
      } catch (e) {
        toast('Failed to update trigger', { type: 'error' })
      } finally {
        setShowTriggerConfirmationModal(false)
      }
    },
    [trigger, fetchCampaignTriggerDetails]
  )

  return (
    <>
      <>
        <OnOffToggleSwitch
          onChange={() => {
            setShowTriggerConfirmationModal(true)
          }}
          checked={triggerEnabled}
          size="sm"
        />
        {showStatusCopy && (
          <div className="ml-3">
            Campaign is currently {triggerEnabled ? 'active' : 'inactive'}.
          </div>
        )}
      </>

      <ConfirmationModal
        show={showTriggerConfirmationModal}
        hideCheckbox={true}
        zIndex={5000}
        confirmButtonColor="primary"
        eventLocation="campaigns"
        eventObject={
          !triggerEnabled
            ? 'enable data trigger modal'
            : 'disable data trigger modal'
        }
        helpText={
          <div>
            <p>
              {!triggerEnabled
                ? 'This will start tracking when contacts enter this Audience. Then, after the designated delay time, the contact will receive the Campaign Script.'
                : 'This will stop tracking when contacts enter this Audience. If there are any contacts who already entered this Audience, but did not receive the Campaign Script because the delay time has not elapsed, they will not receive the Campaign Script. '}
            </p>
          </div>
        }
        onConfirm={() =>
          updateTriggerEnabled({ id: trigger.id, enabled: !triggerEnabled })
        }
        title={
          !triggerEnabled ? `Enable ${trigger.name}` : `Disable ${trigger.name}`
        }
        confirmButtonText={!triggerEnabled ? 'Yes, Enable' : 'Yes, Disable'}
        onClose={() => {
          setShowTriggerConfirmationModal(false)
        }}
      />
    </>
  )
}

interface ITriggerTableRowProps {
  trigger: ICampaignTrigger
}
function TriggerTableRow({ trigger }: ITriggerTableRowProps) {
  const dateFormat = useSelector(getInstitutionDateFormat)

  const to = generatePath(
    !!trigger.lastSentDate
      ? CAMPAIGN_HISTORY.DATA_TRIGGERED_DETAIL
      : CAMPAIGN_HISTORY.EDIT_DATA_TRIGGERED_DETAIL +
          CAMPAIGN_SCHEDULER_ROUTES.TRIGGER_CONFIG,
    {
      id: trigger.id,
    }
  )

  return (
    <NavLink
      to={to}
      key={trigger.id}
      className="w-100 campaign-row pointer hover-text-decoration-none"
      eventLocation="campaigns"
      eventAction="click"
      eventObject="individual campaign">
      <MainstayFlexTableRow className="text-mainstay-dark-blue-80 hover-bg-mainstay-dark-blue-10">
        <MainstayFlexTableCol xs={5}>
          <div className="d-flex align-items-center">
            <h5 className="fw-600 mb-0 mr-2">{trigger.name} </h5>
          </div>
          <h5 className="fw-400">{trigger.description}</h5>
        </MainstayFlexTableCol>
        <MainstayFlexTableCol xs={2}>
          <h5 className="fw-600 mb-0">
            {getCampaignType(trigger.isRespondable)}
          </h5>
          {trigger['isRespondable'] && (
            <h5 className="fw-400">
              {calculateResponsePercentage(
                trigger.countEligibleUsersProcessed,
                trigger.countDistinctUsersResponded
              ) || 0}
              %
            </h5>
          )}
        </MainstayFlexTableCol>
        <MainstayFlexTableCol xs={1}>
          {!!trigger.lastSentDate ? trigger.countEligibleUsersProcessed : '--'}
        </MainstayFlexTableCol>
        <MainstayFlexTableCol className="fw-400" xs={2}>
          {!!trigger.lastSentDate ? (
            <>
              <h5 className="fw-400 mb-0">
                {strftime(dateFormat, trigger.lastSentDate)}
              </h5>
              <h5 className="fw-400">
                {strftime('%l:%M %P', trigger.lastSentDate)}
              </h5>
            </>
          ) : (
            '--'
          )}
        </MainstayFlexTableCol>
        <MainstayFlexTableCol className="fw-400" xs={1}>
          {!!trigger && <TriggerToggle trigger={trigger} />}
        </MainstayFlexTableCol>
      </MainstayFlexTableRow>
    </NavLink>
  )
}

export function CampaignTable({
  campaigns,
  triggers,
  campaignSidebarFilter,
  onPagerClick,
}: ICampaignTableProps & { onPagerClick: () => void }) {
  const dispatch = useDispatch()
  const loading = useSelector(getIsCampaignHistoryLoading)

  const { hasFeature, FeaturesType } = useFeatures()

  const isTriggersTable =
    hasFeature(FeaturesType.DATA_TRIGGERED_CAMPAIGNS) &&
    campaignSidebarFilter === Filters.is_datatriggered

  const [sort, setSort] = React.useState<CampaignTableSort>({
    field: SortBy.date,
    direction: SortOrder.desc,
  })

  const sortTable = (field: SortBy) => {
    const direction =
      field !== sort.field
        ? SortOrder.asc
        : sort.direction === SortOrder.asc
        ? SortOrder.desc
        : SortOrder.asc
    setSort({
      field,
      direction,
    })
    dispatch(
      setCampaignHistorySortBy({
        sortBy: field,
        order: direction,
      })
    )
  }

  return (
    <div>
      <h4>
        {!isTriggersTable ? 'Scheduled Campaigns' : 'Trigger Configurations'}
      </h4>
      <MainstayFlexTable>
        <MainstayFlexTableHeader>
          <MainstayFlexTableHeaderCol
            eventLocation="campaigns"
            eventAction="click"
            eventObject="table sort campaign"
            xs={5}>
            <div className="pointer" onClick={() => sortTable(SortBy.name)}>
              {sort.field === 'name' && (
                <SortArrow
                  className="fill-mainstay-dark-blue-80"
                  direction={sort.direction}
                />
              )}
              <span className={classNames({ 'fw-600': sort.field === 'name' })}>
                Campaign
              </span>
            </div>
          </MainstayFlexTableHeaderCol>
          <MainstayFlexTableHeaderCol
            eventLocation="campaigns"
            eventAction="click"
            eventObject="table sort type"
            xs={2}>
            <div className="pointer" onClick={() => sortTable(SortBy.type)}>
              {sort.field === SortBy.type && (
                <SortArrow
                  className="fill-mainstay-dark-blue-80"
                  direction={sort.direction}
                />
              )}
              <span
                className={classNames({
                  'fw-600': sort.field === SortBy.type,
                })}>
                Type
              </span>
            </div>
          </MainstayFlexTableHeaderCol>
          <MainstayFlexTableHeaderCol
            xs={isTriggersTable ? 1 : 2}
            eventLocation="campaigns"
            eventAction="click"
            eventObject="table sort sent">
            <div className="pointer" onClick={() => sortTable(SortBy.sent)}>
              {sort.field === SortBy.sent && (
                <SortArrow
                  className="fill-mainstay-dark-blue-80"
                  direction={sort.direction}
                />
              )}
              <span
                className={classNames({
                  'fw-600': sort.field === SortBy.sent,
                })}>
                Sent
              </span>
            </div>
          </MainstayFlexTableHeaderCol>
          <MainstayFlexTableHeaderCol
            xs={2}
            eventLocation="campaigns"
            eventAction="click"
            eventObject="table sort last sent">
            <div className="pointer" onClick={() => sortTable(SortBy.date)}>
              {sort.field === 'scheduledAt' && (
                <SortArrow
                  className="fill-mainstay-dark-blue-80"
                  direction={sort.direction}
                />
              )}
              <span
                className={classNames({
                  'fw-600': sort.field === 'scheduledAt',
                })}>
                Last Sent
              </span>
            </div>
          </MainstayFlexTableHeaderCol>
          {isTriggersTable && (
            <MainstayFlexTableHeaderCol xs={1}>
              <div>
                <span>Enabled</span>
              </div>
            </MainstayFlexTableHeaderCol>
          )}
        </MainstayFlexTableHeader>
        <RefetchingOverlay enabled={loading}>
          {isTriggersTable
            ? triggers.map(trigger => (
                <TriggerTableRow key={trigger.id} trigger={trigger} />
              ))
            : campaigns.map(campaign => (
                <CampaignTableRow key={campaign.id} campaign={campaign} />
              ))}
        </RefetchingOverlay>
      </MainstayFlexTable>
      <CampaignPager onClick={onPagerClick} />
    </div>
  )
}
