import 'page/conversations-v2/ConversationsDetail/ConversationsDetail.scss'
import { CenteredLoader } from 'components/Loader/Loader'
import * as api from 'api'
import { WebData, isFailure, isUnresolved } from 'store/webdata'
import { WireframeMessage } from 'components/WireframeMessage/WireframeMessage'
import { ReplyFailure } from 'page/conversations-v2/ConversationsDetail/BotReply/ReplyFailure'

/** Check if a message template has template literals in it. */
export function templateHasAttributes(messageTemplate: string | null): boolean {
  if (messageTemplate == null) {
    return false
  }
  return messageTemplate.includes('{{')
}

export function MessageWithAttributes({
  message,
}: {
  readonly message: WebData<api.ExpandedMessageType>
}) {
  if (isUnresolved(message)) {
    return <CenteredLoader />
  }
  if (isFailure(message)) {
    return <ReplyFailure />
  }
  if (
    templateHasAttributes(message.data.messageTemplate) &&
    message.data.messageTemplate != null
  ) {
    return (
      <div className="px-3 py-2">
        <WireframeMessage
          message={message.data.messageTemplate}
          attributes={message.data.messageAttributes}
          messageClassName="text-mainstay-dark-blue"
        />
      </div>
    )
  }

  return <>{message.data.message}</>
}
