import { useState } from 'react'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'

import 'components/NestedDropdown/NestedDropdown.scss'
import 'components/ContactList/ContactList.scss'

import {
  Menu,
  MenuItem,
  MenuButton,
  SubMenu,
  ClickEvent,
} from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import { OutsideClickHandler } from 'components/OutsideClickHandler/OutsideClickHandler'
import {
  EventAction,
  EventTrackerAttrAdder,
} from 'components/EventTracker/EventTracker'

export type ReactMenuEventHandler<E> = (event: E) => void

export interface INestedDropItem {
  label: string
  isSubMenuItem?: boolean
  subMenuItems?: INestedDropItem[]
  iconBefore?: React.ReactNode
  iconAfter?: React.ReactNode
  disabled?: boolean
  hidden?: boolean
  onClick?: ReactMenuEventHandler<ClickEvent>
  event?: {
    location: string
    action: EventAction
    object: string
  }
}

interface INestedDropdownProps {
  items: INestedDropItem[]
  onClickItem: ReactMenuEventHandler<ClickEvent>
  onClickSubItem?: ReactMenuEventHandler<ClickEvent>
}

export const NestedDropItem = ({
  disabled,
  iconBefore,
  iconAfter,
  label,
  onClick,
  event,
}: Omit<INestedDropItem, 'isSubMenuItem' | 'subMenuItems' | 'hidden'>) => {
  const item = (
    <MenuItem
      disabled={!!disabled}
      className="nested-drop-item"
      onClick={onClick}
      value={label}>
      <div
        className={`d-flex align-items-center justify-content-between w-100 ${
          !!disabled ? 'text-muted' : ''
        }`}>
        <div className="d-flex align-items-center">
          {iconBefore ? <div className="mr-2">{iconBefore}</div> : null}
          <div>{label}</div>
        </div>
        {iconAfter ?? null}
      </div>
    </MenuItem>
  )

  return event ? (
    <EventTrackerAttrAdder
      eventLocation={event.location}
      eventAction={event.action}
      eventObject={event.object}>
      {item}
    </EventTrackerAttrAdder>
  ) : (
    item
  )
}

export const NestedDropdown = ({
  items,
  onClickItem,
  onClickSubItem,
}: INestedDropdownProps) => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <Menu
      menuClassName="nested-drop-menu"
      direction="right" // menu-open direction for majority of time. If insufficient room, library will open menu to left.
      menuButton={
        <MenuButton
          className={`nested-drop-button ${
            menuOpen ? 'menu-open-action-button' : ''
          }`}>
          <OutsideClickHandler onClickOutside={() => setMenuOpen(false)}>
            <AHIcon
              eventLocation="insights"
              eventAction="click"
              eventObject="overflow menu"
              className="text-mainstay-dark-blue-80 action-button pointer"
              name="more_horiz"
              onClick={() => setMenuOpen(!menuOpen)}
            />
          </OutsideClickHandler>
        </MenuButton>
      }>
      {items
        .filter(item => !item.hidden)
        .map(item =>
          item.isSubMenuItem ? (
            <SubMenu
              key={item.label}
              label={
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div>{item.label}</div>
                  <div
                    className="bg-transparent"
                    style={{ rotate: `-90deg`, color: '#092e63' }}>
                    <AHIcon name="arrow_drop_down" />
                  </div>
                </div>
              }
              menuClassName="nested-drop-menu nested-drop-sub-menu"
              itemProps={{
                className:
                  'nested-drop-item d-flex align-items-center justify-content-between w-100 nested-drop-sub-item',
              }}>
              {(item?.subMenuItems ?? []).map((subItem: INestedDropItem) => (
                <NestedDropItem
                  key={subItem.label}
                  disabled={subItem.disabled}
                  iconBefore={subItem.iconBefore}
                  iconAfter={subItem.iconAfter}
                  label={subItem.label}
                  onClick={onClickSubItem}
                  event={subItem.event}
                />
              ))}
            </SubMenu>
          ) : (
            <NestedDropItem
              key={item.label}
              disabled={item.disabled}
              iconBefore={item.iconBefore}
              iconAfter={item.iconAfter}
              label={item.label}
              onClick={onClickItem}
              event={item.event}
            />
          )
        )}
    </Menu>
  )
}
