import cls from 'classnames'
import { TranslationIcon } from 'components/Icons/TranslationIcon/TranslationIcon'
import LinkifiedText from 'components/LinkifiedText/LinkifiedText'
import Tooltip from 'components/Tooltip/Tooltip'
import { BotReply } from 'page/conversations-v2/ConversationsDetail/BotReply/BotReply'
import 'page/conversations-v2/ConversationsDetail/ConversationsDetail.scss'
import { MessageOrigin } from 'page/conversations-v2/ConversationsDetail/index'
import 'page/conversations-v2/ConversationsDetail/Message/Message.scss'
import { MessageContainer } from 'page/conversations-v2/ConversationsDetail/MessageContainer/MessageContainer'
import React from 'react'

export const linkDecorator = (
  decoratedHref: string,
  decoratedText: string,
  key: number,
  tabIndex: number = -1,
  ref?: React.RefObject<HTMLAnchorElement>
): React.ReactNode => (
  <a
    href={decoratedHref}
    key={key}
    target="_blank"
    tabIndex={tabIndex}
    ref={ref}>
    {decoratedText}
  </a>
)

function MessageBody({
  text,
  sourceText,
  direction,
  className,
}: {
  readonly text: string | JSX.Element
  readonly sourceText: string | null
  readonly direction: 'incoming' | 'outgoing'
  readonly className?: string
}) {
  const [translationOpen, setTranslationOpen] = React.useState(false)
  const toggleTranslationOpen = React.useCallback(
    () => setTranslationOpen(s => !s),
    []
  )
  const hasTranslation = sourceText != null

  return (
    <div
      className={cls(className, 'w-100 d-flex', {
        pointer: hasTranslation,
        'conv-v2-user-message-body': direction === 'incoming',
      })}
      onClick={toggleTranslationOpen}>
      {hasTranslation ? (
        <Tooltip content="Some messages have been translated. Click the message to see the original text.">
          <TranslationIcon
            className="conv-v2-translate-icon-padding flex-shrink-0"
            fill={direction === 'incoming' ? '#ffffff' : undefined}
          />
        </Tooltip>
      ) : null}
      <div className="w-100">
        <div className="whitespace-pre-wrap word-wrap-break-word px-3 py-2">
          <LinkifiedText componentDecorator={linkDecorator}>
            {text}
          </LinkifiedText>
        </div>
        {hasTranslation && translationOpen ? (
          <div className="word-wrap-break-word px-3 py-2">
            <div className="mt-1 small font-weight-bold">
              {direction === 'outgoing' ? 'TRANSLATION:' : 'ORIGINAL:'}
            </div>
            <div className="small">{sourceText}</div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export function Message({
  id,
  direction,
  text,
  origin,
  sourceText,
}: {
  readonly direction: 'incoming' | 'outgoing'
  readonly id: string
  readonly text: string | JSX.Element
  readonly sourceText: string | null
  readonly origin: MessageOrigin | null
}) {
  return (
    <MessageContainer direction={direction}>
      {origin ? (
        <BotReply origin={origin} messageId={id}>
          <MessageBody
            direction={direction}
            text={text}
            sourceText={sourceText}
          />
        </BotReply>
      ) : (
        <MessageBody
          direction={direction}
          text={text}
          sourceText={sourceText}
        />
      )}
    </MessageContainer>
  )
}
