import {
  IToggleSwitchV2Props,
  ToggleSwitchV2,
} from 'components/ToggleSwitch/ToggleSwitch'

const ToggleSwitchLabel = ({ label }: { label: string }) => (
  <span className="text-white text-mainstay-body-caption switch-text">
    {label}
  </span>
)

export const OnOffToggleSwitch = (
  props: Omit<IToggleSwitchV2Props, 'checkedIcon' | 'uncheckedIcon'>
) => {
  return (
    <ToggleSwitchV2
      {...props}
      uncheckedIcon={<ToggleSwitchLabel label="OFF" />}
      checkedIcon={<ToggleSwitchLabel label="ON" />}
    />
  )
}
