import React from 'react'
import 'page/conversations-v2/ConversationsDetail/ConversationsDetail.scss'
import * as api from 'api'
import { isRight } from 'fp-ts/lib/Either'
import { WebData, Loading, Success, Failure } from 'store/webdata'
export function useBotMessageExpanded(
  messageId: string,
  genAiTransactionId: number | undefined,
  autoReplyExpanded: boolean
): WebData<api.ExpandedMessageType> {
  const [state, setState] = React.useState<WebData<api.ExpandedMessageType>>()
  React.useEffect(() => {
    if (!autoReplyExpanded) {
      return
    }
    if (!messageId && !genAiTransactionId) {
      return
    }
    setState(Loading())
    api
      .conversationsV2ExpandMessage({ messageId, genAiTransactionId })
      .then(res => {
        if (isRight(res)) {
          setState(Success(res.right))
        } else {
          setState(Failure(''))
        }
      })
  }, [messageId, autoReplyExpanded, genAiTransactionId])
  return state
}
