import React from 'react'
import { useDispatch, useInstitutionId, useIsLoggedIn } from 'util/hooks'
import * as t from 'io-ts'
import { isRight } from 'fp-ts/lib/Either'
import * as Sentry from '@sentry/browser'
import { condenseSchemaViolations } from 'api/http'
import { loadNotificationState } from 'components/LiveChatNotificationsPane/LiveChatNotificationPane'
import * as notificationSoundMp3 from 'components/LiveUpdates/notification-sound-direct.mp3'
import notificationImageUrl from 'components/LiveUpdates/notification-image.png'
import { push } from 'connected-react-router'
import { isSafari } from 'util/browser'
import * as pubsub from 'page/conversations-v2/pubsub'
import { sendEvent } from 'api/events'

const liveChatNotificationSchema = t.type({
  name: t.literal('live-chat-message'),
  data: t.type({
    contactId: t.string,
  }),
})

export function rightOrNullWithSentry<T>(result: t.Validation<T>): T | null {
  if (isRight(result)) {
    return result.right
  }

  Sentry.withScope(scope => {
    const violations = condenseSchemaViolations(result.left)
    scope.setExtras({
      violationCount: violations.length,
      violations,
    })
    Sentry.captureMessage(
      'failed to parse pub-sub message',
      Sentry.Severity.Error
    )
  })
  return null
}

function getConversationURL({
  contactId,
}: {
  readonly contactId: string
}): string {
  const url = new URL(window.location.href)
  url.pathname = `/conversations-v2/${contactId}`
  return url.href
}

/** Hack to play sounds in Safari
 *
 * The user must click the page before we can play sound. So we add an event
 * listener to the page and when the click, we play a sound. After this we have
 * permission to play sounds for the current session.
 *
 * source: https://stackoverflow.com/a/58189464/
 */
function unlockAudio(e: MouseEvent) {
  // We only have permission to play audio after user initiated events.
  // https://developer.mozilla.org/en-US/docs/Web/API/Event/isTrusted
  //
  // Example error: NotAllowedError: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD
  if (!e.isTrusted) {
    return
  }
  // Load sound so we can play it later.
  //
  // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
  const sound = new Audio(notificationSoundMp3)
  sound.load()
  document.body.removeEventListener('click', unlockAudio)
}

/**
 * Connect to Ably to listen for live updates.
 *
 * When we switch institutions or users, we should disconnect and cleanup before
 * creating a new client.
 */
export function LiveUpdates() {
  const institutionId = useInstitutionId()
  const isLoggedIn = useIsLoggedIn()
  const dispatch = useDispatch()
  React.useEffect(() => {
    document.body.addEventListener('click', unlockAudio)
    return () => {
      document.body.removeEventListener('click', unlockAudio)
    }
  }, [])

  React.useEffect(() => {
    // institutionId be null if the app hasn't fully loaded yet.
    // Also we don't need to subscribe when we aren't logged in as we won't be
    // able to get an auth token resulting in some noisy logs and retries.
    if (!institutionId || !isLoggedIn) {
      return
    }
    return pubsub.subscribe({
      institutionId,
      channel: `college:${institutionId}:global-updates`,
      onMessage: rawMessage => {
        const parsedMessage = rightOrNullWithSentry(
          liveChatNotificationSchema.decode(rawMessage)
        )
        if (parsedMessage == null) {
          return
        }
        const notificationState = loadNotificationState()
        // Notification will not exist on iOS
        if (!('Notification' in window)) {
          return
        }
        if (
          Notification.permission !== 'granted' ||
          notificationState !== 'on'
        ) {
          return
        }
        const notification = new Notification('Mainstay — New message', {
          body: 'A new contact is live on your WebChat.',
          tag: rawMessage.id,
          icon: notificationImageUrl,
          requireInteraction: true,
        })
        notification.onclick = (event: Event) => {
          const conversationURL = getConversationURL({
            contactId: parsedMessage.data.contactId,
          })
          // Safari doesn't allow opening new tabs on clicking a notification, so
          // we allow the default behavior of focusing the window and then we
          // navigate to the conversation.
          if (isSafari()) {
            dispatch(push(conversationURL))
          } else {
            // don't open or focus the current tab.
            event.preventDefault()
            // open conversation in new window or tab.
            window.open(conversationURL, '_blank')
          }
          // dismiss the notification.
          notification.close()
        }
        sendEvent('notification:conversations-live-chat', {
          institutionId,
          contactId: parsedMessage.data.contactId,
        })
        // play sound for notification.
        const audio = new Audio(notificationSoundMp3)
        // we cannot play sound if the we don't have browser permission.
        // Safari will raise an exception.
        audio.play().catch(e => {
          // tslint:disable-next-line: no-console
          console.warn('failed to play notification sound', e)
        })
      },
    })
  }, [dispatch, institutionId, isLoggedIn])
  return null
}
