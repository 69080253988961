import * as React from 'react'
import SideEffectNode from 'components/SideEffectNode/SideEffectNode'
import { ICampaignScriptStep } from 'store/campaign-scripts/reducer'
import { VerticalLine } from 'components/NodeSubtree/NodeSubtree'

interface IOptOutProps {
  onUpdateNode: (data: Partial<ICampaignScriptStep>) => void
  actionIndex: number
  step: ICampaignScriptStep
  deletable?: boolean
}

export const OptOut = ({
  onUpdateNode,
  actionIndex,
  step,
  deletable,
}: IOptOutProps) => {
  const handleDelete = React.useCallback(() => {
    const newExitActions = (step.exitActions || []).filter(
      (_, i: number) => i !== actionIndex
    )
    onUpdateNode({ exitActions: newExitActions })
  }, [onUpdateNode, actionIndex, step])

  return (
    <div>
      <VerticalLine />
      <SideEffectNode deletable={deletable} onDelete={handleDelete}>
        Opt out of future messages
      </SideEffectNode>
    </div>
  )
}
export const ScheduleJob = () => {
  return (
    <div>
      <VerticalLine />
      <SideEffectNode>Start dialog</SideEffectNode>
    </div>
  )
}
export const UpdateHubspot = () => (
  <div>
    <VerticalLine />
    <SideEffectNode>Create or Update Hubspot Contact</SideEffectNode>
  </div>
)
