export function ThumbsUpIcon({
  className,
  fill = '#5E6B72',
  width = '24',
  height = '24',
}: {
  readonly className?: string
  readonly fill?: string
  readonly width?: string
  readonly height?: string
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 10.093L21.9573 10.1776H22V11.9061C22 12.1588 21.9573 12.3701 21.872 12.5806L19.106 18.9037C18.8509 19.5359 18.2128 20 17.4466 20H9.27669C8.29811 20 7.44662 19.1564 7.44662 18.187V9.20809C7.44662 8.70177 7.65904 8.28089 7.99945 7.94364L13.9572 2L14.9357 2.96947C15.1908 3.22218 15.3188 3.51716 15.3188 3.89668V4.19166L14.4256 8.32316H20.1699C21.1485 8.32316 22 9.12446 22 10.0939V10.093ZM2 20V9.20809H5.65922V20H2Z"
        fill={fill}
      />
    </svg>
  )
}
