export function ThumbsDownIcon({
  className,
  fill = '#5E6B72',
  width = '20',
  height = '18',
}: {
  readonly className?: string
  readonly fill?: string
  readonly width?: string
  readonly height?: string
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3406 0.000899215H20V10.7923H16.3406V0.000899215ZM12.7239 0.000899215C13.7025 0.000899215 14.554 0.844424 14.554 1.81385V10.7923C14.554 11.2986 14.3416 11.7194 14.0012 12.0567L8.04321 18L7.06459 17.0306C6.80949 16.7779 6.68149 16.4829 6.68149 16.1034V15.8085L7.57478 11.6772H1.83015C0.851527 11.6772 0 10.8759 0 9.90648L0.0426671 9.82194H0V8.09353C0 7.84083 0.0426671 7.6295 0.128001 7.41906L2.8941 1.09622C3.1492 0.464029 3.78739 0 4.55358 0H12.7239V0.000899215Z"
        fill={fill}
      />
    </svg>
  )
}
