import * as React from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from 'components/Icons/FontAwesomeIcon/FontAwesomeIcon'

interface ILinkNodeProps {
  className?: string
  to?: string
}

export default class LinkNode extends React.PureComponent<ILinkNodeProps> {
  render() {
    return (
      <div className={classnames('d-flex p-2 node', this.props.className)}>
        <div className="transition-type text-primary mr-2">
          <FontAwesomeIcon icon="arrow-right" />
        </div>
        <div className="body text-primary">
          <a href={`#${this.props.to}`}>{this.props.children}</a>
        </div>
      </div>
    )
  }
}
