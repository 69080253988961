import { connect } from 'react-redux'
import { logoutAsync, pulseAsync } from 'store/auth/thunks'
import {
  AutoLogout,
  IAutoLogoutStateProps,
  IAutoLogoutDispatchProps,
} from 'components/AutoLogout/AutoLogout'
import { RootState as IState, Dispatch } from 'store/store'

const mapDispatchToProps = (dispatch: Dispatch): IAutoLogoutDispatchProps => ({
  logout: logoutAsync(dispatch),
  pulse: pulseAsync(dispatch),
})

const mapStateToProps = (state: IState): IAutoLogoutStateProps => ({
  isLoggedIn: state.auth.authed,
})

export default connect(mapStateToProps, mapDispatchToProps)(AutoLogout)
