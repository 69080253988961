import React from 'react'
import classnames from 'classnames'
import AutosizingTextArea from 'react-textarea-autosize'
import FancyImagePreview from 'components/FancyTextBox/FancyImagePreview/FancyImagePreview'

interface IFancyTextAreaProps {
  className?: string
  placeholder?: string
  value?: string
  rows?: number
  maxRows?: number
  minRows?: number
  disabled?: boolean
  onCursorChange?: (e: React.MouseEvent) => void
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  onPressEnter?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
  name?: string
  required?: boolean
  error?: string
  isValid?: boolean
  resizable?: boolean
  autoFocus?: boolean
  id?: string
  autosize?: boolean
  readOnly?: boolean
  ariaLabel?: string
  active?: boolean
  style?: React.CSSProperties
  selectedImage?: {
    url: string
    name?: string
  }
  onRemoveMedia?: () => void
  imagePreview?: boolean
}

export const FancyTextArea = React.forwardRef<
  HTMLTextAreaElement,
  IFancyTextAreaProps
>((props, ref) => {
  const {
    isValid = true,
    value,
    resizable,
    disabled,
    className,
    active,
    onCursorChange,
    maxRows,
    minRows,
    onPressEnter,
    style: propsStyle,
    imagePreview = false,
    onRemoveMedia,
    selectedImage,
    ...rest
  } = props
  const classNames = classnames(
    'form-control',
    {
      'is-invalid': !isValid,
      'has-value': !!value,
      'no-transition': resizable,
      disabled,
    },
    active ? 'active-input' : 'inactive-input',
    className
  )
  const style: React.CSSProperties = {
    ...propsStyle,
    resize: resizable ? 'both' : 'none',
  }

  const component = resizable ? (
    <AutosizingTextArea
      {...rest}
      inputRef={ref || undefined}
      disabled={disabled}
      onKeyPress={onPressEnter}
      onClick={onCursorChange}
      className={classNames}
      value={value}
      style={style}
      maxRows={maxRows}
      minRows={minRows}
    />
  ) : (
    <textarea
      {...rest}
      ref={ref}
      onKeyPress={onPressEnter}
      onClick={onCursorChange}
      disabled={disabled}
      className={classNames}
      value={value}
      style={style}
      aria-label={props.ariaLabel || 'Enter text here'}
    />
  )

  return (
    <>
      {component}
      {imagePreview && selectedImage && selectedImage.url && (
        <FancyImagePreview
          onRemove={onRemoveMedia}
          url={selectedImage.url}
          name={selectedImage.name}
        />
      )}
    </>
  )
})
