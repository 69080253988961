import * as React from 'react'
import LinkNode from 'components/LinkNode/LinkNode'
import { PopoverComponent } from 'components/PopoverComponent/PopoverComponent'
import { OutgoingNode } from 'components/OutgoingNode/OutgoingNode'
import MediaNode from 'components/MediaNode/MediaNode'
import { WireframeMessage } from 'components/WireframeMessage/WireframeMessage'
import { ILinkBranchProps } from 'components/LinkBranch/LinkBranch'

interface IPopoverLinkNodeProps extends ILinkBranchProps {
  message: string
  media?: string | null
  editable?: boolean
  children?: React.ReactNode
}

export const PopoverLinkNode = ({
  id,
  enableDeletion = true,
  enableJump = true,
  media,
  message,
  editable,
  children,
}: IPopoverLinkNodeProps) => {
  const [visible, setVisible] = React.useState(false)

  const hidePopover = () => {
    setVisible(false)
  }

  const showPopover = () => {
    setVisible(true)
  }

  const renderPopover = () => {
    return (
      <div className="border rounded" role="tooltip">
        <h3 className="popover-header">Link to previous dialog</h3>
        <div className="popover-body p-3 bg-white d-flex">
          <OutgoingNode editable={editable}>{message}</OutgoingNode>
          {media && <MediaNode url={media} />}
          <div className="ml-2">{children}</div>
        </div>
      </div>
    )
  }

  const renderLink = () => {
    return (
      <div className="popover-wrapper" onMouseEnter={showPopover}>
        <WireframeMessage
          message={message}
          messageClassName="text-mainstay-dark-blue"
        />
      </div>
    )
  }

  const LinkWrapper = ({ children }: { children: React.ReactNode }) => {
    return enableJump ? (
      <LinkNode to={id}>{children}</LinkNode>
    ) : (
      <div>{children}</div>
    )
  }

  return (
    <LinkWrapper>
      {enableDeletion ? (
        <PopoverComponent
          visible={visible}
          renderPopper={renderPopover}
          renderReference={renderLink}
          onClickOutside={hidePopover}
          popoverPlacement="right"
        />
      ) : (
        renderLink()
      )}
    </LinkWrapper>
  )
}
