import React from 'react'
import { WireframeMessage } from 'components/WireframeMessage/WireframeMessage'
import DraftEditor, {
  GenAIDraftEditorProps,
} from 'components/DraftEditor/DraftEditor'
import FormFeedback from 'reactstrap/lib/FormFeedback'
import Label from 'reactstrap/lib/Label'
import classnames from 'classnames'
import { IDialogForUnknownContact } from 'api/response'
import {
  AsyncCustomScriptSearch,
  SelectChangeEvent,
} from 'components/AsyncCustomScriptSearch/AsyncCustomScriptSearch'
import {
  FormGroup,
  IAutoSuggestOption,
} from 'components/AutoSuggest/AutoSuggest'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { CollapsibleSectionHeader } from 'components/CollapsibleSection/CollapsibleSection'
import { ToggleSwitchV2 } from 'components/ToggleSwitch/ToggleSwitch'
import { ReferenceContentCard } from 'components/ContentCard/ContentCard'

export interface IWriteResponseContentProps {
  response: string
  onChangeAnswer: (answer: string) => void
  hasContactAttributes?: boolean
  disableAttributes?: boolean
  includeSensitiveContactAttributes?: boolean
  genAIProps?: GenAIDraftEditorProps
}

export const BasicResponseCardContentWriteMode = ({
  response,
  onChangeAnswer,
  hasContactAttributes,
  disableAttributes = false,
  includeSensitiveContactAttributes = true,
  genAIProps,
}: IWriteResponseContentProps) => {
  return (
    <div className="px-3">
      <span className="caption mb-3">Response</span>
      <DraftEditor
        className="my-2"
        autoFocus={true}
        initialAnswer={response}
        onChange={onChangeAnswer}
        textBoxLabel=""
        toggleResetDraftEditor={false}
        disableInstitutionAttributes={false}
        disableContactAttributes={disableAttributes}
        hasContactAttributes={hasContactAttributes}
        includeSensitiveContactAttributes={includeSensitiveContactAttributes}
        genAIProps={genAIProps}
      />
    </div>
  )
}

export const BasicResponseCardContentReadMode = ({
  response,
}: {
  response: string
}) => {
  return (
    <div className="px-3">
      <span className="caption mb-3">Response</span>
      <WireframeMessage message={response} />
    </div>
  )
}

export interface IScriptAnswerInputProps {
  label: string
  error?: string
  value?: IAutoSuggestOption<IDialogForUnknownContact>
  onChange?: (e: SelectChangeEvent) => void
  touched?: boolean
  name: string
  className?: string
}

export const ScriptResponseContent: React.FC<IScriptAnswerInputProps & {
  editing: boolean
}> = props => {
  const {
    editing,
    children,
    className,
    name,
    onChange,
    touched,
    value,
    error,
    label,
  } = props
  return (
    <div className="px-3">
      <FormGroup className={classnames('m-0 pt-0', className)}>
        <Label className="text-muted small m-0 p-0" for={name}>
          {label}
        </Label>
        {editing && onChange ? (
          <AsyncCustomScriptSearch
            name={name}
            onChange={onChange}
            value={value}
            touched={touched}
          />
        ) : (
          <span onClick={e => e.stopPropagation()}>
            <ReferenceContentCard
              className="pl-0"
              type="Script"
              link={
                props.value?.value
                  ? `/campaign-scripts/${props.value?.value}/view`
                  : undefined
              }
              icon={<AHIcon name="comment" flipX />}>
              {props.value?.label}
            </ReferenceContentCard>
          </span>
        )}
        <FormFeedback className="d-block">{error}</FormFeedback>
      </FormGroup>
      {children}
    </div>
  )
}
export interface IToggleDisplayComponent {
  info?: string
  title: string
  toggled: boolean
  onToggle?: () => void
  on?: React.ReactElement
  off?: React.ReactElement
  editing: boolean
  className?: string
}

export const ConditionalEditDisplay = ({
  title,
  className,
  editing,
  info,
  onToggle,
  toggled,
  off,
  on,
}: IToggleDisplayComponent) => {
  return (
    <div className={classnames('w-100', className)}>
      {editing && onToggle && (
        <div className="d-flex px-3 mb-2 justify-content-between align-items-center">
          <CollapsibleSectionHeader
            titleClassName="font-weight-normal"
            info={info}
            title={title}
          />
          <ToggleSwitchV2
            ariaLabel="Provide an interactive response toggle"
            checked={toggled}
            onChange={onToggle}
            size="sm"
          />
        </div>
      )}
      <div>{toggled ? on : off}</div>
    </div>
  )
}
