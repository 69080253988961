import React from 'react'
import { Form, FormGroup, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { useDispatch, useSelector } from 'util/hooks'
import { Button } from 'components/Button/Button'
import moment from 'moment'
import { parse } from 'date-fns'
import { ChannelsCheckboxList } from 'components/ChannelsCheckboxList/ChannelsCheckboxList'
import {
  parseFirstQueryParam,
  parseQueryString,
  queryParamToArray,
} from 'util/string'
import * as api from 'api'
import {
  ConversationsSelectInput,
  SelectInputValue,
} from 'page/conversations-v2/ConversationList/ConversationHeader/ConversationsListFilterModal/ConversationsSelectInput/ConversationsSelectInput'
import { KBReviewItemsFilterParams } from 'page/knowledge-base/KnowledgeReview/KnowledgeReviewFilter/KnowledgeReviewFilter'
import {
  getKnowledgeBaseReviewItems,
  getKnowledgeBaseDropDownItems,
} from 'page/knowledge-base/KnowledgeReview/selectors'
import { KnowledgeBaseReviewItemsFiltersType } from 'page/knowledge-base/KnowledgeReview/reducer'
import { showKBReviewItemsFilterModal } from 'page/knowledge-base/KnowledgeReview/actions'
import { ReadOnlyDatePicker } from 'components/ReadOnlyDatePicker/ReadOnlyDatePicker'
import { Modifier } from 'react-day-picker/types/common'
import { SimpleModalHeader } from 'components/SimpleModalHeader/SimpleModalHeader'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { ParsedQuery } from 'query-string'

function loadAudiences(search: string) {
  return (
    api
      .listContactSegments({ search })
      .then(res => {
        return res.data.map(x => ({ label: x.name, value: String(x.id) }))
      })
      // ignore errors when loading via react-select.
      .catch(() => [])
  )
}

export function AudienceMultiSelect({
  selectedAudiences,
  setSelectedAudiences,
  placeholder,
}: {
  readonly selectedAudiences: SelectInputValue[]
  readonly setSelectedAudiences: (x: SelectInputValue[]) => void
  readonly placeholder: string
}) {
  const onAudienceLoadOptions = async (
    inputValue: string
  ): Promise<SelectInputValue[]> => {
    return await loadAudiences(inputValue)
  }
  return (
    <ConversationsSelectInput
      isMulti
      selectedOption={selectedAudiences}
      placeholder={placeholder}
      onSelect={setSelectedAudiences}
      onClear={() => {
        setSelectedAudiences([])
      }}
      loadOptions={onAudienceLoadOptions}
    />
  )
}

const getDateFromQueryParams = (
  urlParams: ParsedQuery,
  fromOrTo: 'from' | 'to'
): Date | undefined => {
  const param =
    fromOrTo === 'from'
      ? urlParams[KBReviewItemsFilterParams.DateFrom]
      : urlParams[KBReviewItemsFilterParams.DateTo]

  if (!param) {
    return undefined
  }

  const date = parseFirstQueryParam(param)

  if (!date) {
    return undefined
  }

  return parse(date, 'yyyy-dd-MM', moment().toDate())
}

export function KnowledgeReviewFilterModal({
  onApply,
}: {
  readonly onApply: (params: KnowledgeBaseReviewItemsFiltersType) => void
}) {
  const urlParams = parseQueryString(location.search)

  const dispatch = useDispatch()
  const { showFilterModal } = useSelector(getKnowledgeBaseReviewItems)
  const { audiences } = useSelector(getKnowledgeBaseDropDownItems)

  const transports = queryParamToArray(
    urlParams[KBReviewItemsFilterParams.Transports]
  )
  const [selectedAudiences, setSelectedAudiences] = React.useState<
    SelectInputValue[]
  >(audiences || [])
  const [filterBySMS, setFilterBySMS] = React.useState<boolean>(
    transports.includes(KBReviewItemsFilterParams.Twilio)
  )
  const [filterBySlack, setFilterBySlack] = React.useState<boolean>(
    transports.includes(KBReviewItemsFilterParams.Slack)
  )
  const [filterByWebChat, setFilterByWebChat] = React.useState<boolean>(
    transports.includes(KBReviewItemsFilterParams.WebChat)
  )
  const [filterByFacebook, setFilterByFacebook] = React.useState<boolean>(
    transports.includes(KBReviewItemsFilterParams.Facebook)
  )
  const [reviewed, setReviewed] = React.useState<boolean>(
    urlParams[KBReviewItemsFilterParams.Reviewed] === '1'
  )

  const [dateFilter, setDateFilter] = React.useState<{
    readonly from?: Date
    readonly to?: Date
  }>({
    from: getDateFromQueryParams(urlParams, 'from'),
    to: getDateFromQueryParams(urlParams, 'to'),
  })

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault()
    onApply({
      filterBySMS,
      filterByWebChat,
      filterByFacebook,
      filterBySlack,
      dateFrom: dateFilter.from,
      dateTo: dateFilter.to,
      reviewed,
      selectedAudiences,
    })
  }

  function handleClearFilter() {
    setFilterBySMS(false)
    setFilterByFacebook(false)
    setFilterByWebChat(false)
    setFilterBySlack(false)
    setReviewed(false)
    setDateFilter({})
    setSelectedAudiences([])
  }

  const clearFiltersDisabled =
    !filterBySMS &&
    !filterByWebChat &&
    !filterByFacebook &&
    !filterBySlack &&
    !reviewed &&
    !dateFilter.from &&
    !dateFilter.to &&
    selectedAudiences.length === 0

  const onToggle = () => {
    dispatch(showKBReviewItemsFilterModal(false))
  }

  const today = moment()
    .startOf('day')
    .toDate()

  const handleDayChange = (e: Date, dir: 'from' | 'to') => {
    const { to } = dateFilter

    // set `dateFilter.to` to day after new `e` value, unless `e` is today,
    // in which case set `dateFilter.to` to today as well
    const calcNextToForFromChange = () => {
      return !to
        ? moment(e).isSame(today, 'day')
          ? today
          : moment(e)
              .add(1, 'day')
              .toDate()
        : to
    }

    const nextDateFilter =
      dir === 'from'
        ? {
            from: moment(e).toDate(),
            to: calcNextToForFromChange(),
          }
        : {
            to: moment(e).toDate(),
          }

    setDateFilter(prev => ({
      ...prev,
      ...nextDateFilter,
    }))
  }

  const setDisabledDays = (dir: 'from' | 'to'): Modifier => {
    const { to, from } = dateFilter

    return dir === 'from'
      ? {
          after: to ? moment(to).toDate() : today,
        }
      : {
          before: from ? moment(from).toDate() : undefined,
          after: today,
        }
  }

  return (
    <Modal
      isOpen={showFilterModal}
      size="lg"
      toggle={onToggle}
      className="filter-modal">
      <Form onSubmit={handleSubmit}>
        <SimpleModalHeader text="Filters" onClose={onToggle} />
        <ModalBody>
          <FormGroup className="px-4 pb-2 pt-3" check>
            <div className="filter-modal-sub-header pb-1">Review Status</div>
            <Checkbox
              id="reviewStatusDone"
              type="check"
              titleComponent={
                <div
                  className="d-flex"
                  onClick={() => setReviewed(prev => !prev)}>
                  <div className="d-flex align-items-center">
                    <span className="ml-2 fs-16px text-mainstay-dark-blue">
                      Show items marked "Done"
                    </span>
                  </div>
                </div>
              }
              checked={reviewed}
              onClick={() => setReviewed(prev => !prev)}
            />
          </FormGroup>
          <hr />
          <FormGroup className="w-360 px-4">
            <div className="filter-modal-sub-header pb-1">Audiences</div>
            <AudienceMultiSelect
              selectedAudiences={selectedAudiences}
              setSelectedAudiences={setSelectedAudiences}
              placeholder="All Audiences"
            />
          </FormGroup>
          <hr />
          <div className="px-4">
            <div className="filter-modal-sub-header pb-1">Channels</div>
            <ChannelsCheckboxList
              filterByFacebook={filterByFacebook}
              filterBySlack={filterBySlack}
              filterBySMS={filterBySMS}
              filterByWebChat={filterByWebChat}
              setFilterByFacebook={() => setFilterByFacebook(s => !s)}
              setFilterBySMS={() => setFilterBySMS(s => !s)}
              setFilterByWebChat={() => setFilterByWebChat(s => !s)}
              setFilterBySlack={() => setFilterBySlack(s => !s)}
            />
          </div>
          <hr />
          <FormGroup className="px-4">
            <div className="filter-modal-sub-header pb-1">Date Received</div>
            <div className="d-flex align-items-center">
              <ReadOnlyDatePicker
                value={dateFilter.from}
                onDayChange={date => handleDayChange(date, 'from')}
                classNames={{
                  container: 'DayPickerInput-Container mr-1',
                  overlayWrapper: 'DayPickerInput-OverlayWrapper',
                  overlay: 'DayPickerInput-Overlay',
                }}
                dayPickerProps={{
                  disabledDays: setDisabledDays('from'),
                }}
              />
              <span className="mx-3">-</span>
              <ReadOnlyDatePicker
                value={dateFilter.to}
                onDayChange={date => handleDayChange(date, 'to')}
                dayPickerProps={{
                  disabledDays: setDisabledDays('to'),
                }}
                classNames={{
                  container: 'DayPickerInput-Container mr-1',
                  overlayWrapper: 'DayPickerInput-OverlayWrapper',
                  overlay: 'DayPickerInput-Overlay',
                }}
              />
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button
            className="text-mainstay-dark-blue-65 bg-white"
            disabled={clearFiltersDisabled}
            onClick={handleClearFilter}>
            Reset Filter
          </Button>
          <div>
            <Button
              className="btn btn-secondary-teal px-3 py-2"
              onClick={handleSubmit}>
              Apply Filter
            </Button>
            <Button
              className="ml-2 btn text-mainstay-dark-blue-65 border-color-mainstay-dark-blue-65 border-1px bg-white px-3 py-2"
              onClick={onToggle}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
