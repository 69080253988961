import _variablesScss from 'scss/_variables.scss'

export function ExpandMoreIcon({ rotate = 0 }: { readonly rotate?: number }) {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotate}deg)` }}
      className="cursor-pointer">
      <path
        d="M0.578002 1.406L1.984 -6.14582e-08L7.984 6L1.984 12L0.578003 10.594L5.172 6L0.578002 1.406Z"
        fill={_variablesScss.mainstayDarkBlue65}
      />
    </svg>
  )
}
