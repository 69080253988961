import { ReadOnlyDatePicker } from 'components/ReadOnlyDatePicker/ReadOnlyDatePicker'
import { TimePicker } from 'components/TimePicker/TimePicker'
import moment, { Moment } from 'moment-timezone'
import { CloseButtonIcon } from 'components/Icons/CloseButtonIcon/CloseButtonIcon'
import classNames from 'classnames'

export const InlineDateTimePicker = ({
  date,
  handleDayChange,
  handleTimeChange,
  variant,
  hideLabels = false,
  institutionTimezone,
}: {
  date: string | undefined
  handleDayChange: (e: Date) => void
  handleTimeChange: (e: Moment) => void
  hideLabels?: boolean
  variant?: 'sm'
  institutionTimezone: string
}) => {
  const timeFormat = 'h:mm a'

  const dateStringToMoment = () => {
    if (date) {
      return moment(date).tz(institutionTimezone)
    }
  }

  const toDate = (date: Moment | string) => {
    return moment(date)
      .tz(institutionTimezone)
      .format('MMMM DD, YYYY')
  }

  const dateStringToDate = () => {
    const date = dateStringToMoment()
    if (date) {
      return toDate(date)
    }
  }
  return (
    <>
      <div
        className={classNames('d-flex flex-column max-width-200 mx-2', {
          'w-80': variant === 'sm',
        })}>
        {!hideLabels && <p className="caption text-muted mb-1">Day</p>}
        <ReadOnlyDatePicker
          value={dateStringToDate()}
          onDayChange={handleDayChange}
          dayPickerProps={{
            disabledDays: {
              before: moment()
                .startOf('day')
                .toDate(),
            },
          }}
          classNames={{
            container: 'DayPickerInput-Container mr-1',
            overlayWrapper: 'DayPickerInput-OverlayWrapper',
            overlay: 'DayPickerInput-Overlay',
          }}
        />
      </div>
      <div
        className={classNames('d-flex flex-column max-width-200 mx-2', {
          'w-20': variant === 'sm',
        })}>
        {!hideLabels && <p className="caption text-muted mb-1">Time</p>}
        <TimePicker
          showSecond={false}
          value={dateStringToMoment()}
          onChange={handleTimeChange}
          clearIcon={
            <CloseButtonIcon className="w-5px fill-mainstay-dark-blue-80" />
          }
          format={timeFormat}
          placeholder="Select a time"
          // By default, RC Time Picker renders the picker outside of the root html. This means that if it's being rendered within a modal, clicking inside of the time picker to select a time will trigger the outside click handler (if one exists) and close the modal. To fix this, we need to set the getPopupContainer prop to a function that returns the parent node of the input field.
          // Passing `getPopupContainer` in this way renders the picker inside the parent node of the component.
          // https://stackoverflow.com/a/46030038/10544892
          getPopupContainer={triggerNode => triggerNode.parentNode}
          className="form-control"
          use12Hours
        />
      </div>
    </>
  )
}
