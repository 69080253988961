import { Dispatch } from 'store/store'
import * as api from 'api'
import { isLeft } from 'fp-ts/Either'
import axios, { CancelTokenSource } from 'axios'
import { fetchKnowledgeBaseReviewItems } from 'page/knowledge-base/KnowledgeReview/actions'
import { getKnowledgeBaseReviewItemsParams } from 'page/knowledge-base/KnowledgeReview/KnowledgeReview'

export const fetchKnowledgeReviewItems = (dispatch: Dispatch) => async (
  requestCancelSource: CancelTokenSource | null,
  reason?: string
) => {
  dispatch(fetchKnowledgeBaseReviewItems.request())
  const params = getKnowledgeBaseReviewItemsParams(reason)
  if (requestCancelSource) {
    requestCancelSource.cancel()
  }
  requestCancelSource = axios.CancelToken.source()
  const res = await api.knowledgeBaseReviewItems({
    params,
    cancelToken: requestCancelSource.token,
  })
  if (isLeft(res)) {
    if (res.left.kind !== 'cancel') {
      dispatch(fetchKnowledgeBaseReviewItems.failure())
    }
    return
  }
  dispatch(fetchKnowledgeBaseReviewItems.success(res.right))
}
