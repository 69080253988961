import * as React from 'react'
import classnames from 'classnames'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { WireframeMessage } from 'components/WireframeMessage/WireframeMessage'
import { FallbackTextMessage } from 'components/OutgoingNode/OutgoingNode'

interface INumberPromptProps {
  question: string
  options: string[]
  editable?: boolean
  personalizedPrompt?: string | null
  border?: boolean
}

export default class NumberPrompt extends React.PureComponent<
  INumberPromptProps,
  { hover: boolean }
> {
  constructor(props: INumberPromptProps) {
    super(props)
    this.state = { hover: false }
  }
  renderOptions() {
    return this.props.options.map((x, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <li key={i}>
        [{i + 1}]: {x}
      </li>
    ))
  }

  render() {
    return (
      <div
        className={classnames({
          'border rounded border-color-bluegrey-30': this.props.border,
        })}>
        <div
          className={classnames(
            'rounded bg-white `z-index-100` position-relative py-2 px-3 d-flex node',
            {
              'hover-shadow-md pointer hover-opacity-100-parent': this.props
                .editable,
            }
          )}>
          <div className="body w-100">
            <div className="py-1">
              <div className="d-block">
                <WireframeMessage
                  message={this.props.personalizedPrompt || this.props.question}
                  messageClassName="text-mainstay-dark-blue fs-14"
                />
                {this.props.personalizedPrompt && <FallbackTextMessage />}
              </div>
            </div>
          </div>
          {this.props.editable && (
            <AHIcon
              name="edit"
              className={classnames(
                'pt-2 text-blue-grey-501 hover-opacity-100-child opacity-50'
              )}
            />
          )}
        </div>
        <div className="bg-white w-100 rounded px-3 pt-2 pb-3 mt-negative node">
          <ul className="list-unstyled m-0 text-muted caption">
            {this.renderOptions()}
          </ul>
        </div>
      </div>
    )
  }
}
