import React from 'react'
import { ExpandMoreIcon } from 'components/Icons/ExpandMoreIcon/ExpandMoreIcon'
import cls from 'classnames'

export function ExpandButton({
  expanded,
  onClick,
  style,
  className,
  direction = 'vertical',
}: {
  readonly expanded: boolean
  readonly onClick: () => void
  readonly style?: React.CSSProperties
  readonly className?: string
  readonly direction?: 'horizontal' | 'vertical'
}) {
  const [openDegs, closedDegs] = direction === 'vertical' ? [90, -90] : [90, 0]
  return (
    <div
      onClick={onClick}
      style={style}
      className={cls('text-center d-inline-block pointer', className)}>
      {expanded ? (
        <ExpandMoreIcon rotate={openDegs} />
      ) : (
        <ExpandMoreIcon rotate={closedDegs} />
      )}
    </div>
  )
}
