import * as React from 'react'
import {
  Row,
  Col,
  Nav,
  NavItem,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  ButtonGroup,
  Table,
  Modal,
} from 'reactstrap'
import NavBarPage from 'page/NavBarPage'
import { Route, Switch } from 'react-router'
import { Formik, Form } from 'formik'
import { Button } from 'components/Button/Button'
import {
  listTemplateCollections,
  createTemplateCollection,
  deleteTemplateCollection,
  updateTemplateData,
} from 'api'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { Spinner } from 'components/Spinner/Spinner'
import * as yup from 'yup'
import { ScriptLibraryShow } from 'components/ScriptLibraryConditionalRenderer/ScriptLibraryConditionalRenderer'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import ModalHeader from 'reactstrap/lib/ModalHeader'
import ModalBody from 'reactstrap/lib/ModalBody'
import ModalFooter from 'reactstrap/lib/ModalFooter'
import { TextField } from '@material-ui/core'
import { isRight } from 'fp-ts/lib/Either'

interface IScriptCollection {
  id: number
  name: string
  description: string
  count: number
}

const ManageCollections = () => {
  const [collections, setCollections] = React.useState<IScriptCollection[]>([])
  const [loading, setLoading] = React.useState(false)
  const [tab, setTab] = React.useState<'list' | 'add'>('add')
  const [showDeleteModal, setShowDelete] = React.useState(false)
  const [selectedTemplate, setSelectedTemplate] = React.useState(-1)
  const [modifiedScriptIds, setModifiedScriptIds] = React.useState<number[]>([])
  React.useEffect(() => {
    setLoading(true)
    listTemplateCollections()
      .then(res => {
        setLoading(false)
        setCollections(res.data)
      })
      .catch(() => {
        toast('List collections failed.', { type: 'error' })
      })
  }, [])
  const toggleModal = () => setShowDelete(val => !val)
  const deleteCollection = () => {
    deleteTemplateCollection(selectedTemplate)
      .then(() => {
        setCollections(collections.filter(x => x.id !== selectedTemplate))
        setShowDelete(false)
      })
      .catch(() => {
        toast('Delete collection failed.', { type: 'error' })
      })
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { id, value },
    } = event
    const data = collections.find(x => x.id === parseInt(id, 10))
    if (data) {
      data.name = value
    }
    if (modifiedScriptIds.indexOf(parseInt(id, 10)) === -1) {
      setModifiedScriptIds([...modifiedScriptIds, parseInt(id, 10)])
    }
  }

  const handleDescChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { id, value },
    } = event
    const data = collections.find(x => x.id === parseInt(id, 10))
    if (data) {
      data.description = value
    }
    if (modifiedScriptIds.indexOf(parseInt(id, 10)) === -1) {
      setModifiedScriptIds([...modifiedScriptIds, parseInt(id, 10)])
    }
  }

  const saveEdit = () => {
    setLoading(true)
    modifiedScriptIds.forEach(id => {
      const data = collections.find(x => x.id.toString() === id.toString())
      if (data) {
        updateTemplateData(id, data).then(res => {
          if (isRight(res)) {
            toast(`${data.name} saved successfully`, { type: 'success' })
            setLoading(false)
          } else {
            toast(`Error encountered while saving ${data.name}`, {
              type: 'error',
            })
            data.name = ''
            setLoading(false)
          }
        })
      }
    })
    setModifiedScriptIds([])
  }

  return (
    <div className="p-3">
      <Nav tabs className="mb-3">
        <NavItem
          className="pointer text-primary pr-3 py-2 border-right"
          onClick={() => setTab('add')}>
          <span className={tab === 'add' ? 'font-weight-bold' : ''}>Add</span>
        </NavItem>
        <NavItem
          className="pointer text-primary px-3 py-2"
          onClick={() => setTab('list')}>
          <span className={tab === 'list' ? 'font-weight-bold' : ''}>List</span>
        </NavItem>
      </Nav>
      {tab === 'list' && (
        <>
          {loading ? (
            <>
              <Spinner className="stroke-bg-mainstay-dark-blue" /> loading
              collections..
            </>
          ) : (
            <Table className="table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>
                    Templates
                    <br />
                    Count
                  </th>
                  <th>
                    <Button
                      id="btnSave"
                      color="primary"
                      disabled={modifiedScriptIds.length === 0}
                      onClick={saveEdit}>
                      Save
                    </Button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {collections.map(x => (
                  <tr key={x.id}>
                    <td>
                      <TextField
                        error={x.name === ''}
                        helperText={x.name === '' ? 'Invalid name' : ''}
                        multiline
                        defaultValue={x.name}
                        id={x.id.toString()}
                        onChange={handleNameChange}
                      />
                    </td>
                    <td>
                      <TextField
                        multiline
                        defaultValue={x.description}
                        id={x.id.toString()}
                        onChange={handleDescChange}
                      />
                    </td>
                    <td>{x.count}</td>
                    <td>
                      <Button
                        color="danger"
                        onClick={() => {
                          setSelectedTemplate(x.id)
                          toggleModal()
                        }}>
                        <AHIcon name="delete" />
                      </Button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <th colSpan={3} />
                  <th>
                    <Button
                      id="btnSave"
                      color="primary"
                      disabled={modifiedScriptIds.length === 0}
                      onClick={saveEdit}>
                      Save
                    </Button>
                  </th>
                </tr>
              </tbody>
            </Table>
          )}
        </>
      )}
      {tab === 'add' && (
        <Formik<{ name: string; description: string }>
          validationSchema={yup.object().shape({
            name: yup
              .string()
              .trim()
              .required(),
            description: yup
              .string()
              .trim()
              .required(),
          })}
          initialValues={{
            name: '',
            description: '',
          }}
          onSubmit={values => {
            createTemplateCollection({ ...values })
              .then(res => {
                setCollections([...collections, { ...res.data, count: 0 }])
                toast(`Created new collection ${values.name}!`, {
                  type: 'success',
                })
                setTab('list')
              })
              .catch(() => {
                toast('Create collection failed.', { type: 'error' })
              })
          }}
          render={props => (
            <Form>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  value={props.values.name}
                  touched={props.touched.name}
                  invalid={props.touched.name && !!props.errors.name}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                {props.errors.name && (
                  <FormFeedback>{props.errors.name}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  id="description"
                  name="description"
                  type="textarea"
                  value={props.values.description}
                  touched={props.touched.description}
                  invalid={
                    props.touched.description && !!props.errors.description
                  }
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                {props.errors.name && (
                  <FormFeedback>{props.errors.description}</FormFeedback>
                )}
                <ButtonGroup className="mt-3">
                  <Button color="success" type="submit">
                    Add
                  </Button>
                </ButtonGroup>
              </FormGroup>
            </Form>
          )}
        />
      )}
      <Modal isOpen={showDeleteModal} toggle={toggleModal}>
        <ModalHeader>Confirm delete?</ModalHeader>
        <ModalBody>Are you sure you want to delete this collection?</ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button color="danger" onClick={deleteCollection}>
              Confirm
            </Button>
            <Button color="primary" onClick={toggleModal}>
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const ScriptLibraryAdmin = () => {
  return (
    <ScriptLibraryShow
      render={() => (
        <NavBarPage
          title="Script Collections Admin"
          scrollY
          className="container mt-4">
          <div className="col-lg-8 offset-lg-2">
            <h2 className="h4">Script Collections Admin</h2>
            <Row>
              <Col xs={12}>
                <Switch>
                  <Route
                    path="/internal/script-library/"
                    component={ManageCollections}
                  />
                </Switch>
              </Col>
            </Row>
          </div>
        </NavBarPage>
      )}
    />
  )
}

export default ScriptLibraryAdmin
