import * as api from 'api'
import React, { useState } from 'react'
import { FormGroup, Label, Input, ModalBody, Spinner } from 'reactstrap'
import { Button } from 'components/Button/Button'
import {
  KnowledgeReviewReason,
  KnowledgeReviewMark,
  ALL_REASONS,
  MARK_TITLES,
} from 'page/conversations-v2/ConversationsDetail/index'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'

import 'page/conversations-v2/ConversationsDetail/MessageRow/MarkForKnowledgeReviewModal/MarkForKnowledgeReviewModal.scss'
import pluralize from 'pluralize'
import { getContactFeedbackParams } from 'page/knowledge-base/ContactFeedback/ContactFeedback'
import axios, { CancelTokenSource } from 'axios'
import { isLeft } from 'fp-ts/lib/Either'
import { useDispatch } from 'util/hooks'
import { fetchContactFeedback } from 'page/knowledge-base/ContactFeedback/actions'
import { SimpleModalFooter } from 'components/SimpleModalFooter/SimpleModalFooter'
import { MainstayModal } from 'components/Modal/Modal'

export function MarkForKnowledgeReviewModal({
  loading,
  modalVisible,
  toggleModal,
  applyKnowledgeReviewMark,
  knowledgeReviewMark,
  countToDisplay,
  bulkEdit,
  item,
}: {
  readonly loading: boolean
  readonly modalVisible: boolean
  readonly toggleModal: () => void
  readonly applyKnowledgeReviewMark: (mark: KnowledgeReviewMark | null) => void
  readonly knowledgeReviewMark: KnowledgeReviewMark | null
  readonly countToDisplay?: number
  readonly bulkEdit?: boolean
  readonly item?: api.ContactFeedbackShapeType
}) {
  const dispatch = useDispatch()
  const [reviewReason, setReviewReason] = useState<KnowledgeReviewReason | ''>(
    knowledgeReviewMark?.reason ?? ''
  )
  const [reviewNote, setReviewNote] = useState<string>(
    knowledgeReviewMark?.note ?? item?.note ?? ''
  )
  const requestCancelSource = React.useRef<CancelTokenSource | null>(null)

  const markForKnowledgeReview = async () => {
    if (reviewReason === '') {
      toast.error('A knowledge maintenance reason must be selected.')
      return
    }

    applyKnowledgeReviewMark({
      reason: reviewReason,
      note: reviewNote || null,
    })

    const params = getContactFeedbackParams()
    if (requestCancelSource.current) {
      requestCancelSource.current.cancel()
    }
    requestCancelSource.current = axios.CancelToken.source()
    const res = await api.contactFeedback({
      params,
      cancelToken: requestCancelSource.current.token,
    })
    if (isLeft(res)) {
      if (res.left.kind !== 'cancel') {
        dispatch(fetchContactFeedback.failure())
      }
      return
    }
    dispatch(fetchContactFeedback.success(res.right))
  }

  const removeFromKnowledgeReview = () => {
    applyKnowledgeReviewMark(null)
  }

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    )
  }

  return (
    <MainstayModal
      hideFooter
      show={modalVisible}
      text={
        bulkEdit
          ? `Edit ${countToDisplay} ${pluralize('item', countToDisplay)}`
          : 'Add to Knowledge Maintenance'
      }
      onClose={toggleModal}
      contentClassName="p-0">
      <ModalBody>
        <FormGroup tag="fieldset">
          <div className="fs-16px mb-1">
            Why has this been marked for review?
          </div>
          {ALL_REASONS.map((reason: KnowledgeReviewReason) => (
            <FormGroup
              className="ml-3"
              key={`mark-for-knowledge-review-reason-${reason}`}
              check>
              <Label check>
                <Input
                  value={reason}
                  onChange={() => setReviewReason(reason)}
                  checked={reason === reviewReason}
                  type="radio"
                />

                {MARK_TITLES[reason]}
              </Label>
            </FormGroup>
          ))}
        </FormGroup>
        <FormGroup>
          <Label className="mark-for-knowledge-review-modal-notes">
            Notes about this item (Optional)
            <Input
              value={reviewNote}
              onChange={e => setReviewNote(e.target.value)}
              type="textarea"
            />
          </Label>
        </FormGroup>
      </ModalBody>
      <div className="d-flex justify-content-end">
        <SimpleModalFooter
          onSubmit={markForKnowledgeReview}
          onCancel={toggleModal}
          disableSubmit={loading}
          submitText={
            (loading && 'Loading...') ||
            (knowledgeReviewMark === null && !bulkEdit
              ? 'Add'
              : bulkEdit
              ? `Update ${countToDisplay} Items`
              : 'Update')
          }
          cancelText="Cancel"
        />
        {knowledgeReviewMark !== null && !bulkEdit && (
          <Button
            className="mark-for-knowledge-review-modal-rm-button"
            disabled={loading}
            onClick={removeFromKnowledgeReview}>
            Remove from Review
          </Button>
        )}
      </div>
    </MainstayModal>
  )
}
