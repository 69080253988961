import scssVariables from 'scss/_variables.scss'
interface IHelpIcon {
  readonly height?: number | string
  readonly width?: number | string
  readonly fill?: string
  readonly className?: string
}

export function HelpIcon({
  height = '15',
  width = '15',
  fill = scssVariables.mainstayDarkBlue65,
  className,
}: IHelpIcon) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5ZM8.75 13.25H7.25V11.75H8.75V13.25ZM10.3025 7.4375L9.6275 8.1275C9.0875 8.675 8.75 9.125 8.75 10.25H7.25V9.875C7.25 9.05 7.5875 8.3 8.1275 7.7525L9.0575 6.8075C9.335 6.5375 9.5 6.1625 9.5 5.75C9.5 4.925 8.825 4.25 8 4.25C7.175 4.25 6.5 4.925 6.5 5.75H5C5 4.0925 6.3425 2.75 8 2.75C9.6575 2.75 11 4.0925 11 5.75C11 6.41 10.73 7.01 10.3025 7.4375Z"
        fill={fill}
      />
    </svg>
  )
}
