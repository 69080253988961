import React, { useReducer } from 'react'
import { isLeft } from 'fp-ts/Either'
import classNames from 'classnames'

import * as api from 'api'
import * as ROUTES from 'const/routes'
import {
  WebData,
  Failure,
  Loading,
  Success,
  isLoading,
  isSuccess,
} from 'store/webdata'
import { Spinner } from 'components/Spinner/Spinner'
import {
  ProgressBar,
  ProgressBarItem,
} from 'components/ProgressBar/ProgressBar'
import { ThumbsUpIcon } from 'components/Icons/ThumbsUpIcon/ThumbsUpIcon'
import { ThumbsDownIcon } from 'components/Icons/ThumbsDownIcon/ThumbsDownIcon'
import { HelpIcon } from 'components/Icons/HelpIcon/HelpIcon'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { Link } from 'util/routing'

import { WebchatFeedbackStatistics } from 'components/TrendsV2/types'

import 'page/dashboard/KnowledgeInsights/ContactFeedback/RatingStatistic/RatingStatistic.scss'
import {
  filterContactsReducer,
  DEFAULT_FILTERS_INITIAL_STATE,
} from 'components/FilterContactsModal/filterContactsReducer'
import { DataPointFilters } from 'components/FilterContactsModal/FilterContactsModal'
import { getInsightsFilterFromFilterStates } from 'components/TrendsV2/sharedUtils'
import { ChartWidth } from 'components/InsightsChartMenu/InsightsChartMenu'

interface IWebchatFeedbackContentProps {
  data: WebchatFeedbackStatistics
  justGraph: boolean
  statsClassName?: string
  insightsChart?: boolean
  chartWidth?: ChartWidth
}

const Requested = ({
  data,
  insightsChart = false,
}: {
  data: WebchatFeedbackStatistics
  insightsChart?: boolean
}) => (
  <Statistic
    title="Feedback Requested"
    className={!insightsChart ? 'middle' : 'my-1 mr-5'}
    insightsChart={true}>
    {data.total_requested} times
  </Statistic>
)

const Responded = ({ data }: { data: WebchatFeedbackStatistics }) => (
  <Statistic
    title={
      <>
        Total Responses
        <Tooltip content="Feedback tends to come from people with strong opinions. A low response rate is normal, so use these reactions as an opportunity for improvement.">
          <span className="help pointer">
            <HelpIcon className="fill-mainstay-dark-blue-70 mb-1" />
          </span>
        </Tooltip>
      </>
    }>
    {data.total_responded}
  </Statistic>
)

export const WebchatFeedbackContent = ({
  data,
  justGraph,
  statsClassName = 'd-flex flex-row flex-wrap justify-content-between',
  insightsChart = false,
  chartWidth,
}: IWebchatFeedbackContentProps) => (
  <RatingStatisticContainer
    justGraph={justGraph}
    statsClassName={statsClassName}
    insightsChart={insightsChart}>
    <Statistic
      title="Ratings"
      insightsChart={insightsChart}
      className={classNames('flex-grow-1', {
        'mr-5': chartWidth === ChartWidth.WIDE,
      })}>
      <div
        className={classNames('d-flex flex-row align-items-end pr-4', {
          'my-1': insightsChart,
        })}>
        <ThumbsUpIcon className="fill-mainstay-dark-blue-65" />
        <div className="pl-2">{data.thumbs_up_count}</div>
      </div>

      <ProgressBar className="border-radius-md">
        <ProgressBarItem
          className="bg-mainstay-spark-red"
          value={data.thumbs_up_count}
          maxValue={data.total_responded}
        />

        <ProgressBarItem
          className="bg-mainstay-dark-blue"
          value={data.thumbs_down_count}
          maxValue={data.total_responded}
        />
      </ProgressBar>

      <div className="d-flex flex-row align-items-end pl-4">
        <div className="pr-2">{data.thumbs_down_count}</div>
        <ThumbsDownIcon className="fill-mainstay-dark-blue-70" />
      </div>
    </Statistic>

    <div
      className={classNames('d-flex align-items-center', {
        'my-2': insightsChart,
      })}>
      <Requested data={data} insightsChart={insightsChart} />
      <Responded data={data} />
    </div>
  </RatingStatisticContainer>
)

function RatingStatisticContainer({
  justGraph,
  children,
  statsClassName,
  insightsChart = false,
}: {
  readonly justGraph?: boolean
  readonly children: React.ReactNode
  readonly statsClassName: string
  readonly insightsChart?: boolean
}) {
  return (
    <div
      className={classNames(
        'd-flex flex-column text-mainstay-dark-blue py-3 contact-feedback-rating-statistic',
        { card: !insightsChart, 'px-2': insightsChart, 'px-4': !insightsChart }
      )}>
      <div className={statsClassName}>{children}</div>
      {!justGraph && (
        <Link
          className="pt-2 text-secondary-teal"
          to={ROUTES.CONVERSATION_ROUTES.CONTACT_FEEDBACK}>
          View All Webchat Feedback
        </Link>
      )}
    </div>
  )
}

function Statistic({
  className,
  title,
  children,
  insightsChart = false,
}: {
  readonly className?: string
  readonly title: string | React.ReactNode
  readonly children: React.ReactNode
  readonly insightsChart?: boolean
}) {
  return (
    <div
      className={classNames('d-flex flex-column', className, {
        'statistic-item': !insightsChart,
      })}>
      <div className="fw-600 pb-1">{title}</div>

      <div className="d-flex flex-row align-items-center">{children}</div>
    </div>
  )
}

/**
 * Shows an overview of contact feedback data. Including the thumbs up vs thumbs down, and the number of feedback requests and responses.
 * Additionally a link to a more detailed contact feedback page is included.
 * @param justGraph - If true the link to the contact feedback page will not be included.
 */
export default function RatingStatistic({
  justGraph,
}: {
  readonly justGraph?: boolean
}) {
  const [statistics, setStatistics] = React.useState<
    WebData<WebchatFeedbackStatistics>
  >(Loading())
  const [state, dispatch] = useReducer(
    filterContactsReducer,
    DEFAULT_FILTERS_INITIAL_STATE
  )

  const fetchData = React.useCallback(async () => {
    const insightFilters = getInsightsFilterFromFilterStates(state)
    // Make request
    const resp = await api.contactFeedbackStatistics(insightFilters)

    if (isLeft(resp)) {
      setStatistics(Failure('Unable to load data'))
      return
    }

    // Set data
    setStatistics(Success(resp.right))
  }, [state])

  React.useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isLoading(statistics)) {
    return (
      <RatingStatisticContainer
        justGraph={justGraph}
        statsClassName="d-flex flex-row flex-wrap justify-content-between">
        <div className="width-100 d-flex justify-content-center">
          <Spinner className="stroke-mainstay-dark-blue" />
        </div>
      </RatingStatisticContainer>
    )
  }

  if (!isSuccess(statistics)) {
    return (
      <RatingStatisticContainer
        justGraph={justGraph}
        statsClassName="d-flex flex-row flex-wrap justify-content-between">
        Unable to load data, please try again later.
      </RatingStatisticContainer>
    )
  }

  const { data } = statistics
  return (
    <>
      {!justGraph && (
        <DataPointFilters
          initialFilterValues={state}
          dispatch={dispatch}
          className="pt-4 pb-3"
        />
      )}

      <WebchatFeedbackContent data={data} justGraph={!!justGraph} />
    </>
  )
}
