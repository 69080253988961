import * as React from 'react'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { PopoverComponent } from 'components/PopoverComponent/PopoverComponent'
import { Button } from 'components/Button/Button'
import { CloseButtonIcon } from 'components/Icons/CloseButtonIcon/CloseButtonIcon'
import { EventTrackerAttrAdder } from 'components/EventTracker/EventTracker'

export interface IPanelDrawerHeaderProps {
  onClose: () => void
  onDelete?: () => void
  title: string
  icon: React.ReactNode
  eventLocation?: string
  closeEventObject?: string
  deleteEventObject?: string
}
export const PanelDrawerHeader = ({
  onClose,
  onDelete,
  title,
  icon,
  eventLocation,
  closeEventObject,
  deleteEventObject,
}: IPanelDrawerHeaderProps) => {
  const [moreOptionsVisible, setMoreOptionsVisible] = React.useState(false)
  return (
    <div className="d-flex justify-content-between py-3 flex-shrink-0 text-mainstay-dark-blue-80">
      <div className="d-flex align-items-center">
        {icon}
        <h5 className="m-0">{title}</h5>
      </div>
      <div className="d-flex mr-3 justify-content-between">
        {onDelete && (
          <div className="mt-1 mr-1">
            <PopoverComponent
              popoverPlacement="bottom-end"
              onClickOutside={() => {
                setMoreOptionsVisible(false)
              }}
              visible={moreOptionsVisible}
              renderReference={() => (
                <AHIcon
                  eventLocation={eventLocation}
                  eventAction="click"
                  eventObject="overflow menu"
                  className="pointer pr-2"
                  name="more_horiz"
                  onClick={() => setMoreOptionsVisible(!moreOptionsVisible)}
                />
              )}
              renderPopper={() => (
                <Button
                  eventLocation={eventLocation}
                  eventAction="click"
                  eventObject={deleteEventObject}
                  onClick={() => {
                    setMoreOptionsVisible(false)
                    onDelete()
                  }}
                  className="d-flex align-items-center list-group-item text-mainstay-dark-blue-80 btn bg-white pointer pl-1">
                  <AHIcon className="d-inline-block fs-2rem" name="delete" />{' '}
                  Delete
                </Button>
              )}
            />
          </div>
        )}
        <EventTrackerAttrAdder
          eventLocation={eventLocation}
          eventAction="click"
          eventObject={closeEventObject}>
          <div onClick={onClose}>
            <CloseButtonIcon className="fill-mainstay-dark-blue-80 pointer" />
          </div>
        </EventTrackerAttrAdder>
      </div>
    </div>
  )
}
