import * as React from 'react'
import classNames from 'classnames'
import {
  EventAction,
  EventTrackerAttrAdder,
} from 'components/EventTracker/EventTracker'

interface ISelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  error?: boolean
  errorText?: string | null
  eventLocation?: string
  eventObject?: string
  eventAction?: EventAction
}

export function Select({
  errorText,
  error,
  className,
  disabled,
  eventAction,
  eventObject,
  eventLocation,
  ...props
}: ISelectProps) {
  return (
    <>
      <EventTrackerAttrAdder
        eventAction={eventAction}
        eventObject={eventObject}
        eventLocation={eventLocation}>
        <select
          {...props}
          disabled={disabled}
          className={classNames(
            'form-control',
            'custom-select',
            'text-mainstay-dark-blue-80',
            className,
            {
              'is-invalid': error,
            }
          )}
        />
      </EventTrackerAttrAdder>
      {error && errorText ? (
        <div className="invalid-feedback">{errorText}</div>
      ) : null}
    </>
  )
}
