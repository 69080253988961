import * as React from 'react'
import ClassNames from 'classnames'

export interface ICardProps {
  title?: string | React.ReactNode
  className?: string
  paddingClassName?: string
  onClick?: ((e: React.MouseEvent) => void) | (() => void)
  shadowSize?: 'sm' | 'md' | 'lg'
}

export class Card extends React.PureComponent<ICardProps> {
  static defaultProps = {
    shadowSize: 'sm',
    paddingClassName: 'p-3',
  }
  render() {
    const {
      title,
      className,
      onClick,
      shadowSize,
      children,
      paddingClassName,
    } = this.props
    const classNames = ClassNames(
      'w-100 card-container card border border rounded',
      `hover-shadow-${shadowSize}`,
      className,
      paddingClassName
    )
    return (
      <div className={classNames} onClick={onClick}>
        <div className="title-content">{title}</div>
        <div className="card-content">{children}</div>
      </div>
    )
  }
}
