import scssVariables from 'scss/_variables.scss'

export function UnderstandingQuestionSummaryIcon(props: {
  className?: string
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9851 3.98462C21.9851 2.90662 21.0941 2.01562 20.0161 2.01562H3.98511C2.90711 2.01562 2.01611 2.90662 2.01611 3.98462V21.9846L6.00011 18.0006H20.0161C21.0941 18.0006 21.9851 17.0626 21.9851 15.9846V3.98462ZM20.0001 3.99963H14.0001V12.9996L17.0001 10.4282L20.0001 12.9996V3.99963Z"
        fill={scssVariables.mainstayDarkBlue65}
      />
    </svg>
  )
}
