import * as React from 'react'
import { VerticalNode } from 'components/NodeSubtree/NodeSubtree'
import MediaNode from 'components/MediaNode/MediaNode'
import { EditableOutgoingNode } from 'components/EditableOutgoingNode/EditableOutgoingNode'
import { OutgoingNode } from 'components/OutgoingNode/OutgoingNode'
import {
  ICampaignScriptStateUpdateRequest,
  ICampaignScriptStateSpliceRequest,
  ITransitionType,
} from 'api/request'
import {
  PromptType,
  ICampaignScriptStep,
  IExitAction,
  ICampaignScriptDragState,
} from 'store/campaign-scripts/reducer'
import { getPromptTypeDefaults } from 'util/campaign-scripts'
import PauseTimeMenu from 'components/PauseTimeMenu/PauseTimeMenu'
import {
  DEFAULT_ADDITIONAL_PAUSE_TIME,
  DEFAULT_DIALOG_STATE_MESSAGE,
} from 'const/settings'
import LinkingIndicator from 'components/LinkingIndicator/LinkingIndicatorConnected'
import SelectNodeRadio from 'components/SelectNodeRadio/SelectNodeRadio'
import { isEmpty } from 'lodash'

export interface IBranchProps {
  workflowStep: ICampaignScriptStep
  editable?: boolean
  editing?: boolean
  branch?: React.ReactNodeArray | React.ReactNode
  isTerminal?: boolean
  updateNode?: (request: ICampaignScriptStateUpdateRequest) => Promise<void>
  spliceNode?: (
    request: ICampaignScriptStateSpliceRequest
  ) => Promise<void> | void
  linking?: boolean
  editNode?: ({
    dialogStateId,
    editing,
  }: {
    dialogStateId: string
    editing: boolean
  }) => void
  createNewExitAction?: (action: IExitAction) => void
  optOut?: () => void
  deleteEdge?: (doSplice?: boolean) => void
  changeImage?: (value: string, name?: string, contentType?: string) => void
  startLinking?: (tk: ITransitionType) => void
  stopLinking?: () => void
  createLink?: () => void
  setValueBeingSaved?: (value: string | null) => void
  valueBeingSaved?: string | null
  border?: boolean
  handlePromptChange?: () => void
  campaignScriptDragState?: (value: ICampaignScriptDragState) => void
  isExcessive?: boolean
}

export default class AutoBranch extends React.PureComponent<IBranchProps> {
  state = {
    showPauseTimeMenu: false,
  }

  handleAdd = (type: PromptType) => {
    if (this.props.spliceNode) {
      this.props.spliceNode({
        dialogId: this.props.workflowStep.parentDialog,
        dialogStateId: this.props.workflowStep.id,
        data: {
          ...getPromptTypeDefaults(type),
          prompt: DEFAULT_DIALOG_STATE_MESSAGE,
          promptType: type,
          transitionType: 'default',
        },
      })
    }
  }

  handleSetPauseTime = (value: number) => {
    this.setState({
      showPauseTimeMenu: false,
    })
    if (this.props.updateNode) {
      this.props.updateNode({
        dialogId: this.props.workflowStep.parentDialog,
        dialogStateId: this.props.workflowStep.id,
        data: {
          promptType: this.props.workflowStep.promptType,
          pauseTime: value,
        },
      })
    }
  }

  showPauseTimeMenu = () => {
    this.setState({ showPauseTimeMenu: true })
  }

  clearPauseTime = () => {
    this.handleSetPauseTime(DEFAULT_ADDITIONAL_PAUSE_TIME)
  }

  handleAddLink = () => {
    if (this.props.startLinking) {
      this.props.startLinking('default')
    }
  }

  handleRemoveMedia = () => {
    this.props.changeImage?.('', '', '')
  }

  handleEditNode = (editing: boolean) => {
    this.props.editNode?.({
      dialogStateId: this.props.workflowStep.id,
      editing,
    })
  }

  render() {
    const {
      workflowStep,
      editing,
      isTerminal,
      editable,
      children,
      branch,
    } = this.props
    /*
     * If the workflowStep has a vCard, then we will not render the media node which is meant only for images.
     * If a vCard is present on the node, the vCard preview component will be rendered from the NodeChildren component instead.
     */
    const isNotVcardMedia = !workflowStep.vCard || isEmpty(workflowStep.vCard)
    return (
      <>
        <a id={workflowStep.id} />
        <VerticalNode>
          <div className="d-flex flex-row">
            {this.props.linking && this.props.createLink && (
              <SelectNodeRadio onClick={this.props.createLink} />
            )}
            <EditableOutgoingNode
              node={this.props.workflowStep}
              editing={editing}
              onEditNode={this.handleEditNode}
              onChange={this.props.updateNode}
              isTerminal={!!isTerminal}
              onDelete={this.props.deleteEdge}
              onSetPauseTime={this.showPauseTimeMenu}
              onClearPauseTime={this.clearPauseTime}
              onChangeImage={this.props.changeImage}
              onOptOut={this.props.optOut}
              editable={editable}
              onClickAddLink={this.handleAddLink}
              onClickAddChild={this.handleAdd}
              onRemoveMedia={this.handleRemoveMedia}
              menuType={isTerminal ? 'full' : 'limited'}
              value={workflowStep.prompt}
              personalizedPrompt={workflowStep.personalizedPrompt}
              setValueBeingSaved={this.props.setValueBeingSaved}
              isExcessive={this.props.isExcessive}>
              <OutgoingNode
                personalizedPrompt={workflowStep.personalizedPrompt}
                border={this.props.border}
                editable={editable}>
                {this.props.valueBeingSaved || workflowStep.prompt}
              </OutgoingNode>
            </EditableOutgoingNode>
          </div>
        </VerticalNode>
        {/* with new design we will be removing this, and we will pass imagePreview = true to EditableOutgoingNode */}
        {workflowStep.media && isNotVcardMedia && (
          <VerticalNode>
            <MediaNode
              url={workflowStep.media}
              onRemove={this.handleRemoveMedia}
            />
          </VerticalNode>
        )}
        {this.state.showPauseTimeMenu && (
          <VerticalNode>
            <div className="node">
              <PauseTimeMenu onSubmit={this.handleSetPauseTime} />
            </div>
          </VerticalNode>
        )}
        {children}
        {this.props.linking && (
          <LinkingIndicator stepId={workflowStep.id} transition="default" />
        )}
        {branch}
      </>
    )
  }
}
