import { useEffect, useState, useCallback } from 'react'
import {
  SettingsPageContainer,
  LoadingSettings,
  FailureLoadingSettings,
} from 'page/SettingsGeneral'
import { IntegrationPanel } from 'components/IntegrationPanel/IntegrationPanel'
import { Button } from 'components/Button/Button'
import {
  isFailure,
  isSuccess,
  WebData,
  Success,
  Failure,
  Loading,
  isUnresolved,
} from 'store/webdata'
import * as api from 'api'
import { AxiosResponse, AxiosError } from 'typings/axios'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import {
  ITraySolutionResponseData,
  ITraySolutionInstanceResponseData,
  ICreateSolutionInstanceResponseData,
  IUpdateSolutionInstanceResponseData,
} from 'api/response'
import {
  ICreateSolutionInstanceRequestData,
  IToggleSolutionInstanceEnabledRequestData,
} from 'api/request'
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch'
import { getIcon, solutionTitleMapping } from 'page/SettingsBrowseIntegrations'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { RefetchingOverlay } from 'components/RefetchingOverlay/RefetchingOverlay'
import { Link } from 'util/routing'
import { openDownloadURL } from 'util/links'
import { SFTPConfiguration } from 'components/SFTPSelfService/SFTPModals'
import { MainstayModal, ModalSize } from 'components/Modal/Modal'

interface ITrayEmbedEventData {
  type: string
  action: string
  value: string
}

interface ITrayEmbedEvent {
  data: ITrayEmbedEventData
}

interface ITraySolutionConfiguredProps {
  id: string
  title: string
  description: string
  enabled: boolean
  created: string
  onToggle: ({
    name,
    solutionInstanceId,
    enabled,
  }: IToggleSolutionInstanceEnabledRequestData) => void
  onClickEdit: (solutionInstanceId: string) => void
  onClickDelete: (solutionInstanceId: string) => void
}

export const TraySolutionConfigured = ({
  id,
  title,
  description,
  enabled,
  created,
  onToggle,
  onClickEdit,
  onClickDelete,
}: ITraySolutionConfiguredProps) => (
  <div className="d-flex border rounded px-4 py-3 mx-2 my-3 border-blue-grey-100 justify-content-between align-items-center">
    <div className="d-flex align-items-center">
      <ToggleSwitch
        className="mr-4 pointer"
        checked={enabled}
        onChange={() =>
          onToggle({ name: title, solutionInstanceId: id, enabled })
        }
      />
      <div className="d-flex flex-column">
        {
          // NOTE: It is possible to create the solution instance and not complete configuration via the Config Wizard
          // In this case, a user will not have saved a custom Solution Instance name/description, so we will show these defaults
        }
        <b>
          {title ||
            `Solution Instance created on ${new Date(
              created
            ).toLocaleDateString()}`}
        </b>
        <span>{description}</span>
      </div>
    </div>
    <div className="mr-1">
      <AHIcon className="pointer" name="edit" onClick={() => onClickEdit(id)} />
      <AHIcon
        className="ml-4 pointer"
        name="delete"
        onClick={() => onClickDelete(id)}
      />
    </div>
  </div>
)

interface ITraySolutionProps {
  id: string
  title: string
  description: string
  onClick: ({
    name,
    solutionId,
    isSFTP,
  }: ICreateSolutionInstanceRequestData) => void
  onClickDownload: () => void
  documentationUrl: string | undefined
  isSFTP: boolean
  needCSVDownload: boolean
}

const TraySolution = ({
  id,
  title,
  description,
  onClick,
  onClickDownload,
  documentationUrl,
  isSFTP,
  needCSVDownload,
}: ITraySolutionProps) => {
  return (
    <div className="d-flex align-items-center justify-content-between border rounded px-4 py-3 mx-2 my-3 border-blue-grey-050 bg-blue-grey-050">
      <div className="d-flex flex-column">
        <b>{title}</b>
        <span className="text-muted">{description}</span>
        {documentationUrl && (
          <Link
            rawLink={true}
            target="blank"
            className="pointer text-decoration-none"
            to={documentationUrl}>
            See Documentation
          </Link>
        )}
      </div>
      <div>
        {needCSVDownload ? (
          <Button className="mr-2" color="primary" onClick={onClickDownload}>
            Download CSV Template
          </Button>
        ) : isSFTP ? (
          <SFTPConfiguration />
        ) : (
          <></>
        )}
        <Button
          color="primary"
          // NOTE: we hard-code the name here, as `instanceName` is required in the Tray `createSolutionInstance` endpoint
          // The name that we surface on the frontend is entered by the user post-configuration via the Config Wizard embed
          // The name below is just for identification/troubleshooting purposes on the Tray side
          onClick={() =>
            onClick({
              name: `Solution Instance Created on ${new Date().toLocaleDateString()} at ${new Date().toLocaleTimeString()}`,
              solutionId: id,
              isSFTP,
            })
          }>
          Add New
        </Button>
      </div>
    </div>
  )
}
interface ITraySolutionSettingsProps {
  title: string
}

export const SettingsTraySolution = ({ title }: ITraySolutionSettingsProps) => {
  const [solutionData, setSolutionData] = useState<
    WebData<Array<ITraySolutionResponseData>>
  >()
  const [platformSolutionIds, setPlatformSolutionIds] = useState<string[]>([])
  const [solutionInstanceData, setSolutionInstanceData] = useState<
    WebData<Array<ITraySolutionInstanceResponseData>>
  >()

  const [modalOpen, setModalOpen] = useState(false)
  const [embedUrl, setEmbedUrl] = useState('')
  const [requestPending, setRequestPending] = useState(false)

  const fetchData = useCallback(async () => {
    setRequestPending(true)
    setSolutionData(Loading())
    setSolutionInstanceData(Loading())
    try {
      const solutionsResponse = await api.fetchTraySolutions()
      setSolutionData(
        Success(
          solutionsResponse.data.filter(elem => elem.tags.includes(title))
        )
      )

      const solutionIds: string[] = solutionsResponse.data
        .filter(elem => elem.tags.includes(title))
        .map(elem => elem.id)
      setPlatformSolutionIds(solutionIds)

      const solutionInstancesResponse = await api.fetchTraySolutionInstances(
        solutionIds
      )

      setSolutionInstanceData(Success(solutionInstancesResponse.data))
      setRequestPending(false)
    } catch {
      setSolutionData(Failure(undefined))
      setSolutionInstanceData(Failure(undefined))
      setRequestPending(false)
      toast('There was an error fetching integration data', { type: 'error' })
    }
  }, [title])

  // on mount, fetch all existing Tray Solutions and SolutionInstances for correct platform
  useEffect(() => {
    fetchData()
  }, [fetchData])

  // on mount, add event listener to window to enable embed behavior
  useEffect(() => {
    const handleIFrameEvents = (e: ITrayEmbedEvent) => {
      const data: ITrayEmbedEventData = e.data
      if (data.type === 'tray.configPopup.error') {
        setModalOpen(false)
      }
      if (data.type === 'tray.configPopup.cancel') {
        setModalOpen(false)
      }
      if (data.type === 'tray.configPopup.finish') {
        setModalOpen(false)
        // when the user has completed configuration, re-fetch the data
        // allows us to show the user-entered (as oppposed to default) Solution Instance details in the list
        fetchData()
      }
      if (data.type === 'tray.configPopup.ready') {
        setModalOpen(true)
      }
    }
    window.addEventListener('message', handleIFrameEvents)
    return () => window.removeEventListener('message', handleIFrameEvents)
  }, [fetchData])

  if (isUnresolved(solutionData) || isUnresolved(solutionInstanceData)) {
    return <LoadingSettings />
  }

  if (isFailure(solutionData) || isFailure(solutionInstanceData)) {
    return <FailureLoadingSettings />
  }

  const handleAddNew = ({
    name,
    solutionId,
    isSFTP,
  }: ICreateSolutionInstanceRequestData) => {
    setRequestPending(true)
    api
      .createSolutionInstance({ name, solutionId, isSFTP })
      .then((res: AxiosResponse<ICreateSolutionInstanceResponseData>) => {
        setEmbedUrl(res.data.embedUrl)
        setModalOpen(true)
      })
      .then(() => fetchData())
      .catch((_err: AxiosError) => {
        setModalOpen(false)
        setRequestPending(false)
        toast('There was an error configuring integration', { type: 'error' })
      })
  }

  const handleToggleEnabled = ({
    name,
    solutionInstanceId,
    enabled,
  }: Pick<
    IToggleSolutionInstanceEnabledRequestData,
    'name' | 'solutionInstanceId' | 'enabled'
  >) => {
    setRequestPending(true)
    api
      .setSolutionInstanceEnabled({
        name,
        solutionInstanceId,
        enabled: !enabled,
        solutionIds: platformSolutionIds,
      })
      .then(() => fetchData())
      .catch((_err: AxiosError) => {
        setRequestPending(false)
        toast('There was an error updating integration', { type: 'error' })
      })
  }

  const handleEdit = (solutionInstanceId: string) => {
    setRequestPending(true)
    api
      .updateSolutionInstance(solutionInstanceId)
      .then((res: AxiosResponse<IUpdateSolutionInstanceResponseData>) => {
        setEmbedUrl(res.data.embedUrl)
        setModalOpen(true)
      })
      .then(() => fetchData())
      .catch((_err: AxiosError) => {
        setModalOpen(false)
        setRequestPending(false)
        toast('There was an error updating integration', { type: 'error' })
      })
  }

  const handleDelete = (solutionInstanceId: string) => {
    setRequestPending(true)
    api
      .deleteSolutionInstance(solutionInstanceId, platformSolutionIds)
      .then(() => fetchData())
      .catch((_err: AxiosError) => {
        setRequestPending(false)
        toast('There was an error deleting integration', { type: 'error' })
      })
  }

  const handleClickDownload = () => {
    setRequestPending(true)
    api
      .downloadSlateCSVTemplate()
      .then(res => {
        const fileName = 'admithub_csv_template.csv'
        const url = window.URL.createObjectURL(
          /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
          new Blob(([res.data] as unknown) as BlobPart[])
        )
        openDownloadURL({ url, fileName })
        setRequestPending(false)
        toast('CSV template successfully downloaded', { type: 'success' })
      })
      .catch((_err: AxiosError) => {
        setRequestPending(false)
        toast('There was an error downloading CSV template', { type: 'error' })
      })
  }

  const integrationName = solutionTitleMapping[title]?.name ?? ''

  return (
    <SettingsPageContainer>
      <>
        <IntegrationPanel
          title={
            <div className="w-100">
              <div className="d-flex flex-column">
                <Link
                  to="/settings/browse-integrations/"
                  className="text-decoration-none">
                  <div className="d-flex align-items-center font-weight-normal text-primary h6 pt-2">
                    <AHIcon name="arrow_back" className="mr-1" />
                    <span>Browse Third Party Integrations</span>
                  </div>
                </Link>
                <hr className="w-100 ml-2" />
                <div className="d-flex align-items-center ml-2 mt-2">
                  <div className="border p-3 mr-4">
                    {getIcon(title, 64, 64)}
                  </div>
                  <span>{integrationName}</span>
                </div>
              </div>
            </div>
          }
          className="d-flex flex-column h-100 pt-3">
          <div>
            <hr className="ml-2" />
            <b className="ml-2">Available Integrations</b>
            <div>
              {solutionData.data.length ? (
                solutionData.data.map(elem => {
                  const documentationUrl = elem.customFields.find(
                    field => field['key'] === 'documentation_url'
                  )?.['value']
                  return (
                    <TraySolution
                      key={elem.id}
                      id={elem.id}
                      title={elem.title}
                      description={elem.description}
                      documentationUrl={documentationUrl}
                      isSFTP={elem.isSFTP}
                      needCSVDownload={elem.needCSVDownload}
                      onClick={handleAddNew}
                      onClickDownload={handleClickDownload}
                    />
                  )
                })
              ) : (
                <div className="d-flex justify-content-center align-items-center h-75 mt-5">
                  <div className="d-flex flex-column align-items-center">
                    <div className="h5 font-weight-normal">{`No available ${solutionTitleMapping[title]?.name} integrations`}</div>
                    <div>Integrations coming soon...</div>
                  </div>
                </div>
              )}
            </div>
            <hr className="mt-5 ml-2" />
            <b className="ml-2">Configured</b>
            <RefetchingOverlay enabled={requestPending}>
              {isSuccess(solutionInstanceData) &&
              !solutionInstanceData.data.length ? (
                <div className="d-flex justify-content-center align-items-center h-75 mt-5">
                  <div className="d-flex flex-column align-items-center">
                    <div className="h5 font-weight-normal">{`No configured ${solutionTitleMapping[title]?.name} integrations`}</div>
                    <div>Create new integration from above options</div>
                  </div>
                </div>
              ) : (
                isSuccess(solutionInstanceData) &&
                solutionInstanceData.data.map(elem => (
                  <TraySolutionConfigured
                    key={elem.id}
                    id={elem.id}
                    title={elem.name}
                    description={elem.description || 'None'}
                    enabled={elem.enabled}
                    created={elem.created}
                    onToggle={handleToggleEnabled}
                    onClickEdit={handleEdit}
                    onClickDelete={handleDelete}
                  />
                ))
              )}
            </RefetchingOverlay>
          </div>
        </IntegrationPanel>
        <MainstayModal
          hideFooter
          className="shadow"
          show={modalOpen}
          text="Configuration Wizard"
          size={ModalSize.Large}
          onClose={() => {
            setModalOpen(false)
          }}>
          <div style={{ height: 600 }}>
            <iframe className="w-100 h-100" src={embedUrl} frameBorder="0" />
          </div>
        </MainstayModal>
      </>
    </SettingsPageContainer>
  )
}

export default SettingsTraySolution
