import { ButtonIconRound } from 'components/ButtonIconRound/ButtonIconRound'
import { PERMISSIONS } from 'util/permissions/permissions'
import PermissionsGuard from 'util/permissions/PermissionsGuard'
import classNames from 'classnames'

interface IOffset {
  left?: string
  right?: string
  top?: string
  bottom?: string
}

export interface IDeleteButtonProps {
  tooltipText?: string | undefined
  disabled: boolean
}
interface IFloatingTrashCanProps {
  onClick: () => void
  readonly deleteButtonProps?: IDeleteButtonProps
  offset?: IOffset
  isAbsolute?: boolean
}

export const FloatingTrashCan = ({
  onClick,
  offset,
  isAbsolute = true,
  deleteButtonProps,
}: IFloatingTrashCanProps) => {
  return (
    <PermissionsGuard permission={PERMISSIONS.UNDERSTANDING.DELETE}>
      <div
        className={classNames('show-on-hover z-index-10', {
          'position-absolute': isAbsolute,
        })}
        style={{ right: offset?.right ?? '8px', top: offset?.top ?? '8px' }}>
        <ButtonIconRound
          className="text-muted hover-text-new-ui-danger"
          onClick={onClick}
          icon="delete"
          tooltipText={deleteButtonProps?.tooltipText ?? 'Delete Response'}
          tooltipPlacement="top"
          disabled={!!deleteButtonProps?.disabled}
        />
      </div>
    </PermissionsGuard>
  )
}
