import scssVariables from 'scss/_variables.scss'

interface ISFTPIconProps {
  readonly height?: number | string
  readonly width?: number | string
}
export function SFTPIcon({ height = '32px', width = '100%' }: ISFTPIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 492 394"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="492" height="394" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M466.25 286H459.375V272.286C459.375 253.36 443.975 238 425 238C406.025 238 390.625 253.36 390.625 272.286V286H383.75C376.188 286 370 292.171 370 299.714V368.286C370 375.829 376.188 382 383.75 382H466.25C473.812 382 480 375.829 480 368.286V299.714C480 292.171 473.812 286 466.25 286ZM425 347.714C417.438 347.714 411.25 341.543 411.25 334C411.25 326.457 417.438 320.286 425 320.286C432.562 320.286 438.75 326.457 438.75 334C438.75 341.543 432.562 347.714 425 347.714ZM446.312 286H403.687V272.286C403.687 260.56 413.244 251.029 425 251.029C436.756 251.029 446.312 260.56 446.312 272.286V286Z"
        fill={scssVariables.secondaryTeal}
      />
      <path
        d="M437.148 69.9649C428.58 60.9941 418.469 56.5088 406.817 56.5088H231L186.789 12H55.1831C43.5305 12 33.4202 16.4854 24.8521 25.4561C16.284 34.4269 12 44.7778 12 56.5088V321.491C12 333.222 16.284 343.573 24.8521 352.544C33.4202 361.515 43.5305 366 55.1831 366H358V299.714C358 287.273 366.897 276.893 378.625 274.513V272.286C378.625 246.703 399.427 226 425 226C434.199 226 442.782 228.679 450 233.301V101.018C450 89.2865 445.716 78.9357 437.148 69.9649Z"
        fill="#D6E0E6"
      />
      <path
        d="M84.6895 206.666C84.6895 204.561 83.9375 202.92 82.4336 201.744C80.957 200.568 78.3457 199.352 74.5996 198.094C70.8535 196.836 67.791 195.619 65.4121 194.443C57.6738 190.643 53.8047 185.42 53.8047 178.775C53.8047 175.467 54.7617 172.555 56.6758 170.039C58.6172 167.496 61.3516 165.527 64.8789 164.133C68.4062 162.711 72.3711 162 76.7734 162C81.0664 162 84.9082 162.766 88.2988 164.297C91.7168 165.828 94.3691 168.016 96.2559 170.859C98.1426 173.676 99.0859 176.902 99.0859 180.539H84.7305C84.7305 178.105 83.9785 176.219 82.4746 174.879C80.998 173.539 78.9883 172.869 76.4453 172.869C73.875 172.869 71.8379 173.443 70.334 174.592C68.8574 175.713 68.1191 177.148 68.1191 178.898C68.1191 180.43 68.9395 181.824 70.5801 183.082C72.2207 184.312 75.1055 185.598 79.2344 186.938C83.3633 188.25 86.7539 189.672 89.4062 191.203C95.8594 194.922 99.0859 200.049 99.0859 206.584C99.0859 211.807 97.1172 215.908 93.1797 218.889C89.2422 221.869 83.8418 223.359 76.9785 223.359C72.1387 223.359 67.75 222.498 63.8125 220.775C59.9023 219.025 56.9492 216.646 54.9531 213.639C52.9844 210.604 52 207.117 52 203.18H66.4375C66.4375 206.379 67.2578 208.744 68.8984 210.275C70.5664 211.779 73.2598 212.531 76.9785 212.531C79.3574 212.531 81.2305 212.025 82.5977 211.014C83.9922 209.975 84.6895 208.525 84.6895 206.666Z"
        fill="#5E6B72"
      />
      <path
        d="M149.96 198.75H126.745V222.539H112.349V162.82H152.298V173.936H126.745V187.676H149.96V198.75Z"
        fill="#5E6B72"
      />
      <path
        d="M211.867 173.936H193.944V222.539H179.547V173.936H161.951V162.82H211.867V173.936Z"
        fill="#5E6B72"
      />
      <path
        d="M236.255 202.277V222.539H221.859V162.82H245.689C250.255 162.82 254.288 163.668 257.788 165.363C261.316 167.031 264.036 169.424 265.951 172.541C267.892 175.631 268.863 179.145 268.863 183.082C268.863 188.906 266.771 193.568 262.587 197.068C258.431 200.541 252.716 202.277 245.443 202.277H236.255ZM236.255 191.162H245.689C248.478 191.162 250.597 190.465 252.046 189.07C253.523 187.676 254.261 185.707 254.261 183.164C254.261 180.375 253.509 178.146 252.005 176.479C250.501 174.811 248.451 173.963 245.853 173.936H236.255V191.162Z"
        fill="#5E6B72"
      />
      <path
        d="M327.975 167.191L390.254 167.191V157.165C390.254 156.319 391.24 155.855 391.892 156.395L416 176.34L391.892 196.284C391.24 196.824 390.254 196.36 390.254 195.514V185.488L327.975 185.488V167.191Z"
        fill="#95A7B0"
      />
      <path
        d="M374.025 217.906H311.746V227.932C311.746 228.778 310.76 229.241 310.108 228.702L286 208.757L310.108 188.813C310.76 188.273 311.746 188.737 311.746 189.583V199.609H374.025V217.906Z"
        fill="#95A7B0"
      />
    </svg>
  )
}
