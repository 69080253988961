import React from 'react'
import * as api from 'api'
import { useDispatch } from 'util/hooks'
import NavBarPage from 'page/NavBarPage'
import { KnowledgeSideBar } from 'page/knowledge-base/KnowledgeSideBar/KnowledgeSideBar'
import KnowledgeReviewTable from 'page/knowledge-base/KnowledgeReview/KnowledgeReviewTable/KnowledgeReviewTable'
import KnowledgeReviewFilter, {
  KBReviewItemsFilterParams,
} from 'page/knowledge-base/KnowledgeReview/KnowledgeReviewFilter/KnowledgeReviewFilter'
import {
  parseFirstQueryParam,
  parseQueryString,
  queryParamToArray,
  updateQueryString,
} from 'util/string'
import { CancelTokenSource } from 'axios'
import { isLeft } from 'fp-ts/lib/Either'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { replace } from 'connected-react-router'
import { useLocation, useParams } from 'react-router'
import {
  knowledgeReviewRouterParamToEnum,
  MARK_TITLES,
  MARK_DESCRIPTIONS,
} from 'page/conversations-v2/ConversationsDetail/'
import { fetchKnowledgeReviewItems } from 'page/knowledge-base/KnowledgeReview/thunks'
import { fetchKnowledgeBaseDropdownItems } from 'page/knowledge-base/KnowledgeReview/actions'
import { PageHeader } from 'components/PageHeader/PageHeader'

export const KB_REVIEW_ITEMS_PER_PAGE: number = 15

function idNameToLabelValue(x: {
  readonly id: string
  name: string
}): { label: string; value: string } {
  return { label: x.name, value: x.id }
}

export type UnderstandingsOverviewType = {
  total: number
  unapproved: number
  noAnswers: number
}

const KnowledgeReview = ({
  fetchCounts,
}: {
  readonly fetchCounts: () => void
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const routerParams = useParams<{
    readonly reason: string
  }>()
  const requestCancelSource = React.useRef<CancelTokenSource | null>(null)

  const fetchData = React.useCallback(async () => {
    const params = getKnowledgeBaseReviewItemsParams(location.search)
    if (!location.search) {
      const newSearch = updateQueryString(
        {
          [KBReviewItemsFilterParams.Reviewed]: 0,
          [KBReviewItemsFilterParams.Page]: 1,
          [KBReviewItemsFilterParams.Limit]: KB_REVIEW_ITEMS_PER_PAGE,
        },
        location.search
      )
      dispatch(replace({ search: newSearch }))
      return
    }
    dispatch(fetchKnowledgeBaseDropdownItems.request())
    const data = await api.conversationsV2GetFilterLabels({
      audienceIds: params.audience,
    })
    if (isLeft(data)) {
      dispatch(fetchKnowledgeBaseDropdownItems.failure())
      toast.error('Unable to fetch audience names.')
      return
    }
    const audiences = data.right.audiences.map(idNameToLabelValue)

    dispatch(
      fetchKnowledgeBaseDropdownItems.success({
        audiences,
      })
    )
    await fetchKnowledgeReviewItems(dispatch)(
      requestCancelSource.current,
      routerParams.reason
    )
  }, [dispatch, location.search, routerParams.reason])

  React.useEffect(() => {
    fetchData()
  }, [fetchData])

  const knowledgeReviewReason = knowledgeReviewRouterParamToEnum(
    routerParams.reason
  )
  return (
    <NavBarPage title="Knowledge Base" className="d-flex h-100">
      <KnowledgeSideBar
        topics={[]}
        totalApprovedSeedsCount={0}
        totalBlankAnswers={0}
        totalSeedsCount={0}
        totalUnapprovedSeedsCount={0}
        pageContent={
          <>
            <PageHeader
              title={
                knowledgeReviewReason
                  ? MARK_TITLES[knowledgeReviewReason]
                  : 'All Messages'
              }
              description={
                knowledgeReviewReason
                  ? MARK_DESCRIPTIONS[knowledgeReviewReason]
                  : 'Answer questions and update responses marked for review by your bot or your team'
              }
            />
            <KnowledgeReviewFilter />
            <KnowledgeReviewTable
              fetchData={fetchData}
              fetchCounts={fetchCounts}
            />
          </>
        }
      />
    </NavBarPage>
  )
}

export function getKnowledgeBaseReviewItemsParams(
  reason?: string
): api.KnowledgeBaseReviewItemsParams {
  const urlParams = parseQueryString(location.search)

  return {
    page: parseInt(
      parseFirstQueryParam(urlParams[KBReviewItemsFilterParams.Page]) || '1',
      10
    ),
    limit: KB_REVIEW_ITEMS_PER_PAGE,
    search:
      parseFirstQueryParam(urlParams[KBReviewItemsFilterParams.Search]) ??
      undefined,
    transports: queryParamToArray(
      urlParams[KBReviewItemsFilterParams.Transports]
    ),
    audience: queryParamToArray(urlParams[KBReviewItemsFilterParams.Audience]),
    ...(urlParams[KBReviewItemsFilterParams.DateFrom] && {
      dateFrom: urlParams[KBReviewItemsFilterParams.DateFrom],
    }),
    ...(urlParams[KBReviewItemsFilterParams.DateTo] && {
      dateTo: urlParams[KBReviewItemsFilterParams.DateTo],
    }),
    ...(reason && { reason: knowledgeReviewRouterParamToEnum(reason) }),
    ...(urlParams[KBReviewItemsFilterParams.Reviewed] === '0' && {
      reviewed: 0,
    }),
  }
}

export default KnowledgeReview
