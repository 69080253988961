export function MoreIcon({
  className,
  onClick,
}: {
  readonly className?: string
  readonly onClick: () => void
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9999 9.98401C13.0779 9.98401 14.0159 10.922 14.0159 12C14.0159 13.078 13.0779 14.016 11.9999 14.016C10.9219 14.016 9.98389 13.078 9.98389 12C9.98389 10.922 10.9219 9.98401 11.9999 9.98401ZM17.9999 9.98401C19.0779 9.98401 20.0159 10.922 20.0159 12C20.0159 13.078 19.0779 14.016 17.9999 14.016C16.9219 14.016 15.9839 13.078 15.9839 12C15.9839 10.922 16.9219 9.98401 17.9999 9.98401ZM5.99989 9.98401C7.07789 9.98401 8.01589 10.922 8.01589 12C8.01589 13.078 7.07789 14.016 5.99989 14.016C4.92189 14.016 3.98389 13.078 3.98389 12C3.98389 10.922 4.92189 9.98401 5.99989 9.98401Z"
        fill="#536D92"
      />
    </svg>
  )
}
