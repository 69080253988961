import * as React from 'react'
import pluralize from 'pluralize'
import {
  OverviewRow,
  MOMENT_DATETIME_DISPLAY_FORMAT,
} from 'page/CampaignDetailsPage'
import momentTz from 'moment-timezone'
import { getInstitutionTimeZone } from 'store/triage/profile/selectors'
import { useSelector } from 'util/hooks'
import {
  COUNT_RECURRING_CAMPAIGNS_PER_PAGE,
  getAggListRecurringCampaigns,
} from 'api'
import { CollapsibleSection } from 'components/CollapsibleSection/CollapsibleSection'
import {
  SortTableAsync,
  IAsyncTableOptions,
  IAsyncRows,
  ITableAsyncColumnProps,
} from 'components/TableAsyncSort/TableAsyncSort'
import { isRight } from 'fp-ts/lib/Either'
import { isEqual } from 'lodash'
import { IRecurringCampaignsListResponseData } from 'api/response'
import { MainstayFlexTableCol } from 'mainstay-ui-kit/MainstayFlexCol/MainstayFlexCol'
import { generatePath } from 'react-router'
import { CAMPAIGN_HISTORY } from 'const/routes'
import { Link } from 'util/routing'

interface ICampaignHistorySectionProps {
  parentId: string
  countCampaignsSent: number
  totalRecipients: number
  startDate: string
  isInteractive: boolean
}
export const CampaignHistorySection = ({
  parentId,
  countCampaignsSent,
  totalRecipients,
  startDate,
  isInteractive,
}: ICampaignHistorySectionProps) => {
  const [historicalTableRows, setHistoricalTableRows] = React.useState<
    Pick<IRecurringCampaignsListResponseData, 'count' | 'results'>
  >({
    count: 0,
    results: [],
  })

  const timeZone = useSelector(getInstitutionTimeZone)

  const displayStartDate = momentTz
    .tz(new Date(startDate), timeZone)
    .format(MOMENT_DATETIME_DISPLAY_FORMAT)

  const tableColumns: ITableAsyncColumnProps[] = [
    {
      title: 'Send Date',
      xs: isInteractive ? 2 : 3,
      alignment: 'start',
      sortByField: 'sentDate',
    },
    {
      title: 'Passive Recipients',
      xs: 2,
      alignment: 'end',
      sortByField: 'countPassive',
    },
    {
      title: 'Opt-outs',
      xs: 2,
      alignment: 'end',
      sortByField: 'countOptOuts',
    },
    {
      title: 'Pauses',
      xs: 2,
      alignment: 'end',
      sortByField: 'countPauses',
    },
    {
      title: 'Delivery Failures',
      xs: isInteractive ? 2 : 3,
      alignment: 'end',
      sortByField: 'countDeliveryFailures',
    },
  ]

  if (isInteractive) {
    tableColumns.splice(1, 0, {
      title: 'Active Recipients',
      xs: 2,
      alignment: 'end',
      sortByField: 'countActive',
    })
  }

  const loadRows = React.useCallback(
    async ({
      page = 1,
      sortBy = 'sendDate',
      order = 'desc',
    }: IAsyncTableOptions): Promise<void> => {
      const res = await getAggListRecurringCampaigns(
        parentId,
        page,
        COUNT_RECURRING_CAMPAIGNS_PER_PAGE,
        sortBy,
        order
      )
      if (isRight(res)) {
        if (!isEqual(res.right, historicalTableRows)) {
          setHistoricalTableRows(res.right)
        }
      }
    },
    [historicalTableRows, parentId]
  )

  const memoizedRows = React.useMemo<IAsyncRows>(() => {
    if (!historicalTableRows) {
      return { total: 0, rows: [], error: true }
    } else {
      return {
        total: historicalTableRows.count,
        rows: historicalTableRows.results.map(rowRes => {
          const row = [
            <MainstayFlexTableCol xs={isInteractive ? 2 : 3} key={rowRes.id}>
              <Link
                eventLocation="campaigns"
                eventAction="click"
                eventObject="campaign history individual instance link"
                to={generatePath(CAMPAIGN_HISTORY.RECURRING_DETAIL, {
                  id: rowRes.id,
                })}>
                {momentTz
                  .tz(new Date(rowRes.sent_date), timeZone)
                  .format(MOMENT_DATETIME_DISPLAY_FORMAT)}
              </Link>
            </MainstayFlexTableCol>,
            String(rowRes.count_passive_recipients),
            String(rowRes.count_opt_outs),
            String(rowRes.count_pauses),
            String(rowRes.count_delivery_failures),
          ]

          if (isInteractive) {
            row.splice(1, 0, String(rowRes.count_active_recipients))
          }
          return row
        }),
      }
    }
  }, [historicalTableRows, isInteractive, timeZone])

  return (
    <>
      <div className="text-mainstay-dark-blue m-0 mainstay-header-h4-overview">
        Campaign History
      </div>
      <div className="overview-container pt-3 pb-1">
        <OverviewRow field="Start Date" value={displayStartDate} />
        <OverviewRow
          field="Campaign Sent"
          value={`${countCampaignsSent} ${pluralize(
            'time',
            countCampaignsSent
          )}`}
        />
        <OverviewRow field="Total Recipients" value={totalRecipients} />
      </div>
      <CollapsibleSection title="Engagement History">
        <SortTableAsync
          className="pt-3"
          pageSize={COUNT_RECURRING_CAMPAIGNS_PER_PAGE}
          itemsName="campaign sends"
          pagerHash="#aggregate"
          initialTableOptions={{ sortBy: 'sentDate', order: 'desc' }}
          columns={tableColumns}
          fetchRows={loadRows}
          renderRows={memoizedRows}
        />
      </CollapsibleSection>
    </>
  )
}
