import ConnectedSubtree from 'components/ConnectedSubtree/ConnectedSubtree'
import { Link } from 'util/routing'

interface ICampaignPreviewCardProps {
  initialStateId: string
  workflowHumanName?: string
  showHeader?: boolean
  campaignReview?: boolean
  isArchived?: boolean
  scriptId?: string
  useAggregateSteps?: boolean
}

export const CampaignPreviewCard = ({
  initialStateId,
  workflowHumanName = 'Campaign Script',
  showHeader = true,
  campaignReview = false,
  isArchived = false,
  scriptId,
  useAggregateSteps,
}: ICampaignPreviewCardProps) => {
  return (
    <div className="position-relative">
      {showHeader && (
        <div className="pt-4 pb-2">
          <span className="mainstay-header-h4 text-mainstay-dark-blue pr-2">
            {workflowHumanName}
          </span>
          {isArchived ? (
            <span>(Deleted)</span>
          ) : scriptId ? (
            <Link
              eventLocation="campaigns"
              eventAction="click"
              eventObject="script view in scripts"
              to={`/campaign-scripts/${scriptId}/view`}
              className="text-decoration-none expand-link mainstay-body-caption mt-1">
              View in Scripts
            </Link>
          ) : null}
        </div>
      )}
      <div>
        <ConnectedSubtree
          root={true}
          workflowStepId={initialStateId}
          // Do not allow editing for scripts previewed in this Campaign view
          // This prop controls if the Edit pencil is shown or not on the <OutgoingNode> components down the tree
          editable={false}
          campaignReview={campaignReview}
          useAggregateSteps={useAggregateSteps}
        />
      </div>
    </div>
  )
}
