import * as React from 'react'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import 'components/FancyTextBox/FancyImagePreview/FancyImagePreview.scss'

interface IFancyImagePreviewProps {
  readonly url: string
  readonly name?: string
  readonly onRemove?: () => void
}

export default class FancyImagePreview extends React.PureComponent<
  IFancyImagePreviewProps
> {
  render() {
    const { url, onRemove, name } = this.props

    return (
      <div className="fancy-image-preview">
        <div className="image-container d-flex">
          <div className="image" style={{ backgroundImage: `url(${url})` }} />
          <div className="image-name pl-1 text-muted-v2">
            <span className="text-ellipsis">{name || url}</span>
          </div>
        </div>
        <div className="remove-button pointer">
          <AHIcon
            className="text-muted-v2"
            name="remove_circle"
            onClick={onRemove}
          />
        </div>
      </div>
    )
  }
}
