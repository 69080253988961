import React from 'react'
import cls from 'classnames'
import 'page/conversations-v2/ConversationsDetail/ConversationsDetail.scss'

export function MessageContainer({
  direction,
  children,
  className,
}: {
  readonly direction: 'incoming' | 'outgoing'
  readonly children: React.ReactNode
  readonly className?: string
}) {
  return (
    <div
      className={cls(
        'message-details-wrapper max-w-360 flex-grow-1 d-flex flex-column word-wrap-break-word border-radius-md',
        className,
        {
          'border-bottom-left-radius-0 bg-mainstay-dark-blue-80 text-mainstay-cream-30':
            direction === 'incoming',
          'border-bottom-right-radius-0 bg-mainstay-dark-blue-05 text-mainstay-blue':
            direction === 'outgoing',
        }
      )}>
      {children}
    </div>
  )
}
