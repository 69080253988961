import AsyncSelect from 'react-select/lib/Async'
import { ValueType } from 'react-select/lib/types'
import { DropdownIndicator } from 'components/SettingsEscalation/reactSelectUtils'

export type SelectInputValue = { value: string; label: string }

export function ConversationsSelectInput(
  props:
    | {
        onSelect: (value: SelectInputValue | null) => void
        onClear?: () => void
        loadOptions: (inputValue: string) => Promise<SelectInputValue[]>
        placeholder: string
        selectedOption: SelectInputValue | null
        isMulti?: false
      }
    | {
        onSelect: (value: SelectInputValue[]) => void
        onClear?: () => void
        loadOptions: (inputValue: string) => Promise<SelectInputValue[]>
        placeholder: string
        selectedOption: SelectInputValue[]
        isMulti: true
      }
) {
  return (
    <AsyncSelect
      isMulti={props.isMulti}
      cacheOptions
      defaultOptions
      value={props.selectedOption}
      classNamePrefix="react-select"
      placeholder={props.placeholder}
      isClearable
      onChange={(value: ValueType<SelectInputValue>, actionMeta) => {
        if (props.isMulti) {
          if (!Array.isArray(value)) {
            throw Error(
              'invalid type for select. Expected object, found array.'
            )
          }
          props.onSelect(value)
        } else {
          if (Array.isArray(value)) {
            throw Error('invalid type for select. Expected array, found object')
          }
          props.onSelect(value ?? null)
        }
        if (props.onClear && actionMeta.action === 'clear') {
          return props.onClear()
        }
      }}
      loadOptions={props.loadOptions}
      components={{
        DropdownIndicator,
        IndicatorSeparator: undefined,
      }}
    />
  )
}
