export interface IIntroDialogCopyItem {
  WEB_BOT: string
  WEB_HOOK: string
  UNKNOWN_STUDENT: string
  IM_SICK: string
}

interface IIntrodDialogCopy {
  title: IIntroDialogCopyItem
  description: IIntroDialogCopyItem
  recipients: IIntroDialogCopyItem
  trigger: IIntroDialogCopyItem
}

export const INTRO_DIALOGS_COPY: IIntrodDialogCopy = {
  title: {
    WEB_BOT: 'Custom Web Introduction',
    WEB_HOOK: 'Automatic Introduction',
    UNKNOWN_STUDENT: 'Introduction to Unknown Contacts',
    IM_SICK: 'COVID-19 Information Dialog',
  },
  description: {
    WEB_BOT:
      'When a contact initiates a web chat, they will enter the web introduction script',
    WEB_HOOK:
      "When a contact is created via your webhook integration, they automatically enter your bot's introduction script",
    UNKNOWN_STUDENT:
      'When a new contact messages your bot for the first time, they will receive the bot’s automatic introduction script',
    IM_SICK: 'When a contact initiates the #imsick dialog',
  },
  recipients: {
    WEB_BOT:
      'Unknown contacts: anyone who has not yet communicated with the bot',
    WEB_HOOK: 'New contacts',
    UNKNOWN_STUDENT:
      'Unknown contacts: anyone who has not yet communicated with the bot',
    IM_SICK:
      'Anyone who initiates the coronavirus information dialog via the #imsick keyword',
  },
  trigger: {
    WEB_BOT: 'Incoming message',
    WEB_HOOK: 'Contact is created via webhook',
    UNKNOWN_STUDENT: 'Incoming message',
    IM_SICK: 'The #imsick keyword',
  },
}

export const INTRO_DIALOGS_COPYV2: IIntrodDialogCopy = {
  title: {
    WEB_BOT: 'Introduction to Webchat Contacts',
    WEB_HOOK: 'Introduction to API-created Contacts',
    UNKNOWN_STUDENT: 'Introduction to Unknown SMS Contacts',
    IM_SICK: 'COVID-19 Information Script',
  },
  description: {
    WEB_BOT:
      'When a contact initiates a webchat, they enter the web introduction script.',
    WEB_HOOK:
      'When a contact is created via the API create-and-intro webhook, they enter the introduction script.',
    UNKNOWN_STUDENT:
      'When an unknown phone number messages your bot, they receive the SMS introduction script.',
    IM_SICK:
      'When a contact initiates the #imsick script, they receive the #imsick script',
  },
  recipients: {
    WEB_BOT: 'Webchat contacts',
    WEB_HOOK: 'API-created contacts',
    UNKNOWN_STUDENT: 'Unknown SMS contacts',
    IM_SICK: 'Contacts using the #imsick command',
  },
  trigger: {
    WEB_BOT: 'New webchat interaction.',
    WEB_HOOK: 'Create-and-intro API webhook',
    UNKNOWN_STUDENT: 'Incoming SMS message from unknown number',
    IM_SICK: '#imsick command',
  },
}
