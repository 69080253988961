import { RootState as IState } from 'store/store'
import { createSelector } from 'reselect'
import * as _ from 'lodash'
import { IKnowledgeSeederPageRouteProps } from 'components/IRouteProps'
import { SeedFilter, IKnowledgeSeed } from 'store/knowledgeSeeder/reducer'
import { parseQueryString } from 'util/string'
import { notUndefined } from 'util/typeguards'

export const getSelectedCategory = (
  _state: IState,
  ownProps: IKnowledgeSeederPageRouteProps
) => {
  if (ownProps.match.params.category) {
    return ownProps.match.params.category.toLowerCase()
  }
}

export const getFilterFromQueryString = (
  _state: IState,
  ownProps: IKnowledgeSeederPageRouteProps
): SeedFilter | undefined => {
  const qs = parseQueryString(ownProps.location.search)
  if (qs.filter && !Array.isArray(qs.filter) && qs.filter in SeedFilter) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return SeedFilter[qs.filter as keyof typeof SeedFilter]
  }
}

export const getSelectedSeedGrouping = createSelector(
  getSelectedCategory,
  getFilterFromQueryString,
  (category, filter) => {
    return filter || category
  }
)

export const getAllCategoryLabels = (state: IState) => {
  return Object.keys(state.knowledgeSeeder.subCategoriesByCategory)
}

const getSortedCategoryLabels = createSelector(getAllCategoryLabels, labels => {
  return labels.sort()
})

const getSeedsById = (state: IState) => {
  return state.knowledgeSeeder.seedsById
}

const getAllSeedIds = (state: IState) => {
  return state.knowledgeSeeder.allIds
}

const getCategories = (state: IState) => {
  return state.knowledgeSeeder.subCategoriesByCategory
}

const getSubCategories = (state: IState) => {
  return state.knowledgeSeeder.seedIdsBySubCategory
}

/** Return loading status for knowledge seeds.
 *
 * If we already have knowledge seeds in the database, we don't need to display
 * a loader to users and can simply do the fetch in the background while the
 * users interact with partially stale data.
 *
 * NOTE(chdsbd): Mascot does a similar operation, where the user doesn't wait
 * when navigating back to knowledge after already loading it. I believe
 * Meteor/Minimongo helps here.
 */
export const getLoadingSeeds = (state: IState) =>
  state.knowledgeSeeder.loading && state.knowledgeSeeder.allIds.length === 0

export const getCategoryCounts = createSelector(
  getSortedCategoryLabels,
  getCategories,
  getSubCategories,
  (labels, categories, subCategories) => {
    return labels.map(cat => {
      let totalCount = 0
      let approvedCount = 0
      let unapprovedCount = 0
      let blankCount = 0
      const subCategoriesByCategory = categories[cat]
      subCategoriesByCategory.forEach(subCat => {
        blankCount += subCategories[subCat].blank.length
        totalCount += subCategories[subCat].all.length
        approvedCount += subCategories[subCat].approved.length
        unapprovedCount += subCategories[subCat].unapproved.length
      })
      return {
        name: cat,
        totalCount,
        approvedCount,
        blankCount,
        unapprovedCount,
      }
    })
  }
)

export const getTotalSeedsCount = createSelector(getAllSeedIds, ids => {
  return ids.length
})

export const getTotalBlankAnswers = createSelector(
  getSubCategories,
  subCategories => {
    return Object.keys(subCategories).reduce((acc, curr) => {
      return acc + subCategories[curr].blank.length
    }, 0)
  }
)

export const getTotalApprovedSeedsCount = createSelector(
  getSubCategories,
  subCategories => {
    return Object.keys(subCategories).reduce((acc, curr) => {
      return acc + subCategories[curr].approved.length
    }, 0)
  }
)

export const getTotalUnapprovedSeedsCount = createSelector(
  getSubCategories,
  subCategories => {
    return Object.keys(subCategories).reduce((acc, curr) => {
      return acc + subCategories[curr].unapproved.length
    }, 0)
  }
)

export const getLoadingStatusBySeedId = (state: IState) => {
  return state.knowledgeSeeder.loadingStatusBySeedId
}

const getSortedCategories = createSelector(
  getSortedCategoryLabels,
  getCategories,
  (categoryLabels, categories) => {
    return categoryLabels.map(cat => categories[cat])
  }
)

const getSortedSeedIds = createSelector(
  getSortedCategories,
  getSubCategories,
  getFilterFromQueryString,
  (sortedCategories, subCategories, filter) => {
    const seedIds: string[] = []
    sortedCategories.forEach(cat => {
      cat.forEach(subCat => {
        subCategories[subCat][filter || SeedFilter.all].forEach(seedId => {
          seedIds.push(seedId)
        })
      })
    })
    return seedIds
  }
)

const getVisibleCategories = createSelector(
  getSelectedCategory,
  getCategories,
  getSortedCategories,
  (selectedCategory, categories, sortedCategories) => {
    if (selectedCategory && categories[selectedCategory]) {
      return [categories[selectedCategory]]
    } else {
      return sortedCategories
    }
  }
)

const getVisibleSubCategories = createSelector(
  getVisibleCategories,
  getSubCategories,
  (visibleCategories, subCategories) => {
    const sortedVisibleCategories = visibleCategories.map(categoryList => {
      return _.sortBy(categoryList, x => x.toLowerCase())
    })
    return _.flatten(
      sortedVisibleCategories.map(cat =>
        cat.map(subCat => subCategories[subCat])
      )
    )
  }
)

export const getVisibleSeeds = createSelector(
  getVisibleSubCategories,
  getSeedsById,
  getFilterFromQueryString,
  (subCategories, seedsById, filter) => {
    const seedsSortedBySubCategory: IKnowledgeSeed[][] = []
    subCategories.forEach(subCat => {
      const seedsForSubCat: IKnowledgeSeed[] = []
      subCat[filter || SeedFilter.all].forEach(seedId => {
        const understanding = seedsById[seedId]
        if (understanding != null) {
          seedsForSubCat.push(understanding)
        }
      })
      if (seedsForSubCat.length) {
        seedsSortedBySubCategory.push(seedsForSubCat)
      }
    })
    return seedsSortedBySubCategory
  }
)

export const getAllSortedSeeds = createSelector(
  getSortedSeedIds,
  getSeedsById,
  (ids, seeds) => {
    return ids.map(id => seeds[id]).filter(notUndefined)
  }
)
