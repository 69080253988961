import { Dispatch } from 'store/store'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import axios, { AxiosError, CancelToken } from 'axios'
import * as api from 'api'
import {
  fetchCampaignHistory,
  fetchCampaignTriggers,
} from 'store/campaign-history/actions'
import { SortBy, SortOrder } from 'store/campaign-history/reducer'
import { isRight } from 'fp-ts/lib/Either'

export const CAMPAIGNS_PER_PAGE = 30

interface IFetchCampaignHistoryAsync {
  readonly query: string
  readonly cancelToken: CancelToken
  readonly page: number
  readonly sort: {
    readonly name: SortBy
    readonly order: SortOrder
  }
  readonly sidebarFilter?: string
  readonly dateFilter?: {
    readonly start?: string
    readonly end?: string
  }
}
export const fetchCampaignHistoryAsync = (dispatch: Dispatch) => ({
  query,
  cancelToken,
  page,
  sort,
  sidebarFilter,
  dateFilter,
}: IFetchCampaignHistoryAsync) => {
  dispatch(fetchCampaignHistory.request())
  return api
    .getCampaigns({
      sidebarFilter,
      query,
      limit: CAMPAIGNS_PER_PAGE,
      page,
      cancelToken,
      sort,
      dateFilter,
    })
    .then(response => {
      dispatch(fetchCampaignHistory.success(response.data))
    })
    .catch((err: AxiosError<{ detail: string }>) => {
      if (!axios.isCancel(err)) {
        toast(
          err.response?.data.detail ||
            'There was an error fetching campaign history',
          {
            type: 'error',
          }
        )
        dispatch(fetchCampaignHistory.failure())
      }
    })
}

export const fetchCampaignTriggersAsync = (dispatch: Dispatch) => ({
  query,
  sort,
}: Pick<IFetchCampaignHistoryAsync, 'query' | 'sort'>) => {
  const errorMsg = 'There was an error fetching campaign triggers'
  dispatch(fetchCampaignTriggers.request())
  return api
    .getCampaignTriggers({ query, sort })
    .then(response => {
      if (isRight(response)) {
        dispatch(fetchCampaignTriggers.success(response.right))
      } else {
        toast(errorMsg, {
          type: 'error',
        })
        dispatch(fetchCampaignTriggers.failure())
      }
    })
    .catch((err: AxiosError<{ detail: string }>) => {
      toast(err.response?.data.detail || errorMsg, {
        type: 'error',
      })
      dispatch(fetchCampaignTriggers.failure())
    })
}
