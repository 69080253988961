import * as api from 'api'
import { Dispatch, store } from 'store/store'
import { isRight } from 'fp-ts/lib/Either'
import {
  ICampaignSchedulerFormData,
  TriggerType,
} from 'store/campaign-scheduler/reducer'
import { http2KeysOrDefault, toastOnHttpError500or400 } from 'api/http'
import campaignSchedulerSchema from 'util/campaignSchedulerSchema'
import { ValidationError } from 'yup'
import { CAMPAIGN_HISTORY } from 'const/routes'
import { AxiosError } from 'axios'
import { push } from 'connected-react-router'
import { getPath } from 'page/campaign/CampaignTable'
import moment from 'moment'
import {
  createScheduledMessage,
  createCampaignTrigger,
  updateFormData,
  updateValidationErrors,
  stopCurrentDialogForStudent,
  updateScheduledMessage,
  updateCampaignTrigger,
  fetchScheduledMessage,
  fetchCampaignTrigger,
  fetchGlobalAppropriateTimeSettings,
} from 'store/campaign-scheduler/actions'
import { generatePath } from 'react-router'
import { ICampaignSchedulerRequestData } from 'api/request'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import * as _ from 'lodash'
import { retrieveCampaignScript } from 'store/campaign-scripts/actions'

export const createScheduledMessageAsync = (dispatch: Dispatch) => (
  formData: ICampaignSchedulerRequestData
) => {
  dispatch(createScheduledMessage.request(formData))
  return api
    .createScheduledMessage(formData)
    .then(res => {
      dispatch(createScheduledMessage.success())
      res.data.multiAudienceFailures.forEach(failedAudienceName => {
        toast(
          `Something went wrong. Unable to schedule campaign for ${failedAudienceName}. Please try again.`,
          { type: 'error' }
        )
      })

      const scheduledDateString = formData.recurring
        ? formData.recurrenceSettings?.startDate
        : formData.date
      const navToDate = !!scheduledDateString
        ? new Date(scheduledDateString)
        : new Date()
      const [navToMonth, navToYear] = navToDate
        .toLocaleString('en-us', { month: 'long', year: 'numeric' })
        .split(' ') // returns in the format ['April', '2029']
      dispatch(push(`/calendar/?month=${navToMonth}&year=${navToYear}`))
      toast('Your campaign is scheduled!', { type: 'success' })
    })
    .catch((err: AxiosError<{ non_field_errors: string[] }>) => {
      if (err?.response?.data?.non_field_errors.includes('contactFilterId')) {
        toast(
          `Something went wrong with selecting an audience. Please try again.`,
          { type: 'error' }
        )
      }

      dispatch(createScheduledMessage.failure(err))
      toastOnHttpError500or400(err)
    })
}

export const updateScheduledMessageAsync = (dispatch: Dispatch, id: string) => (
  formData: ICampaignSchedulerRequestData
) => {
  dispatch(updateScheduledMessage.request(formData))
  return api
    .updateScheduledMessage(formData, id)
    .then(res => {
      dispatch(updateScheduledMessage.success(res.data))
      const scheduledDateString = formData.recurring
        ? formData.recurrenceSettings?.startDate
        : formData.date
      const navToDate = !!scheduledDateString
        ? new Date(scheduledDateString)
        : new Date()
      const [navToMonth, navToYear] = navToDate
        .toLocaleString('en-us', { month: 'long', year: 'numeric' })
        .split(' ') // returns in the format ['April', '2029']
      dispatch(push(`/calendar/?month=${navToMonth}&year=${navToYear}`))
      toast('Your campaign is scheduled!', { type: 'success' })
    })
    .catch((err: AxiosError) => {
      dispatch(updateScheduledMessage.failure(err))
      if (
        Array.isArray(err?.response?.data) &&
        typeof err?.response?.data[0] === 'string'
      ) {
        toast(err.response?.data[0], { type: 'error' })
      } else {
        toastOnHttpError500or400(err)
      }
    })
}

export const createCampaignTriggerAsync = (dispatch: Dispatch) => (
  formData: ICampaignSchedulerRequestData
) => {
  dispatch(createCampaignTrigger.request(formData))
  return api
    .createCampaignTrigger(formData)
    .then(res => {
      if (isRight(res)) {
        dispatch(createCampaignTrigger.success())
        dispatch(push('/campaigns?sidebar_filter=is_datatriggered'))
        toast('Your campaign is scheduled!', { type: 'success' })
        return
      } else {
        dispatch(createCampaignTrigger.failure(res.left))
        const messages = http2KeysOrDefault(
          res.left,
          'There was a problem updating your campaign.',
          ['contactFilterId', 'dialogId']
        )
        Object.keys(messages).forEach(k => {
          toast(messages[k], { type: 'error' })
        })
      }
    })
    .catch((err: AxiosError<{ non_field_errors: string[] }>) => {
      if (err?.response?.data?.non_field_errors.includes('contactFilterId')) {
        toast(
          `Something went wrong with selecting an audience. Please try again.`,
          { type: 'error' }
        )
      }

      dispatch(createCampaignTrigger.failure(err))
      toastOnHttpError500or400(err)
    })
}

export const fetchScheduledMessageAsync = (dispatch: Dispatch) => (
  id: string
) => {
  dispatch(fetchScheduledMessage.request())
  return api
    .fetchScheduledMessage(id)
    .then(res => {
      if (res?.data) {
        const upcoming = moment(res.data.scheduledAt).isAfter(moment())
        const redirect = getPath({
          ...res.data,
          upcoming,
          isParentRecurringInstance: res.data.recurring,
        })

        if (!upcoming && document.location.pathname !== redirect) {
          dispatch(push(redirect))
          return
        }

        dispatch(fetchScheduledMessage.success(res.data))
        dispatch(
          retrieveCampaignScript.success({
            dialog: res.data.dialog,
            states: res.data.states,
          })
        )
      }
    })
    .catch((err: AxiosError) => {
      dispatch(fetchScheduledMessage.failure(err))
      toastOnHttpError500or400(err)
    })
}

export const fetchCampaignTriggerAsync = (dispatch: Dispatch) => ({
  id,
  editing = false,
}: {
  id: string
  editing?: boolean
}) => {
  dispatch(fetchCampaignTrigger.request())
  return api
    .getCampaignTrigger({ id, editing })
    .then(res => {
      if (isRight(res)) {
        if (res.right.trigger.started) {
          const redirect = generatePath(
            CAMPAIGN_HISTORY.DATA_TRIGGERED_DETAIL,
            {
              id: res.right.trigger.id,
            }
          )
          dispatch(push(redirect))
          return
        }

        dispatch(fetchCampaignTrigger.success(res.right))
        dispatch(
          retrieveCampaignScript.success({
            dialog: res.right.trigger.dialog,
            states: res.right.workflow_steps,
          })
        )
      } else {
        dispatch(fetchCampaignTrigger.failure())
        toast('There was a problem fetching your campaign.', { type: 'error' })
      }
    })
    .catch((err: AxiosError) => {
      dispatch(fetchCampaignTrigger.failure())
      toastOnHttpError500or400(err)
    })
}

export const updateCampaignTriggerAsync = (dispatch: Dispatch, id: string) => (
  formData: ICampaignSchedulerRequestData
) => {
  dispatch(updateCampaignTrigger.request(formData))
  return api
    .updateCampaignTrigger({ data: formData, id })
    .then(res => {
      if (isRight(res)) {
        dispatch(updateCampaignTrigger.success(res.right))
        dispatch(push('/campaigns?sidebar_filter=is_datatriggered'))
        toast('Your campaign is scheduled!', { type: 'success' })
      } else {
        dispatch(updateCampaignTrigger.failure(res.left))
        const messages = http2KeysOrDefault(
          res.left,
          'There was a problem updating your campaign.',
          ['contactFilterId', 'dialogId']
        )
        Object.keys(messages).forEach(k => {
          toast(messages[k], { type: 'error' })
        })
      }
    })
    .catch((err: AxiosError) => {
      dispatch(updateCampaignTrigger.failure(err))
      if (
        Array.isArray(err?.response?.data) &&
        typeof err?.response?.data[0] === 'string'
      ) {
        toast(err.response?.data[0], { type: 'error' })
      } else {
        toastOnHttpError500or400(err)
      }
    })
}

export const createTriggerOrScheduledMessage = (dispatch: Dispatch) => (
  formData: ICampaignSchedulerRequestData
) => {
  if (formData.triggerType === TriggerType.DataTriggered) {
    return createCampaignTriggerAsync(dispatch)(formData)
  } else {
    return createScheduledMessageAsync(dispatch)(formData)
  }
}

export const updateTriggerOrScheduledMessage = (
  dispatch: Dispatch,
  id: string,
  isDTC: boolean
) => (formData: ICampaignSchedulerRequestData) => {
  if (isDTC) {
    return updateCampaignTriggerAsync(dispatch, id)(formData)
  } else {
    return updateScheduledMessageAsync(dispatch, id)(formData)
  }
}

export const fetchGlobalAppropriateTimeSettingsAsync = (
  dispatch: Dispatch
) => () => {
  dispatch(fetchGlobalAppropriateTimeSettings.request())
  return api
    .getSMSSettings()
    .then(res => {
      dispatch(
        fetchGlobalAppropriateTimeSettings.success(res.data.campaignSendWindow)
      )
    })
    .catch((err: AxiosError) => {
      dispatch(fetchGlobalAppropriateTimeSettings.failure(err))
    })
}

export const initiateDialogForStudentAsync = (dispatch: Dispatch) => {
  return (name: string, dialogId: string, studentId: string) => {
    const requestData = {
      name,
      description: `Campaign script '${name}' sent to contact '${studentId}'`,
      dialogId,
      hidden: true,
      test: false,
      immediate: true,
      users: [studentId],
    }
    dispatch(createScheduledMessage.request(requestData))
    return api
      .createScheduledMessage(requestData)
      .then(() => {
        dispatch(createScheduledMessage.success())
        toast('Campaign Script Sent', { type: 'success' })
      })
      .catch((err: AxiosError) => {
        dispatch(createScheduledMessage.failure(err))
        toastOnHttpError500or400(err)
      })
  }
}

export const stopCurrentDialogForStudentAsync = (dispatch: Dispatch) => {
  return (studentId: string) => {
    dispatch(stopCurrentDialogForStudent.request(studentId))
    return api
      .stopCurrentDialogForStudent(studentId)
      .then(() => {
        dispatch(stopCurrentDialogForStudent.success())
        toast('Campaign Script Stopped', { type: 'success' })
      })
      .catch((err: AxiosError) => {
        dispatch(stopCurrentDialogForStudent.failure(err))
        toastOnHttpError500or400(err)
      })
  }
}

export const updateValidationErrorsAsync = (dispatch: Dispatch) => (
  formData: ICampaignSchedulerFormData,
  timezone: string
) => {
  return campaignSchedulerSchema(timezone)
    .validate(formData, { abortEarly: false })
    .then(() => {
      dispatch(updateValidationErrors({}))
    })
    .catch((err: ValidationError) => {
      const validationErrors = _.chain(err.inner)
        .keyBy(x => x.path)
        .mapValues(x => x.errors)
        .value()
      dispatch(
        updateValidationErrors({
          ...validationErrors,
        })
      )
      return Promise.reject(validationErrors)
    })
}

export const updateFormDataAsync = (dispatch: Dispatch) => (
  formData: Partial<ICampaignSchedulerFormData>,
  timezone?: string
) => {
  // note(jsimms): going to leave this here in case we need to do field level validation on update.
  dispatch(updateFormData(formData))
  const existingFormData = store.getState().campaignScheduler.form
  const merged = { ...existingFormData, ...formData }
  updateValidationErrorsAsync(dispatch)(merged, timezone || '').catch(
    (err: ValidationError) => {
      return err
    }
  )
}
