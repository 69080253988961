import { ITransitionType } from 'api/request'
import AddNodeMenuButton from 'components/AddNodeButton/AddNodeButton'
import { Button } from 'components/Button/Button'
import CampaignBuilderContextMenu from 'components/CampaignBuilderContextMenu/CampaignBuilderContextMenu'
import DeleteNodeButton from 'components/DeleteNodeButton/DeleteNodeButton'
import EditDisplayComponent from 'components/EditDisplayComponent/EditDisplayComponent'
import IncomingNode from 'components/IncomingNode/IncomingNode'
import { HorizontalNode } from 'components/NodeSubtree/NodeSubtree'
import { TextInput } from 'components/TextInput/TextInput'
import * as React from 'react'
import { PromptType } from 'store/campaign-scripts/reducer'
import classnames from 'classnames'
import { NEW_PROMPT_OPTION_INITIAL_MESSAGE } from 'util/campaign-scripts'

interface IHorizontalNodeWrapper {
  condition: boolean | undefined
  children: React.ReactNode
}

const HorizontalNodeWrapper = ({
  condition,
  children,
}: IHorizontalNodeWrapper): React.ReactElement =>
  condition ? <HorizontalNode>{children}</HorizontalNode> : <>{children}</>

interface IEditableIncomingNode {
  value: string
  transitionKey: ITransitionType
  transitionType: React.ReactNode
  onEditNode?: (editing: boolean) => void
  onChange?: (transitionKey: ITransitionType, value: string) => Promise<void>
  onDelete?: (transitionKey: ITransitionType) => void
  onClickAddLink: () => void
  onClickAddChild: (type: PromptType) => void
  menuType: 'full' | 'limited'
  editable: boolean
  addingNewBranch?: boolean
  hasHorizontalNode?: boolean | undefined
}

export default class EditableIncomingNode extends React.PureComponent<
  IEditableIncomingNode,
  {
    value: string
    initialValue: string
    editing: boolean
    menuVisible: boolean
  }
> {
  constructor(props: IEditableIncomingNode) {
    super(props)
    this.state = {
      value: props.value,
      initialValue: props.value,
      editing: Boolean(props.addingNewBranch),
      menuVisible: false,
    }
  }

  handleEdit = (editing: boolean) => {
    this.setState({ editing })
    this.props.onEditNode?.(editing)
  }

  componentDidUpdate(prevProps: IEditableIncomingNode) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value })
      this.handleEdit(false)
    }
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ value: event.target.value })
  }

  handleUpdate = () => {
    if (this.props.onChange && this.state.value.length >= 1) {
      this.props
        .onChange(this.props.transitionKey, this.state.value)
        .then(() => this.handleEdit(false))
    }
  }

  handleStopEditing = () => {
    if (this.state.editing) {
      this.handleUpdate()
    }
  }

  handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    this.handleStopEditing()
  }

  handleStartEditing = () => {
    this.handleEdit(true)
  }

  handlePressEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.handleStopEditing()
    }
  }

  handleToggleMenu = () => {
    this.setState(s => ({ menuVisible: !s.menuVisible }))
  }

  handleDelete = (e: React.MouseEvent) => {
    this.handleEdit(false)
    e.stopPropagation()
    e.preventDefault()
    if (this.props.onDelete) {
      this.props.onDelete(this.props.transitionKey)
    }
  }

  render() {
    const editable =
      this.props.editable && this.props.transitionType !== 'PROMPT'

    const display = (
      <IncomingNode
        transitionType={this.props.transitionType}
        editable={editable}>
        {this.state.value}
      </IncomingNode>
    )
    const editor = (
      <IncomingNode transitionType={this.props.transitionType} editing={true}>
        <div className="d-flex align-items-center">
          <div>
            <TextInput
              className={classnames(
                'rounded-0 px-3 py-2 w-100 ml-1 height-auto',
                {
                  'bg-blue-grey-050': this.state.value.length >= 1,
                  'bg-new-ui-danger-light border-bottom-2 border-new-ui-danger background-image-none':
                    this.state.value.length === 0,
                }
              )}
              placeholder={NEW_PROMPT_OPTION_INITIAL_MESSAGE}
              autoFocus={true}
              value={
                this.state.value === NEW_PROMPT_OPTION_INITIAL_MESSAGE
                  ? ''
                  : this.state.value
              }
              onChange={this.handleChange}
              onKeyPress={this.handlePressEnter}
            />
            {this.state.value.length === 0 && (
              <div className="position-absolute small text-new-ui-danger">
                Choice cannot be empty
              </div>
            )}
          </div>
          <Button
            disabled={this.state.value.length === 0}
            onClick={this.handleClick}
            className={classnames(
              'text-white bg-secondary-teal py-2 px-3 rounded ml-4 height-min-content',
              { 'hover-bg-secondary-teal': this.state.value.length === 0 }
            )}>
            Save
          </Button>
          {this.props.onDelete && editable && (
            <DeleteNodeButton onClick={this.handleDelete} />
          )}
        </div>
      </IncomingNode>
    )

    return (
      <>
        <HorizontalNodeWrapper condition={this.props.hasHorizontalNode}>
          <div className="d-flex align-items-center editable-incoming-container">
            <div>
              {this.props.onChange && editable ? (
                <div onClick={this.handleStartEditing}>
                  <EditDisplayComponent
                    editing={this.state.editing}
                    onClick={() => false}
                    onClickOutside={this.handleStopEditing}
                    display={display}
                    editor={editor}
                  />
                </div>
              ) : (
                display
              )}
            </div>
            {editable && !this.state.editing && (
              <AddNodeMenuButton
                onClick={this.handleToggleMenu}
                className="node-add-button"
              />
            )}
          </div>
        </HorizontalNodeWrapper>

        {this.state.menuVisible && (
          <HorizontalNode hideLine={this.props.hasHorizontalNode}>
            <CampaignBuilderContextMenu
              onClickAddLink={this.props.onClickAddLink}
              onClickAdd={this.props.onClickAddChild}
              menuType={this.props.menuType}
              onClose={this.handleToggleMenu}
            />
          </HorizontalNode>
        )}
      </>
    )
  }
}
