import React from 'react'
import {
  FloatingTrashCan,
  IDeleteButtonProps,
} from 'components/FloatingTrashCan/FloatingTrashCan'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import cls from 'classnames'
import { ConfirmationModal } from 'components/Modal/Modal'

export interface IReadWriteResponseHeaderProps {
  readonly onDelete: (() => void) | undefined
  readonly deleteButtonProps?: IDeleteButtonProps
  readonly editing?: boolean
  readonly dragHandleProps?: DraggableProvidedDragHandleProps
  readonly audience: {
    selector?: React.ReactNode
    targetAudienceInfo?: React.ReactNode
  } | null
}
interface IDragHandleProps {
  readonly content?: React.ReactElement
  readonly className?: string
  readonly dragHandleProps?: DraggableProvidedDragHandleProps
}

function DragHandle({ dragHandleProps, content, className }: IDragHandleProps) {
  return (
    <div
      {...dragHandleProps}
      className={cls(className, 'position-relative hover-bg-blue-grey-050')}>
      {dragHandleProps && (
        <div
          className={cls(
            'width-100 d-flex justify-content-center align-items-center'
          )}>
          <AHIcon
            rotate={90}
            name="drag_indicator"
            className="text-muted hover-text-blue-grey-900"
          />
        </div>
      )}
      {content}
    </div>
  )
}

export const BasicResponseHeader = ({
  onDelete,
  editing,
  dragHandleProps,
  audience,
  deleteButtonProps,
}: IReadWriteResponseHeaderProps) => {
  const [
    showResponseDeleteConfModal,
    setShowResponseDeleteConfModal,
  ] = React.useState<boolean>(false)
  return (
    <>
      <DragHandle
        dragHandleProps={dragHandleProps}
        className="hover-parent"
        content={dragHandleProps && <hr className="my-0" />}
      />
      <div className="w-100 position-relative">
        {editing && (
          <>
            <h5 className="text-mainstay-dark-blue pt-3 pb-2 px-3">
              Edit response
            </h5>
            <hr className="my-0" />
          </>
        )}
        {onDelete && (
          <FloatingTrashCan
            onClick={() => {
              setShowResponseDeleteConfModal(true)
            }}
            deleteButtonProps={deleteButtonProps}
          />
        )}
        {audience?.targetAudienceInfo}
        {audience?.selector}
      </div>
      <ConfirmationModal
        show={showResponseDeleteConfModal}
        hideCheckbox={true}
        zIndex={5000}
        helpText="Are you sure you want to permanently delete this answer?"
        onConfirm={() => {
          setShowResponseDeleteConfModal(false)
          onDelete?.()
        }}
        title="Delete response?"
        confirmButtonText="Yes, Delete"
        onClose={() => setShowResponseDeleteConfModal(false)}
      />
    </>
  )
}
