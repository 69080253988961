import { getAttributeDataFromMustache } from 'components/DraftEditor/draftUtils'
import Mustache from 'mustache'
import cls from 'classnames'
import { useSelector } from 'util/hooks'
import { AttributeBlock } from 'components/DraftEditor/AttributeBlock'
import {
  IAttributesById,
  IAttributeNamesById,
} from 'store/personalization/institutionAttributes/selectors'
import { isSuccess, isLoading } from 'store/webdata'
import { getCampaignScriptIsUpdating } from 'store/campaign-scripts/selectors'
import { useGetAttributeMapping } from 'components/DraftEditor/DraftEditor'
import * as Raven from '@sentry/browser'
import 'components/DraftEditor/DraftEditor.scss'

interface IWireframeMessage {
  message?: string
  attributes?: IAttributesById | IAttributeNamesById
  showToolTip?: boolean
  readonly onClick?: () => void
  className?: string
  children?: JSX.Element
  messageClassName?: string
}

export const WireframeMessage = ({
  message,
  attributes,
  showToolTip = true,
  onClick,
  className,
  children,
  messageClassName,
}: IWireframeMessage) => {
  const mappingResultsToUse = useGetAttributeMapping()
  const mapping =
    attributes ||
    (isSuccess(mappingResultsToUse)
      ? mappingResultsToUse.data
      : { institution: {}, contact: {}, toplevel: {} })

  const isUpdating = useSelector(getCampaignScriptIsUpdating)

  try {
    const mustacheBlocks = Mustache.parse(message || '')

    return (
      <div
        onClick={() => {
          if (onClick) {
            onClick()
          }
        }}
        className="word-wrap-break-word whitespace-pre-wrap word-break-break-word d-flex flex-column text-mainstay-dark-blue-80">
        <p className={cls('m-0', messageClassName)}>
          {mustacheBlocks
            ? mustacheBlocks.map(block => {
                if (block[0] === 'text') {
                  return block[1]
                }
                if (block[0] === 'name') {
                  const attribute = getAttributeDataFromMustache(
                    block[1],
                    mapping
                  )
                  if (attribute) {
                    return (
                      <AttributeBlock
                        className={cls(
                          className,
                          'attribute-block d-inline-block text-truncate max-w-400px shift-down-4px'
                        )}
                        attribute={attribute}
                        key={`${attribute?.id} + ${attribute?.field}`}
                        showToolTip={showToolTip}>
                        {attribute.field ||
                          (isLoading(mappingResultsToUse)
                            ? 'Loading Attributes...'
                            : 'Attribute not found')}
                      </AttributeBlock>
                    )
                  }
                }
                return (
                  <AttributeBlock showToolTip={false} key={block[1]}>
                    Attribute not found
                  </AttributeBlock>
                )
              })
            : message}
        </p>
        {children}
      </div>
    )
  } catch {
    if (isUpdating) {
      return null
    }
    Raven.captureMessage('Invalid templating in script detected.')
    return (
      <div className="text-danger">
        Invalid Templating Detected. Please contact support@mainstay.com to
        resolve
      </div>
    )
  }
}
