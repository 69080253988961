import * as React from 'react'
import SideEffectNode from 'components/SideEffectNode/SideEffectNode'
import {
  IExitAction,
  ICampaignScriptStep,
  isEmailExitAction,
  isWebhookExitAction,
  PromptType,
  IEmailExitActionArgs,
  ExitActionType,
} from 'store/campaign-scripts/reducer'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { Formik, FormikProps } from 'formik'
import { Radio } from '@material-ui/core'
import { Button } from 'components/Button/Button'

import { EditableOutgoingNodeContext } from 'components/EditableOutgoingNode/EditorContext'

import { EmailAutoSuggest } from 'components/EmailAutoSuggest/EmailAutoSuggest'
import { CenteredLoader } from 'components/Loader/Loader'

import { FormFeedback, FormGroup, Input, Label } from 'reactstrap'

import { Link } from 'util/routing'
import * as yup from 'yup'
import { OutsideClickHandler } from 'components/OutsideClickHandler/OutsideClickHandler'
import { VerticalLine } from 'components/NodeSubtree/NodeSubtree'
import { useFeatures, useSelector } from 'util/hooks'
import { getInstitutionWebhookSystemName } from 'store/triage/profile/selectors'

interface ISendEmailProps {
  step: ICampaignScriptStep
  action: IExitAction<IEmailExitActionArgs | {}>
  actionIndex: number
  editable: boolean
  onCancel: () => void
  editMode: boolean
  setEditMode: (val: boolean) => void
  onUpdateNode: (
    data: Partial<ICampaignScriptStep>,
    stayInEditMode?: boolean
  ) => void
}

export const SendEmail = ({
  editMode,
  action,
  step,
  editable,
  setEditMode,
  onCancel,
  onUpdateNode,
  actionIndex,
}: ISendEmailProps) => {
  const { response } = React.useContext(EditableOutgoingNodeContext)

  const handleDeleteAction = React.useCallback(() => {
    const newExitActions = (step.exitActions || []).filter(
      (_, i: number) => i !== actionIndex
    )
    onUpdateNode({ exitActions: newExitActions })
  }, [actionIndex, onUpdateNode, step.exitActions])

  const recipientLabel = React.useMemo(() => {
    if (isWebhookExitAction(action)) {
      const prefix =
        step.promptType === PromptType.auto
          ? 'Send conversation to'
          : 'Responses will be sent to'
      return `${prefix} enabled webhooks`
    }
    if (isEmailExitAction(action)) {
      // Sending to a specific counselor by id
      if (action.args.counselorId) {
        const prefix =
          step.promptType === PromptType.auto
            ? 'Confirmation for receipt of this message will be sent to '
            : 'Responses will be sent to '
        return `${prefix} ${response?.[1].data.filter(
          x => x.id === action.args.counselorId
        )?.[0]?.email || '(unknown counselor)'}.`
      }
      // Sending to an email address
      else if (action.args.to) {
        const prefix =
          step.promptType === PromptType.auto
            ? 'Confirmation for receipt of this message will be sent to'
            : 'Responses will be sent to'
        return `${prefix} ${action.args.to}.`
      }
      // Sending to a placeholder that becomes the best-matching counselor at send time
      else {
        return step.promptType === PromptType.auto
          ? 'Send conversation to an email determined by Email Forward Suggestions settings.'
          : 'Send responses and conversation to an email determined by Email Forward Suggestions settings.'
      }
    }
  }, [action, response, step.promptType])

  const handleClick = React.useCallback(() => setEditMode(true), [setEditMode])

  const handleUpdateAction = React.useCallback(
    (updatedAction: IExitAction<IEmailExitActionArgs>) => {
      const newExitActions = step.exitActions || []
      newExitActions[actionIndex] = { ...updatedAction, newlyCreated: false }
      onUpdateNode({ exitActions: newExitActions })
    },
    [actionIndex, onUpdateNode, step.exitActions]
  )

  if (editMode || action.newlyCreated) {
    return (
      <div>
        <EmailActionForm
          action={action}
          onDeleteAction={handleDeleteAction}
          onCancel={onCancel}
          onUpdateAction={handleUpdateAction}
        />
      </div>
    )
  } else {
    return (
      <div>
        <VerticalLine />
        <SideEffectNode editable={editable} onClick={handleClick}>
          {recipientLabel}
        </SideEffectNode>
      </div>
    )
  }
}

const mapExitActionToFormValues = (
  exitAction: IExitAction<IEmailExitActionArgs>,
  hasForwardingRules: boolean
): ISendEmailActionForm => {
  return {
    counselorId: exitAction.args.counselorId || exitAction.args.to,
    subject: exitAction.args.subject || '',
    note: exitAction.args.prefix || '',
    autoSelect:
      !hasForwardingRules ||
      (exitAction.name === ExitActionType.SendEmail &&
        !exitAction.args.to &&
        !exitAction.args.counselorId),
    sendToWebhook: exitAction.name === ExitActionType.sendToWebhook,
    specifyCounselor:
      exitAction.name === ExitActionType.SendEmail &&
      !(!exitAction.args.to && !exitAction.args.counselorId),
  }
}

const mapFormValuesToExitAction = (
  action: ISendEmailActionForm
): IExitAction<IEmailExitActionArgs | {}> => {
  const args: {
    to?: string
    subject: string
    prefix?: string
    counselorId?: number
  } = {
    subject: action.subject,
    prefix: action.note,
  }
  if (action.sendToWebhook) {
    return {
      name: ExitActionType.sendToWebhook,
      args: {},
    }
  } else if (action.autoSelect || action.specifyCounselor) {
    if (typeof action.counselorId === 'number') {
      args['counselorId'] = action.counselorId
    }
  }
  return {
    name: ExitActionType.SendEmail,
    args,
  }
}

const validationSchema = yup.object().shape({
  counselorId: yup.string().when('specifyCounselor', {
    is: true,
    then: yup.string().required('Email is a required field.'),
  }),
  subject: yup
    .string()
    .when(['specifyCounselor', 'autoSelect'], {
      is: (specifyCounselor: boolean, autoSelect: boolean) =>
        specifyCounselor || autoSelect,
      then: yup.string().required('Subject is a required field.'),
    })
    .test(
      'lodash template',
      'Template variables in the form <% templateVariableText %> are no longer supported',
      (value: string) => {
        return !/<%.*%>/.test(value)
      }
    ),
  note: yup.string(),
  autoSelect: yup.bool(),
  specifyCounselor: yup.bool(),
  sendToWebhook: yup.bool(),
})

interface ISendEmailActionForm {
  subject: string
  note: string
  counselorId: string | number | undefined
  autoSelect: boolean
  specifyCounselor: boolean
  sendToWebhook: boolean
}

interface IEmailActionFormProps {
  action: IExitAction<IEmailExitActionArgs>
  onCancel: () => void
  onDeleteAction: () => void
  onUpdateAction: (updatedAction: IExitAction<IEmailExitActionArgs>) => void
}

const EmailActionForm = ({
  action,
  onCancel,
  onUpdateAction,
  onDeleteAction,
}: IEmailActionFormProps) => {
  const { loading, response } = React.useContext(EditableOutgoingNodeContext)

  const { hasFeature, FeaturesType } = useFeatures()

  const webhookSystemName = useSelector(getInstitutionWebhookSystemName)

  if (loading) {
    return (
      <>
        <CenteredLoader />
        Loading email escalation rules.
      </>
    )
  }
  return (
    <Formik<ISendEmailActionForm>
      validationSchema={validationSchema}
      initialValues={mapExitActionToFormValues(
        action,
        !!response &&
          response?.[0].data.MANUAL &&
          response?.[0].data.MANUAL.length > 0
      )}
      onSubmit={(formValues: ISendEmailActionForm) => {
        const updatedAction = mapFormValuesToExitAction(formValues)
        onUpdateAction(updatedAction)
      }}>
      {(form: FormikProps<ISendEmailActionForm>) => (
        <OutsideClickHandler
          excludeClassname="modal"
          containerClassName="border rounded background-white w-500px shadow-md mt-3"
          onClickOutside={() => form.handleSubmit()}>
          <div className="border rounded background-white">
            <div className="px-4 pt-4">
              <div className="d-flex justify-content-between">
                <h6>Send responses to email</h6>
                <AHIcon
                  name="delete"
                  onClick={onDeleteAction}
                  className="pointer opacity-50 hover-opacity-100 hover-text-new-ui-danger"
                />
              </div>

              <p className="mt-4 pr-3">
                Each response will be sent with a clip of the conversation. The
                recipient will be able to respond to contacts directly from the
                email. You can manage available recipients in{' '}
                <Link to="/settings/escalation" target="_blank">
                  Forwarding & Escalations Settings.
                </Link>
              </p>
            </div>
            <form onSubmit={form.handleSubmit}>
              <div className="my-3 px-4 pt-1">
                <small className="mb-1">Recipient</small>
                {response?.[0].data.MANUAL &&
                response?.[0].data.MANUAL.length > 0 ? (
                  <div>
                    <div className="d-flex pointer">
                      <div>
                        <Radio
                          id="specify-counselor"
                          name="specifyCounselor"
                          onChange={() => {
                            form.setValues({
                              ...form.values,
                              specifyCounselor: true,
                              autoSelect: false,
                              sendToWebhook: false,
                            })
                          }}
                          checked={form.values.specifyCounselor}
                          color="primary"
                          className="p-0"
                        />
                      </div>
                      <div className="pl-2 w-100">
                        <Label
                          for="specify-counselor"
                          className="pointer"
                          onClick={() =>
                            form.setValues({
                              ...form.values,
                              specifyCounselor: true,
                              autoSelect: false,
                              sendToWebhook: false,
                            })
                          }>
                          Send to a specific person or department
                        </Label>
                        <EmailAutoSuggest
                          name="counselorId"
                          onBlur={form.handleBlur}
                          onChange={option => {
                            if (
                              option &&
                              !Array.isArray(option) &&
                              option.value
                            ) {
                              form.setValues({
                                ...form.values,
                                counselorId: option.value,
                              })
                            }
                          }}
                          disabled={
                            form.values.autoSelect || form.values.sendToWebhook
                          }
                          value={form.values.counselorId}
                          error={form.errors?.counselorId}
                          touched={!!form.touched?.counselorId}
                          isValid={!form.errors?.counselorId}
                        />
                      </div>
                    </div>
                    <div className="d-flex pointer">
                      <div>
                        <Radio
                          name="autoSelect"
                          id="determine-best"
                          onChange={() => {
                            form.setValues({
                              ...form.values,
                              autoSelect: true,
                              specifyCounselor: false,
                              sendToWebhook: false,
                              counselorId: undefined,
                            })
                          }}
                          checked={form.values.autoSelect}
                          color="primary"
                          className="p-0"
                        />
                      </div>
                      <div className="pl-2">
                        <Label
                          for="determine-best"
                          className="d-flex flex-column pointer"
                          onClick={() =>
                            form.setValues({
                              ...form.values,
                              specifyCounselor: false,
                              autoSelect: true,
                              sendToWebhook: false,
                              counselorId: undefined,
                            })
                          }>
                          <span>
                            Determine best email using contact’s fields
                          </span>
                          <small className="text-muted">
                            We’ll forward to an email based on the message and
                            contact fields according to Email Forward
                            Suggestions in{' '}
                            <Link
                              rawLink={true}
                              to="/settings/escalation"
                              target="_blank">
                              Email Settings
                            </Link>
                          </small>
                        </Label>
                      </div>
                    </div>
                    {hasFeature(FeaturesType.WEBHOOKS_ESCALATION) ? (
                      <div className="d-flex align-items-center mb-2">
                        <div>
                          <Radio
                            name="sendToWebhook"
                            id="send-to-webhook"
                            onChange={() => {
                              form.setValues({
                                ...form.values,
                                sendToWebhook: true,
                                autoSelect: false,
                                specifyCounselor: false,
                              })
                            }}
                            checked={form.values.sendToWebhook}
                            color="primary"
                            className="p-0"
                          />
                        </div>
                        <div className="pl-2">
                          <Label
                            for="send-to-webhook"
                            className="d-flex flex-column mb-0">
                            <span>{`Send only to ${webhookSystemName}`}</span>
                          </Label>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="w-100 mb-3">
                    <EmailAutoSuggest
                      name="counselorId"
                      onBlur={form.handleBlur}
                      onChange={option => {
                        if (option && !Array.isArray(option) && option.value) {
                          form.setValues({
                            ...form.values,
                            counselorId: option.value,
                          })
                        }
                      }}
                      value={form.values.counselorId}
                      error={form.errors?.counselorId}
                      touched={form.touched?.counselorId}
                      isValid={!form.errors?.counselorId}
                      helpText={
                        <>
                          Want the AI to decide where to send the response,
                          depending on each contact who responds? Set up Email
                          Forwarding Suggestions in{' '}
                          <Link to="/settings/escalation">Email Settings</Link>
                        </>
                      }
                    />
                  </div>
                )}
                {!form.values.sendToWebhook ? (
                  <small>
                    <FormGroup>
                      <Label className="mb-1">Subject</Label>
                      <Input
                        error={form.errors?.subject}
                        name="subject"
                        value={form.values.subject}
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        placeholder="Subject line to the email"
                        invalid={
                          !!form.touched?.subject && !!form.errors?.subject
                        }
                      />
                      <FormFeedback>{form.errors?.subject}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label className="mb-1">Note (optional)</Label>
                      <Input
                        type="textarea"
                        name="note"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.note}
                        invalid={!!form.touched?.note && !!form.errors?.note}
                        placeholder="Explain why you are forwarding these messages"
                        className="height-100px"
                      />
                      <FormFeedback>{form.errors?.note}</FormFeedback>
                    </FormGroup>
                  </small>
                ) : null}
              </div>
              <hr className="w-100 m-0" />
              <div className="px-4 py-3">
                <Button
                  className="mr-2 btn btn-primary bg-secondary-teal"
                  disabled={Object.keys(form.errors).length > 0}
                  type="submit">
                  Save
                </Button>
                <Button
                  onClick={onCancel}
                  className="text-blue-grey-501 text-decoration-none"
                  color="link">
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </OutsideClickHandler>
      )}
    </Formik>
  )
}
