export class VirtualReference {
  private top: number
  private left: number
  private bottom: number
  private right: number
  private width: number
  private heigth: number

  constructor({ top, left, bottom, right, width, height }: ClientRect) {
    this.top = top
    this.left = left
    this.bottom = bottom
    this.right = right
    this.width = width
    this.heigth = height
  }

  getBoundingClientRect() {
    return {
      top: this.top,
      left: this.left,
      bottom: this.bottom,
      right: this.right,
      width: this.width,
      height: this.heigth,
    }
  }

  get clientWidth() {
    return this.width
  }

  get clientHeight() {
    return this.getBoundingClientRect().height
  }
}
