import React from 'react'
import cls from 'classnames'
import { push } from 'connected-react-router'
import { FilterIcon } from 'components/Icons/FilterIcon/FilterIcon'
import { SearchInput } from 'components/SearchInput/SearchInput'
import {
  parseFirstQueryParam,
  parseQueryString,
  queryParamToArray,
  updateQueryString,
} from 'util/string'
import { format } from 'date-fns'

import { useDispatch, useSelector } from 'util/hooks'
import debounce from 'lodash/debounce'
import { KnowledgeReviewFilterModal } from 'page/knowledge-base/KnowledgeReview/KnowledgeReviewFilter/KnowledgeReviewFilterModal/KnowledgeReviewFilterModal'
import { KnowledgeBaseReviewItemsFiltersType } from 'page/knowledge-base/KnowledgeReview/reducer'
import {
  getKnowledgeBaseReviewItems,
  getKnowledgeBaseDropDownItems,
} from 'page/knowledge-base/KnowledgeReview/selectors'
import {
  resetCheckboxList,
  showKBReviewItemsFilterModal,
  knowledgeBaseRemoveDropdownFilter,
  knowledgeBaseClearDropdownFilter,
} from 'page/knowledge-base/KnowledgeReview/actions'

import { notUndefined } from 'util/typeguards'

import { Chip } from 'components/Chip/Chip'
import { useLocation } from 'react-router'
import { KB_REVIEW_ITEMS_PER_PAGE } from 'page/knowledge-base/KnowledgeReview/KnowledgeReview'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { Button } from 'components/Button/Button'

type FilterChip = {
  readonly id: string
  readonly key: string
  readonly label: string
}

const PAGINATION_URL_PARAMS = ['limit', 'page', 'reviewed']

function hasFilters(urlQuery: string) {
  const filters = parseQueryString(urlQuery)
  return !isEmpty(omit(filters, PAGINATION_URL_PARAMS))
}

export const KBReviewItemsFilterParams = {
  Twilio: 'twilio',
  WebChat: 'web',
  Facebook: 'facebook',
  Slack: 'slack',
  DateFrom: 'dateFrom',
  DateTo: 'dateTo',
  Search: 'search',
  Transports: 'transports',
  Reviewed: 'reviewed',
  Page: 'page',
  Limit: 'limit',
  Audience: 'audience',
}

const SEARCH_DEBOUNCE_MS = 300

const KnowledgeReviewFilter = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { audiences } = useSelector(getKnowledgeBaseDropDownItems)
  const hasFiltersSet = hasFilters(location.search)
  const { showFilterModal } = useSelector(getKnowledgeBaseReviewItems)
  const [filterChips, setFilterChips] = React.useState<FilterChip[]>([])

  React.useEffect(() => {
    const urlParams = parseQueryString(location.search)

    const transports =
      queryParamToArray(urlParams[KBReviewItemsFilterParams.Transports]) || []
    const selectedFilters: FilterChip[] = []

    if (urlParams[KBReviewItemsFilterParams.Reviewed] === '1') {
      selectedFilters.push({
        id: KBReviewItemsFilterParams.Reviewed,
        key: 'reviewed',
        label: 'Marked as Done',
      })
    }
    if (audiences) {
      audiences.forEach(audience => {
        if (!audience) {
          return
        }
        selectedFilters.push({
          key: KBReviewItemsFilterParams.Audience,
          label: audience.label,
          id: audience.value,
        })
      })
    }

    if (transports.includes(KBReviewItemsFilterParams.Slack)) {
      selectedFilters.push({
        id: KBReviewItemsFilterParams.Slack,
        key: 'transport',
        label: 'Slack',
      })
    }
    if (transports.includes(KBReviewItemsFilterParams.WebChat)) {
      selectedFilters.push({
        id: KBReviewItemsFilterParams.WebChat,
        key: 'transport',
        label: 'Web',
      })
    }
    if (transports.includes(KBReviewItemsFilterParams.Twilio)) {
      selectedFilters.push({
        id: KBReviewItemsFilterParams.Twilio,
        key: 'transport',
        label: 'Twilio',
      })
    }
    if (transports.includes(KBReviewItemsFilterParams.Facebook)) {
      selectedFilters.push({
        id: KBReviewItemsFilterParams.Facebook,
        key: 'transport',
        label: 'Facebook',
      })
    }
    if (
      urlParams[KBReviewItemsFilterParams.DateFrom] &&
      urlParams[KBReviewItemsFilterParams.DateTo]
    ) {
      selectedFilters.push({
        id: `${KBReviewItemsFilterParams.DateFrom}-${KBReviewItemsFilterParams.DateTo}`,
        key: `${KBReviewItemsFilterParams.DateFrom}-${KBReviewItemsFilterParams.DateTo}`,
        label: `${moment(urlParams[KBReviewItemsFilterParams.DateFrom]).format(
          'MMMM Do, YYYY'
        )} -
          ${moment(urlParams[KBReviewItemsFilterParams.DateTo]).format(
            'MMMM Do, YYYY'
          )}`,
      })
    }
    dispatch(resetCheckboxList())

    setFilterChips(selectedFilters)
  }, [location.search, setFilterChips, audiences, dispatch])
  const handleQueryChange = debounce((value: string) => {
    const newSearch = updateQueryString(
      {
        [KBReviewItemsFilterParams.Search]: value,
        [KBReviewItemsFilterParams.Page]: 1,
      },
      location.search
    )
    dispatch(resetCheckboxList())
    dispatch(push({ search: newSearch }))
  }, SEARCH_DEBOUNCE_MS)

  const handleApplyFilter = (
    appliedFilters: KnowledgeBaseReviewItemsFiltersType
  ) => {
    const urlParams = parseQueryString(location.search)
    const transports = []
    if (appliedFilters.filterByFacebook) {
      transports.push(KBReviewItemsFilterParams.Facebook)
    }
    if (appliedFilters.filterBySMS) {
      transports.push(KBReviewItemsFilterParams.Twilio)
    }
    if (appliedFilters.filterByWebChat) {
      transports.push(KBReviewItemsFilterParams.WebChat)
    }
    if (appliedFilters.filterBySlack) {
      transports.push(KBReviewItemsFilterParams.Slack)
    }

    const newSearch = updateQueryString(
      {
        [KBReviewItemsFilterParams.Transports]: transports || [],
        [KBReviewItemsFilterParams.Search]:
          urlParams[KBReviewItemsFilterParams.Search],
        [KBReviewItemsFilterParams.Reviewed]: appliedFilters.reviewed ? 1 : 0,
        [KBReviewItemsFilterParams.Audience]: appliedFilters.selectedAudiences
          ?.map(x => x?.value)
          .filter(notUndefined),
        [KBReviewItemsFilterParams.DateFrom]: appliedFilters.dateFrom
          ? format(appliedFilters.dateFrom, 'yyyy-MM-dd')
          : undefined,
        [KBReviewItemsFilterParams.DateTo]: appliedFilters.dateTo
          ? format(appliedFilters.dateTo, 'yyyy-MM-dd')
          : undefined,
        [KBReviewItemsFilterParams.Page]: 1,
        [KBReviewItemsFilterParams.Limit]:
          urlParams[KBReviewItemsFilterParams.Limit] ||
          KB_REVIEW_ITEMS_PER_PAGE,
      },
      location.search
    )

    dispatch(resetCheckboxList())
    dispatch(showKBReviewItemsFilterModal(false))

    dispatch(push({ search: newSearch }))
  }

  const onFilterRemove = (filterChip: FilterChip) => {
    const urlParams = parseQueryString(location.search)
    const normalized = { ...urlParams }
    if (filterChip.key === 'transport') {
      normalized[KBReviewItemsFilterParams.Transports] = queryParamToArray(
        urlParams[KBReviewItemsFilterParams.Transports]
      ).filter(p => p !== filterChip.id)
    } else if (filterChip.key === 'audience') {
      normalized[KBReviewItemsFilterParams.Audience] = queryParamToArray(
        normalized[KBReviewItemsFilterParams.Audience]
      ).filter(p => p !== filterChip.id)
    } else if (filterChip.key === KBReviewItemsFilterParams.Reviewed) {
      normalized[KBReviewItemsFilterParams.Reviewed] = '0'
    } else if (
      filterChip.key ===
      `${KBReviewItemsFilterParams.DateFrom}-${KBReviewItemsFilterParams.DateTo}`
    ) {
      normalized[KBReviewItemsFilterParams.DateFrom] = ''
      normalized[KBReviewItemsFilterParams.DateTo] = ''
      normalized[
        `${KBReviewItemsFilterParams.DateTo}-${KBReviewItemsFilterParams.DateFrom}`
      ] = ''
    } else {
      normalized[filterChip.key] = ''
    }
    if (filterChip.key === KBReviewItemsFilterParams.Audience) {
      dispatch(knowledgeBaseRemoveDropdownFilter(filterChip))
    }
    const newSearch = updateQueryString(normalized, location.search)
    setFilterChips(prev => prev.filter(f => f.id !== filterChip.id))
    dispatch(resetCheckboxList())
    dispatch(push({ search: newSearch }))
  }

  const onClearFilters = () => {
    const newSearch = updateQueryString(
      {
        [KBReviewItemsFilterParams.Reviewed]: 0,
        [KBReviewItemsFilterParams.Page]: 1,
        [KBReviewItemsFilterParams.Limit]: KB_REVIEW_ITEMS_PER_PAGE,
        [KBReviewItemsFilterParams.Search]: '',
      },
      ''
    )
    dispatch(knowledgeBaseClearDropdownFilter())

    setFilterChips([])
    dispatch(resetCheckboxList())
    dispatch(push({ search: newSearch }))
  }

  return (
    <div className="d-flex flex-column">
      {showFilterModal && (
        <KnowledgeReviewFilterModal onApply={handleApplyFilter} />
      )}
      <div className="d-flex flex-row">
        <div className="w-360 mb-2rem">
          <SearchInput
            defaultValue={
              parseFirstQueryParam(
                parseQueryString(location.search)[
                  KBReviewItemsFilterParams.Search
                ]
              ) || ''
            }
            onClear={() => {
              const newSearch = updateQueryString(
                {
                  [KBReviewItemsFilterParams.Search]: '',
                  [KBReviewItemsFilterParams.Page]: 1,
                },
                location.search
              )
              dispatch(push({ search: newSearch }))
            }}
            placeholder="Search"
            onChange={e => handleQueryChange(e.target.value)}
          />
        </div>
        <div className="pl-1">
          <button
            className={cls('btn', {
              'bg-blue-grey-005': !hasFiltersSet,
              'bg-blue-grey-035': hasFiltersSet,
            })}
            onClick={() => dispatch(showKBReviewItemsFilterModal(true))}>
            <FilterIcon />
          </button>
        </div>
      </div>
      <div className="pt-2">
        {filterChips.map(filterChip => {
          return (
            <Chip
              key={filterChip.id}
              label={filterChip.label}
              type="clear"
              onClear={() => onFilterRemove(filterChip)}
              className="mr-2"
            />
          )
        })}
        {filterChips.length > 0 && (
          <Button
            className="ml-1 btn btn-class"
            onClick={onClearFilters}
            color="link">
            Clear Filters
          </Button>
        )}
      </div>
    </div>
  )
}

export default KnowledgeReviewFilter
