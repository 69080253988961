import { getReportPreferences } from 'api'
import { Http2ErrorUnion } from 'api/http'
import { Button } from 'components/Button/Button'
import { CampaignPreviewCard } from 'components/CampaignPreviewCard/CampaignPreviewCard'
import { MainstayModal, ModalSize } from 'components/Modal/Modal'

import { RadioGroup } from 'components/RadioGroup/RadioGroup'
import { ReadOnlyDatePicker } from 'components/ReadOnlyDatePicker/ReadOnlyDatePicker'
import TabbedView from 'components/TabbedView/TabbedView'
import {
  CustomCampaignReportDownloadModal,
  generateReportFileName,
  IPreferencesRequestPayload,
  IReportPreference,
} from 'components/TrendsV2/CustomizedDownloadModal'
import { INTRO_DIALOGS_COPYV2 } from 'const/copy'
import { Field, FieldProps, Formik, FormikProps } from 'formik'
import { Either } from 'fp-ts/lib/Either'
import { round } from 'lodash'
import moment from 'moment-timezone'
import {
  CampaignContainer,
  useGetCampaignSidebarLinks,
} from 'page/campaign/CampaignHistory'
import {
  CampaignTag,
  LoadingCampaign,
  OverviewRow,
} from 'page/CampaignDetailsPage'
import pluralize from 'pluralize'
import React, { useEffect } from 'react'
import 'react-dates/lib/css/_datepicker.css'
import { IIntroDialog, IntroDialogKind } from 'store/campaign-scripts/reducer'
import {
  getAreIntroDialogsLoading,
  getSelectedDialogReportProgress,
  getSelectedIntroDialog,
} from 'store/campaign-scripts/selectors'
import {
  fetchIntroDialogDetailsAsync,
  generateDialogReportAsync,
} from 'store/campaign-scripts/thunks'
import { useDispatch, useSelector } from 'util/hooks'
import { noOp } from 'util/noOp'
import * as yup from 'yup'

export interface IDateRangeReportDownload {
  range: 'dates' | 'allTime'
  startDate: null | Date
  endDate: null | Date
}

const NO_FILTER = undefined

const downloadFormSchema = yup.object().shape({
  range: yup
    .string()
    .matches(/(dates|allTime)/)
    .required(),
  startDate: yup.mixed().when('range', {
    is: 'dates',
    then: yup.mixed().required(),
    otherwise: yup.mixed(),
  }),
  endDate: yup.mixed().when('range', {
    is: 'dates',
    then: yup.mixed().required(),
    otherwise: yup.mixed(),
  }),
})

interface IIntroDialogDownloadModalProps {
  onClose: () => void
  initialDateRangeValues: IDateRangeReportDownload
  onSubmitDateRangeValues: (values: IDateRangeReportDownload) => void
  submitText?: string
}

export const DateRangeDownloadModal = ({
  onClose,
  initialDateRangeValues,
  onSubmitDateRangeValues,
  submitText,
}: IIntroDialogDownloadModalProps) => {
  return (
    <Formik<IDateRangeReportDownload>
      validationSchema={downloadFormSchema}
      isInitialValid={true}
      initialValues={initialDateRangeValues}
      onSubmit={onSubmitDateRangeValues}
      render={(props: FormikProps<IDateRangeReportDownload>) => {
        return (
          <MainstayModal
            wrapContentInForm
            text="Date Range"
            size={ModalSize.Large}
            show={true}
            onSubmit={() => onSubmitDateRangeValues(props.values)}
            onClose={onClose}
            disableSubmit={
              props.values.range === 'dates' &&
              !(props.values.startDate && props.values.endDate)
            }
            submitText={submitText || 'Next'}
            cancelText="Cancel"
            submitTrackingEvent={{
              location: 'campaigns',
              action: 'click',
              object: 'introductory download report modal next',
            }}
            cancelTrackingEvent={{
              location: 'campaigns',
              action: 'click',
              object: 'introductory download report modal cancel',
            }}>
            <Field
              name="range"
              render={({ field }: FieldProps<IDateRangeReportDownload>) => {
                return (
                  <div>
                    <div className="mb-1">
                      Include campaign data for the following span of time:
                    </div>
                    <RadioGroup
                      className="d-flex flex-column"
                      inline={true}
                      selectedValue={props.values.range}
                      onChange={e => {
                        props.setFieldValue('range', e.target.value)
                      }}
                      items={[
                        {
                          name: field.name,
                          id: 'allTime',
                          value: 'allTime',
                          label: 'All time',
                          checked: props.values.range === 'allTime',
                          trackingEvent: {
                            location: 'campaigns',
                            action: 'click',
                            object:
                              'introductory download report modal all time',
                          },
                        },
                        {
                          name: field.name,
                          id: 'dates',
                          value: 'dates',
                          label: 'Select a date range',
                          checked: props.values.range === 'dates',
                          trackingEvent: {
                            location: 'campaigns',
                            action: 'click',
                            object:
                              'introductory download report modal date range',
                          },
                        },
                      ]}
                    />
                  </div>
                )
              }}
            />
            {props.values.range === 'dates' && (
              <div className="w-100 d-flex align-items-center pt-2 pb-4 px-4">
                <div className="d-flex flex-column mr-3">
                  <div className="mainstay-body-caption mb-2 text-mainstay-dark-blue-80">
                    Start Date
                  </div>
                  <ReadOnlyDatePicker
                    eventLocation="campaigns"
                    eventObject="introductory download report modal start date"
                    value={props.values.startDate ?? undefined}
                    onDayChange={startDate => {
                      props.setValues({ ...props.values, startDate })
                    }}
                    dayPickerProps={{
                      disabledDays: {
                        after: moment()
                          .startOf('day')
                          .toDate(),
                      },
                    }}
                    classNames={{
                      container: 'DayPickerInput-Container mr-1',
                      overlayWrapper: 'DayPickerInput-OverlayWrapper',
                      overlay: 'DayPickerInput-Overlay',
                    }}
                  />
                </div>
                <div className="d-flex flex-column">
                  <div className="mainstay-body-caption mb-2 text-mainstay-dark-blue-80">
                    End Date
                  </div>
                  <ReadOnlyDatePicker
                    eventLocation="campaigns"
                    eventObject="introductory download report modal end date"
                    value={props.values.endDate ?? undefined}
                    onDayChange={endDate => {
                      props.setValues({ ...props.values, endDate })
                    }}
                    dayPickerProps={{
                      disabledDays: {
                        before: props.values.startDate || undefined,
                        after: moment()
                          .startOf('day')
                          .toDate(),
                      },
                    }}
                    classNames={{
                      container: 'DayPickerInput-Container mr-1',
                      overlayWrapper: 'DayPickerInput-OverlayWrapper',
                      overlay: 'DayPickerInput-Overlay',
                    }}
                  />
                </div>
              </div>
            )}
          </MainstayModal>
        )
      }}
    />
  )
}

const DialogTitle = ({
  kind,
  title,
}: {
  kind: IntroDialogKind
  title: string
}) => {
  return (
    <div className="pt-4">
      <div className="d-flex align-items-center">
        <h3 className="text-mainstay-dark-blue mb-0 mr-2">{title}</h3>{' '}
        <CampaignTag className="mt-1" isIntroductory={true} />
      </div>
      <span className="fs-20px">{INTRO_DIALOGS_COPYV2.description[kind]}</span>
    </div>
  )
}

interface IIntroCampaignOverviewProps {
  kind: IntroDialogKind
  dialog: IIntroDialog
  dialogName: string
}
const IntroDialogOverview = ({
  kind,
  dialog,
  dialogName,
}: IIntroCampaignOverviewProps) => {
  const expirationDays = round(dialog.expirationLength / 60 / 24, 2)
  return (
    <div className="overview-container border-bottom py-4">
      <div className="text-mainstay-dark-blue flex-100 mb-4"> Overview </div>
      <OverviewRow
        field="Recipients"
        value={INTRO_DIALOGS_COPYV2.recipients[kind]}
      />
      <OverviewRow
        field="Type"
        value={dialog.isRespondable ? 'Interactive' : 'Nudge'}
      />
      <OverviewRow field="Script" value={dialogName} />
      <OverviewRow field="Trigger" value={INTRO_DIALOGS_COPYV2.trigger[kind]} />
      <OverviewRow field="Sent" value={dialog.messagedUsers} />
      <OverviewRow
        field="Duration"
        value={`${expirationDays} ${pluralize('day', expirationDays)}`}
      />
      {dialog.isRespondable && (
        <OverviewRow
          field="Responded"
          value={`${round(
            (dialog.distinctResponses / dialog.messagedUsers) * 100,
            2
          )}%`}
        />
      )}
    </div>
  )
}

const IntroDialogDetailsPage = ({ kind }: { kind: IntroDialogKind }) => {
  const dispatch = useDispatch()

  const loading = useSelector(getAreIntroDialogsLoading)
  const dialog = useSelector(getSelectedIntroDialog)

  const downloadProgress = useSelector(getSelectedDialogReportProgress)

  useEffect(() => {
    fetchIntroDialogDetailsAsync(dispatch)(kind)
  }, [dispatch, kind])

  const [showDownloadModal, setShowDownloadModal] = React.useState<boolean>(
    false
  )

  const getPreferenceData = React.useCallback(() => {
    return getReportPreferences({
      insight: 'dialog_report',
      dialogId: dialog?.id,
    })
  }, [dialog?.id])

  const onReportDownloadComplete = () => setShowDownloadModal(false)

  const handleSubmitSelectedFields = (
    preferences: IPreferencesRequestPayload
  ) => {
    if (dialog) {
      const { range, startDate, endDate } = preferences
      const useDates = range === 'dates'
      generateDialogReportAsync(
        dialog.id,
        useDates && startDate ? startDate : null,
        useDates && endDate ? endDate : null,
        null,
        preferences,
        'dialog_report',
        onReportDownloadComplete
      )(dispatch)
    }
  }

  const links = useGetCampaignSidebarLinks({
    campaignSidebarFilter: NO_FILTER,
    navigateBackToListView: false,
  })

  if (loading) {
    return <LoadingCampaign />
  }

  if (!dialog) {
    return null
  }

  const dialogHumanName = dialog.humanName || INTRO_DIALOGS_COPYV2.title[kind]

  return (
    <CampaignContainer links={links}>
      <>
        <DialogTitle kind={kind} title={INTRO_DIALOGS_COPYV2.title[kind]} />
        <TabbedView
          className="h-100"
          tabCollectionClassName="px-0"
          tabContentClassName="px-0"
          tabClassName="py-2 mr-3"
          options={[
            {
              label: 'Report',
              hashParam: 'report',
              component: (
                <div className="h-100">
                  <IntroDialogOverview
                    kind={kind}
                    dialog={dialog}
                    dialogName={dialogHumanName}
                  />
                  <div className="w-100 d-flex">
                    <Button
                      eventLocation="campaigns"
                      eventAction="click"
                      eventObject="introductory download report"
                      className="bg-white text-secondary-teal border-1px border-secondary-teal mt-5 ml-auto"
                      onClick={() => setShowDownloadModal(true)}>
                      Download Report
                    </Button>
                  </div>
                  {showDownloadModal && (
                    <DateRangeCustomFieldsDownload
                      isAggregate={false}
                      reportTitle={INTRO_DIALOGS_COPYV2.title[kind]}
                      initialFileName={generateReportFileName(dialogHumanName)}
                      getPreferenceData={getPreferenceData}
                      isDownloading={downloadProgress !== undefined}
                      onSubmit={handleSubmitSelectedFields}
                    />
                  )}
                </div>
              ),
            },
            {
              label: 'Script',
              hashParam: 'script',
              component: (
                <>
                  {dialog ? (
                    <CampaignPreviewCard
                      scriptId={dialog.id}
                      initialStateId={dialog.initialState}
                      workflowHumanName={dialogHumanName}
                      isArchived={!!dialog.hidden}
                      campaignReview={true}
                    />
                  ) : null}
                </>
              ),
            },
          ]}
        />
      </>
    </CampaignContainer>
  )
}

export function DateRangeCustomFieldsDownload({
  onSubmit,
  reportTitle,
  isDownloading,
  isAggregate,
  onFormError,
  getPreferenceData,
  initialFileName,
  dateRangeOnly,
  hideCampaignHistory,
  countCampaignsSent,
  onClose,
}: {
  onSubmit: (preferences: IPreferencesRequestPayload) => void
  reportTitle: string
  initialFileName: string
  hideCampaignHistory?: boolean
  isAggregate: boolean
  dateRangeOnly?: boolean
  onFormError?: () => void
  countCampaignsSent?: number
  getPreferenceData: () => Promise<Either<Http2ErrorUnion, IReportPreference>>
  isDownloading: boolean
  onClose?: () => void
}) {
  const [showDateRangeModal, setShowDateRangeModal] = React.useState<boolean>(
    true
  )
  const [showSelectFieldsModal, setShowSelectFieldsModal] = React.useState<
    boolean
  >(false)

  const initialDateRangeValues: IDateRangeReportDownload = React.useMemo(() => {
    return {
      range: 'allTime',
      startDate: null,
      endDate: null,
    }
  }, [])

  const [dateRangeValues, setDateRangeValues] = React.useState<
    IDateRangeReportDownload
  >(initialDateRangeValues)

  const submitData = (preferences: IPreferencesRequestPayload) => {
    onSubmit({ ...preferences, ...dateRangeValues })
    setShowSelectFieldsModal(false)
  }

  const advanceStep = (values: IDateRangeReportDownload) => {
    setDateRangeValues(values)
    setShowDateRangeModal(false)
    setShowSelectFieldsModal(true)
  }

  return (
    <div>
      {showDateRangeModal && (
        <DateRangeDownloadModal
          onClose={onClose ?? noOp}
          initialDateRangeValues={dateRangeValues}
          onSubmitDateRangeValues={advanceStep}
        />
      )}
      {!dateRangeOnly && showSelectFieldsModal && (
        <CustomCampaignReportDownloadModal
          eventLocation="campaigns"
          eventObject="introductory"
          hideCampaignHistory={hideCampaignHistory}
          show={showSelectFieldsModal}
          initialFileName={initialFileName}
          getPreferenceData={getPreferenceData}
          reportTitle={reportTitle}
          onClose={onClose ?? noOp}
          onSubmit={submitData}
          isAggregate={isAggregate}
          submissionInProgress={isDownloading}
          onFormError={onFormError}
          countCampaignsSent={countCampaignsSent}
        />
      )}
    </div>
  )
}

export default IntroDialogDetailsPage
