import React from 'react'
import { MainstayDrawer } from 'components/MainstayDrawer/MainstayDrawer'
import { MainstayDrawerFooter } from 'components/MainstayDrawerFooter/MainstayDrawerFooter'
import { ConversationMessageLink } from 'page/knowledge-base/KnowledgeReview/KnowledgeReviewTable/KnowledgeReviewTableRow/KnowledgeReviewTableRow'
import { ContainerButton } from 'components/ContainerButton/ContainerButton'
import * as api from 'api'
import { KnowledgeSeedEditPanelContent } from 'components/KnowledgeSeedEditPanel/KnowledgeSeedEditPanelContent'
import {
  KnowledgeReviewReason,
  knowledgeReviewReasonToEnum,
} from 'page/conversations-v2/ConversationsDetail/'
import { Button } from 'components/Button/Button'
import { isLeft } from 'fp-ts/Either'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import MarkForKnowledgeReviewNotesModal from 'page/conversations-v2/ConversationsDetail/MessageRow/MarkForKnowledgeReviewNotesModal/MarkForKnowledgeReviewNotesModal'
import { Spinner } from 'components/Spinner/Spinner'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { SearchUnderstandings } from 'page/knowledge-base/KnowledgeReview/AllMessagesDrawer/SearchUnderstandings/SearchUnderstandings'
import { UnderstandingCreateContent } from 'components/UnderstandingCreatePanel/UnderstandingCreatePanel'
import { KnowledgeDrawerHeader } from 'components/KnowledgeSeedEditPanel/KnowledgeSeedEditPanelClone'
import { RDK } from 'store/webdata'
import { CenteredLoader } from 'components/Loader/Loader'
import { useGetAttributeMapping } from 'components/DraftEditor/DraftEditor'

export enum DrawerStates {
  matched_understanding = 'matched_understanding',
  search_understandings = 'search_understandings',
  create_new_understanding = 'create_new_understanding',
  edit_understanding = 'edit_understanding',
}

/**
 * @param markAsReviewed - Mark the knowledge review item as reviewed. Returns true if it succeeded.
 */
export function AllMessagesDrawer({
  reviewItem,
  open,
  onClose,
  markAsReviewed,
  initialDrawerState = DrawerStates.matched_understanding,
  initialEditUnderstandingId,
}: {
  readonly reviewItem: api.KnowledgeBaseReviewItemShapeType
  open: boolean
  onClose: () => void
  markAsReviewed: (createdUnderstandingId?: string) => Promise<boolean>
  initialDrawerState?: DrawerStates
  initialEditUnderstandingId?: string
}): JSX.Element {
  const [savingNote, setSavingNote] = React.useState<boolean>(false)
  const [showKBReviewModal, setKBReviewModal] = React.useState(false)
  const [editUnderstandingId, setEditUnderstandingId] = React.useState<
    string | undefined
  >(initialEditUnderstandingId)

  const panelRef = React.useRef<HTMLDivElement>(null)

  const mappingResults = useGetAttributeMapping()

  /**
   * Set when the user searches for an understanding after a missed match, and then clicks the edit button.
   * We set this variable to the body of the message which was a missed match.
   * In the understanding edit panel we will show this potentially new question to add.
   * If this variable is empty it is equivilent to being unset.
   */
  const [
    editUnderstandingPotentialNewQuestion,
    setEditUnderstandingPotentialNewQuestion,
  ] = React.useState('')

  const [drawerState, setDrawerState] = React.useState<DrawerStates>(
    initialDrawerState
  )

  /**
   * Used by {@link onResolveKnowledgeReview} to show a loading state to the user.
   */
  const [markResolvedLoading, setMarkResolvedLoading] = React.useState(false)

  const onApplyKnowledgeReviewNote = async (note: string | null) => {
    if (!reviewItem) {
      return
    }
    setSavingNote(true)
    const res = await api.conversationsV2MarkForKnowledgeReview({
      messageId: reviewItem.message.id,
      mark: {
        reason: knowledgeReviewReasonToEnum(reviewItem.reason),
        note: note || null,
      },
    })
    setSavingNote(false)

    if (isLeft(res)) {
      toast.error('Failed to set note.')
      return
    }

    toast.success('Note successfully saved.')

    setKBReviewModal(prev => !prev)
  }

  /**
   * Click handler for the "Mark Resolved" button in the footer.
   */
  const onResolveKnowledgeReview = React.useCallback(
    async (createdUnderstandingId?: string) => {
      setMarkResolvedLoading(true)

      const success = await markAsReviewed(createdUnderstandingId)

      setMarkResolvedLoading(false)

      // Only if user has not just created an Understanding in response
      // to review should the drawer close. Otherwise, leave it open so
      // we can render the created Understanding in Edit mode.
      if (success && !createdUnderstandingId) {
        onClose()
      }
    },
    [setMarkResolvedLoading, markAsReviewed, onClose]
  )

  const handleSelectSimilarUnderstanding = (understandingId: string) => {
    setEditUnderstandingId(understandingId)
    setDrawerState(DrawerStates.edit_understanding)
  }

  const renderDrawerHeader = React.useCallback(() => {
    return (
      <KnowledgeDrawerHeader
        title={`“${reviewItem.message.body}”`}
        titleClassName="ml-3 matched-understanding-title truncate-title"
        onClose={onClose}
        knowledgeMatchDrawer={true}
        conversationMessageLink={
          <ConversationMessageLink reviewItem={reviewItem} />
        }
      />
    )
  }, [reviewItem, onClose])

  const renderDrawerContent = React.useCallback(() => {
    if (mappingResults?.kind !== RDK.Success) {
      return <CenteredLoader />
    }
    switch (drawerState) {
      case DrawerStates.matched_understanding:
        return (
          <>
            <h4 className="px-4 pb-2 pt-4">Matched Understanding</h4>
            {reviewItem.understandingId ? (
              <KnowledgeSeedEditPanelContent
                understandingId={reviewItem.understandingId}
                onClose={onClose}
                panelRef={panelRef}
                matchedQuestion={reviewItem.matchedQuestion}
                contentClassName="px-4"
              />
            ) : (
              <div className="mainstay-body-paragraph px-4">None</div>
            )}
          </>
        )
      case DrawerStates.search_understandings:
        return (
          <div className="px-3 pt-3">
            {reviewItem.reason !== KnowledgeReviewReason.BotCouldNotAnswer && (
              <ContainerButton
                className="text-secondary-teal"
                onClick={() => {
                  setDrawerState(DrawerStates.matched_understanding)
                }}>
                <div className="d-flex align-items-center">
                  <AHIcon name="arrow_back" />
                  <span>Back to Matched Understanding</span>
                </div>
              </ContainerButton>
            )}

            <h4 className="px-2 pb-1 pt-3">Find a similar existing question</h4>

            <div className="px-1">
              <SearchUnderstandings
                onEdit={(understandingId: string) => {
                  setEditUnderstandingId(understandingId)
                  setEditUnderstandingPotentialNewQuestion(
                    reviewItem.message.body
                  )
                  setDrawerState(DrawerStates.edit_understanding)
                }}
                defaultSearch={reviewItem.message.body}
              />
            </div>
          </div>
        )
      case DrawerStates.edit_understanding:
        return (
          <div className="px-3 pt-3">
            {reviewItem.reason !== KnowledgeReviewReason.BotCouldNotAnswer && (
              <ContainerButton
                className="text-secondary-teal"
                onClick={() => {
                  setDrawerState(DrawerStates.search_understandings)
                }}>
                <div className="d-flex align-items-center move-back-arrow-down">
                  <AHIcon name="arrow_back" />
                  <span>Back to Find Question</span>
                </div>
              </ContainerButton>
            )}

            <h4 className="px-2 pb-1 pt-3">Edit Understanding</h4>

            {editUnderstandingId && (
              <KnowledgeSeedEditPanelContent
                understandingId={editUnderstandingId}
                potentialNewQuestion={
                  editUnderstandingPotentialNewQuestion.length > 0
                    ? editUnderstandingPotentialNewQuestion
                    : undefined
                }
                onClose={onClose}
                panelRef={panelRef}
                matchedQuestion={reviewItem.matchedQuestion}
              />
            )}
          </div>
        )
      case DrawerStates.create_new_understanding:
        return (
          <div className="px-3 pt-3">
            <ContainerButton
              className="text-secondary-teal"
              onClick={() => {
                setDrawerState(DrawerStates.search_understandings)
              }}>
              <div className="d-flex align-items-center move-back-arrow-down">
                <AHIcon name="arrow_back" />
                <span>Back to Find Question</span>
              </div>
            </ContainerButton>

            <h4 className="px-2 pb-1 pt-3">Create New Understanding</h4>

            <UnderstandingCreateContent
              defaultSummaryQuestion={reviewItem.message.body}
              onCreate={({ id }: { id: string }) => {
                setEditUnderstandingId(id)
                setDrawerState(DrawerStates.edit_understanding)
                onResolveKnowledgeReview(id)
              }}
              onSelectSimilarUnderstanding={(id: string) =>
                handleSelectSimilarUnderstanding(id)
              }
            />
          </div>
        )
    }
  }, [
    drawerState,
    editUnderstandingId,
    editUnderstandingPotentialNewQuestion,
    onClose,
    reviewItem,
    onResolveKnowledgeReview,
    mappingResults?.kind,
  ])

  const renderDrawerFooter = React.useCallback(() => {
    switch (drawerState) {
      case DrawerStates.matched_understanding:
        return (
          <>
            <div className="mb-2 mainstay-header-h5">
              Incorrect question match?
            </div>
            <Button
              color="primary"
              loadingText="Loading..."
              onClick={() => {
                setDrawerState(DrawerStates.search_understandings)
              }}>
              Find Better Match
            </Button>
          </>
        )
      case DrawerStates.search_understandings:
        return (
          <>
            <div className="mb-2 mainstay-header-h5">No good matches?</div>
            <Button
              color="primary"
              loadingText="Loading..."
              onClick={() => {
                setDrawerState(DrawerStates.create_new_understanding)
              }}>
              Create New Knowledge
            </Button>
          </>
        )
      case DrawerStates.edit_understanding:
        return (
          <>
            <div className="mb-2 mainstay-header-h5">
              Finished with maintenance?
            </div>
            <Button
              color="primary"
              loadingText="Loading..."
              onClick={() => onResolveKnowledgeReview()}>
              {(!markResolvedLoading && <>Mark Resolved</>) || (
                <>
                  <Spinner size="sm" className="stroke-secondary-teal" />
                  Loading
                </>
              )}
            </Button>
          </>
        )
    }
  }, [drawerState, markResolvedLoading, onResolveKnowledgeReview])

  return (
    <MainstayDrawer
      panelClassName="mb-3rem"
      containerClassName="panel h-100 pb-4"
      open={open}
      onClose={onClose}
      showHr
      panelContentRef={panelRef}
      header={renderDrawerHeader()}>
      {renderDrawerContent()}
      {drawerState !== DrawerStates.create_new_understanding ? (
        <MainstayDrawerFooter>{renderDrawerFooter()}</MainstayDrawerFooter>
      ) : null}
      {showKBReviewModal && reviewItem && reviewItem.reason && (
        <MarkForKnowledgeReviewNotesModal
          isLoading={savingNote}
          onSubmit={onApplyKnowledgeReviewNote}
          isOpen={showKBReviewModal}
          toggle={() => setKBReviewModal(prev => !prev)}
          note={reviewItem.note || ''}
        />
      )}
    </MainstayDrawer>
  )
}
