import React from 'react'
import { SimpleModalHeader } from 'components/SimpleModalHeader/SimpleModalHeader'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from 'components/Button/Button'
import { ReadOnlyDatePicker } from 'components/ReadOnlyDatePicker/ReadOnlyDatePicker'
import { format } from 'date-fns'
import { QUERY_PARAM_DATE_FORMAT } from 'components/FilterContactsModal/FilterContactsModal'
import { removeQueryFilter } from 'util/queryFilters'
import { useHistory } from 'react-router'

const eventLocation = 'campaign-history-filter'

interface ICampaignHistoryFilterModalProps {
  show: boolean
  onClose: () => void
  setStartDate: (start: string | undefined) => void
  setEndDate: (end: string | undefined) => void
  startDate?: string
  endDate?: string
}

export function CampaignHistoryFilterModal({
  show,
  onClose,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
}: ICampaignHistoryFilterModalProps) {
  const [startDateLocal, setStartDateLocal] = React.useState<Date | undefined>()
  const [endDateLocal, setEndDateLocal] = React.useState<Date | undefined>()
  const history = useHistory()

  React.useEffect(() => {
    // Ensure dates are treated as UTC via adding + "T00:00:00"
    if (startDate) {
      setStartDateLocal(new Date(startDate + 'T00:00:00'))
    } else {
      setStartDateLocal(undefined)
    }
    if (endDate) {
      setEndDateLocal(new Date(endDate + 'T00:00:00'))
    } else {
      setEndDateLocal(undefined)
    }
  }, [startDate, endDate, setStartDateLocal, setEndDateLocal])

  const handleSubmit = React.useCallback(() => {
    if (startDateLocal) {
      setStartDate(format(startDateLocal, QUERY_PARAM_DATE_FORMAT))
    } else {
      setStartDate(undefined)
    }
    if (endDateLocal) {
      setEndDate(format(endDateLocal, QUERY_PARAM_DATE_FORMAT))
    } else {
      setEndDate(undefined)
    }
    // Clear out page filter
    history.replace(removeQueryFilter(window.location, 'page'))
    onClose()
  }, [startDateLocal, endDateLocal, setStartDate, setEndDate, onClose, history])

  const reset = React.useCallback(() => {
    setStartDateLocal(undefined)
    setEndDateLocal(undefined)
  }, [setStartDateLocal, setEndDateLocal])

  return (
    <Modal isOpen={show} size="md">
      <div className="text-mainstay-dark-blue-80 h-100">
        <SimpleModalHeader
          cancelTrackingEvent={{
            location: eventLocation,
            action: 'click',
            object: 'close filter',
          }}
          onClose={onClose}
          text="Filters"
        />
        <ModalBody className="pb-4">
          <h6 className="pb-1">Time range</h6>
          <div className="d-flex">
            <span className="mr-2 d-flex align-items-center">From</span>
            <ReadOnlyDatePicker
              value={startDateLocal}
              onDayChange={(date: Date) => {
                setStartDateLocal(date)
              }}
              dayPickerProps={{
                disabledDays: endDateLocal && {
                  after: endDateLocal,
                },
              }}
              classNames={{
                container: 'DayPickerInput-Container',
                overlayWrapper: 'DayPickerInput-OverlayWrapper',
                overlay: 'DayPickerInput-Overlay',
                maxWidth: '9.75em',
              }}
              eventLocation={eventLocation}
              eventObject="from date"
            />
            <span className="mx-2 d-flex align-items-center">To</span>
            <ReadOnlyDatePicker
              value={endDateLocal}
              onDayChange={(date: Date) => {
                setEndDateLocal(date)
              }}
              dayPickerProps={{
                disabledDays: startDateLocal && {
                  before: startDateLocal,
                },
              }}
              classNames={{
                container: 'DayPickerInput-Container',
                overlayWrapper: 'DayPickerInput-OverlayWrapper',
                overlay: 'DayPickerInput-Overlay',
                maxWidth: '9.75em',
              }}
              eventLocation={eventLocation}
              eventObject="to date"
            />
          </div>
        </ModalBody>
      </div>
      <ModalFooter className="d-flex justify-content-between">
        <Button
          type="button"
          onClick={reset}
          eventAction="click"
          eventLocation={eventLocation}
          className="text-mainstay-dark-blue-65 bg-white"
          eventObject="clear filter">
          Reset Filter
        </Button>
        <div>
          <Button
            className="btn btn-secondary-teal px-3 py-2"
            onClick={handleSubmit}
            eventAction="click"
            eventLocation={eventLocation}
            eventObject="apply filter">
            Apply Filter
          </Button>
          <Button
            className="ml-2 btn text-mainstay-dark-blue-65 border-color-mainstay-dark-blue-65 border-1px bg-white px-3 py-2"
            eventAction="click"
            onClick={onClose}
            eventLocation={eventLocation}
            eventObject="close filter">
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
