import classNames from 'classnames'
import { ContainerButton } from 'components/ContainerButton/ContainerButton'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'

import 'components/HorizontalPaginationBar/HorizontalPaginationBar.scss'

export interface IHorizontalPaginationOption {
  readonly label: string
  readonly value: string
}

function getPrevOption({
  options,
  selectedOptionIndex,
  wrapOptions,
}: {
  readonly options: IHorizontalPaginationOption[]
  readonly selectedOptionIndex: number | null
  readonly wrapOptions: boolean
}): IHorizontalPaginationOption | null {
  if (selectedOptionIndex !== null && selectedOptionIndex > 0) {
    // If can get the previous option from array, return previous option
    return options[selectedOptionIndex - 1]
  } else if (wrapOptions === true) {
    // If wrapping then wrap to end of options
    return options[options.length - 1]
  } else {
    // If not wrapping then disable button
    return null
  }
}

function getNextOption({
  options,
  selectedOptionIndex,
  wrapOptions,
}: {
  readonly options: IHorizontalPaginationOption[]
  readonly selectedOptionIndex: number | null
  readonly wrapOptions: boolean
}): IHorizontalPaginationOption | null {
  if (
    selectedOptionIndex !== null &&
    selectedOptionIndex + 1 <= options.length - 1
  ) {
    // If can get next option from array, return next option
    return options[selectedOptionIndex + 1]
  } else if (wrapOptions === true) {
    // If wrapping then wrap back to the start of the options
    return options[0]
  } else {
    // If not wrapping then disable the button
    return null
  }
}

/**
 * @prop wrapOptions If true when the user reaches the last option they will wrap around to the first option and vice versa
 */
export const HorizontalPaginationBar = ({
  keyPrefix,
  selected,
  onSelect,
  options,
  showNumberOptionsBefore = 1,
  showNumberOptionsAfter = 4,
  wrapOptions = true,
  selectedClassName = 'color-secondary-teal horizontal-pagination-bar-selected-item',
  className = '',
}: {
  readonly keyPrefix: string
  readonly selected: string
  readonly onSelect: (value: string) => void
  readonly options: IHorizontalPaginationOption[]
  readonly showNumberOptionsBefore?: number
  readonly showNumberOptionsAfter?: number
  readonly wrapOptions?: boolean
  readonly selectedClassName?: string
  readonly className?: string
}) => {
  // Calculate which index in the options array corresponds to the selected option
  // Null if selected is not in options
  let selectedOptionIndex: null | number = null
  for (let i = 0; i < options.length; i++) {
    const option = options[i]
    if (option.value === selected) {
      selectedOptionIndex = i
      break
    }
  }

  // Calculate what the previous and next options are
  const prevOption = getPrevOption({
    options,
    selectedOptionIndex,
    wrapOptions,
  })

  const nextOption = getNextOption({
    options,
    selectedOptionIndex,
    wrapOptions,
  })

  // Calculate which options to show
  const showNumberOptionsTotal =
    showNumberOptionsBefore + showNumberOptionsAfter
  let showOptions = options.slice(0, showNumberOptionsTotal)

  if (selectedOptionIndex !== null) {
    // Try to get enough options to show after the selected
    let getNumberAfter = showNumberOptionsAfter
    if (selectedOptionIndex < showNumberOptionsBefore) {
      // We won't be able to get the total number of options from before so try to get them from after
      getNumberAfter += showNumberOptionsBefore - selectedOptionIndex
    }
    showOptions = options.slice(
      selectedOptionIndex,
      selectedOptionIndex + getNumberAfter
    )

    // Try to get the remaining options from before the selected
    if (showOptions.length < showNumberOptionsTotal) {
      const missingNum =
        selectedOptionIndex - (showNumberOptionsTotal - showOptions.length) > 0
          ? showNumberOptionsTotal - showOptions.length
          : selectedOptionIndex

      showOptions = [
        ...options.slice(selectedOptionIndex - missingNum, selectedOptionIndex),
        ...showOptions,
      ]
    }
  }

  return (
    <div className={`d-flex flex-row align-items-center ${className}`}>
      <ContainerButton
        className="d-flex mr-2"
        disabled={prevOption === null}
        onClick={() => prevOption !== null && onSelect(prevOption.value)}>
        <AHIcon name="arrow_back" />
      </ContainerButton>

      <div className="d-flex flex-row">
        {showOptions.map((option, i) => (
          <ContainerButton
            key={`${keyPrefix}-${option.value}`}
            onClick={() => onSelect(option.value)}
            className={classNames('horizontal-pagination-bar-item', {
              'color-mainstay-dark-blue': option.value !== selected,
              [selectedClassName]: option.value === selected,
              'ml-0': i === 0,
              'mr-0': i === showOptions.length - 1,
            })}>
            {option.label}
          </ContainerButton>
        ))}
      </div>

      <ContainerButton
        className="d-flex ml-2"
        disabled={nextOption === null}
        onClick={() => nextOption !== null && onSelect(nextOption.value)}>
        <AHIcon name="arrow_forward" />
      </ContainerButton>
    </div>
  )
}
