interface IBotIconProps {
  readonly className?: string
}
export function BotIcon({ className }: IBotIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.07 11V10.26C21.07 8.504 19.646 7.08 17.89 7.08H12.74V4.49C13.609 4.183 14.22 3.368 14.22 2.411C14.22 1.196 13.235 0.210999 12.02 0.210999C10.805 0.210999 9.82002 1.196 9.82002 2.411C9.82002 2.414 9.82002 2.417 9.82002 2.42C9.82402 3.368 10.424 4.175 11.265 4.485L11.28 4.49V7.07H6.15002C4.40702 7.087 3.00002 8.504 3.00002 10.25V11C1.35602 11.135 0.0720215 12.502 0.0720215 14.17C0.0720215 15.838 1.35602 17.205 2.98902 17.339L3.00002 17.34V18.08C3.00602 19.834 4.42602 21.254 6.17902 21.26H17.89C19.644 21.254 21.064 19.834 21.07 18.081V17.34C22.714 17.205 23.998 15.838 23.998 14.17C23.998 12.502 22.714 11.135 21.081 11.001L21.07 11ZM22.53 14.17C22.529 15.026 21.9 15.734 21.079 15.859L21.07 15.86V12.46C21.901 12.591 22.53 13.302 22.53 14.16V14.17ZM12.75 2.42C12.75 2.423 12.75 2.426 12.75 2.43C12.75 2.839 12.419 3.17 12.01 3.17C11.601 3.17 11.27 2.839 11.27 2.43C11.27 2.025 11.596 1.696 11.999 1.69H12C12.006 1.69 12.013 1.69 12.02 1.69C12.423 1.69 12.75 2.017 12.75 2.42ZM19.6 10.25V18.07C19.594 19.012 18.832 19.774 17.891 19.78H6.15002C5.20602 19.78 4.44002 19.014 4.44002 18.07V10.25C4.44002 10.247 4.44002 10.244 4.44002 10.24C4.44002 9.296 5.20602 8.53 6.15002 8.53H17.89C18.836 8.536 19.6 9.304 19.6 10.25ZM1.50002 14.16C1.50302 13.29 2.15102 12.573 2.99102 12.461L3.00002 12.46V15.85C2.15302 15.742 1.50402 15.027 1.50002 14.16Z"
        fill="#536D92"
      />
      <path
        d="M10.31 12.2C10.31 12.197 10.31 12.194 10.31 12.19C10.31 10.969 9.32102 9.98 8.10002 9.98C6.87902 9.98 5.89001 10.969 5.89001 12.19C5.89001 13.411 6.87902 14.4 8.10002 14.4C9.31702 14.4 10.304 13.416 10.31 12.201V12.2ZM8.84001 12.2C8.84001 12.203 8.84001 12.206 8.84001 12.21C8.84001 12.619 8.50902 12.95 8.10002 12.95C7.69102 12.95 7.36001 12.619 7.36001 12.21C7.36001 11.801 7.69102 11.47 8.10002 11.47C8.50502 11.47 8.83401 11.796 8.84001 12.199V12.2Z"
        fill="#536D92"
      />
      <path
        d="M15.93 10C14.715 10 13.73 10.985 13.73 12.2C13.73 13.415 14.715 14.4 15.93 14.4C17.145 14.4 18.13 13.415 18.13 12.2C18.124 10.987 17.143 10.006 15.931 10H15.93ZM16.66 12.2C16.66 12.603 16.333 12.93 15.93 12.93C15.527 12.93 15.2 12.603 15.2 12.2C15.2 11.797 15.527 11.47 15.93 11.47C16.331 11.476 16.654 11.799 16.66 12.199V12.2Z"
        fill="#536D92"
      />
      <path
        d="M14.52 16.74C13.875 17.121 13.098 17.347 12.268 17.347C12.181 17.347 12.094 17.344 12.008 17.34L12.02 17.341C11.949 17.345 11.865 17.347 11.781 17.347C10.948 17.347 10.168 17.122 9.49798 16.729L9.51898 16.741C9.40198 16.661 9.25698 16.614 9.10098 16.614C8.86698 16.614 8.65698 16.721 8.51998 16.89L8.51898 16.891C8.43298 17.009 8.38098 17.158 8.38098 17.318C8.38098 17.55 8.48898 17.756 8.65698 17.89L8.65898 17.891C9.54798 18.464 10.633 18.804 11.798 18.804C11.869 18.804 11.939 18.803 12.009 18.8H11.999C12.082 18.804 12.178 18.807 12.276 18.807C13.422 18.807 14.491 18.477 15.393 17.906L15.369 17.92C15.543 17.785 15.653 17.576 15.653 17.342C15.653 17.184 15.603 17.037 15.517 16.918L15.518 16.92C15.382 16.737 15.166 16.62 14.923 16.62C14.772 16.62 14.632 16.665 14.516 16.742L14.519 16.74H14.52Z"
        fill="#536D92"
      />
    </svg>
  )
}

export function BotIconFilled({ className }: IBotIconProps) {
  return (
    <svg
      className={className}
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1558_1909)">
        <path
          d="M21.9479 11.9576V11.1727C21.9479 9.31032 20.4645 7.80002 18.6354 7.80002H13.2708V5.05305C14.176 4.72745 14.8125 3.86305 14.8125 2.84806C14.8125 1.55942 13.7864 0.514725 12.5208 0.514725C11.2552 0.514725 10.2291 1.55942 10.2291 2.84806C10.2291 2.85124 10.2291 2.85442 10.2291 2.8576C10.2333 3.86305 10.8583 4.71896 11.7343 5.04775L11.75 5.05305V7.78941H6.4062C4.59058 7.80745 3.12495 9.31032 3.12495 11.1621V11.9576C1.41245 12.1008 0.0749512 13.5506 0.0749512 15.3197C0.0749512 17.0888 1.41245 18.5386 3.11349 18.6808L3.12495 18.6818V19.4667C3.1312 21.327 4.61037 22.833 6.43641 22.8394H18.6354C20.4625 22.833 21.9416 21.327 21.9479 19.4677V18.6818C23.6604 18.5386 24.9979 17.0888 24.9979 15.3197C24.9979 13.5506 23.6604 12.1008 21.9593 11.9587L21.9479 11.9576ZM23.4687 15.3197C23.4677 16.2276 22.8125 16.9785 21.9572 17.1111L21.9479 17.1121V13.5061C22.8135 13.645 23.4687 14.3991 23.4687 15.3091V15.3197ZM13.2812 2.8576C13.2812 2.86078 13.2812 2.86397 13.2812 2.86821C13.2812 3.30199 12.9364 3.65306 12.5104 3.65306C12.0843 3.65306 11.7395 3.30199 11.7395 2.86821C11.7395 2.43866 12.0791 2.08972 12.4989 2.08336H12.5C12.5062 2.08336 12.5135 2.08336 12.5208 2.08336C12.9406 2.08336 13.2812 2.43018 13.2812 2.8576ZM1.56245 15.3091C1.56558 14.3864 2.24058 13.6259 3.11558 13.5071L3.12495 13.5061V17.1015C2.24266 16.987 1.56662 16.2286 1.56245 15.3091Z"
          fill="#5F779A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 12.7909C10 13.6193 9.32843 14.2909 8.5 14.2909C7.67157 14.2909 7 13.6193 7 12.7909C7 11.9625 7.67157 11.2909 8.5 11.2909C9.32843 11.2909 10 11.9625 10 12.7909ZM15.125 18.0454C14.4531 18.4495 13.6438 18.6892 12.7792 18.6892C12.6885 18.6892 12.5979 18.686 12.5083 18.6818L12.5208 18.6828C12.4469 18.6871 12.3594 18.6892 12.2719 18.6892C11.4042 18.6892 10.5917 18.4505 9.89375 18.0337L9.91563 18.0465C9.79375 17.9616 9.64271 17.9118 9.48021 17.9118C9.23646 17.9118 9.01771 18.0252 8.875 18.2045L8.87396 18.2056C8.78438 18.3307 8.73021 18.4887 8.73021 18.6584C8.73021 18.9045 8.84271 19.123 9.01771 19.2651L9.01979 19.2662C9.94584 19.8739 11.076 20.2345 12.2896 20.2345C12.3617 20.2345 12.4328 20.2335 12.5038 20.2305C12.5892 20.2346 12.6873 20.2377 12.7875 20.2377C13.9813 20.2377 15.0948 19.8877 16.0344 19.2821L16.0094 19.2969C16.1906 19.1537 16.3052 18.9321 16.3052 18.6839C16.3052 18.5163 16.2531 18.3604 16.1635 18.2342L16.1646 18.2363C16.0229 18.0422 15.7979 17.9181 15.5448 17.9181C15.3889 17.9181 15.2442 17.965 15.124 18.0454H15.124L15.1208 18.0475L15.124 18.0454H15.125ZM15.5 14.2909C16.3284 14.2909 17 13.6193 17 12.7909C17 11.9625 16.3284 11.2909 15.5 11.2909C14.6716 11.2909 14 11.9625 14 12.7909C14 13.6193 14.6716 14.2909 15.5 14.2909Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1558_1909">
          <rect
            width="25"
            height="25.4545"
            fill="white"
            transform="translate(0 0.290894)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
