import { getCounselors } from 'api'
import { ICounselorResponseData } from 'api/response'
import { AsyncAutoSuggestProps } from 'components/AutoSuggest/AsyncAutoSuggest'
import 'components/EmailAutoSuggest/EmailAutoSuggest.scss'
import * as React from 'react'
import {
  CounselorSelect,
  ICounselorOption,
} from 'components/SettingsEscalation/CounselorSelect'
import { ICounselors } from 'store/escalation/selectors'
import { ActionMeta, ValueType } from 'react-select/lib/types'

export const EmailAutoSuggest = (
  props: Omit<
    AsyncAutoSuggestProps<ICounselorResponseData>,
    'loadOptions' | 'value' | 'name' | 'onChange'
  > & {
    value?: number | string
    name: string
    onChange: (value: ValueType<ICounselorOption>, meta: ActionMeta) => void
  }
) => {
  const [options, setOptions] = React.useState<ICounselors>([])

  React.useEffect(() => {
    getCounselors('', { reason__isnull: false }).then(response => {
      const mapped = response.data.map(x => ({
        value: x.id,
        id: x.id,
        collegeId: x.college_id,
        name: x.name,
        email: x.email,
        reason: x.reason,
      }))
      setOptions(mapped)
    })
  }, [setOptions])

  return (
    <CounselorSelect
      counselors={options}
      selectedCounselorId={props.value}
      onChange={props.onChange}
    />
  )
}
