import * as React from 'react'
import classnames from 'classnames'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'

interface ISideEffectNodeProps {
  className?: string
  children: React.ReactNode
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  onDelete?: () => void
  editable?: boolean
  deletable?: boolean
}

const SideEffectNode = ({
  editable,
  deletable,
  onDelete,
  className,
  children,
  onClick,
}: ISideEffectNodeProps) => {
  const handleDelete = onDelete

  return (
    <div
      className={classnames(
        'bg-blue-grey-050 border-blue-grey-030 d-flex flex-row node card py-2 px-3 hover-opacity-100-parent',
        { pointer: editable, 'hover-shadow-md': editable || deletable },
        className
      )}
      onClick={onClick}>
      <div className="body flex-grow-1">{children}</div>
      {editable && (
        <AHIcon
          name="edit"
          className={classnames(
            'mt-1 text-blue-grey-501 hover-opacity-100-child opacity-50'
          )}
        />
      )}
      {!editable && deletable && (
        <AHIcon
          name="delete"
          className="mt-1 pointer opacity-50 hover-opacity-100 hover-text-new-ui-danger"
          onClick={handleDelete}
        />
      )}
    </div>
  )
}

export default SideEffectNode
