import React from 'react'
import { Drawer } from 'components/MaterialComponents/MaterialComponents'
import classNames from 'classnames'

export function MainstayDrawer({
  children,
  onClose,
  header,
  open = true,
  showHr = true,
  panelContentRef,
  panelClassName,
  containerClassName,
}: {
  readonly children: React.ReactNode
  readonly onClose: () => void
  readonly header: React.ReactNode
  readonly open?: boolean
  readonly showHr?: boolean
  readonly panelContentRef?: React.RefObject<HTMLElement>
  readonly panelClassName?: string
  readonly containerClassName?: string
}) {
  return (
    <Drawer
      open={open}
      anchor="right"
      transitionDuration={500}
      disableEnforceFocus={true}
      onBackdropClick={onClose}>
      <div style={{ width: '600px' }} className="h-100">
        <Panel
          containerClassName={containerClassName}
          className={panelClassName}
          header={header}
          showHr={showHr}
          panelContentRef={panelContentRef}>
          {children}
        </Panel>
      </div>
    </Drawer>
  )
}

function Panel({
  header,
  children,
  showHr = true,
  panelContentRef,
  className,
  containerClassName,
}: {
  readonly header: React.ReactNode
  readonly children: React.ReactNode
  readonly showHr?: boolean
  readonly panelContentRef?: React.RefObject<HTMLElement>
  readonly className?: string
  readonly containerClassName?: string
}) {
  return (
    <aside className={classNames(containerClassName || 'panel h-100 py-4')}>
      {header}
      {showHr && <hr className="text-mainstay-dark-blue-20 m-0" />}
      <section
        ref={panelContentRef}
        className={classNames(
          'panel-content h-100 px-0 align-items-center',
          className
        )}>
        {children}
      </section>
    </aside>
  )
}
