import * as React from 'react'
import ClassNames from 'classnames'
import { CheckableInput } from 'components/CheckableInput/CheckableInput'
import { ICheckableItem } from 'components/CheckboxGroup/CheckboxGroup'
import 'components/CheckableInput/CheckableInput.scss'

interface IRadioGroupProps {
  className?: string
  items: ICheckableItem[]
  selectedValue: string
  onChange?: (event: React.FocusEvent<HTMLInputElement>) => void
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void
  inline?: boolean
}

export class RadioGroup extends React.PureComponent<IRadioGroupProps> {
  render() {
    const {
      items,
      selectedValue,
      className,
      inline,
      onClick,
      onChange,
    } = this.props
    const classNames = ClassNames(className, 'checkable-list-container')
    return (
      <div className={classNames}>
        {items.map(item => (
          <CheckableInput
            eventLocation={item?.trackingEvent?.location}
            eventAction={item?.trackingEvent?.action}
            eventObject={item?.trackingEvent?.object}
            type="radio"
            disabled={item.disabled}
            id={item.id}
            key={item.id}
            inline={inline}
            checked={item.value === selectedValue}
            value={item.value}
            onClick={onClick}
            onChange={onChange}>
            {item.label}
          </CheckableInput>
        ))}
      </div>
    )
  }
}
