import * as React from 'react'
import IdleTimer from 'react-idle-timer'
import { MAX_INACTIVE_SESSION_TIME } from 'const/settings'
import { AutoLogoutModal } from 'components/AutoLogoutModal/AutoLogoutModal'

export interface IAutoLogoutDispatchProps {
  logout: () => void
  pulse: () => void
}

export interface IAutoLogoutStateProps {
  isLoggedIn: boolean
}

export const AutoLogout = (
  props: IAutoLogoutDispatchProps & IAutoLogoutStateProps
) => {
  const idleTimer = React.createRef<IdleTimer>()

  const [idle, setIdle] = React.useState(false)

  React.useEffect(() => {
    setIdle(false)
  }, [props.isLoggedIn])

  return (
    <>
      {props.isLoggedIn && (
        <>
          {/*
           /* We begin a 5 minute timer after 85 minutes of inactivity. 
           /* Every 10 minutes of activity on a single route, 
           /* we check authentifcation and refresh our token.
           /* In backend/backend/settings.py, we set a token to expire
           /* after 100 minutes, accounting for the throttle and both timers */}
          <IdleTimer
            ref={idleTimer}
            element={document}
            timeout={MAX_INACTIVE_SESSION_TIME - 5}
            throttle={1000 * 60 * 10}
            onAction={props.pulse}
            onIdle={() => {
              setIdle(true)
            }}
          />
          <AutoLogoutModal
            show={idle}
            onClose={() => {
              setIdle(false)
            }}
            onLogout={() => {
              setIdle(false)
            }}
            updateLoginExpiration={props.pulse}
            logout={props.logout}
          />
        </>
      )}
    </>
  )
}
