import { connect } from 'react-redux'
import { RootState as IState, Dispatch } from 'store/store'
import { withRouter } from 'react-router'
import { KnowledgeSideBar } from 'page/knowledge-base/KnowledgeSideBar/KnowledgeSideBar'
import { IKnowledgeSeederPageRouteProps } from 'components/IRouteProps'
import {
  getCategoryCounts,
  getSelectedSeedGrouping,
  getTotalBlankAnswers,
  getTotalSeedsCount,
  getTotalApprovedSeedsCount,
  getTotalUnapprovedSeedsCount,
} from 'store/knowledgeSeeder/selectors'
import { getCountsOfInstAttributesByType } from 'store/personalization/institutionAttributes/selectors'
import { getInstitutionAttrsCountByTypeAsync } from 'store/personalization/institutionAttributes/thunks'

const mapStateToProps = (
  state: IState,
  ownProps: IKnowledgeSeederPageRouteProps
) => {
  return {
    ...ownProps,
    activeListItemName: getSelectedSeedGrouping(state, ownProps),
    topics: getCategoryCounts(state),
    totalSeedsCount: getTotalSeedsCount(state),
    totalApprovedSeedsCount: getTotalApprovedSeedsCount(state),
    totalUnapprovedSeedsCount: getTotalUnapprovedSeedsCount(state),
    totalBlankAnswers: getTotalBlankAnswers(state),
    countInstAttrsByType: getCountsOfInstAttributesByType(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchInstAttrsCountByType: getInstitutionAttrsCountByTypeAsync(dispatch),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(KnowledgeSideBar)
)
