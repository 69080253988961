import scssVariables from 'scss/_variables.scss'

export function UnderstandingQuestionIcon({
  small,
}: {
  readonly small?: boolean
}) {
  if (small) {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.4887 2.98874C16.4887 2.18024 15.8205 1.51199 15.012 1.51199H2.98874C2.18024 1.51199 1.51199 2.18024 1.51199 2.98874V16.4887L4.49999 13.5007H15.012C15.8205 13.5007 16.4887 12.7972 16.4887 11.9887V2.98874ZM11.3185 9.16223C11.4962 8.78454 11.5851 8.34338 11.5851 7.83874V7.59594C11.5819 7.05639 11.4708 6.58032 11.2518 6.16772C11.036 5.75195 10.7313 5.43298 10.3378 5.21081C9.94739 4.98864 9.50146 4.87756 9 4.87756C8.48901 4.87756 8.03674 4.99182 7.64319 5.22033C7.24963 5.44567 6.94653 5.76623 6.73389 6.182C6.52124 6.59777 6.41492 7.07861 6.41492 7.62451V7.81017C6.41492 8.66393 6.65295 9.33837 7.12903 9.83349C7.60828 10.3286 8.23352 10.5762 9.00476 10.5762C9.13806 10.5762 9.26978 10.5666 9.3999 10.5476L10.671 11.5236L11.528 10.7809L10.5948 10.081C10.9027 9.84619 11.1439 9.53991 11.3185 9.16223ZM9.89026 6.40576C10.1029 6.68823 10.2092 7.09289 10.2092 7.61975V7.82446C10.2092 8.35766 10.1013 8.76708 9.8855 9.05273C9.67285 9.33837 9.37927 9.4812 9.00476 9.4812C8.6239 9.4812 8.32556 9.33837 8.10974 9.05273C7.89709 8.76708 7.79077 8.36242 7.79077 7.83874V7.61975C7.79077 7.09289 7.89392 6.68823 8.10022 6.40576C8.30652 6.12329 8.60645 5.98205 9 5.98205C9.38086 5.98205 9.67761 6.12329 9.89026 6.40576Z"
          fill={scssVariables.mainstayDarkBlue65}
        />
      </svg>
    )
  }
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9851 3.98462C21.9851 2.90662 21.0941 2.01562 20.0161 2.01562H3.98511C2.90711 2.01562 2.01611 2.90662 2.01611 3.98462V21.9846L6.00011 18.0006H20.0161C21.0941 18.0006 21.9851 17.0626 21.9851 15.9846V3.98462ZM15.0914 12.2159C15.3284 11.7124 15.4469 11.1241 15.4469 10.4513V10.1276C15.4427 9.40815 15.2946 8.77339 15.0026 8.22326C14.7148 7.6689 14.3086 7.2436 13.7838 6.94738C13.2633 6.65116 12.6687 6.50304 12.0001 6.50304C11.3188 6.50304 10.7158 6.65539 10.191 6.96008C9.6663 7.26053 9.26217 7.68794 8.97864 8.2423C8.69511 8.79666 8.55334 9.43778 8.55334 10.1656V10.4132C8.55334 11.5515 8.87073 12.4508 9.50549 13.111C10.1445 13.7711 10.9781 14.1012 12.0065 14.1012C12.1842 14.1012 12.3598 14.0885 12.5333 14.0631L14.2281 15.3644L15.3707 14.3741L14.1266 13.441C14.5371 13.1279 14.8587 12.7195 15.0914 12.2159ZM13.1871 8.54064C13.4707 8.91727 13.6124 9.45682 13.6124 10.1593V10.4322C13.6124 11.1432 13.4685 11.6891 13.1808 12.0699C12.8973 12.4508 12.5058 12.6412 12.0065 12.6412C11.4987 12.6412 11.1009 12.4508 10.8131 12.0699C10.5296 11.6891 10.3878 11.1495 10.3878 10.4513V10.1593C10.3878 9.45682 10.5253 8.91727 10.8004 8.54064C11.0755 8.16401 11.4754 7.9757 12.0001 7.9757C12.5079 7.9757 12.9036 8.16401 13.1871 8.54064Z"
        fill={scssVariables.mainstayDarkBlue65}
      />
    </svg>
  )
}
