import * as React from 'react'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import 'components/CloseableImage/CloseableImage.scss'

export interface ICloseableImageProps {
  image: string
  onClose?: () => void
  visible: boolean
  className?: string
}

export const CloseableImage = (props: ICloseableImageProps) => {
  const { visible, onClose, image, className } = props
  const [showClose, toggleShowClose] = React.useState(false)
  const setCloseVisible = React.useCallback(() => {
    toggleShowClose(true)
  }, [])
  const setCloseHidden = React.useCallback(() => {
    toggleShowClose(false)
  }, [])
  return (
    <div className={className}>
      {visible && image && (
        <div
          className="m-1 closable-image h-auto"
          onMouseEnter={setCloseVisible}
          onMouseLeave={setCloseHidden}>
          <img className="w-100" src={props.image} />
          {showClose && onClose && <AHIcon name="cancel" onClick={onClose} />}
        </div>
      )}
    </div>
  )
}
