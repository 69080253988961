import React from 'react'
import { useSelector, useDispatch, useFeatures } from 'util/hooks'
import {
  commandsThunks,
  HashtagCommand,
  commandsSelectors,
} from 'store/commands/commands-store'
import { Route, RouteComponentProps } from 'react-router'
import NavBarPage from 'page/NavBarPage'
import { PanelDrawer } from 'components/Panel/Panel'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { push } from 'connected-react-router'
import { CreateButton, PageHeader } from 'components/PageHeader/PageHeader'
import { KnowledgeSideBar } from 'page/knowledge-base/KnowledgeSideBar/KnowledgeSideBar'
import {
  getTotalBlankAnswers,
  getTotalUnapprovedSeedsCount,
  getCategoryCounts,
  getTotalSeedsCount,
  getTotalApprovedSeedsCount,
} from 'store/knowledgeSeeder/selectors'
import { getCountsOfInstAttributesByType } from 'store/personalization/institutionAttributes/selectors'
import { Table } from 'reactstrap'
import { ToggleSwitchV2 } from 'components/ToggleSwitch/ToggleSwitch'
import { ButtonIconRound } from 'components/ButtonIconRound/ButtonIconRound'
import classnames from 'classnames'
import { getInstitutionAttrsCountByTypeAsync } from 'store/personalization/institutionAttributes/thunks'
import CommandCreateEditForm from 'page/knowledge-base/Commands/CommandCreateEditForm'
import { generateDialogReportAsync } from 'store/campaign-scripts/thunks'
import Loader from 'components/Loader/Loader'
import { uniqBy } from 'lodash'
import 'page/knowledge-base/Commands/CommandsIndexPage.scss'
import { TransportId } from 'store/transport'
import PermissionsGuard from 'util/permissions/PermissionsGuard'
import { PERMISSIONS } from 'util/permissions/permissions'
import { ConfirmationModal } from 'components/Modal/Modal'
import {
  CustomCampaignReportDownloadModal,
  IPreferencesRequestPayload,
  generateReportFileName,
} from 'components/TrendsV2/CustomizedDownloadModal'
import { getReportPreferences } from 'api'
import { FloatingTrashCan } from 'components/FloatingTrashCan/FloatingTrashCan'

const fetchPreferenceData = () =>
  getReportPreferences({ insight: 'hashtag_command' })

const DrawerHeader: React.FC<{
  onClose: () => void
  loading?: boolean
  id?: React.ReactText
}> = ({ onClose, children, id, loading = false }) => {
  const dispatch = useDispatch()
  const { hasFeature, FeaturesType } = useFeatures()
  const [showCustomDownloadModal, setShowCustomDownloadModal] = React.useState(
    false
  )
  const institution = useSelector(
    state => state.triage.application.profile.currentUser.institution
  )
  const channels: (TransportId | 'unknown')[] = institution.channels.map(x => {
    if (x === 'web_bot') {
      return 'web'
    }
    if (x === 'sms') {
      return 'twilio'
    }
    return x
  })
  const command = useSelector(state =>
    commandsSelectors.selectById(state, id || -1)
  )
  const isDownloading = useSelector(
    state =>
      !!command?.dialogs.some(
        x =>
          state.campaignScripts.introDialogs.reportDownloadProgressById[
            x.dialog_id
          ] !== undefined
      )
  )
  const handleDownload = (preferences?: IPreferencesRequestPayload) => {
    if (command) {
      uniqBy(
        command.dialogs.filter(x => channels.includes(x.transport)),
        dialog => dialog.dialog_id
      ).map(x => {
        generateDialogReportAsync(
          x.dialog_id,
          null,
          null,
          command.id,
          preferences,
          'hashtag_command',
          handleCloseModal
        )(dispatch)
      })
    }
  }

  const handleCloseModal = React.useCallback(
    () => setShowCustomDownloadModal(false),
    []
  )

  const handleClickDownload = () => {
    if (hasFeature(FeaturesType.MULTI_CHANNEL_CONTACTS_REPORTS)) {
      setShowCustomDownloadModal(true)
    } else {
      handleDownload()
    }
  }

  return (
    <>
      <div className="py-3 d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <AHIcon
            className="ml-4 mr-2 mb-1 d-flex align-items-center justify-content-center rounded ah-knowledge-seeder-icon"
            name="bot"
          />
          <h5 className="m-0">{children}</h5>
          <span className="ml-3">
            {loading && (
              <AHIcon name="cloud_upload" className="h2 text-warning" />
            )}
          </span>
        </div>

        <div className="d-flex align-items-center">
          <span className="download-link pointer pt-1 px-1">
            <PermissionsGuard permission={PERMISSIONS.REPORT.VIEW}>
              {!isDownloading ? (
                <AHIcon
                  className="pointer"
                  name="file_download"
                  onClick={handleClickDownload}
                />
              ) : (
                <Loader />
              )}
            </PermissionsGuard>
            <span className="tooltip bg-dark text-light rounded p-2 small">
              Download
            </span>
          </span>
          <div className="vertical-rule" />
          <AHIcon className="mr-3 pointer" name="close" onClick={onClose} />
        </div>
      </div>
      <hr className="text-blue-grey-200 m-0" />
      <CustomCampaignReportDownloadModal
        show={showCustomDownloadModal}
        initialFileName={generateReportFileName('hashtag_commands_report')}
        getPreferenceData={fetchPreferenceData}
        reportTitle="#Commands Report"
        onClose={handleCloseModal}
        onSubmit={handleDownload}
        submissionInProgress={isDownloading}
        isAggregate={false}
      />
    </>
  )
}

const CommandsDrawer: React.FC<{
  onClose: () => void
  id?: React.ReactText
  title: React.ReactElement | string
  loading?: boolean
}> = ({ onClose, children, id, title, loading = false }) => {
  return (
    <PanelDrawer
      className="z-index-100"
      onClose={onClose}
      header={
        <DrawerHeader id={id} loading={loading} onClose={onClose}>
          {title}
        </DrawerHeader>
      }>
      {children}
    </PanelDrawer>
  )
}

const CommandsList = ({
  commands,
  onDelete,
  onEdit,
  onToggle,
}: {
  commands: HashtagCommand[]
  onDelete: (id: number) => void
  onEdit: (id: number) => void
  onToggle: (id: number, enabled: boolean) => void
}) => {
  const headerClassnames =
    'border-top-0 border-dark pb-2 pt-1 pr-2 m-0 font-weight-normal'
  return (
    <div className="w-100">
      <div className="table-responsive">
        <Table className="border-bottom-0 position-relative">
          <thead>
            <tr className="position-relative">
              <th className={headerClassnames}>
                <small>#Command Word</small>
              </th>
              <th className={headerClassnames}>
                <small>Description</small>
              </th>
              <th className={headerClassnames}>
                <small>Enabled</small>
              </th>
              <th className={headerClassnames} />
            </tr>
          </thead>
          <tbody>
            {commands.map(x => {
              const cns = classnames('py-3', {})
              return (
                <tr key={x.id}>
                  <td className={cns}>
                    <h4>{x.words[0].command_text}</h4>
                  </td>
                  <td className={cns}>
                    <div className="mw-50">{x.description}</div>
                  </td>
                  <td>
                    <div className="d-flex flex-column justify-content-around pt-3">
                      {!!x.institution ? (
                        <ToggleSwitchV2
                          size="sm"
                          onChange={checked => {
                            onToggle(x.id, checked)
                          }}
                          checked={!!x.enabled}
                        />
                      ) : (
                        <small className="text-muted">required</small>
                      )}
                    </div>
                  </td>
                  <td className={cns}>
                    {!!x.institution && (
                      <div className="d-flex flex-row">
                        <div className="ml-5 d-flex flex-row justify-content-start line-height-0 h-100">
                          <ButtonIconRound
                            icon="edit"
                            onClick={() => onEdit(x.id)}
                            tooltipText="Edit"
                          />
                          <FloatingTrashCan
                            isAbsolute={false}
                            onClick={() => onDelete(x.id)}
                          />
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

const CommandsIndexPage = () => {
  const loadingState = useSelector(state => state.commands.listing)
  const updatingState = useSelector(state => state.commands.updating)

  const commands = useSelector(commandsSelectors.selectAll)
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (loadingState === 'idle') {
      dispatch(commandsThunks.listCommands())
    }
  }, [dispatch, loadingState])
  const handleClose = React.useCallback(() => {
    dispatch(push('/commands/'))
  }, [dispatch])
  const handleDelete = React.useCallback(
    (id: number) =>
      dispatch(commandsThunks.deleteCommand({ id })).then(() => {
        dispatch(push('/commands/'))
      }),
    [dispatch]
  )
  const handleToggle = React.useCallback(
    (id: number, enabled: boolean) => {
      dispatch(
        commandsThunks.partialUpdate({
          id,
          enabled,
        })
      )
    },
    [dispatch]
  )
  const handleEdit = React.useCallback(
    (id: number) => {
      dispatch(push(`/commands/${id}/edit`))
    },
    [dispatch]
  )
  const handleCloseModal = () => dispatch(push(`/commands/`))

  const {
    countInstAttrsByType,
    topics,
    totalSeedsCount,
    totalApprovedSeedsCount,
    totalBlankAnswers,
    totalUnapprovedSeedsCount,
  } = useSelector(state => ({
    countInstAttrsByType: getCountsOfInstAttributesByType(state),
    topics: getCategoryCounts(state),
    totalSeedsCount: getTotalSeedsCount(state),
    totalApprovedSeedsCount: getTotalApprovedSeedsCount(state),
    totalUnapprovedSeedsCount: getTotalUnapprovedSeedsCount(state),
    totalBlankAnswers: getTotalBlankAnswers(state),
  }))
  const fetchInstAttrCounts = React.useCallback(
    () => getInstitutionAttrsCountByTypeAsync(dispatch)(),
    [dispatch]
  )

  return (
    <NavBarPage
      title="Knowledge Base"
      className="d-flex placeholder-mobile-panels flex-grow-1 min-height-0"
      pageMainClassName="page-knowledgebase"
      loading={loadingState === 'pending'}>
      <KnowledgeSideBar
        countInstAttrsByType={countInstAttrsByType}
        fetchInstAttrsCountByType={fetchInstAttrCounts}
        topics={topics}
        totalSeedsCount={totalSeedsCount}
        totalApprovedSeedsCount={totalApprovedSeedsCount}
        totalUnapprovedSeedsCount={totalUnapprovedSeedsCount}
        totalBlankAnswers={totalBlankAnswers}
        pageContent={
          <div className="pb-5">
            <div className="d-flex flex-column">
              <PageHeader
                title="#Commands"
                description="Create a #Command to advertise on social media and flyers. Give quick
          access to important scripts with a single word.">
                <CreateButton
                  title="New Command"
                  onClick={() => dispatch(push('/Commands/create'))}
                />
              </PageHeader>
              <div className=" scroll-y flex-grow-1">
                <CommandsList
                  commands={commands}
                  onDelete={id => dispatch(push(`/commands/${id}/delete`))}
                  onEdit={handleEdit}
                  onToggle={handleToggle}
                />
              </div>
            </div>
            <Route
              path="/commands/:id/edit"
              render={(props: RouteComponentProps<{ id: string }>) => (
                <CommandsDrawer
                  title="#Command"
                  id={props.match.params.id}
                  loading={updatingState === 'pending'}
                  onClose={handleClose}>
                  <CommandCreateEditForm id={props.match.params.id} />
                </CommandsDrawer>
              )}
            />
            <Route
              path="/commands/:id/delete"
              render={(props: RouteComponentProps<{ id: number }>) => (
                <ConfirmationModal
                  title="Delete command?"
                  helpText="Are you sure you want to delete this command?"
                  onClose={handleCloseModal}
                  show={true}
                  confirmButtonText="Confirm"
                  onConfirm={() => {
                    handleDelete(props.match.params.id)
                  }}
                  hideCheckbox
                />
              )}
            />
            <Route
              path="/commands/create"
              render={() => (
                <CommandsDrawer title="Command" onClose={handleClose}>
                  <CommandCreateEditForm />
                </CommandsDrawer>
              )}
            />
          </div>
        }
      />
    </NavBarPage>
  )
}

export default CommandsIndexPage
