import * as React from 'react'
import { FontAwesomeIcon } from 'components/Icons/FontAwesomeIcon/FontAwesomeIcon'

import { TextInput } from 'components/TextInput/TextInput'
import { Select } from 'components/Select/Select'
import { Button } from 'components/Button/Button'
import { Form, Row, Col } from 'reactstrap'

interface IPauseTimeMenu {
  onSubmit: (value: number) => void
}

const convertToSeconds = (value: number, unit: string) => {
  switch (unit) {
    case 'm':
      return value * 60
    case 'h':
      return value * 60 * 60
    default:
      return value
  }
}

const PauseTimeMenu: React.FC<IPauseTimeMenu> = props => {
  const [value, setValue] = React.useState(16)
  const [unit, setUnit] = React.useState('s')
  return (
    <div className="border rounded bg-white">
      <div className="inner p-4">
        <h5>
          <FontAwesomeIcon icon="stopwatch" /> Wait to send the next message for
          at least...
        </h5>
        <Form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault()
            props.onSubmit(convertToSeconds(value, unit))
          }}>
          <Row className="mt-3">
            <Col xs={6}>
              <TextInput
                type="number"
                value={value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setValue(parseInt(e.target.value, 10))
                }
              />
            </Col>
            <Col xs={6}>
              <Select
                value={unit}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setUnit(e.target.value)
                }>
                <option value="s">Seconds</option>
                <option value="m">Minutes</option>
                <option value="h">Hours</option>
              </Select>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Button type="submit" color="primary">
                SET TIMING
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}

export default PauseTimeMenu
