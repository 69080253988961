import React, { useState } from 'react'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import classnames from 'classnames'
import 'components/UnderstandingApprovalMenu/UnderstandingApprovalMenu.scss'
import Dropdown from 'reactstrap/lib/Dropdown'
import DropdownToggle from 'reactstrap/lib/DropdownToggle'
import DropdownMenu from 'reactstrap/lib/DropdownMenu'
import DropdownItem from 'reactstrap/lib/DropdownItem'
import { PERMISSIONS } from 'util/permissions/permissions'
import PermissionsGuard from 'util/permissions/PermissionsGuard'

export const enum AnswerApprovalStatus {
  Approved = 'Approved',
  NotApproved = 'NotApproved',
  NoAnswer = 'NoAnswer',
}

export function UnderstandingApprovalMenu({
  approvedStatus,
  wide,
  className,
}: {
  readonly approvedStatus: AnswerApprovalStatus
  readonly wide?: boolean
  readonly className?: string
}) {
  return (
    <div
      className={classnames(
        'understanding-approval-menu-component',
        {
          wide,
        },
        className
      )}>
      <div className="menu-button d-flex-inline align-items-center justify-content-space-between border text-nowrap">
        {approvedStatus === AnswerApprovalStatus.Approved ? (
          <>
            <span className="dot approved" /> Approved
          </>
        ) : approvedStatus === AnswerApprovalStatus.NotApproved ? (
          <>
            <span className="dot not-approved" /> Not Approved
          </>
        ) : approvedStatus === AnswerApprovalStatus.NoAnswer ? (
          <>
            <span className="dot no-answer" /> No Answer
          </>
        ) : null}
      </div>
    </div>
  )
}

interface IDotProps {
  readonly variant: 'muted' | 'approved' | 'not-approved'
}
function Dot({ variant }: IDotProps) {
  if (variant === 'approved') {
    return <span className="dotv2 approved" />
  }
  if (variant === 'not-approved') {
    return <span className="dotv2 not-approved" />
  }
  // default to muted
  return <span className="dotv2 bg-muted" />
}

interface IUnderstandingApprovalMenuPropsV2 {
  approved: boolean
  onApprove: () => void
  onUnapprove: () => void
  loading: boolean
}
export function UnderstandingApprovalMenuV2({
  approved,
  onApprove,
  onUnapprove,
  loading,
}: IUnderstandingApprovalMenuPropsV2) {
  const [showDropdown, setDropdown] = useState(false)
  function handleApprove() {
    onApprove()
  }
  function handleUnApprove() {
    onUnapprove()
  }
  const toggle = () => setDropdown(s => !s)
  return (
    <div className="understanding-approval-menu-component">
      <PermissionsGuard permission={PERMISSIONS.UNDERSTANDING.APPROVE}>
        <Dropdown
          isOpen={showDropdown}
          toggle={toggle}
          onClick={(e: React.MouseEvent<HTMLElement>): void => {
            e.stopPropagation()
          }}>
          <DropdownToggle
            tabIndex={0}
            tag="div"
            data-toggle="dropdown"
            className="menu-buttonv2 mr-3 d-flex align-items-center pointer border text-nowrap bg-white z-index-10">
            {loading ? (
              <>
                <Dot variant="muted" />{' '}
                <span className="caption">Loading...</span>
                <AHIcon name="arrow_drop_down" />
              </>
            ) : approved ? (
              <>
                <Dot variant="approved" />{' '}
                <span className="caption">Approved</span>
                <AHIcon name="arrow_drop_down" />
              </>
            ) : (
              <>
                <Dot variant="not-approved" />
                <span className="caption">Not Approved</span>
                <AHIcon name="arrow_drop_down" />
              </>
            )}
          </DropdownToggle>
          <DropdownMenu className="shadow-sm">
            <DropdownItem
              tabIndex={0}
              active={!approved}
              onClick={handleUnApprove}>
              Not Approved
            </DropdownItem>
            <DropdownItem
              tabIndex={0}
              active={approved}
              onClick={handleApprove}>
              Approved
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </PermissionsGuard>
    </div>
  )
}
